/* eslint-disable react/prop-types */
// import IconContext from '@ant-design/icons/lib/components/Context';
// import { FormProvider as RcFormProvider } from 'rc-field-form';
// import { ValidateMessages } from 'rc-field-form/lib/interface';
import useMemo from 'rc-util/lib/hooks/useMemo';
import * as React from 'react';
import ReactDOM from 'react-dom'
import {prefix} from "../../wui-core/src/index"
import LocaleProvider, {ANT_MARK} from '../../wui-locale/src/locale-provider';
import LocaleReceiver from '../../wui-locale/src/locale-receiver';
// import message from '../../wui-message/src';
// import notification from '../../wui-notification/src';
import {ConfigConsumer, ConfigContext} from './context';
import SizeContext, {SizeContextProvider} from './SizeContext';
// import PropTypes from 'prop-types';
import { registerTheme, updateTheme, getTheme2Style } from './cssVariables';
import type {ProviderChildrenProps, ConfigProviderProps} from './iProvider'
import { Locale } from '../../wui-locale/src/iLocale';
export {
    ConfigContext,
    ConfigConsumer,
    // CSPConfig,
    // DirectionType,
    // ConfigConsumerProps,
};

// export const configConsumerProps = [
//     'getTargetContainer',
//     'getPopupContainer',
//     'rootPrefixCls',
//     'getPrefixCls',
//     'renderEmpty',
//     'csp',
//     'autoInsertSpaceInButton',
//     'locale',
//     'pageHeader',
// ];

// These props is used by `useContext` directly in sub component
// const PASSED_PROPS = [
//     'getTargetContainer',
//     'getPopupContainer',
//     'renderEmpty',
//     'pageHeader',
//     'input',
//     'form',
// ];

// export interface ConfigProviderProps {
//   getTargetContainer?: () => HTMLElement;
//   getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
//   prefixCls?: string;
//   iconPrefixCls?: string;
//   children?: React.ReactNode;
//   renderEmpty?: RenderEmptyHandler;
//   csp?: CSPConfig;
//   autoInsertSpaceInButton?: boolean;
//   form?: {
//     validateMessages?: ValidateMessages;
//     requiredMark?: RequiredMark;
//   };
//   input?: {
//     autoComplete?: string;
//   };
//   locale?: Locale;
//   pageHeader?: {
//     ghost: boolean;
//   };
//   componentSize?: SizeType;
//   direction?: DirectionType;
//   space?: {
//     size?: SizeType | number;
//   };
//   virtual?: boolean;
//   dropdownMatchSelectWidth?: boolean;
// }

// interface ProviderChildrenProps extends ConfigProviderProps {
//   parentContext: ConfigConsumerProps;
//   legacyLocale: Locale;
// }

export const defaultPrefixCls = `${prefix}`;
let globalPrefixCls: string;
let globalLocale: Locale;

const setGlobalConfig = (params: ConfigProviderProps) => {
    if (params.prefixCls !== undefined) {
        globalPrefixCls = params.prefixCls;
    }
    if (params.locale !== undefined) {
        globalLocale = params.locale as Locale;
    }
};

function getGlobalPrefixCls() {
    return globalPrefixCls || defaultPrefixCls;
}

function filterProps(config: ConfigProviderProps, props: ConfigProviderProps, obj: {[key: string]: string | string[]} = {antd: 'antd', size: ['componentSize', 'size'], locale: 'locale'}): any {
    for (let k in obj) {
        if (Array.isArray(obj[k])) {
            let hasAttr = (obj[k] as string[]).find((k: string) => k in props);
            if (!hasAttr) {
                delete config[k]
            }
        } else {
            if (!((obj[k] as string) in props) && (k in config)) {
                delete config[k]
            }
        }
    }
    return config;
}

export const globalConfig = () => ({
    getPrefixCls: (suffixCls: string, customizePrefixCls?: string) => {
        if (customizePrefixCls) return customizePrefixCls;
        return suffixCls ? `${getGlobalPrefixCls()}-${suffixCls}` : getGlobalPrefixCls();
    },
    getRootPrefixCls: (rootPrefixCls: string, customizePrefixCls?: string) => {
        // Customize rootPrefixCls is first priority
        if (rootPrefixCls) {
            return rootPrefixCls;
        }

        // If Global prefixCls provided, use this
        if (globalPrefixCls) {
            return globalPrefixCls;
        }

        // [Legacy] If customize prefixCls provided, we cut it to get the prefixCls
        if (customizePrefixCls && customizePrefixCls.includes('-')) {
            return customizePrefixCls.replace(/^(.*)-[^-]*$/, '$1');
        }

        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    },
    getGlobalLocale: () => globalLocale
});

const ProviderChildren: React.FC<ProviderChildrenProps> = props => {
    const {
        children,
        // csp,
        // autoInsertSpaceInButton,
        // form,
        locale,
        componentSize,
        // direction,
        // space,
        // virtual,
        // dropdownMatchSelectWidth,
        legacyLocale,
        parentContext,
        // iconPrefixCls,
        antd,
        ...others
    } = props;

    // const getPrefixCls = React.useCallback(
    //     (suffixCls, customizePrefixCls) => {
    //         const {prefixCls} = props;
    //
    //         if (customizePrefixCls) return customizePrefixCls;
    //
    //         const mergedPrefixCls = prefixCls || parentContext.getPrefixCls('');
    //
    //         return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
    //     },
    //     [parentContext.getPrefixCls, props.prefixCls],
    // );

    let config = {
        ...parentContext,
        provider: {...others}, // 每个组件定制属性
        locale: locale || legacyLocale,
        size: componentSize || parentContext.size,
        antd: antd || parentContext.antd,
        // csp,
        // autoInsertSpaceInButton,
        // direction,
        // space,
        // virtual,
        // dropdownMatchSelectWidth,
        // getPrefixCls,
    };
    config = filterProps(config, props); // provider没有提供属性，默认不设置;
    // Pass the props used by `useContext` directly with child component.
    // These props should merged into `config`.
    // PASSED_PROPS.forEach(propName => {
    //     const propValue = props[propName];
    //     if (propValue) {
    //         config[propName] = propValue;
    //     }
    // });

    const memoedConfig = useMemo(
        () => config,
        config,
        (prevConfig: Record<string, any>, currentConfig: Record<string, any>) => {
            const prevKeys = Object.keys(prevConfig);
            const currentKeys = Object.keys(currentConfig);
            return (
                prevKeys.length !== currentKeys.length ||
				prevKeys.some(key => prevConfig[key] !== currentConfig[key])
            );
        },
    );

    // const memoIconContextValue = React.useMemo(() => ({prefixCls: iconPrefixCls, csp}), [
    //     iconPrefixCls,
    // ]);

    let childNode = children;
    // Additional Form provider
    // let validateMessages = {};
    //
    // if (locale && locale.Form && locale.Form.defaultValidateMessages) {
    //     validateMessages = locale.Form.defaultValidateMessages;
    // }
    // if (form && form.validateMessages) {
    //     validateMessages = {...validateMessages, ...form.validateMessages};
    // }
    //
    // if (Object.keys(validateMessages).length > 0) {
    //     childNode = <RcFormProvider validateMessages={validateMessages}>{children}</RcFormProvider>;
    // }

    if (locale) {
        childNode = (
            <LocaleProvider locale={locale as Locale} _ANT_MARK__={ANT_MARK}>
                {childNode}
            </LocaleProvider>
        );
    }

    // if (iconPrefixCls) {
    // childNode = (
    // <IconContext.Provider value={memoIconContextValue}>{childNode}</IconContext.Provider>
    // );
    // }

    if (componentSize) {
        childNode = <SizeContextProvider size={componentSize}>{childNode}</SizeContextProvider>;
    }

    // console.log('memoedConfig',memoedConfig)
    return <ConfigContext.Provider value={memoedConfig}>{childNode}</ConfigContext.Provider>;
};
/* ProviderChildren.propTypes = {
    antd: PropTypes.bool,
    locale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    legacyLocale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    componentSize: PropTypes.string,
    parentContext: PropTypes.object
} */

const ConfigProvider: React.FC<ConfigProviderProps> & {
    ConfigContext: typeof ConfigContext;
    SizeContext: typeof SizeContext;
    config: typeof setGlobalConfig;
    registerTheme: typeof registerTheme;
  } = props => {
      // React.useEffect(() => {
      //     if (props.direction) {
      //         message.config({
      //             rtl: props.direction === 'rtl',
      //         });
      //         notification.config({
      //             rtl: props.direction === 'rtl',
      //         });
      //     }
      // }, [props.direction]);
      const ref = React.useRef<any>({});

      const getContainerDOM = (container: any) => {
          if (!container) return;
          let containerDom;
          if (typeof container === 'function') {
              containerDom = container();
          } else {
              containerDom = ReactDOM.findDOMNode(container as any)
          }
          return containerDom
      }

      React.useEffect(() => {
          try {
              if (props.theme) {
                  const {node, nodeStyle} = ref.current;
                  if (node && nodeStyle) {
                      for (let k in nodeStyle) {
                          node.style.removeProperty(k)
                      }
                      ref.current.node = null;
                      ref.current.nodeStyle = null;
                  }
                  const partDom = getContainerDOM(props.getThemeContainer);
                  if (partDom) {
                      const style = getTheme2Style(props.theme);

                      for (let k in style) {
                          partDom.style.setProperty(k, style[k])
                      }

                      ref.current.node = partDom;
                      ref.current.nodeStyle = style;
                  }
                  updateTheme(props.theme, !!partDom);
              }
          } catch (e) {
              console.error(e);
          }
      }, [props.theme, props.getThemeContainer]);
      return (
          // @ts-ignore LocaleReceiver
          <LocaleReceiver>
              {(_: any, __: any, legacyLocale: any) => (
                  <ConfigConsumer>
                      {context => (
                          <ProviderChildren
                              parentContext={context}
                              legacyLocale={legacyLocale}
                              {...props}
                          />
                      )}
                  </ConfigConsumer>
              )}
          </LocaleReceiver>
      );
  };

/** @private internal Usage. do not use in your production */
ConfigProvider.ConfigContext = ConfigContext;
ConfigProvider.SizeContext = SizeContext;
ConfigProvider.config = setGlobalConfig;
ConfigProvider.registerTheme = registerTheme;

export default ConfigProvider;
