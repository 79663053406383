/**
 * Refactor by liuxiangyang on 2021/9/7.
 */
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import moment from 'moment'
import omit from 'omit.js'
// import PropTypes from 'prop-types'
import RcPicker from 'rc-picker'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import {cssUtil, KeyCode, prefix, Warning, WebUI} from '../../wui-core/src'
import Icon from '../../wui-icon/src'
import {autoFormat, deleteFormat, getDefaultFormat} from './_utils/autoFix'
import {getHalfYear, halfYearFormat, getHalfYearArr} from './_utils/halfYearUtils'
import {getLangInfo} from './_utils/lang'
import {BUILT_IN_PLACEMENTS} from './_utils/placement'
import type {Locale, PanelMode} from 'rc-picker/lib/interface'
import type {Moment} from 'moment'
import type {DatePickerProps, DatePickerState} from './iPicker'

const {isShouldUpdate /* 废弃API调用时提示更新 */} = Warning

export const _PREFIX_CLS = `${prefix}-picker`
const pickers = ['time', 'date', 'week', 'month', 'year']

// 需包装处理的 props
const _handleProps: (keyof DatePickerProps)[] = [
    'antd',
    'autoFix',
    'clsPrefix',
    'getPopupContainer',
    'locale',
    'open',
    'disabledDateValid',
    'defaultOpen',
    'defaultPanelShown',
    'defaultValue',
    'dropdownAlign',
    'dropdownClassName',
    'popupClassName',
    'placement',
    'showTime',
    'showHour',
    'showMinute',
    'showSecond',
    'enterKeyDown',
    'onOpenChange',
    'onKeyDown',
    'outInputKeydown',
    'onBlur',
    'onDateInputBlur',
    'onFocus',
    'onClick',
    'outInputFocus',
    'onChange',
    'onOk',
    'onSelect',
    'dateRender',
    'dateCellRender',
    'monthCellRender',
    'monthCellContentRender',
    'clearIcon',
    'closeIcon',
    'suffixIcon',
    'iconClick'
]

const defaultProps = {
    // locale: 'zh-cn',
    transitionName: 'slide-up',
    clearIcon: <Icon type='uf-close-c' />,
    suffixIcon: <Icon type='uf-calendar' />,
    allowClear: true,
    autoFix: true,
    enterKeyDown: true,
    validatorFunc: () => true,
    isHeaderSelect: true
}

@WebUI({name: 'datepicker', defaultProps})
class DatePicker extends Component<DatePickerProps, DatePickerState> {
    // pickerRef: React.MutableRefObject<PickerRefConfig>;
    pickerRef = React.createRef<HTMLDivElement>()
    yearUl?: HTMLDivElement | null
    monthUl?: HTMLDivElement | null
    constructor(props: DatePickerProps) {
        super(props)

        this.state = {
            lang: getLangInfo(props.locale),
            open: this.initOpen(props),
            value: this.initValue(props),
            format: this.generateFormat(props.format as string),
            initFormat: this.generateFormat(props.format as string),
            dropdownAlign: this.getPlacement(),
            showTime: this.getShowTimeOptions(props),
            clearIcon: this.generateClearIcon(),
            suffixIcon: this.generateSuffixIcon(props),
            _isFix: this.initFix(),
            _forbidOpen: false,
            diffValue: [0, 0],
            selectYear: 0,
            selectMonth: 0,
            // menuValue: '',
            maskShow: false,
            activeYearNum: 0,
            activeMonthNum: 0
        }
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        const pickerRef = findDOMNode(this.pickerRef.current) as HTMLDivElement
        const input = pickerRef?.getElementsByTagName('input')[0]
        input.addEventListener('input', this.handleInputFormat)
        // <--------- 仅用于自动化测试 start ------->
        const {fieldid} = this.props
        fieldid !== undefined && input.setAttribute('fieldid', `${fieldid}_input`)
        // <--------- 仅用于自动化测试 end ------->
        this.initSelectValue()
    }

    componentWillReceiveProps(nextProps: DatePickerProps) {
        if ('value' in nextProps) {
            // QDJCJS-7554 不能增加新值原值不同判断，有业务场景会判断后传入相同的值
            this.setState({
                value: this.initValue(nextProps)
            })
        }
        if ('open' in nextProps) {
            this.setState({
                open: nextProps.open
            })
        }
        if ('format' in nextProps) {
            this.setState({
                format: this.generateFormat(nextProps.format as string),
                initFormat: this.generateFormat(nextProps.format as string)
            })
        }
        if (
            'showTime' in nextProps ||
            'showHour' in nextProps ||
            'showMinute' in nextProps ||
            'showSecond' in nextProps
        ) {
            this.setState({
                showTime: this.getShowTimeOptions(nextProps)
            })
        }
        if ('suffixIcon' in nextProps || 'renderIcon' in nextProps) {
            this.setState({
                suffixIcon: this.generateSuffixIcon(nextProps)
            })
        }
        if ('locale' in nextProps) {
            let lang = getLangInfo(nextProps.locale) // 获取语言包信息
            this.setState({lang})
        }
    }

    componentWillUnmount() {
        // eslint-disable-next-line react/no-find-dom-node
        const input = (findDOMNode(this.pickerRef.current) as HTMLDivElement)?.getElementsByTagName('input')[0]
        input.removeEventListener('input', this.handleInputFormat)
    }

    initOpen = (props: DatePickerProps) => {
        const {open, defaultOpen, defaultPanelShown} = props
        return open ?? defaultOpen ?? defaultPanelShown
    }

    initSelectValue = () => {
        // 初始化下拉默认值
        let {value, defaultValue, picker, disabledDateValid, disabledDate, isHeaderSelect} = this.props
        if (!isHeaderSelect) false
        let selectYear = 0
        let selectMonth = 0
        let dateValue = value || defaultValue
        if (dateValue) {
            if (picker === 'week') {
                let formatCopy = 'gggg-ww'
                if (moment(dateValue, formatCopy).isValid()) {
                    let newValue = moment(dateValue, formatCopy)
                    selectYear = moment(newValue).year()
                    selectMonth = moment(newValue).month() + 1
                    // }
                } else {
                    console.error('value is not in the correct format')
                    // returnVal = ''
                }
            } else {
                if (moment(dateValue).isValid()) {
                    // returnVal = moment(value || defaultValue).year() + '年 ' + (moment(value || defaultValue).month() + 1) + '月'
                    selectYear = moment(dateValue).year()
                    selectMonth = moment(dateValue).month() + 1
                } else {
                    console.error('value is not in the correct format')
                }
            }
            // }
        } else {
            // returnVal = new Date().getFullYear() + '年 ' + (new Date().getMonth() + 1) + '月'
            selectYear = new Date().getFullYear()
            selectMonth = new Date().getMonth() + 1
        }

        if (!disabledDateValid && dateValue) {
            let isDisableDate = disabledDate?.(moment(dateValue))
            if (isDisableDate) {
                selectYear = new Date().getFullYear()
                selectMonth = new Date().getMonth() + 1
            }
        }

        this.setState({
            selectYear: selectYear,
            selectMonth: selectMonth
            // menuValue: returnVal,
        })
    }

    initValue = ({
        value,
        showTime,
        picker,
        locale,
        format: customFormat,
        disabledDateValid = true,
        disabledDate,
        onChange
    }: DatePickerProps) => {
        const format = this.generateFormat(customFormat as string)
        if (value) {
            if (typeof value === 'string') {
                if (moment(value, format).isValid()) {
                    if (!showTime && (!picker || picker === 'date')) {
                        value = moment(moment(value, format).format(format), format).startOf('date')
                    } else if (picker === 'halfYear') {
                        value = getHalfYear(value, getLangInfo(locale)).value
                    } else {
                        value = moment(moment(value, format).format(format), format)
                    }
                } else {
                    console.error('value is not in the correct format')
                    value = null
                }
            } else if (value?.isValid?.()) {
                // nothing to do
                value = this._getFormatStartDate({showTime, picker, value})
            } else {
                console.error('value is not in the correct format')
                value = null
            }
        }

        if (!disabledDateValid && value) {
            let isDisableDate = disabledDate?.(value as Moment)
            if (isDisableDate) {
                value = null
                onChange?.(value, '')
                return value
            }
        }

        // 确保value的moment实例多语版本一致
        if (value && typeof value !== 'string' /*  && value?.locale */) {
            let langInfo = getLangInfo(locale) // 获取语言包信息
            moment.locale(langInfo.lang)
            value = moment(value, format)
        }
        return value
    }

    initFix = () => {
        const {autoFix = defaultProps.autoFix, picker, format: customFormat} = this.props
        let format = this.generateFormat(customFormat as string)
        if (
            // 用户提供格式非年、月、日顺序时，由于其存在依赖关系，无法自动修正
            format &&
            ((format.search(/Y/gi) !== -1 &&
                format.search(/M/gi) !== -1 &&
                format.search(/Y/gi) >= format.search(/M/gi)) ||
                (format.search(/Y/gi) !== -1 &&
                    format.search(/D/gi) !== -1 &&
                    format.search(/Y/gi) >= format.search(/D/gi)) ||
                (format.search(/M/gi) !== -1 &&
                    format.search(/D/gi) !== -1 &&
                    format.search(/M/gi) >= format.search(/D/gi)) ||
                (format.search(/Y/gi) !== -1 &&
                    format.search(/W/gi) !== -1 &&
                    format.search(/Y/gi) >= format.search(/W/gi)))
        ) {
            return false
        }
        return autoFix && (!picker || ['date', 'month', 'year', 'week'].includes(picker))
    }

    generateFormat = (customFormat?: string | string[]) => {
        const {format, picker = '', showTime} = this.props
        // 数组format来源于用户设置的首选项，其中第0项是用户选中值，故format强制取[0]，无需根据list顺序匹配
        customFormat = Array.isArray(customFormat) ? customFormat[0] : customFormat // 兼容string、array类型format
        let mergeFormat = Array.isArray(format) ? format[0] : format // 兼容string、array类型format
        if (picker === 'week' && (customFormat || mergeFormat)) {
            /**
             * YYYY 格式会导致1月1号等日期所在周年错误，需替换为ISO标准的 g 周年；
             * 如：2023-01-01 在YYYY-ww 格式下错误输出 2023-52，
             *                 gggg-ww 格式下正确输出 2023-01
             */
            customFormat = customFormat?.replace(/y/gi, 'g')
            mergeFormat = mergeFormat?.replace(/y/gi, 'g')
        }
        return (
            (customFormat ||
                mergeFormat ||
                getDefaultFormat(pickers.includes(picker) ? picker : 'date', showTime, this.props)) as string
        ).replace(/W/g, 'w')
    }

    /**
     * 格式化Input输入内容
     */
    handleInputFormat = (e: any) => {
        const {onChange, onOpenChange, picker, showTime} = this.props
        let format = this.state.initFormat
        // * 开启自动校正
        if (this.state._isFix) {
            let value = e.target.value,
                isDeleteChanged = false
            if (/^\d{5}$/.test(value)) {
                // 处理删除尾部补足的分隔符-后再输入数字时只填充分隔符-而没数字问题
                value = value.slice(0, 4) + '-' + value.slice(4)
                isDeleteChanged = true
            } else if (value.search(/\d{5,}/) > -1) {
                // 处理日期中间删除问题
                value =
                    e.target.value.slice(0, Math.max(0, e.target.selectionStart - 1)) +
                    e.target.value.slice(e.target.selectionStart)
                isDeleteChanged = true
            }

            let formatDate = autoFormat({value, format, showTime, locale: this.state.lang.lang})
            if (e.inputType === 'deleteContentBackward') {
                if (value.length === 0) {
                    // 删除到空时需触发onChange，并关闭面板触发onOpenChange
                    this.setState({
                        open: false,
                        value: null,
                        format
                    })
                    onChange?.(null, '')
                    onOpenChange?.(false, null, '')
                    return
                }
                // 删除操作尾部分隔符处理
                formatDate = Object.assign(formatDate, this.deleteTailSeperator(formatDate.value, formatDate.format))
            }

            const valueMoment = moment(formatDate.value, format)
            if (formatDate.isChanged || isDeleteChanged) {
                if (/\D0$/g.test(formatDate.value)) {
                    /* 尾部的-0特别处理，format替换为-0 */
                    let tempFormat = formatDate.format.slice(0, -2) + formatDate.value.slice(-2)
                    this.setState({
                        value: formatDate.value ? moment(formatDate.value, tempFormat) : null,
                        format: tempFormat
                    })
                } else if (!/[^S]+/g.test(formatDate.format)) {
                    /* 只有年的特别处理，Y被转S替换，禁止用户非数字输入 */
                    this.setState({
                        value: formatDate.value
                            ? moment(formatDate.value.replace(/\D+/g, ''), formatDate.format)
                            : null,
                        format: formatDate.format
                    })
                } else {
                    this.setState({
                        value: formatDate.value ? moment(formatDate.value, formatDate.format) : null,
                        format: formatDate.format
                    })
                }
            } else if (
                // 输入完成
                format?.length === formatDate.value?.length &&
                !formatDate.isChanged &&
                valueMoment.isValid()
            ) {
                this.setState({
                    open: false, // 输入完成需关闭面板，并触发onOpenChange回调
                    value: valueMoment,
                    format
                })
                onChange?.(valueMoment, formatDate.value)
                onOpenChange?.(false, valueMoment, formatDate.value)
            }
        } else {
            // * 无需自动校正场景，仅保留合法值
            let inputValue = e.target.value

            // halfYear 单独处理回调及相关dom事件
            if (inputValue.length === format.length && picker === 'halfYear') {
                requestAnimationFrame(() => {
                    const {value, dateString} = getHalfYear(inputValue, this.state.lang)
                    this.setState({value})
                    const halfYearArr = getHalfYearArr(this.state.lang, dateString)
                    onChange?.(value, dateString, halfYearArr)
                })
            } else if (inputValue.length === format.length && moment(inputValue, format).isValid()) {
                requestAnimationFrame(() => {
                    this.setState({
                        value: moment(inputValue, format),
                        format
                    })
                    onChange?.(moment(inputValue, format), inputValue)
                })
            }
        }
    }

    /**
     * 删除尾部分隔符
     * @param {dateString} value 日期字符串
     * @param {formatString} format 格式字符串
     */
    deleteTailSeperator = (value: string, format: string) => {
        let isvalidFormatStrReg = /[^YyMmDdHhSsGgWwOoAa]+$/g
        if (isvalidFormatStrReg.test(format)) {
            let _deleteStr = format.match(isvalidFormatStrReg)?.[0]
            let _deleteValueReg = new RegExp(`${_deleteStr}$`, 'g')
            return {
                value: value.replace(_deleteValueReg, ''),
                format: format.replace(isvalidFormatStrReg, '')
            }
        }
        return {value, format}
    }

    // 获取格式后的日期字符串
    getValue = (value: moment.Moment | string) => {
        return moment.isMoment(value) ? value?.format(this.state.initFormat) : value
    }

    getShowTimeOptions = (props: DatePickerProps) => {
        const {format: customFormat, showTime, showHour, showMinute, showSecond} = props
        const format = this.generateFormat(customFormat as string)
        let showTimeOptions = {}
        if (!showTime) {
            showTimeOptions = false
        } else if (typeof showTime === 'object') {
            // showTime.defaultValue如非moment，则转换为moment
            let {defaultValue, format = 'HH:mm:ss'} = showTime
            if (defaultValue) {
                if (typeof defaultValue === 'string') {
                    if (moment(defaultValue, format).isValid()) {
                        defaultValue = moment(defaultValue, format)
                    } else if (!moment(defaultValue, format).isValid()) {
                        defaultValue = moment()
                    }
                } else if (!defaultValue?.isValid?.()) {
                    defaultValue = moment()
                }
            }
            showTimeOptions = {...showTime, defaultValue}
        } else {
            // 兼容 TimePickerPanel 配置
            showTimeOptions = {
                format,
                showHour,
                showMinute,
                showSecond,
                defaultValue: moment()
            }
        }
        return showTimeOptions
    }

    getPlacement = () => {
        // placement更新为dropdownAlign
        const {dropdownAlign, placement = 'bottomLeft'} = this.props
        return BUILT_IN_PLACEMENTS[dropdownAlign || placement]
    }

    handleOpenChange = (open: boolean) => {
        // 兼容enterKeyDown禁止打开面板
        if (this.state._forbidOpen && open) return
        this.setState({open})
        const {onOpenChange, picker} = this.props
        const format = this.state.initFormat
        let value = this.state.value
        onOpenChange?.(
            open,
            value,
            picker === 'halfYear'
                ? getHalfYear(value, this.state.lang).dateString
                : moment.isMoment(value)
                    ? value.format(format)
                    : value ?? ''
        )
        if (!open) {
            this.setState({
                maskShow: open
            })
        } else {
            if (value) {
                this.setState({
                    selectYear: moment(value).year(),
                    selectMonth: moment(value).month() + 1
                })
            }
        }
    }

    handleKeyDown = (e: any) => {
        const {onKeyDown, outInputKeydown, onOpenChange, onChange, enterKeyDown, picker, showTime} = this.props
        const format = this.state.initFormat
        let _forbidOpen = false
        if (e.keyCode === KeyCode.ESC) {
            let value = this.state.value
            this.setState({value, format, open: false})
            onOpenChange?.(false, value, moment.isMoment(value) ? value.format(format) : value ?? '')
        } else if (e.keyCode === KeyCode.BACKSPACE) {
            // * 删除拖蓝选中内容
            let s = getSelection() as unknown as string
            let reg = new RegExp(s, 'g'),
                _value = e.target.value
            if (_value.length === 1) return // * 删除到空不在此处处理
            if (_value.match(reg)?.length === 1) {
                _value = _value.replace(s.toString(), '')
            }
            // s.deleteFromDocument()
            s = s.toString()

            // * 开启自动校正
            if (this.state._isFix) {
                let value = _value
                if (s.length === 0) {
                    // * backspace直接删除，无拖蓝选中区域，则只删除光标前一个字符
                    value = value.slice(0, e.target.selectionStart - 1) + value.slice(e.target.selectionStart)
                    let yearStr = value.match(/\d{5,}/g)?.[0]
                    if (yearStr && _value[e.target.selectionStart - 1]?.search(/\D+/) > -1) {
                        value =
                            _value.slice(0, Math.max(0, e.target.selectionStart - 2)) +
                            _value.slice(Math.max(0, e.target.selectionStart - 1))
                    }
                }

                let formatDate = autoFormat({value, format, showTime, locale: this.state.lang.lang})
                /* 删除操作后结尾为分隔符，则把分隔符一同删除 */
                formatDate = Object.assign(formatDate, this.deleteTailSeperator(formatDate.value, formatDate.format))

                if (
                    (formatDate.format === format || formatDate.format === '') && // fix: QDJCJS-9354 合法日期但尚未完成操作时不应执行赋值操作
                    formatDate.value &&
                    formatDate.isChanged &&
                    moment(formatDate.value, formatDate.format).isValid() &&
                    !/S+/g.test(formatDate.format) /* 删除到最后的年不特别处理 */
                ) {
                    this.setState({
                        value: moment(formatDate.value, formatDate.format),
                        format: formatDate.format
                    })
                }
            }
        } else if (e.keyCode === KeyCode.ENTER) {
            // enterKeyDown为false时按下enter键不打开面板
            if (!enterKeyDown) {
                _forbidOpen = true
            }
            let value: moment.Moment | null
            let inputValue = e.target.value
            // * 开启自动校正
            if (this.state._isFix) {
                if (inputValue.length < format.length) {
                    inputValue = deleteFormat(inputValue, format)
                }
                const formatDate = autoFormat({value: inputValue, format, showTime, locale: this.state.lang.lang})
                value = moment(formatDate.value, format)
                value.isValid() && this.setState({value, format})

                onChange?.(value, formatDate.value)
                // Enter确认时需要触发onOpenChange事件
                onOpenChange?.(false, value, formatDate.value)
            } else {
                // halfYear 单独处理回调及相关dom事件
                if (picker === 'halfYear') {
                    value = getHalfYear(inputValue, this.state.lang).value
                    this.setState({value, format})
                    const halfYearArr = getHalfYearArr(this.state.lang, inputValue)
                    onChange?.(value, inputValue, halfYearArr)
                } else {
                    value = moment(inputValue, format)
                    if (value === null || value.isValid()) {
                        this.setState({value, format})
                    }
                    onChange?.(value, inputValue)
                }
                // Enter确认时需要触发onOpenChange事件
                onOpenChange?.(false, value, inputValue)
            }
        }
        this.setState({_forbidOpen})
        onKeyDown?.(e, e.preventDefault) || outInputKeydown?.(e, e.preventDefault)
    }

    handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let value = e.target.value
        const {onBlur, onDateInputBlur, onChange, picker} = this.props

        // halfYear 单独处理回调及相关dom事件
        if (picker === 'halfYear') {
            let valueMoment = getHalfYear(value, this.state.lang).value
            this.setState({value: valueMoment})
            onChange?.(valueMoment, value)
            if (this.pickerRef.current) {
                this.pickerRef.current.blur()
            }
            onBlur?.(e, value) || onDateInputBlur?.(e, value)
            return
        }

        let format = this.state.initFormat
        let valueMoment = moment(value, format)
        // QDJCJS-10002, moment不能简易传入format里非年月日时分秒的格式，对周 w 单独处理
        if (/w/gi.test(format)) {
            const reg = /[YyMmDdHhSsWwGgOo\d]+/gi
            const formatArr = format.match(reg)
            const valueArr = value.match(reg)
            let weekNum
            formatArr?.forEach((item, i) => {
                if (/w/gi.test(item)) {
                    weekNum = +valueArr?.[i]!
                    valueMoment = valueMoment.week(weekNum)
                }
            })
        }
        // 失焦时有效值保存
        const isValidValue = value && valueMoment.isValid()
        let _value = isValidValue ? valueMoment : null
        let _valueString = isValidValue ? valueMoment.format(format) : ''
        this.setState({
            value: _value,
            format
        })
        if (_valueString !== value) {
            // 失焦需触发onChange
            onChange?.(_value, _valueString)
        }
        if (this.pickerRef.current) {
            this.pickerRef.current.blur()
        }
        onBlur?.(e, _valueString) || onDateInputBlur?.(e, _valueString)
    }

    handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (this.pickerRef.current) {
            this.props.open ?? e.stopPropagation()
            this.pickerRef.current.focus()
            this.props.onFocus?.(e) || this.props.outInputFocus?.(e)
            this.setState({_forbidOpen: false})
        }
    }

    handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        e.stopPropagation()
        this.props.onClick?.(e)
    }

    _getFormatStartDate = ({showTime, picker, value, format: customFormat}: DatePickerProps) => {
        const format = this.generateFormat(customFormat as string)
        if (!value) return null
        if (picker === 'halfYear') {
            return getHalfYear(value, getLangInfo(this.props.locale)).value
        } else if (picker === 'week' && moment.isMoment(value)) {
            // 修复QDJCJS-7764：picker===week时，moment.format会将周格式化为该周第一天问题
            return value
        } else if (!showTime && (!picker || picker === 'date')) {
            return moment(moment.isMoment(value) ? value.format(format) : value, format).startOf('date')
        } else if (typeof showTime === 'object') {
            // 防止传入moment不符合步长
            value = moment.isMoment(value) ? value : moment(value)
            let {hourStep, minuteStep, secondStep} = showTime
            hourStep = hourStep ? +hourStep : 1
            minuteStep = minuteStep! ? +minuteStep : 1
            secondStep = secondStep! ? +secondStep : 1
            let hour = value.get('h'),
                min = value.get('m'),
                second = value.get('s')
            if (hour % hourStep !== 0) {
                hour = Math.floor(hour / hourStep) * hourStep
            }
            if (min % minuteStep !== 0) {
                min = Math.floor(min / minuteStep) * minuteStep
            }
            if (second % secondStep !== 0) {
                second = Math.floor(second / secondStep) * secondStep
            }
            value.set({hour, minute: min, second})
            return value
        } else {
            return moment(moment.isMoment(value) ? value.format(format) : value, format)
        }
    }

    // 确认修改日期
    handleChange = (value: moment.Moment, dateString: string) => {
        const {onChange, showTime, picker, format} = this.props
        let showTimeValue = this._getFormatStartDate({showTime, picker, value, format})
        this.setState({value: showTimeValue})
        if (picker === 'halfYear') {
            const halfYearArr = getHalfYearArr(this.state.lang, dateString)
            onChange?.(showTimeValue, dateString, halfYearArr)
        } else {
            onChange?.(showTimeValue, dateString)
        }
    }

    handleSelect = (value: moment.Moment) => {
        const {onSelect, onChange, showTime, picker} = this.props

        // halfYear 单独处理回调及相关dom事件
        if (picker === 'halfYear') {
            const {value: valueMoment, dateString} = getHalfYear(value, this.state.lang)
            this.setState({value: valueMoment})
            onSelect?.(valueMoment, dateString)
            return
        }

        // 确保用户拿到值为startOf date
        let showTimeValue = this._getFormatStartDate({showTime, picker, value})
        const format = this.state.initFormat
        // showTime和picker = time时，只有点击‘确定’按钮或者‘此刻’按钮才赋值（修复showTime时，value修改没有触发onChange事件）
        if (!showTime && picker !== 'time') {
            this.setState({value: showTimeValue, format})
        }
        onSelect?.(showTimeValue, (showTimeValue && this.getValue(showTimeValue)) || '')
        // QDJCJS-7925 修复手动输入年份后再弹出面板选择1月1号时不触发onChange问题
        if (value.diff(this.state.value) === 0) {
            onChange?.(showTimeValue, showTimeValue?.format(format) ?? '')
        }
    }

    handleOk = (value: moment.Moment) => {
        const {onOk, showTime, picker} = this.props
        let showTimeValue = this._getFormatStartDate({showTime, picker, value})
        const format = this.state.initFormat
        this.setState({value: showTimeValue, format})
        onOk?.(showTimeValue, (showTimeValue && this.getValue(showTimeValue)) || '')
    }

    dateRender = (currentDate: moment.Moment, today: moment.Moment) => {
        const {dateRender, dateCellRender} = this.props
        if (dateRender) {
            return (
                // 包装一层解决 dateRender 自定义配置样式丢失问题
                <div className={`${_PREFIX_CLS}-cell-inner`}>{dateRender(currentDate, today)}</div>
            )
        } else if (dateCellRender) {
            return (
                // 包装一层解决 dateCellRender 自定义配置样式丢失问题
                <div className={`${_PREFIX_CLS}-cell-inner`}>{dateCellRender(currentDate, today)}</div>
            )
        } else {
            return (
                // 默认日期样式
                <div className={`${_PREFIX_CLS}-cell-inner`}>{currentDate.get('date')}</div>
            )
        }
    }

    monthCellRender = (currentDate: moment.Moment, locale: Locale) => {
        const {monthCellRender, monthCellContentRender} = this.props
        if (monthCellRender || monthCellContentRender) {
            return (
                // 包装一层解决 monthCellRender 自定义配置样式丢失问题
                <div className={`${_PREFIX_CLS}-cell-inner`}>
                    {monthCellRender?.(currentDate, locale) || monthCellContentRender?.(currentDate, locale)}
                </div>
            )
        }
    }

    generateClearIcon = (): React.ReactNode => {
        // closeIcon更新为clearIcon，兼容函数式写法
        const {clearIcon, closeIcon, id, fieldid} = this.props
        return (
            <span id={id ? id + '_clear' : undefined} fieldid={fieldid ? fieldid + '_clear' : undefined}>
                {(typeof clearIcon === 'function' && clearIcon()) ||
                    (typeof closeIcon === 'function' && closeIcon()) ||
                    closeIcon ||
                    clearIcon}
            </span>
        )
    }

    iconClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation()
        const {iconClick} = this.props
        this.setState({_forbidOpen: false, open: true})
        iconClick?.(e)
    }

    generateSuffixIcon = (props: DatePickerProps): React.ReactNode => {
        // 兼顾 iconClick处理
        const {suffixIcon, renderIcon, id, fieldid} = props
        return (
            <span
                id={id ? id + '_suffix' : undefined}
                fieldid={fieldid ? fieldid + '_suffix' : undefined}
                onClick={this.iconClick}
            >
                {(typeof suffixIcon === 'function' && suffixIcon()) ||
                    suffixIcon ||
                    (typeof renderIcon === 'function' && renderIcon()) ||
                    renderIcon}
            </span>
        )
    }

    disabledDate = (current: moment.Moment) => {
        const {disabledDate, showTime, picker} = this.props
        if (!disabledDate) return false
        if (!showTime && (!picker || picker === 'date')) {
            current = current?.startOf('date')
        }
        return disabledDate?.(current)
    }

    getPopupContainerDom = (dom: HTMLElement) => {
        const {getPopupContainer, getCalendarContainer} = this.props
        if (typeof getPopupContainer === 'function') {
            return getPopupContainer(dom)
        } else if (typeof getCalendarContainer === 'function') {
            return getCalendarContainer(dom) // 兼容旧版本
        } else {
            return cssUtil.parentsUntil(dom)
        }
    }

    onPanelChange = (viewValue: moment.Moment, mode: PanelMode) => {
        // 点击前后按钮时更新下拉value值
        let {isHeaderSelect, onPanelChange} = this.props
        onPanelChange && onPanelChange(viewValue, mode)
        if (!isHeaderSelect) return
        let year = viewValue.year()
        let month = viewValue.month() + 1
        let {picker} = this.props
        if (!picker || picker === 'date' || picker === 'week') {
            this.setState({
                // menuValue: year + ' ' + month,
                selectYear: year,
                selectMonth: month
            })
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            this.setState({
                // menuValue: year,
                selectYear: year
            })
        } else if (picker === 'year') {
            let downRound = viewValue.year() - parseInt(viewValue.year().toString().slice(3, 4))
            this.setState({
                // menuValue: downRound + '-' + (downRound + 9)
                selectYear: downRound
            })
        }
    }

    cascaderChange = (val: any) => {
        // 选择cascader之后，联动日历面板
        let {picker} = this.props
        let differenceYear = 0
        let differenceMonth = 0

        if (!picker || picker === 'date' || picker === 'week') {
            differenceYear = parseInt(val[0]) - this.state.selectYear
            differenceMonth = parseInt(val[1]) - this.state.selectMonth
            this.setState({
                selectYear: parseInt(val[0]),
                selectMonth: parseInt(val[1])
                // menuValue: parseInt(val[0]) + '年' + ' ' + parseInt(val[1]) + '月'
            })
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            differenceYear = parseInt(val[0]) - this.state.selectYear
            differenceMonth = 0
            this.setState({
                selectYear: parseInt(val[0])
                // menuValue: parseInt(val[0]) + '年'
            })
        } else if (picker === 'year') {
            // differenceYear = parseInt(val[0].slice(0, 4))
            let currentYear = this.state.selectYear - parseInt(this.state.selectYear.toString().slice(3, 4))
            differenceYear = (parseInt(val[0].slice(0, 4)) - currentYear) / 10
            differenceMonth = 0
            this.setState({
                selectYear: parseInt(val[0])
                // menuValue: val[0]
            })
        }
        this.setState({
            diffValue: [differenceYear, differenceMonth] // 联动选择值之后让日历面板跳转到相应的年月，传入rc-picker
        })
    }
    // 点击年份
    onChangeYear = (val: string, index: number) => {
        let {picker} = this.props
        if (!picker || picker === 'date' || picker === 'week') {
            this.setState({
                // maskShow: false,
                selectYear: parseInt(val)
            })
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            this.setState({
                maskShow: false,
                selectYear: parseInt(val)
            })
        } else if (picker === 'year') {
            this.setState({
                maskShow: false,
                selectYear: parseInt(val)
            })
        }
        this.setState({
            activeYearNum: index
        })
        let newArr = [val, this.state.selectMonth]
        this.cascaderChange(newArr)
    }
    // 点击月份
    // @ts-ignore
    onChangeMonth = (monthValue: string, index: number) => {
        this.setState({
            maskShow: false,
            selectMonth: index + 1,
            activeMonthNum: index
        })
        let newArr = [this.state.selectYear, index + 1]
        this.cascaderChange(newArr)
    }
    clickValue = (e: any) => {
        let {picker} = this.props
        let {lang} = this.state
        this.setState({maskShow: !this.state.maskShow})
        let currentYear = parseInt(e.target.parentNode.parentNode.querySelector(`.${_PREFIX_CLS}-year-btn`)?.textContent)
        let currentMonth = e.target.parentNode.parentNode.querySelector(`.${_PREFIX_CLS}-month-btn`)?.textContent
        let options = this.getOptions()
        options.map((item, index) => {
            if (picker === 'year') {
                // picker为年时以10年为单位
                // let currentYear = this.state.selectYear - parseInt(this.state.selectYear.toString().slice(3, 4))
                let currentYearRang = e.target.parentNode.parentNode.querySelector(`.${_PREFIX_CLS}-decade-btn`).textContent
                // let itemYear = parseInt(item.label.slice(0, 4))
                if (item.label === currentYearRang) {
                    this.setState({
                        activeYearNum: index
                    })
                }
                // if (itemYear === currentYear) {
                //     this.setState({
                //         activeYearNum: index
                //     })
                // }
            } else {
                if (parseInt(item.label) === currentYear) {
                    this.setState({
                        activeYearNum: index,
                        selectYear: currentYear
                    })
                }
                // if (parseInt(item.label) === this.state.selectYear) {
                //     this.setState({
                //         activeYearNum: index
                //     })
                // }
            }
        })
        // let monthArr = this.state.monthData
        moment.locale(lang.lang)
        let monthArr = moment.localeData().monthsShort()
        // @ts-ignore
        monthArr.map((item: any, index: number) => {
            if (currentMonth === item) {
                this.setState({
                    activeMonthNum: index,
                    selectMonth: index + 1
                })
            }
            // if (index + 1 === this.state.selectMonth) {
            //     this.setState({
            //         activeMonthNum: index
            //     })
            // }
        })
        setTimeout(() => {
            // 每次面板展开选中项在面板中间
            if (this.yearUl) {
                this.yearUl.scrollTop = (this.state.activeYearNum - 2) * 34
            }
            if (this.monthUl) {
                this.monthUl.scrollTop = (this.state.activeMonthNum - 2) * 34
            }
        }, 0)
    }
    headerSelect = () => {
        let {lang} = this.state
        let {picker} = this.props
        let options: {label: string; value: string; children?: any}[] = this.getOptions()
        moment.locale(lang.lang)
        let monthData = moment.localeData().monthsShort()
        let node = (
            <div className={`${_PREFIX_CLS}-header-select`}>
                <div className={`${_PREFIX_CLS}-header-select-year`} ref={el => (this.yearUl = el)}>
                    <ul>
                        {options.map((item, index) => {
                            return (
                                <li
                                    className={
                                        this.state.activeYearNum == index
                                            ? `${_PREFIX_CLS}-header-select-year-active`
                                            : undefined
                                    }
                                    key={item.value + index}
                                    onClick={this.onChangeYear.bind(this, item.label, index)}
                                >
                                    {lang.lang === 'zh-cn' || lang.lang === 'zh-tw'
                                        ? picker != 'year'
                                            ? this.state.activeYearNum == index
                                                ? parseInt(item.label) + ' 年'
                                                : parseInt(item.label)
                                            : item.label
                                        : picker != 'year'
                                            ? parseInt(item.label)
                                            : item.label}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {options[0]?.children && (
                    <div className={`${_PREFIX_CLS}-header-select-month`} ref={el => (this.monthUl = el)}>
                        <ul>
                            {monthData.map((item: string, index: number) => {
                                return (
                                    <li
                                        className={
                                            this.state.activeMonthNum == index
                                                ? `${_PREFIX_CLS}-header-select-month-active`
                                                : undefined
                                        }
                                        key={item + index}
                                        onClick={this.onChangeMonth.bind(this, item, index)}
                                    >
                                        {lang.lang === 'zh-cn' || lang.lang === 'zh-tw' ? parseInt(item) : item}
                                        {lang.lang === 'zh-cn' || lang.lang === 'zh-tw'
                                            ? this.state.activeMonthNum == index
                                                ? ' 月'
                                                : ''
                                            : ''}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
            </div>
        )
        let iconNode = (
            <div className={`${_PREFIX_CLS}-header-select-icon`}>
                {this.state.maskShow ? <Icon type="uf-gridcaretarrowup" /> : <Icon type="uf-gridcaretdown" />}
            </div>
        )
        return (
            <div className={`${_PREFIX_CLS}-header-select-box`}>
                {this.state.maskShow && node}
                <div style={{background: 'transparent', height: '100%'}} onClick={this.clickValue}>
                    {' '}
                </div>{iconNode}
            </div>)
        // return (
        //     <div className={`${_PREFIX_CLS}-header-select-box`}>
        //         {this.state.maskShow && node}
        //         <div style={{background: 'transparent', height: '100%'}} onClick={this.clickValue}>
        //             {' '}
        //         </div>
        //     </div>
        // )
    }

    getOptions = () => {
        // 获取cascader的options
        let {picker} = this.props
        let nowDate = new Date()
        let currentYear: number = nowDate.getFullYear()
        let options = []
        let childrenOptions = []
        for (let i = 1; i < 13; i++) {
            let obj = {label: i + '月', value: i + '月'}
            childrenOptions.push(obj)
        }
        if (!picker || picker === 'date' || picker === 'week') {
            for (let i = currentYear - 200; i < currentYear + 200; i++) {
                let obj = {label: i + '年', value: i + '年', children: childrenOptions}
                options.push(obj)
            }
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            for (let i = currentYear - 200; i < currentYear + 200; i++) {
                let obj = {label: i + '年', value: i + '年'}
                options.push(obj)
            }
        } else if (picker === 'year') {
            // let downRound = currentYear - parseInt(currentYear.toString().slice(3, 4))
            // console.log('下去10', downRound)
            for (let i = currentYear - 200; i < currentYear + 200; i++) {
                if (i % 10 === 0) {
                    let obj = {label: i + '-' + (i + 9), value: i + '-' + (i + 9)}
                    options.push(obj)
                }
            }
        }
        return options
    }

    render() {
        const props = this.props
        const {
            defaultValue,
            monthCellRender,
            picker,
            superPrevIcon,
            superNextIcon,
            prevIcon,
            nextIcon,
            dropdownClassName,
            popupClassName,
            isHeaderSelect
        } = props
        let {value, format, open, lang, dropdownAlign, showTime, clearIcon, suffixIcon} = this.state
        isShouldUpdate('DatePicker', props)

        return (
            <RcPicker
                /* 内置配置 */
                ref={this.pickerRef as any}
                generateConfig={momentGenerateConfig}
                prefixCls={_PREFIX_CLS}
                transitionName='slide-up'
                tabIndex={0} //  Tab 按键切换顺序，不暴露给用户
                /* custom需omit配置 */
                {...omit(props, _handleProps)}
                /* custom配置 */
                defaultValue={(defaultValue && moment(defaultValue, format)) ?? undefined}
                value={value ? value : value !== undefined ? null : undefined}
                format={picker === 'halfYear' ? (date: Moment) => halfYearFormat(date, lang) : format}
                open={open ?? undefined}
                locale={Object.assign({}, lang.langMap, {
                    halfYearFormat: (date: Moment) => halfYearFormat(date, lang, false)
                })}
                dropdownAlign={dropdownAlign}
                dropdownClassName={dropdownClassName || popupClassName}
                showTime={showTime}
                clearIcon={clearIcon}
                suffixIcon={suffixIcon}
                superPrevIcon={superPrevIcon ?? <Icon type='uf-daoshouye' />} // UE 需求，更换默认翻页箭头
                superNextIcon={superNextIcon ?? <Icon type='uf-youjiantou_shuang' />}
                prevIcon={prevIcon ?? <Icon type='uf-arrow-left' />}
                nextIcon={nextIcon ?? <Icon type='uf-arrow-right' />}
                disabledDate={this.disabledDate}
                onOpenChange={this.handleOpenChange}
                onSelect={this.handleSelect}
                onChange={this.handleChange}
                onOk={this.handleOk}
                onKeyDown={this.handleKeyDown}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onClick={this.handleClick}
                dateRender={this.dateRender}
                monthCellRender={monthCellRender ? this.monthCellRender : undefined}
                getPopupContainer={this.getPopupContainerDom}
                onPanelChange={this.onPanelChange}
                // @ts-ignore
                diffValue={isHeaderSelect && this.state.diffValue}
                headerSelect={isHeaderSelect ? this.headerSelect() : null}
                showSelectMask={isHeaderSelect && this.state.maskShow}
            />
        )
    }
}

// DatePicker.propTypes = propTypes
export default DatePicker
