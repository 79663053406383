import * as React from 'react';
import InternalAvatar from './Avatar';
import { AvatarProps } from './iAvatar';
import Group from './Group';

interface CompoundedComponent
  extends React.ForwardRefExoticComponent<AvatarProps & React.RefAttributes<HTMLElement>> {
  Group: typeof Group;
}

const Avatar = InternalAvatar as CompoundedComponent;
Avatar.Group = Group;

export { Group };
export default Avatar;
