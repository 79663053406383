// export function getComponentLocale(props, context, componentName, getDefaultLocale) {
//     let locale = {};
//     if (context && context.beeLocale && context.beeLocale[componentName]) {
//         locale = context.beeLocale[componentName];
//     } else {
//         const defaultLocale = getDefaultLocale();

//         locale = defaultLocale.default || defaultLocale;
//     }

//     const result = {
//         ...locale,
//         ...props.locale,
//     };
//     if (props.locale) {
//         result.lang = {
//             ...locale.lang,
//             ...props.locale.lang,
//         };
//     } else {
//         result.lang = {
//             ...locale.lang
//         };
//     }

//     return result;
// }

// export function getLocaleCode(context) {
//     const localeCode = context.beeLocale && context.beeLocale.lang;
//     // Had use LocaleProvide but didn't set locale
//     if (context.beeLocale && context.beeLocale.exist && !localeCode) {
//         return 'zh-cn';
//     }
//     return localeCode;
// }


import { Lang } from '../../wui-core/src/utils/type';
import { Locale, LangMap } from './iLocale'
/**
 * @desc 语言locale转换为小写和中划线
 * @param {String} lang 输入语言
 */
export function langTransform(lang: string): Lang {
    if (!lang) return lang
    let reg = new RegExp('([a-zA-Z]{2})[_-]?([A-Za-z]+)', 'g')
    return lang.replace(reg, '$1-$2').toLowerCase()
}

/**
 * @desc 语言locale key map对象
 */
export const langMap: LangMap = {
    'zh': 'zh-cn',
    'zh-cn': 'zh-cn',
    'en': 'en-us',
    'en-us': 'en-us',
    'zh-tw': 'zh-tw',
    'vi': 'vi-vn',
    'vi-vn': 'vi-vn'
}
/**
 * 获取locale的语言对象
 * @param localeVal
 * @param i18n
 */
export function getLangInfo(localeVal?: string | Locale, i18n: Locale = {}): {lang: Lang, langMap: Record<string, any>} {
    let lang = '';
    if (typeof localeVal === "object") {
        if ('locale' in localeVal) {// 兼容antd语言包对象，对象中存在locale属性则直接获取语言类型即可
            lang = localeVal.locale as string ;
        } else if ('lang' in localeVal) {// 兼容tinper的Locale组件语言包
            lang = localeVal.lang as string;
        } else {// 直接引入DataPicker组件语言包对象暂不提供支持
            console.error('暂不支持语言包对象，请使用locale="zh-cn"或locale="en"等语言标识');
        }
    } else if (typeof localeVal === "string") {// 4.x支持的语言标识
        lang = localeVal as string;
    }
    lang = langTransform(lang)
    lang = langMap[lang] ?? lang; // locale='en' 转换为 locale='en-us'
    return {// 默认中文
        lang: lang || 'zh-cn', // 语言类型
        langMap: i18n[lang] || {} // 语言关系包
    }
}
