import React, {Component} from 'react';
import moment from 'moment';
// import PropTypes from 'prop-types';
import {WithConfigConsumer} from '../../wui-provider/src/context';
import {cssUtil, formatUtils, Warning, WebUI} from './../../wui-core/src';
import {getLangInfo} from './../../wui-datepicker/src/_utils/lang';
import {langTransform} from './../../wui-locale/src/tool';
import i18n from './i18n';
import RcTimePicker from './rc/index';
import type {Moment} from 'moment';
import type {TimePickerProps, TimePickerState, TimePickerDefaultProps, TimePickerWithDefaultProps} from './iTimePicker';

const {isShouldUpdate} = Warning;

const defaultProps: TimePickerDefaultProps = {
    allowClear: true, // 默认允许清除
    focusOnOpen: false,
    autoComplete: 'off',
    placeholder: '',
    locale: 'zh-cn',
    // showHour: true,
    // showMinute: true,
    // showSecond: true,
    clearIcon: <i className='uf uf-close-c' />,
    // inputIcon: <i className='uf uf-time-c-o' />,
    // onKeyDown: () => {}
};

@WithConfigConsumer()
@WebUI({name: 'time-picker', defaultProps})
class TimePicker extends Component<TimePickerProps, TimePickerState> {
    constructor(props: TimePickerWithDefaultProps) {
        super(props);
        const langInfo = getLangInfo(props.locale); // 获取语言包信息
        this.state = {
            langInfo,
            value: this.initValue(props.value, props),
            defaultValue: this.initValue(props.defaultValue, props),
            showTime: formatUtils.format2ShowTime(this.getFormat(props)),
            format: this.getFormat(props)
        };
    }

    componentWillReceiveProps(nextProps: TimePickerProps) {
        if ('value' in nextProps) {
            let value = this.initValue(nextProps.value, nextProps);
            this.setState({
                value
            });
        }

        if ('defaultValue' in nextProps) {
            let defaultValue = this.initValue(nextProps.defaultValue, nextProps);
            this.setState({
                defaultValue
            });
        }

        if ('format' in nextProps) {
            this.setState(
                Object.assign(nextProps.format ? {showTime: formatUtils.format2ShowTime(nextProps.format)} : {}, {
                    format: this.getFormat(nextProps)
                })
            )
        }

        if ('locale' in nextProps) {
            let langInfo = getLangInfo(nextProps.locale); // 获取语言包信息
            this.setState({langInfo});
        }
    }

    initValue = (value: TimePickerProps['value'], props: TimePickerProps) => {
        const format = this.getFormat(props);
        if (value) {
            if (typeof value === 'string' && moment(value, format).isValid()) {
                value = moment(value, format);
            } else if (typeof value !== 'string' && value.format && value.isValid?.()) {
                value = moment(value.format(format), format);
            } else {
                console.error('`value` of TimePicker is not in correct format');
                value = null;
            }
        } else if (value === undefined) {
            value = undefined;
        } else {
            value = null;
        }
        // 确保value的moment实例多语版本一致
        if (value) {
            let langInfo = getLangInfo(this.props.locale); // 获取语言包信息
            moment.locale(langInfo.lang);
            value = moment(value, format);
        }
        return value;
    };

    getFormat = (props: TimePickerProps) => {
        const {format} = props;
        if (format) {
            return format;
        }

        // 未传入format时，showTime配置都默认为true【***不要合并props，逻辑更清晰***】
        const {use12Hours, showHour = true, showMinute = true, showSecond = true} = props;

        if (use12Hours) {
            const fmtString = [showHour ? 'h' : '', showMinute ? 'mm' : '', showSecond ? 'ss' : '']
                .filter(item => !!item)
                .join(':');
            return fmtString.concat(' a');
        }

        return [showHour ? 'HH' : '', showMinute ? 'mm' : '', showSecond ? 'ss' : ''].filter(item => !!item).join(':');
    };

    getPopupContainerDom = (dom: HTMLElement) => {
        const {getPopupContainer} = this.props;
        if (typeof getPopupContainer === 'function') {
            return (getPopupContainer as (h?: HTMLElement) => HTMLElement)(dom);
        } else {
            return cssUtil.parentsUntil(dom);
        }
    };

    handleTimeChange = (time: Moment | null) => {
        const {onChange} = this.props;
        let timeString = '';
        if (time !== null && typeof time.format === 'function') {
            timeString = time.format(this.state.format);
        }
        this.setState({
            value: time
        });
        onChange?.(time, timeString);
    };

    render() {
        const {
            defaultOpenValue,
            showClear,
            allowClear,
            showNow,
            renderExtraFooter,
            addon,
            inputIcon,
            suffixIcon,
            clsPrefix,
            onChange,
            locale,
            ...other
        } = this.props;

        isShouldUpdate('TimePicker', this.props);
        const extral = {
            renderExtraFooter: renderExtraFooter || addon,
            allowClear: showClear !== undefined ? showClear : allowClear
        };
        let {value, defaultValue, format, showTime} = this.state;
        i18n.lang = locale && langTransform(locale) || i18n.lang
        let clearText = this.props.clearText || locale && langTransform(locale) && (i18n[langTransform(locale)] as {'clear': string})?.clear;
        let _defaultOpenValue = moment(defaultOpenValue, format).isValid()
            ? moment(defaultOpenValue, format)
            : moment();

        return (
            <RcTimePicker
                {...(!showTime ? {} : showTime as any)}
                {...other}
                {...extral}
                transitionName='slide-up'
                getPopupContainer={this.getPopupContainerDom}
                prefixCls={clsPrefix}
                locale={i18n} // 补充多语的locale
                showNow={showNow}
                value={value}
                defaultValue={defaultValue}
                defaultOpenValue={_defaultOpenValue}
                format={format}
                onChange={this.handleTimeChange}
                clearText={clearText}
                inputIcon={inputIcon || suffixIcon || <i className='uf uf-time-c-o' />}
            />
        );
    }
}

// TimePicker.propTypes = propTypes;
export default TimePicker;
