const _assign = Object.assign;
const _isArray = Array.isArray;
// const _isFunction = function(value: any) {
//     return !!(typeof value === 'function');
// };
const _forEach = function(arr: any[], callback: (a: {[key: string]: any}, b: string) => void) {
    if (!arr) return;
    for (let idx in arr) {
        callback(arr[idx], idx);
    }
};
// const _startsWith = function(fullStr:string, startStr: string) {
//     if (typeof fullStr !== 'string') return false;
//     return !!(fullStr.substr(0, startStr.length) == startStr);
// };
const _isEmpty = function(value: any) {
    if (value === null || value === '' || value === undefined) return true;
    if (Array.isArray(value)) {
        return (value.length == 0);
    } else if (typeof value === 'object') {
        return (Object.keys(value).length == 0);
    } else {
        return !value;
    }
};

// const _isObject = function(value: any) {
//     let type = typeof value;
//     return value != null && (type == 'object' || type == 'function');
// };

export default {
    assign: _assign,
    isArray: _isArray,
    // isFunction: _isFunction,
    forEach: _forEach,
    // startsWith: _startsWith,
    isEmpty: _isEmpty,
    // isObject: _isObject
}