import {
    Button,
    Layout,
    Row,
    Col,
    Badge,
    Alert,
    Form,
    Input,
    InputNumber,
    Checkbox,
    Pagination,
    Progress,
    Radio,
    Switch,
    Tooltip,
    Dropdown,
    DatePicker,
    Collapse,
    Select,
    Icon,
    Menu,
    Upload,
    Breadcrumb,
    Popconfirm,
    Modal,
    Tabs,
    Cascader,
    Spin,
    Table,
    Tree,
    Rate,
    Steps,
    Timeline,
    Transfer,
    BackTop,
    Slider,
    AutoComplete,
    Popover,
    Anchor,
    ColorPicker,
    Tag,
    Affix,
    Drawer,
    TreeSelect,
    TimePicker,
    Image,
    Carousel,
    Space,
    Empty,
    Avatar,
    Card,
    Divider,
    Calendar,
    Skeleton
} from '../../index';
import React from 'react';
let getHtmlNode = (WrapHtmlNode) => {
    class HtmlNode extends React.Component {
        render() {
            let {...other} = this.props
            return (
                <WrapHtmlNode {...other}></WrapHtmlNode>
            )
        }
    }
    return HtmlNode
}

export default {
    "Layout": Layout,
    "Layout.Header": Layout.Header,
    "Layout.Content": Layout.Content,
    "Layout.Footer": Layout.Footer,
    "Layout.Sider": Layout.Sider,
    "Button": Button,
    "Slider": Slider,
    "AutoComplete": AutoComplete,
    "Badge": Badge,
    "Alert": Alert,
    "Button.Group": Button.Group,
    "Form": Form,
    "Form.Item": Form.Item,
    "Form.Provider": Form.Provider,
    "Input": Input,
    "Input.Group": Input.Group,
    "InputNumber": InputNumber,
    "Input.Search": Input.Search,
    "Input.TextArea": Input.TextArea,
    "Input.Password": Input.Password,
    "Checkbox": Checkbox,
    "Checkbox.Group": Checkbox.Group,
    "Pagination": Pagination,
    "Progress": Progress,
    "Progress.Bar": Progress.Bar,
    "Radio": Radio,
    "Radio.Button": Radio.Button,
    "Radio.Group": Radio.Group,
    "Switch": Switch,
    "Tooltip": Tooltip,
    "Row": Row,
    "Col": Col,
    "Dropdown": Dropdown,
    "Dropdown.Button": Dropdown.Button,
    "Collapse": Collapse,
    "Collapse.Panel": Collapse.Panel,
    "Select": Select,
    "Select.Option": Select.Option,
    "Select.OptGroup": Select.OptGroup,
    "Icon": Icon,
    "Menu": Menu,
    "Menu.Item": Menu.Item,
    "Menu.SubMenu": Menu.SubMenu,
    "Menu.ItemGroup": Menu.ItemGroup,
    "Breadcrumb": Breadcrumb,
    "Breadcrumb.Item": Breadcrumb.Item,
    "Upload": Upload,
    "Upload.Dragger": Upload.Dragger,
    "Tabs": Tabs,
    "Tabs.TabPane": Tabs.TabPane,
    "Tabs.SearchTabs": Tabs.SearchTabs,
    "Tabs.SearchTabs.Item": Tabs.SearchTabs.Item,
    "Cascader": Cascader,
    "Spin": Spin,
    "Table": Table,
    "Tree": Tree,
    "Tree.TreeNode": Tree.TreeNode,
    "Rate": Rate,
    "Steps": Steps,
    "Steps.Step": Steps.Step,
    "Timeline": Timeline,
    "Timeline.Item": Timeline.Item,
    "Transfer": Transfer,
    "BackTop": BackTop,
    "Tag": Tag,
    "ColorPicker": ColorPicker,
    "Affix": Affix,
    "Drawer": Drawer,
    "TreeSelect": TreeSelect,
    "TreeSelect.TreeNode": TreeSelect.TreeNode,
    "DatePicker": DatePicker,
    "DatePicker.MonthPicker": DatePicker.MonthPicker,
    "DatePicker.WeekPicker": DatePicker.WeekPicker,
    "DatePicker.YearPicker": DatePicker.YearPicker,
    "DatePicker.RangePicker": DatePicker.RangePicker,
    "TimePicker": TimePicker,
    "Image": Image,
    "Carousel": Carousel,
    "Space": Space,
    "Empty": Empty,
    "Avatar": Avatar,
    "Avatar.Group": Avatar.Group,
    "Card": Card,
    "Card.Grid": Card.Grid,
    "Divider": Divider,
    "Calendar": Calendar,
    "Skeleton": Skeleton,
    "Popconfirm": Popconfirm,
    "div": getHtmlNode('div'),
    "img": getHtmlNode('img'),
    "span": getHtmlNode('span'),
    "Modal": Modal,
    "Modal.Header": Modal.Header,
    "Modal.Body": Modal.Body,
    "Modal.Footer": Modal.Footer,
    "Popover": Popover,
    "Anchor": Anchor
};
