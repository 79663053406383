/*
 * @Author: Mr.mjc
 * @Date: 2022-06-15 17:39:30
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2023-02-02 20:06:12
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/lib/dragColumn.tsx
 */
import PropTypes from "prop-types";
import React, {Component} from "react";
import {prefix} from "../../../wui-core/src/index";
import { TableProps, IDragColumnState, ColumnType, DropType } from '../iTable';
import { DefaultRecordType } from '../interface';
import { TableInterface } from "../index";

/**
 * 参数: 列拖拽
 * @param {*} Table
 */

export default function dragColumn(Table: React.ComponentClass<Partial<TableProps>> | TableInterface) {

    return class DragColumn extends Component<TableProps, IDragColumnState> {

		static propTypes = {
		    columns: PropTypes.any,
		    data: PropTypes.any,
		    onDragEnd: PropTypes.func,
		    dragborder: PropTypes.bool,
		    draggable: PropTypes.bool,
		}

		constructor(props: TableProps) {
		    super(props);
		    this.state = {
		        columns: this.setColumOrderByIndex(props.columns)
		    };
		}

		componentWillReceiveProps(nextProps: TableProps) {
		    if (nextProps.columns != this.props.columns) {
		        this.setState({
		            columns: this.setColumOrderByIndex(nextProps.columns)
		        })
		    }
		}

		setColumOrderByIndex = (_column: ColumnType[]): ColumnType[] => {
		    _column.forEach((da, i) => {
		        da.dragIndex = i;
		        da.drgHover = false;
		    });
		    return _column;
		}

		// recursion(obj, data = {}) {
		//     for (let key in obj) {
		//         if (typeof obj[key] == 'object' && Object.keys(obj[key].length > 0)) {
		//             data[key] = recursion(obj[key])
		//         } else {
		//             data[key] = obj[key]
		//         }
		//     }
		//     return data
		// }

		// 拖拽交互列后触发
		onDragEnd = (event: React.DragEvent<HTMLElement>, data: DropType<DefaultRecordType>) => {
		    let {dragSource = {}, dragTarget = {}} = data;
		    let {columns} = this.state;
		    let sourceIndex = -1, targetIndex = -1;

		    sourceIndex = columns.findIndex((da) => da.key == dragSource.key);
		    targetIndex = columns.findIndex((da) => da.key == dragTarget.key);
		    // 向前移动
		    if (targetIndex < sourceIndex) {
		        targetIndex = targetIndex + 1;
		    }
		    columns.splice(
		        targetIndex,
		        0,
		        columns.splice(sourceIndex, 1)[0]
		    );
		    let sortedColumns:ColumnType[] = [];
		    columns.forEach((da) => {
		        sortedColumns.push(Object.assign({}, da));
		    });
		    this.setState({
		        columns: sortedColumns
		    });
		    if (this.props.onDragEnd) {
		        this.props.onDragEnd(event, data, sortedColumns);
		    }
		}

		// getTarget = (evt: any) => {
		//     return evt.target || evt.srcElement;
		// }

		render() {
		    const {
		        data,
		        dragborder,
		        draggable,
		        className,
		        ...others
		    } = this.props;
		    return (
		        <Table
		            {...others}
		            columns={this.state.columns}
		            data={data}
		            className={`${className} ${prefix}-table-drag-border`}
		            onDragEnd={this.onDragEnd}
		            draggable={draggable}
		            dragborder={dragborder}
		        />)
		}
    } as React.ComponentClass<Partial<TableProps>> | TableInterface;
}
