import arrayTreeFilter from 'array-tree-filter';
import classNames from 'classnames';
// import Rcascader from './rc/index';
// import PropTypes from 'prop-types';
import RcCascader from 'rc-cascader';
// import omit from 'rc-util/lib/omit';
import KeyCode from 'rc-util/lib/KeyCode';
import React, {Component} from 'react';
import {cssUtil, getNid, WebUI} from "../../wui-core/src/index"
import Empty from '../../wui-empty/src'
import Icon from '../../wui-icon/src'
import Input from '../../wui-input/src';
import addEventListener from '../../wui-overlay/src/utils/addEventListener';

import { CascaderProps, CascaderState, OptionType, CascaderOption, ShowSearchType, FieldNames } from './iCascader.js'
// const propTypes = {
//     allowClear: PropTypes.bool,
//     autoFocus: PropTypes.bool,
//     bordered: PropTypes.bool,
//     changeOnSelect: PropTypes.bool,
//     className: PropTypes.string,
//     defaultValue: PropTypes.array,
//     disabled: PropTypes.bool,
//     displayRender: PropTypes.func,
//     dropdownRender: PropTypes.func,
//     expandIcon: PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.node,
//     ]),
//     expandTrigger: PropTypes.string,
//     fieldNames: PropTypes.object,
//     getPopupContainer: PropTypes.func,
//     loadData: PropTypes.func,
//     notFoundContent: PropTypes.string,
//     options: PropTypes.array,
//     placeholder: PropTypes.string,
//     popupClassName: PropTypes.string,
//     popupPlacement: PropTypes.string,
//     showSearch: PropTypes.oneOfType([
//         PropTypes.bool,
//         PropTypes.object,
//     ]),
//     size: PropTypes.string,
//     separator: PropTypes.string,
//     style: PropTypes.object,
//     suffixIcon: PropTypes.node,
//     onChange: PropTypes.func,
//     onPopupVisibleChange: PropTypes.func,
//     popupVisible: PropTypes.bool,
//     value: PropTypes.any
// };
const defaultProps = {
    prefixCls: 'wui-cascader',
    showSearch: false,
    allowClear: true,
    bordered: true,
    changeOnSelect: false,
    autoFocus: false,
    suffixIcon: null,
    popupPlacement: 'bottomLeft',
    expandIcon: null,
    size: 'md',
    // getPopupContainer: () => document.body,
    expandTrigger: 'click',
    separator: '/',
    placeholder: '请选择',
    dropdownRender: null,
    fieldNames: {},
    className: '',
    defaultValue: [],
    disabled: false,
    loadData: null,
    notFoundContent: '',
    options: [],
    popupClassName: '',
    style: {},
    onChange: () => {
    },
    onPopupVisibleChange: () => {
    }
};
const keepFilteredValueField = '__KEEP_FILTERED_OPTION_VALUE';

@WebUI({name: "cascader", defaultProps})
class Cascader extends Component<CascaderProps, CascaderState> {
    constructor(props: CascaderProps) {
        super(props)
        this.state = {
            value: props.value || props.defaultValue || [],
            inputValue: '',
            inputFocused: false,
            popupVisible: props.popupVisible,
            prevProps: props,
            flattenOptions: props.showSearch ? this.flattenTree(props.options, props) : undefined,
            popupClassName: props.popupClassName
        }
        this.getLabel = this.getLabel.bind(this)
        this.defaultDisplayRender = this.defaultDisplayRender.bind(this)
        this.getFilledFieldNames = this.getFilledFieldNames.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.clearSelection = this.clearSelection.bind(this)
        this.handlePopupVisibleChange = this.handlePopupVisibleChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputClick = this.handleInputClick.bind(this)
        this.handleInputBlur = this.handleInputBlur.bind(this)
        this.saveInput = this.saveInput.bind(this)
        this.handleInputBox = this.handleInputBox.bind(this)
        this.generateFilteredOptions = this.generateFilteredOptions.bind(this)
        this.defaultFilterOption = this.defaultFilterOption.bind(this)
        this.defaultRenderFilteredOption = this.defaultRenderFilteredOption.bind(this)
        this.defaultSortFilteredOption = this.defaultSortFilteredOption.bind(this)
        this.flattenTree = this.flattenTree.bind(this)
        // this.getOptios = this.getOptios.bind(this)
    }

	input?: HTMLInputElement;
	menuAddEvent: any;
	matchingoptions: any;

	// eslint-disable-next-line
    componentWillReceiveProps(nextProps: CascaderProps) {
	    if ('value' in nextProps && nextProps.value instanceof Array) {
	        this.setState({
	            value: nextProps.value
	        })
	    }
	}

	componentWillUnmount() {
	    this.menuAddEvent && this.menuAddEvent.remove()
	}

	// 判断cascader是否父元素是modal或drawer，如果是则挂在到modal或drawer上
	getPopupContainerDom = (dom: HTMLElement) => {
	    const {getPopupContainer} = this.props;
	    if (typeof getPopupContainer === 'function') {
	        return getPopupContainer(dom)
	    } else {
	        return cssUtil.parentsUntil(dom);
	    }
	}

	getFilledFieldNames() {
	    const fieldNames = this.props.fieldNames || {};
	    const names = {
	        children: fieldNames.children || 'children',
	        label: fieldNames.label || 'label',
	        value: fieldNames.value || 'value',
	    };
	    return names;
	}

	getEmptyNode(names: any, notFoundContent: string) {
	    return {
	        [names.value]: 'ANT_CASCADER_NOT_FOUND',
	        [names.label]: notFoundContent || (<Empty/>),
	        disabled: true,
	        isEmptyNode: true,
	    };
	}

	defaultDisplayRender(label: (string | number)[] | OptionType[]) {
	    let {value} = this.state;
	    let {separator} = this.props
	    // console.log(value)
	    if (value.length > 0) {
	        if (typeof value[0] == 'string') {
	            return label.length > 0 ? label.join(` ${separator} `) : ''
	        } else if (typeof value[0] == 'object') {

	            let filterArr: (string | number)[] = []
	            value.map((item: any) => {
	                filterArr.push(item.label)
	            })
	            // console.log(filterArr)
	            return filterArr.length > 0 ? filterArr.join(` ${separator} `) : ''
	        }
	    } else {
	        return
	    }


	}

	getLabel() {
	    const {options, displayRender = this.defaultDisplayRender} = this.props;
	    const names = this.getFilledFieldNames();
	    const {value} = this.state;
	    const unwrappedValue = Array.isArray(value[0]) ? value[0] : value;
	    const selectedOptions = arrayTreeFilter(
	        options as CascaderOption[],
	        (o:CascaderOption, level: number) => o[names.value] === unwrappedValue[level],
	        {childrenKeyName: names.children},
	    );
	    const label = selectedOptions.length ? selectedOptions.map((o: CascaderOption) => o[names.label]) : value;
	    return displayRender(label, selectedOptions);
	}

	saveInput(node: HTMLInputElement) {
	    this.input = node;
	}

	setDomFieldid = () => {
	    let {fieldid, prefixCls} = this.props;
	    let arr = document.querySelectorAll(`.${prefixCls}-menus.${this.state.popupClassName} .uf-arrow-right`)
	    arr.forEach((item, index)=>{
	        item.setAttribute('fieldid', fieldid + '_expand_' + index)
	    })
	}

	// 设置fieldid方案
	setFieleid = () => {
	    let {fieldid, prefixCls, popupClassName, expandTrigger} = this.props;
	    if (fieldid) {
	        // 添加fieldid时，设置popupClassName，以方便找到当前级联的下拉dom，给相应的dom内的icon添加唯一fieldid
	        this.setState({
	            popupClassName: popupClassName || fieldid
	        })
	        // 因初始化时下拉dom是不存在的，只有点击了级联组件才在body下创建了下拉dom，这里为了一定能找到相应的dom，所以添加了一个定时器
	        setTimeout(()=>{
	            if (expandTrigger == "hover") {
	                let arrLabel = document.querySelectorAll(`.${prefixCls}-menus.${this.state.popupClassName} .${prefixCls}-menu-item`)
	                arrLabel.forEach((node)=>{
	                    // node.addEventListener('mouseover', this.menuMouseoverHandle)
	                    this.menuAddEvent = addEventListener(node, 'mouseover', this.menuMouseoverHandle)
	                })
	            } else {
	                let arrLabel = document.querySelectorAll(`.${prefixCls}-menus.${this.state.popupClassName} .${prefixCls}-menu-item`)
	                arrLabel.forEach((node)=>{
	                    // node.addEventListener('click', this.menuMouseoverHandle)
	                    this.menuAddEvent = addEventListener(node, 'click', this.menuMouseoverHandle)
	                })
	                this.setDomFieldid()
	            }
	        }, 200)
	    }
	}

	menuMouseoverHandle = () => {
	    setTimeout(()=>{
	        this.setDomFieldid()
	    }, 200)
	}

	handleChange(value: Array<string>, selectedOptions: CascaderOption[]) {
	    this.setState({inputValue: ''});
	    if (selectedOptions[0].__IS_FILTERED_OPTION) {
	        const unwrappedValue =
				selectedOptions[0][keepFilteredValueField] === undefined
				    ? value[0]
				    : selectedOptions[0][keepFilteredValueField];
	        const unwrappedSelectedOptions = selectedOptions[0].path;
	        this.setValue(unwrappedValue, unwrappedSelectedOptions);
	        return;
	    }
	    this.setValue(value, selectedOptions);
	}

	setValue = (value: Array<string>, selectedOptions?: CascaderOption[]) => {
	    // if (!('value' in this.props)) {
	    //   this.setState({ value });
	    // }
	    this.setState({value});
	    const {onChange} = this.props;
	    onChange?.(value, selectedOptions);
	};

	handlePopupVisibleChange(popupVisible: boolean) {
	    if (!('popupVisible' in this.props)) {
	        this.setState(state => ({
	            popupVisible,
	            inputFocused: popupVisible,
	            inputValue: popupVisible ? state.inputValue : '',
	        }));
	    }
	    const {onPopupVisibleChange} = this.props;
	    onPopupVisibleChange?.(popupVisible);
	}

	clearSelection(e: React.MouseEvent<HTMLElement>) {
	    const {inputValue} = this.state;
	    e.preventDefault();
	    e.stopPropagation();
	    if (!inputValue) {
	        this.handlePopupVisibleChange(false);
	        setTimeout(() => {
	            this.setValue([]);
	        }, 200);
	    } else {
	        this.setState({inputValue: ''});
	    }
	}

	handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
	    // SPACE => https://github.com/ant-design/ant-design/issues/16871
	    if (e.keyCode === KeyCode.BACKSPACE || e.keyCode === KeyCode.SPACE) {
	        e.stopPropagation();
	    }
	}

	handleInputChange(e: string) {
	    this.setState({value: []});
	    const {popupVisible} = this.state;
	    const inputValue = e;
	    if (!popupVisible) {
	        this.handlePopupVisibleChange(true);
	    }
	    this.setState({inputValue});
	    // 可搜索时（showSearch），返回input的输入值及匹配项
	    const {onSearch} = this.props;
	    onSearch && onSearch(inputValue, this.matchingoptions);
	}

	handleInputBox() {
	    let {showSearch} = this.props;
	    if (showSearch) {
	        this.input!.focus()
	    }
	    this.setFieleid()
	}

	getPopupPlacement(direction = 'ltr') {
	    const {popupPlacement} = this.props;
	    if (popupPlacement !== undefined) {
	        return popupPlacement;
	    }
	    return direction === 'rtl' ? 'bottomRight' : 'bottomLeft';
	}

	handleInputClick(e: React.MouseEvent<HTMLInputElement>) {
	    const {inputFocused, popupVisible} = this.state;
	    // Prevent `Trigger` behaviour.
	    if (inputFocused || popupVisible) {
	        e.stopPropagation();
	    }
	}

	handleInputBlur() {
	    this.setState({
	        inputFocused: false,
	    });
	}

	highlightKeyword(str: string, keyword: string, prefixCls: string | undefined) {
	    return str.split(keyword).map((node, index) =>
	        index === 0
	            ? node
	            : [
	                <span className={`${prefixCls}-menu-item-keyword`} key="seperator">
	                    {keyword}
	                </span>,
	                node,
	            ],
	    );
	}

	defaultFilterOption(inputValue: string, path: CascaderOption[]) {
	    // let path = this.props.options
	    let names = this.getFilledFieldNames();
	    return path.some(option => (option[names.label]).indexOf(inputValue) > -1);
	}

	defaultRenderFilteredOption(inputValue: string, path: CascaderOption[]) {
	    let {prefixCls} = this.props
	    let names = this.getFilledFieldNames();
	    return path.map((option, index) => {
	        const label = option[names.label];
	        const node =
				(label).indexOf(inputValue) > -1
				    ? this.highlightKeyword(label, inputValue, prefixCls)
				    : label;
	        return index === 0 ? node : [' / ', node];
	    });
	}

	defaultSortFilteredOption(a: CascaderOption[], b: CascaderOption[], inputValue: string) {
	    // let { inputValue } = this.state
	    let names = this.getFilledFieldNames();

	    function callback(elem: any) {
	        return (elem[names.label]).indexOf(inputValue) > -1;
	    }

	    return a.findIndex(callback) - b.findIndex(callback);
	}

	flattenTree(options = this.props.options, props = this.props, ancestor = []) {
	    // let ancestor = []
	    const names = this.getFilledFieldNames();
	    let flattenOptions: CascaderOption[] = [];
	    const childrenName = names.children;
	    (options as Array<any>).forEach(option => {
	        const path = ancestor.concat(option);
	        if (props.changeOnSelect || !option[childrenName] || !option[childrenName].length) {
	            flattenOptions.push(path);
	        }
	        if (option[childrenName]) {
	            flattenOptions = flattenOptions.concat(this.flattenTree(option[childrenName], this.props, path));
	        }
	    });
	    return flattenOptions;
	}

	generateFilteredOptions(prefixCls: string) {
	    const {showSearch, notFoundContent} = this.props;
	    const names = this.getFilledFieldNames();
	    const {
	        filter = this.defaultFilterOption,
	        render = this.defaultRenderFilteredOption,
	        sort = this.defaultSortFilteredOption,
	        limit = 50,
	    } = showSearch as ShowSearchType;
	    const {flattenOptions = [], inputValue} = this.state;
	    // Limit the filter if needed
	    let filtered = [];
	    // debugger
	    if (limit > 0) {
	        filtered = [];
	        let matchCount = 0;

	        // Perf optimization to filter items only below the limit
	        flattenOptions.some((path: any) => {
	            const match = filter(this.state.inputValue, path, names);
	            if (match) {
	                filtered.push(path);
	                matchCount += 1;
	            }
	            return matchCount >= limit;
	        });
	    } else {
	        filtered = flattenOptions.filter((path: any) => filter(this.state.inputValue, path, names as FieldNames));
	    }

	    filtered = filtered.sort((a: CascaderOption[], b: CascaderOption[]) => sort(a, b, inputValue, names));

	    if (filtered.length > 0) {
	        // Fix issue: https://github.com/ant-design/ant-design/issues/26554
	        const field = names.value === names.label ? keepFilteredValueField : names.value;

	        return filtered.map(
	            (path: CascaderOption[]) =>
	                ({
	                    __IS_FILTERED_OPTION: true,
	                    path,
	                    [field]: path.map((o: CascaderOption) => o[names.value]),
	                    [names.label]: render(inputValue, path, prefixCls, names),
	                    disabled: path.some((o: CascaderOption) => !!o.disabled),
	                    isEmptyNode: true,
	                }),
	        );
	    }
	    return [this.getEmptyNode(names, notFoundContent as string)];
	}

	focus() {
	    (this.input as HTMLInputElement).focus();
	}

	blur() {
	    (this.input as HTMLInputElement).blur();
	}

	render() {
	    let {
	        prefixCls,
	        style,
	        // clearIcon,
	        // inputIcon,
	        children,
	        showSearch,
	        disabled,
	        allowClear,
	        suffixIcon,
	        className,
	        expandIcon,
	        size,
	        // getPopupContainer,
	        expandTrigger,
	        placeholder,
	        autoFocus,
	        bordered,
	        changeOnSelect,
	        dropdownRender,
	        fieldNames,
	        notFoundContent,
	        loadData,
	        // popupClassName,
	        fieldid
	    } = this.props;
	    const {value, inputValue, popupVisible, inputFocused} = this.state;
	    const direction = 'rtl';
	    const isRtlLayout = direction === 'rtl';
	    const pickerCls = classNames(
	        `${prefixCls}-picker`,
	        {
	            [`${prefixCls}-picker-rtl`]: isRtlLayout,
	            [`${prefixCls}-picker-with-value`]: inputValue,
	            [`${prefixCls}-picker-disabled`]: disabled,
	            //   [`${prefixCls}-picker-${mergedSize}`]: !!mergedSize,
	            [`${prefixCls}-picker-show-search`]: !!showSearch,
	            [`${prefixCls}-picker-focused`]: inputFocused,
	            [`${prefixCls}-picker-borderless`]: !bordered,
	        },
	        className,
	    );

	    const clearIcon =
			(allowClear && !disabled && value.length > 0) || inputValue ? (
			    <span className={`${prefixCls}-picker-clear`}>
			        <Icon
			            type='uf-close-c'
			            onClick={this.clearSelection}
			            fieldid = {fieldid ? fieldid + '_close' : undefined}
			        />
			    </span>

			) : null;
	    let inputIcon = suffixIcon ? <span className={`${prefixCls}-picker-arrow`}>{suffixIcon}</span> :
	        <span className={`${prefixCls}-picker-arrow`}><Icon fieldid = {fieldid ? fieldid + '_down' : undefined} type='uf-arrow-down'/></span>;

	    let adapterNid = getNid(this.props) // 适配nid、uitype
	    let input = (children || (
	        <span style={style} className={pickerCls} onClick={this.handleInputBox} {...adapterNid} fieldid={fieldid}>
	            <span className={`${prefixCls}-picker-label`}>{this.getLabel()}</span>
	            <Input
	                // {...inputProps}
	                // tabIndex={-1}
	                ref={this.saveInput}
	                // prefixCls={prefixCls}
	                placeholder={value && value.length > 0 ? undefined : placeholder}
	                // className={`${prefixCls}-input ${sizeCls}`}
	                size={size}
	                className={`${prefixCls}-input`}
	                value={inputValue}
	                disabled={disabled}
	                readOnly={!showSearch}
	                // autoComplete={inputProps.autoComplete || 'off'}
	                onClick={showSearch ? this.handleInputClick : undefined}
	                onBlur={showSearch ? this.handleInputBlur : undefined}
	                onKeyDown={this.handleKeyDown}
	                onChange={showSearch ? this.handleInputChange : undefined}
	                autoFocus={autoFocus}
	                fieldid={fieldid ? fieldid + '-input' : undefined}
	            />
	            {clearIcon}
	            {inputIcon}
	        </span>
	    ));
	    let expandIconNode;
	    if (expandIcon) {
	        expandIconNode = expandIcon;
	    } else {
	        expandIconNode = isRtlLayout ? <Icon type='uf-arrow-right'/> : <Icon type='uf-arrow-left'/>;
	    }
	    // console.log('^&^&^&^&^&^&',this.getOptios())
	    let {options} = this.props;
	    const names = this.getFilledFieldNames();
	    if (options && options.length > 0) {
	        if (inputValue) {
	            options = this.generateFilteredOptions(prefixCls as string);
	        }
	    } else {
	        options = [this.getEmptyNode(names, notFoundContent as string)];
	    }
	    this.matchingoptions = options
	    let dropdownMenuColumnStyle: React.CSSProperties = {};
	    let isNotFound = (options as CascaderOption[]).length === 1 && (options as CascaderOption[])[0].isEmptyNode
	    if (isNotFound) {
	        dropdownMenuColumnStyle.height = 'auto';
	    }
	    let resultListMatchInputWidth = false;
	    if (showSearch && (typeof showSearch === 'object')) {
	        resultListMatchInputWidth = showSearch.matchInputWidth !== false;
	    }
	    if (resultListMatchInputWidth || ((inputValue || isNotFound) && this.input)) {
	        dropdownMenuColumnStyle.width = (this.input as any).input.offsetWidth;
	    }
	    const rcCascaderPopupClassName = classNames(this.state.popupClassName, {
	        [`${prefixCls}-menu-${direction}`]: direction === 'rtl',
	        [`${prefixCls}-menu-empty`]:
			(options as CascaderOption[]).length === 1 && (options as CascaderOption[])[0].value === 'ANT_CASCADER_NOT_FOUND',
	    });
	    let loadingIcon = (
	        <span className={`${prefixCls}-menu-item-loading-icon`}>
	            {/* <Icon type="uf-qq" /> */}
	            <div className={`${prefixCls}-spinner-icon`}></div>
	        </span>
	    )
	    return (
	        <RcCascader
	            // options={this.props.options}
	            options={options as CascaderOption[]}
	            prefixCls={prefixCls}
	            getPopupContainer={this.getPopupContainerDom}
	            value={value as (string | number)[]}
	            popupVisible={popupVisible}
	            onPopupVisibleChange={this.handlePopupVisibleChange}
	            onChange={this.handleChange}
	            expandIcon={expandIconNode}
	            loadingIcon={loadingIcon}
	            popupPlacement={this.getPopupPlacement(direction)}
	            disabled={disabled}
	            expandTrigger={expandTrigger}
	            dropdownMenuColumnStyle={dropdownMenuColumnStyle}
	            changeOnSelect={changeOnSelect}
	            dropdownRender={dropdownRender}
	            fieldNames={fieldNames}
	            loadData={loadData}
	            popupClassName={rcCascaderPopupClassName}
	            // {...other}
	        >
	            {input as React.ReactElement}
	        </RcCascader>
	    )
	}
}
// Cascader.propTypes = propTypes;
export default Cascader;
