/* eslint-disable react/prop-types */
// import PropTypes from 'prop-types'
import React, {Component, useContext} from 'react'
import {ConfigContext, WithConfigConsumer} from '../../wui-provider/src/context'
import DatePicker from './DatePicker'
import RangePicker from './RangePicker'
import {DatePickerProps, PickerLocale, RangePickerProps, DateRangePickerProps} from './iPicker'

// 适配 antd的 picker属性
@WithConfigConsumer()
class DatePickerAdapter extends Component<DatePickerProps | RangePickerProps | DateRangePickerProps> {
	static WeekPicker: typeof DatePicker;
	static MonthPicker: typeof DatePicker;
	static QuarterPicker: typeof DatePicker;
	static HalfYearPicker: typeof DatePicker;
	static YearPicker: typeof DatePicker;
	static RangePicker: typeof RangePicker;
	render() {
	    const {picker, ...other}: any = this.props
	    switch (picker) {
	        case 'range':
	            return <RangePicker {...other} />
	        default:
	            return <DatePicker picker={picker} {...other} />
	    }
	}
}

const DatePickerProvider = (props: DatePickerProps, ref: React.Ref<any>) => {
    let {locale, ...other} = props
    const context = useContext(ConfigContext) // 接受provider控制
    const locale1 = 'locale' in props ? locale : context.locale
    // 此处locale 暂时修改类型  日后需要改正 @刘向阳
    return <DatePickerAdapter {...other} locale={locale1 as string | PickerLocale | undefined} ref={ref} />
}

const dateMap: Record<string, string> = {
    week: 'WeekPicker',
    month: 'MonthPicker',
    quarter: 'QuarterPicker',
    halfYear: 'HalfYearPicker', // 半年
    year: 'YearPicker'
}
for (let k in dateMap) {
    const Comp = (others: DatePickerProps, ref: React.Ref<any>) =>
        DatePickerProvider({...others, picker: k} as DatePickerProps, ref)
    ;(DatePickerAdapter as Record<string, any>)[dateMap[k]] = React.forwardRef(Comp)
}

(DatePickerAdapter as Record<string, any>).RangePicker = React.forwardRef((props: RangePickerProps, ref: React.Ref<any>) => {
    let {locale, ranges, presets, ...other} = props
    const context = useContext(ConfigContext) // 接受provider控制
    const locale1 = 'locale' in props ? locale : context.locale
    // 此处locale 暂时修改类型  日后需要改正 @刘向阳
    return <RangePicker {...other} ranges={ranges || presets} locale={locale1 as PickerLocale | undefined} ref={ref} />
})

// DatePickerAdapter.propTypes = {
//     getPopupContainer: PropTypes.func,
//     picker: PropTypes.oneOf(['date', 'week', 'month', 'year', 'range'])
// }
export default DatePickerAdapter
