/*
 * @Author: Mr.mjc
 * @Date: 2022-06-28 10:56:19
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2023-02-03 16:41:05
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/lib/filterColumn.tsx
 */
// import PropTypes from "prop-types";
import React, {Component} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Button from "../../../wui-button/src";
import Checkbox from "../../../wui-checkbox/src";
import {prefix} from "../../../wui-core/src/index"
import Icon from "../../../wui-icon/src";
import Input from "../../../wui-input/src";
import i18n from "./i18n";
import {ObjectAssign} from "./util";
import {getLangInfo} from "../../../wui-locale/src/tool";
import {WithConfigConsumer} from "../../../wui-provider/src/context";
import { TableProps, IFilterColumnState, ColumnType } from '../iTable';
import { TableInterface } from "../index";

/**
 * 参数: 过滤表头
 * @param {*} Table
 * @param {*} Popover
 * @param {*} Icon
 */

export default function filterColumn(Table: React.ComponentClass<Partial<TableProps>> | TableInterface) {
    class FilterColumn extends Component<TableProps, IFilterColumnState> {
		static defaultProps = {
		    prefixCls: `${prefix}-table-filter-column`,
		    afterFilter: () => {},
		    columnFilterAble: true,
		    scroll: {},
		    // locale: 'zh-cn',
		    lockable: true, // 是否开启锁定列功能
		    filterCheckboxProps: (_column?: ColumnType) => {}
		};
		// static propTypes = {
		//     columns: PropTypes.any,
		//     data: PropTypes.any,
		//     scroll: PropTypes.any,
		//     showFilterPopover: PropTypes.bool,
		//     columnFilterAble: PropTypes.bool,
		//     checkMinSize: PropTypes.number,
		//     afterFilter: PropTypes.func,
		//     lockable: PropTypes.bool,
		//     locale: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		// };
		// static contextTypes = {
		//     beeLocale: PropTypes.object
		// }
		dragArr: ColumnType[] = [];
		unDragArrLeft: ColumnType[] = [];
		unDragArrRight: ColumnType[] = [];

		constructor(props: TableProps) {
		    super(props);
		    const {columns} = props;
		    this.state = {
		        columns: this.setColumOrderByIndex(ObjectAssign(columns) as ColumnType[]),
		        tempColumns: this.setColumOrderByIndex(ObjectAssign(columns) as ColumnType[]), // 修改，未确定之前，临时的变量,popover展示的数据源
		        srcColumns: this.setColumOrderByIndex(ObjectAssign(columns) as ColumnType[]), // 最原始的数据源
		        showModal: false,
		        searchStr: '',
		    };
		}

		setColumOrderByIndex = (_column: ColumnType[]) => {
		    _column.forEach((da: ColumnType) => {
		        // 默认所有的列都显示，如果传递ifshow属性，根据ifshow属性值来判断是否显示某列
		        if (da.hasOwnProperty("ifshow")) {
		            da.checked = !!da.ifshow;
		            da.ifshow = da.checked;
		            da.isShow = da.checked;
		        } else if (da.hasOwnProperty("isShow")) {
		            da.checked = !!da.isShow;
		            da.ifshow = da.checked;
		            da.isShow = da.checked;
		        } else {
		            da.checked = true;
		            da.ifshow = true;
		            da.isShow = true;
		        }
		    });
		    return _column;
		};

		componentWillReceiveProps(nextProps: TableProps) {
		    if (nextProps.columns != this.props.columns) {
		        this.setState({
		            columns: this.setColumOrderByIndex(ObjectAssign(nextProps.columns) as ColumnType[]),
		            tempColumns: this.setColumOrderByIndex(ObjectAssign(nextProps.columns) as ColumnType[]),
		            srcColumns: this.setColumOrderByIndex(ObjectAssign(nextProps.columns) as ColumnType[])
		        });
		    }
		    this.setState({
		        showModal: !!nextProps.showFilterPopover
		    });
		}

		checkedColumItemClick = (da: ColumnType) => {
		    let {checkMinSize} = this.props; // 设置最少被选中的
		    let sum = 0,
		        leng = 0;
		    this.state.tempColumns.forEach(da => {
		        da.fixed ? "" : leng++;
		        !da.fixed && da.checked ? sum++ : "";
		    });
		    if (checkMinSize && sum < checkMinSize && da.checked) {
		        return false;
		    } else {
		        if (sum <= 1 && da.checked) return;
		    }
		    da.checked = !da.checked;
		    da.ifshow = !!da.checked;
		    da.isShow = !!da.checked;

		    this.setState({
		        ...this.state
		    });
		    // afterFilter(da, this.state.columns);
		};

		openCloumList = () => {
		    this.setState({
		        showModal: true
		    });
		};

		onHide = () => {
		    this.setState({
		        showModal: false
		    })
		}

		onChange = (e: string) => {
		    // console.log(e)
		    this.setState({
		        searchStr: e || ''
		    })
		}

		search = (str: any) => {
		    // return str.title.indexOf(this.state.searchStr) !== -1
		    // return typeof str.title == 'string' ? str.title.indexOf(this.state.searchStr) !== -1 : true; // 兼容处理，不是字符串时的情况
		    const { searchStr } = this.state;
		    if (typeof str.title == 'string') {
		        return str.title.indexOf(searchStr) !== -1
		    } else {
		        return this.getChildrenText(str.title).indexOf(searchStr) !== -1
		    }
		}

		/**
		 * 递归获取React元素子集的文本集合
		 * @param elem
		 * @param results
		 * @returns {*}
		 */
		getChildrenText = (elem: HTMLElement | string | undefined, results?: (string | HTMLElement)[]) => {
		    let _results: (string | HTMLElement)[] = results ? results : [];
		    if (!React.isValidElement(elem) && (typeof elem == 'string' || !Object.is(elem, NaN))) {
		        _results.push(elem === undefined ? "" : elem);
		    } else {
		        if (React.isValidElement(elem)) { // react对象则遍历children
		            let self = this;
		            if (Array.isArray((elem.props as HTMLElement).children)) {
		                // @ts-ignore
		                (elem.props as HTMLElement).children.forEach((item: HTMLElement | string) => {
		                    self.getChildrenText(item, _results);
		                })
		            } else {
		                // @ts-ignore
		                self.getChildrenText((elem.props as HTMLElement).children, _results);
		            }
		        }
		    }
		    return _results.join();
		}

		// sort = (a: any) => {
		//     // 兼容火狐浏览器排序错误
		//     let returnNum = a.fixed ? -1 : 1
		//     return returnNum;
		// }

		lockClick = (da: ColumnType) => {
		    let {lockIndex, fixed} = da;
		    const {tempColumns} = this.state;
		    tempColumns.forEach((da, i) => {
		        if (lockIndex && fixed && i >= lockIndex) {
		            da.fixed = undefined;
		        }

		        if (lockIndex && !fixed && i <= lockIndex) {
		            da.fixed = 'left';
		        }

		    })

		    this.setState({
		        ...this.state
		    });
		}

		getCloumItem = () => {
		    const {prefixCls, lockable = true, filterCheckboxProps, fieldid} = this.props;
		    const {tempColumns} = this.state;
		    this.dragArr = [];
		    this.unDragArrLeft = [];
		    this.unDragArrRight = [];
		    let fieldidCheckBox = fieldid ? { fieldid: `${fieldid}_check_box` } : {};
		    let fieldidDrag = fieldid ? { fieldid: `${fieldid}_drag` } : {};
		    let fieldidLock = fieldid ? { fieldid: `${fieldid}_lock` } : {};
		    let fieldidUnlock = fieldid ? { fieldid: `${fieldid}_unlock` } : {};
		    // tempColumns.forEach((da, i) => {
		    //     let paramObj = {
		    //         id: da.key,
		    //         checked: da.checked
		    //     }
		    //     da.lockIndex = i;
		    //     if (da.fixed === true || da.fixed === 'left' || da.required) {
		    //         // eslint-disable-next-line dot-notation
		    //         paramObj['disable'] = true;
		    //         this.unDragArrLeft.push(da);
		    //     } else if (da.fixed === 'right') {
		    //         // eslint-disable-next-line dot-notation
		    //         paramObj['disable'] = true;
		    //         this.unDragArrRight.push(da);
		    //     } else {
		    //         // eslint-disable-next-line dot-notation
		    //         paramObj['onClick'] = () => {
		    //             this.checkedColumItemClick(da);
		    //         }
		    //         this.dragArr.push(da);
		    //     }
		    // });
		    tempColumns.forEach((da, i) => {
		        // let paramObj = {
		        //     id: da.key,
		        //     checked: da.checked
		        // }
		        da.lockIndex = i;
		        if (da.fixed === true || da.fixed === 'left') {
		            // eslint-disable-next-line dot-notation
		            // paramObj['disable'] = true;
		            this.unDragArrLeft.push(da);
		        } else if (da.fixed === 'right') {
		            // eslint-disable-next-line dot-notation
		            // paramObj['disable'] = true;
		            this.unDragArrRight.push(da);
		        } else {
		            // eslint-disable-next-line dot-notation
		            // paramObj['onClick'] = () => {
		            //     this.checkedColumItemClick(da);
		            // }
		            this.dragArr.push(da);
		        }
		    });
		    return (
		        <DragDropContext onDragEnd={this.onDragEnd}>
		            {
		                this.unDragArrLeft.filter(this.search).map((da, i) => ( // 表现行为和列表展示一致
		                    <div
		                        key={da.key + "_" + i}
		                        className={`${prefixCls}-pop-cont-item`}
		                    >
		                        <Icon type="uf-tuodong" { ...fieldidDrag }/>
		                        <Checkbox { ...fieldidCheckBox } {...((filterCheckboxProps && filterCheckboxProps(da)) || {})} checked={da.checked} disabled>
		                            {da.title}
		                        </Checkbox>
		                        {lockable && da.fixed && <Icon type="uf-dongjie" { ...fieldidLock} onClick={() => {
		                            this.lockClick(da)
		                        }}/>}
		                        {lockable && !da.fixed && <Icon type="uf-weidongjie" { ...fieldidUnlock } onClick={() => {
		                            this.lockClick(da)
		                        }}/>}
		                    </div>
		                ))
		            }
		            <Droppable droppableId="droppable">
		                {(provided: any) => (
		                    <div
		                        ref={provided.innerRef}
		                    >
		                        {
		                            this.dragArr.filter(this.search).map((da, i) =>
		                                (
		                                    <Draggable key={da.key + "_" + i} index={i} draggableId={da.key + "_" + i}>
		                                        {(provided: any) => (
		                                            <div
		                                                ref={provided.innerRef}
		                                                {...provided.draggableProps}
		                                                {...provided.dragHandleProps}
		                                                key={da.key + "_" + i}
		                                                className={`${prefixCls}-pop-cont-item`}
		                                            >
		                                                <Icon type="uf-tuodong" { ...fieldidDrag }/>
		                                                <Checkbox { ...fieldidCheckBox } checked={da.checked} onClick={() => this.checkedColumItemClick(da)} {...((filterCheckboxProps && filterCheckboxProps(da)) || {})}>
		                                                    {da.title}
		                                                </Checkbox>
		                                                {lockable && da.fixed && <Icon type="uf-dongjie" { ...fieldidLock} onClick={() => {
		                                                    this.lockClick(da)
		                                                }}/>}
		                                                {lockable && !da.fixed && <Icon type="uf-weidongjie" { ...fieldidUnlock } onClick={() => {
		                                                    this.lockClick(da)
		                                                }}/>}
		                                            </div>
		                                        )}
		                                    </Draggable>
		                                )
		                            )
		                        }
		                        {provided.placeholder}
		                    </div>
		                )}
		            </Droppable>
		            {
		                this.unDragArrRight.filter(this.search).map((da, i) => ( // 表现行为和列表展示一致
		                    <div
		                        key={da.key + "_" + i}
		                        className={`${prefixCls}-pop-cont-item`}
		                    >
		                        <Icon type="uf-tuodong" { ...fieldidDrag }/>
		                        <Checkbox { ...fieldidCheckBox } {...((filterCheckboxProps && filterCheckboxProps(da)) || {})} checked={da.checked} disabled>
		                            {da.title}
		                        </Checkbox>
		                        {lockable && da.fixed && <Icon type="uf-dongjie" { ...fieldidLock} onClick={() => {
		                            this.lockClick(da)
		                        }}/>}
		                        {lockable && !da.fixed && <Icon type="uf-weidongjie" { ...fieldidUnlock } onClick={() => {
		                            this.lockClick(da)
		                        }}/>}
		                    </div>
		                ))
		            }
		        </DragDropContext>
		    );
		};

		clear = () => {
		    const {srcColumns} = this.state;
		    srcColumns.forEach(da => {
		        da.checked = true;
		        da.ifshow = true;
		        da.isShow = true;
		    });
		    this.setState({
		        tempColumns: srcColumns,
		        searchStr: ''
		    });
		    // this.props.afterFilter(this.state.columns, this.state.tempColumns);
		};

		// getCloumnsScroll = (columns: ColumnType[]) => {
		//     let sum = 0;
		//     columns.forEach((da: ColumnType) => {
		//         if (da.checked) {
		//             sum += Number(da.width);
		//         }
		//     });
		//     // console.log("sum",sum);
		//     return sum;
		// };

		save = () => {
		    const { afterFilter } = this.props;
		    this.setState({
		        columns: ObjectAssign(this.state.tempColumns) as ColumnType[]
		    })
		    afterFilter && afterFilter(this.state.columns, this.state.tempColumns);
		    this.onHide()
		};

		onDragEnd = (result: any) => {
		     // console.log(result)
			 if (!result.destination) {
		        return;
		    }
		    // const {tempColumns} = this.state
		    const [removed] = this.dragArr.splice(result.source.index, 1);
		    this.dragArr.splice(result.destination.index, 0, removed);
		    this.setState({
		        tempColumns: this.unDragArrLeft.concat(this.dragArr).concat(this.unDragArrRight)
		    })

		};

		onVisible = (bool: boolean) => {
		    if (bool) { // 弹框显示，同步popover要展示的数据
		        this.setState({
		            tempColumns: ObjectAssign(this.state.columns) as ColumnType[],
		            searchStr: ''
		        });
		    }
		}
		lockTableClick = (column: ColumnType, index: number) => {
		    let {lockIndex = index, fixed} = column;
		    const {tempColumns} = this.state;
		    const { afterFilter } = this.props;
		    tempColumns.forEach((da, i) => {
		        if (fixed && i >= lockIndex) {
		            da.fixed = undefined;
		        }

		        if (!fixed && i <= lockIndex) {
		            da.fixed = 'left';
		        }

		    })

		    this.setState({
		        columns: ObjectAssign(this.state.tempColumns) as ColumnType[]
		    })
		    afterFilter && afterFilter(this.state.columns, this.state.tempColumns);
		}
		lockColumn = (column: ColumnType, index: number) => {
		    const { lockable = true, fieldid } = this.props;
		    let fieldidLockAttr = fieldid ? { fieldid: `${fieldid}_filter_column_lock_icon` } : {};
		    let fieldidUnlockAttr = fieldid ? { fieldid: `${fieldid}_filter_column_unlock_icon` } : {};
		    if (column.dataIndex === 'checkbox' || column.dataIndex === 'radio') {
		        return column;
		    }
		    let wrapClassName = column.fixed ? `${prefix}-table-column-lock ${prefix}-table-column-lock-active` : `${prefix}-table-column-lock`;
		    let lockButton = <div className={`${wrapClassName} ${prefix}-table-title-icon`}>
		        {column.fixed && <Icon type="uf-dongjie" { ...fieldidLockAttr } onClick={() => {
		            this.lockTableClick(column, index)
		        }}/>}
		        {!column.fixed && <Icon type="uf-weidongjie" { ...fieldidUnlockAttr } onClick={() => {
		            this.lockTableClick(column, index)
		        }}/>}
		    </div>;

		    column.title = <>
		        {typeof column.title === 'string' ? <span className={`${prefix}-table-title-text-span`}
														  title={column.title}>{column.title}</span> : column.title}
		        {lockable ? lockButton : null}
		    </>;
		    return column;
		}
		renderColums = (columns: ColumnType[]) => {
		    let tempColumns = [];
		    tempColumns = columns.map((originColumn: ColumnType, i: number) => {
		        let column = Object.assign({}, originColumn);
		        return this.lockColumn(column, i);
		    });
		    return tempColumns;
		}

		render() {
		    const {data, prefixCls, fieldid} = this.props;
		    const {columns, showModal} = this.state;

		    let locale = getLangInfo(this.props.locale, i18n);
		    let fieldidInput = fieldid ? { fieldid } : {};
		    let fieldidReset = fieldid ? { fieldid: `${fieldid}_reset` } : {};
		    let fieldidCancel = fieldid ? { fieldid: `${fieldid}_cancel` } : {};
		    let fieldidOk = fieldid ? { fieldid: `${fieldid}_ok` } : {};
		    let _columns: ColumnType[] = [];
		        // widthState = 0;
		        // scroll = scrollPro;
		    columns.forEach(da => {
		        if (da.ifshow || da.isShow) {
		            _columns.push(da);
		            // if (da.width) {
		            //     widthState++;
		            // }
		        }
		    });
		    // if(_columns.length == widthState){
		    //   scroll.x = this.getCloumnsScroll(columns);
		    // }

		    let content = (
		        <div className={`${prefixCls}-pop-cont`}>
		            <div className={`${prefixCls}-pop-header`}>
		                <Input type="search" value={this.state.searchStr} onChange={this.onChange} { ...fieldidInput }
							   onSearch={this.onChange}/>
		            </div>
		            <div className={`${prefixCls}-pop-content`}>
		                {this.getCloumItem()}
		            </div>
		            <div className={`${prefixCls}-pop-footer`}>
		                <span className={`${prefixCls}-clear-setting`} onClick={this.clear} { ...fieldidReset }>
		                    {locale.langMap.resetSettings || '还原设置'}
		                </span>
		                <Button colors="secondary" size="sm" { ...fieldidCancel } onClick={this.onHide}>{locale.langMap.cancel || '取消'}</Button>
		                <Button colors="primary" size="sm" { ...fieldidOk } onClick={this.save}>{locale.langMap.ok || '确定'}</Button>
		            </div>
		        </div>
		    );

		    return (
		        <Table
		            {...this.props}
		            columns={this.renderColums(_columns)}
		            data={data}
		            filterColumnContent={content}
		            filterColumnShowModal={showModal}
		            filterColumnOnHide={this.onHide}
		            filterColumnOnVisibleChange={this.onVisible}
		            filterColumnOpenCloumList={this.openCloumList}
		        />
		    );
		}
    }
    return WithConfigConsumer()(FilterColumn) as React.ComponentClass<Partial<TableProps>> | TableInterface;
}
