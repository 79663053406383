import React from 'react';
import { ResizerProps } from './iLayout';
import classNames from 'classnames';
import { WebUI } from "../../wui-core/src/index";
// import Prefixer from 'inline-style-prefixer';
// import stylePropType from 'react-style-proptype';

@WebUI({name: "spliter-resizer"})
class Resizer extends React.Component<ResizerProps> {
    render() {
        const {
            className,
            onClick,
            onDoubleClick,
            onMouseDown,
            onTouchEnd,
            onTouchStart,
            clsPrefix,
            direction,
            style,
            trigger
        } = this.props;
        const classes = classNames(clsPrefix, direction, className);

        return (
            <div className={classes}>
                <span
                    className={`${clsPrefix}-line`}
                    style={style || {}}
                    onMouseDown={onMouseDown}
                    onTouchStart={event => {
                        event.preventDefault();
                        onTouchStart && onTouchStart(event);
                    }}
                    onTouchEnd={event => {
                        event.preventDefault();
                        onTouchEnd && onTouchEnd(event);
                    }}
                    onClick={event => {
                        if (onClick) {
                            event.preventDefault();
                            onClick(event);
                        }
                    }}
                    onDoubleClick={event => {
                        if (onDoubleClick) {
                            event.preventDefault();
                            onDoubleClick(event);
                        }
                    }}
                />
                {trigger && React.cloneElement(trigger)}
            </div>
        );
    }
}

export default Resizer;
