// import PropTypes from "prop-types";
import React, {Component} from 'react';
import Button from '../../wui-button/src';
import Icon from '../../wui-icon/src';
import {getLangInfo} from "../../wui-locale/src/tool";
import i18n from './i18n';
import { ModalConfirmProps, ModalConfirmState, ModalType } from "./iModal";
import Modal from './Modal';
import {prefix} from "../../wui-core/src/index";

// const propTypes = {
//     title: PropTypes.string,
//     keywords: PropTypes.string,
//     content: PropTypes.string,
//     onOk: PropTypes.func,
//     onCancel: PropTypes.func,
//     okType: PropTypes.string,
//     show: PropTypes.bool,
//     close: PropTypes.bool,
//     keyword: PropTypes.string,
//     type: PropTypes.string,
//     zIndex: PropTypes.string,
//     okText: PropTypes.string,
//     cancelText: PropTypes.string,
//     getPopupContainer: PropTypes.func,
//     okButtonProps: PropTypes.object,
//     cancelButtonProps: PropTypes.object,
//     locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
//     fieldid: PropTypes.string,
// };

const defaultProps = {
    // title: "提示信息",
    onOk: () => {
    },
    onCancel: () => {
    },
    // content: "确认要删除吗 ?",
    show: false,
    locale: 'zh-cn',
    // keyword: '删除',
};

const iconTypes = {
    success: 'uf-correct',
    info: 'uf-i-c',
    warning: 'uf-exc-t',
    error: 'uf-exc-c',
    default: 'uf-i-c'
}

class AlertDialog extends Component<ModalConfirmProps, ModalConfirmState> {
    static defaultProps = defaultProps;
    constructor(props: ModalConfirmProps) {
        super(props);
        this.state = {
            show: true,
            okLoading: false
        }
    }

    /* eslint-disable */
    UNSAFE_componentWillReceiveProps(nextProps: ModalConfirmProps) {
        const {show} = nextProps;
        if (typeof show === 'boolean') {
            this.setState({
                show
            })
        }
    }

	closeModal = () => {
	    this.setState({
	        show: false,
	        okLoading: false
	    })
	}

	handleOK = () => {
	    const {onOk} = this.props
	    const res: boolean | void | Promise<boolean> = onOk && onOk instanceof Function && onOk()
	    if (!res || !res.then) {
	        return this.closeModal()
	    } else {
	        this.setState({okLoading: true})
	        res.then(() => {
	            this.closeModal()
	        }, (error) => {
	            this.setState({okLoading: false})
	            console.error(error)
	        })
	    }
	}

	render() {
	    const {okLoading} = this.state
	    let {
	        close, title, keyword, content, onCancel, type, zIndex, locale, okType, fieldid, width, footer,
	        okText = '', cancelText = '', getPopupContainer, className = '', okButtonProps = {}, cancelButtonProps = {}, 
			afterClose, bodyStyle, centered, keyboard, style, onOk, autoFocus
	    } = this.props;
	    const iconType = type as Exclude<ModalType, 'confirm'>  in iconTypes ? iconTypes[type as Exclude<ModalType, 'confirm'>] : iconTypes.default
	    const isConfirmType = type as ModalType & 'confirm' === 'confirm'
	    const keywordTitleHack = title || keyword
	    const local = getLangInfo(locale, i18n);
	    // 按钮组
	    return (
	        <span>
	            <Modal
	                show={this.state.show}
	                wrapClassName={`ac-confirm ${className}`}
	                onHide={close}
	                container={getPopupContainer}
	                draggable={true}
	                width={width || 420}
	                header={null}
	                fieldid={fieldid}
	                zIndex={zIndex || 1700}
					footer={footer}
					afterClose = {afterClose}
					bodyStyle = {bodyStyle}
					centered = {centered}
					keyboard = {keyboard} 
					style = {style}
					onOk={onOk}
					autoFocus={autoFocus}
	            >
	                <Modal.Body fieldid={fieldid ? `${fieldid}_modal_body` : undefined} className='ac-confirm-body'>
	                    <span className='ac-confirm-body-title'>
	                        <Icon fieldid={fieldid ? `${fieldid}_modal_icon` : undefined} type={iconType}
								  className={`ac-confirm-body-title-icon ${isConfirmType ? 'confirm-type' : ''}`}></Icon>
	                        <span className='ac-confirm-body-title-keyword'>{keywordTitleHack}</span>
	                    </span>
	                    {content && <span fieldid={fieldid ? `${fieldid}_modal_tips` : undefined} className='ac-confirm-body-content'>{content}</span>}
	                </Modal.Body>

	                {footer? null : <Modal.Footer className='ac-confirm-footer'>
	                    <Button className={`${prefix}-modal-cancel-button`} fieldid={fieldid ? `${fieldid}_modal_footer_cancel` : undefined} colors="secondary" onClick={() => {
	                        this.setState({
	                            show: false
	                        })
	                        onCancel && onCancel()
	                    }}
	                    {...cancelButtonProps}>
	                        {cancelText || local.langMap.cancel}
	                    </Button>
	                    <Button className={`${prefix}-modal-ok-button`} colors={okType || "primary"} disabled={okLoading}
                            fieldid={fieldid ? `${fieldid}_modal_footer_ok` : undefined}
	                        onClick={this.handleOK}
	                        {...okButtonProps}
	                    >
	                        {okText || local.langMap.ok}
	                    </Button>
	                </Modal.Footer>}
	            </Modal>
	        </span>
	    )
	}
}

// AlertDialog.propTypes = propTypes;
// AlertDialog.defaultProps = defaultProps;
export default AlertDialog;
