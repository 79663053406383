import classnames from 'classnames'
import omit from 'omit.js'
// import PropTypes from 'prop-types'import TextArea from 'rc-textarea'
import TextArea from 'rc-textarea'
import React, {Component} from 'react'
import Icon from '../../wui-icon/src'
import Button from '../../wui-button/src'
import {WebUI, prefix as componentPrefix} from '../../wui-core/src/index'
import {InputWithDefaultProps, InputProps, InputState, DefaultProps} from './iInput'

const textareaPrefix = componentPrefix + '-textarea'

const defaultProps: DefaultProps = {
    componentClass: 'input',
    clsPrefix: 'wui-input',
    type: 'text',
    size: 'md',
    trigger: 'click',
    iconRender: () => null,
    // showMaxLabel: false, // 需兼容showCount
    allowInputOverMax: true,
    passwordVisible: false,
    visibilityToggle: true,
    debounceDelay: 0,
    antd: false
}

let cutValue = (value?: string | number, maxLength?: number) => {
    if (maxLength && value) {
        value = value.toString().substring(0, maxLength)
    }
    return value ?? ''
}

@WebUI({name: 'input', defaultProps})
class Input extends Component<InputWithDefaultProps, InputState> {
    constructor(props: InputWithDefaultProps) {
        super(props)
        const value =
            props.value === undefined
                ? cutValue(props.defaultValue, props.maxLength)
                : cutValue(props.value, props.maxLength)
        this.state = {
            passwordVisible: props.passwordVisible,
            showSearch: !props.value,
            value,
            focused: false,
            prevValue: props.value
        }
        this.input = null
        this.clickClearBtn = false
        this.lastScrollCall = 0
    }

    private input: any
    private clickClearBtn: boolean
    private lastScrollCall: number
    private e!: any

    static getDerivedStateFromProps(nextProps: InputProps, {prevValue}: InputState) {
        const {value, passwordVisible} = nextProps
        let newState: InputState = {prevValue: value}
        if (value !== undefined || prevValue !== value) {
            newState.value = value
        }
        if (nextProps.passwordVisible !== undefined) {
            newState.passwordVisible = passwordVisible
        }
        return newState
    }

    handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {onChange, debounceDelay, antd, value: propValue} = this.props
        const now = new Date().getTime()
        if (now - this.lastScrollCall < debounceDelay) return
        this.lastScrollCall = now
        // const value = cutValue(this.input.value || e.target.value, maxLength)
        // #QDJCJS-7720设置maxLength后搜狗输入法录入中文过程中，当录入快到达最大长度时，会删已经录入的文本
        const value = this.input.value || e.target.value
        if (propValue === undefined) {
            this.setState({
                value: value ?? '',
                showSearch: value == null || value === ''
            })
        }
        antd ? onChange?.(e) : onChange?.(value, e)
    }

    handleTextareaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {onChange, debounceDelay, antd, value: propValue, allowInputOverMax, maxLength} = this.props
        const now = new Date().getTime()
        if (now - this.lastScrollCall < debounceDelay) return
        this.lastScrollCall = now

        // 超长处理
        let limitValue = e.target.value
        if (!allowInputOverMax && maxLength && !this.input.isComposition) {
            let innerBreakNum = 0
            limitValue = [...(limitValue ?? '')] // spread操作符用于处理表情等合成字符slice后错误或乱码等问题，如：'👨👨👨'.slice(0, 2) === '👨'
                .filter((char, i) => {
                    if (char === '\n') innerBreakNum++ // QDJCJS-9920，修复右下角角标忽略了换行符，但value未忽略问题，保持同步
                    return char === '\n' || (char !== '\n' && i < maxLength + innerBreakNum)
                }) // QDJCJS-9829,修复结尾字符为回车\n时超长无法继续输入问题(忽略换行符，保证输入长度与角标一致)
                .join('')
        }

        // const value = cutValue(this.input.value || e.target.value, maxLength)
        // #QDJCJS-7720设置maxLength后搜狗输入法录入中文过程中，当录入快到达最大长度时，会删已经录入的文本(去掉cutValue方法之后，录入过程字节数可能会超过maxLength,但最终结果保持不变)
        // const value = this.input.value || limitValue
        if (propValue === undefined) {
            this.setState({value: limitValue ?? ''})
        }
        antd ? onChange?.(e) : onChange?.(limitValue, e)
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {onChange, debounceDelay, antd, value: propValue} = this.props
        const now = new Date().getTime()
        if (now - this.lastScrollCall < debounceDelay) return
        this.lastScrollCall = now
        // const value = cutValue(this.input.value || e.target.value, maxLength)
        // #QDJCJS-7720设置maxLength后搜狗输入法录入中文过程中，当录入快到达最大长度时，会删已经录入的文本(去掉cutValue方法之后，录入过程字节数可能会超过maxLength,但最终结果保持不变)
        const value = this.input.value || e.target.value
        if (propValue === undefined) {
            this.setState({value: value ?? ''})
        }
        antd ? onChange?.(e) : onChange?.(value, e)
    }

    // 输入中文处理，文字合成输入过程中不再触发change
    handleComposition = (e: React.CompositionEvent<HTMLInputElement>, composition: 'start' | 'end') => {
        const {onCompositionStart, onCompositionEnd, type, componentClass} = this.props
        if (composition === 'start') {
            this.input.isComposition = true
            onCompositionStart?.(e)
        } else if (composition === 'end') {
            this.input.isComposition = false
            onCompositionEnd?.(e)
            if (type === 'search') {
                this.handleSearchChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            } else if (type === 'textarea' || componentClass === 'textarea') {
                this.handleTextareaChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            } else {
                this.handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            }
        }
    }

    clearValue = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        const {onChange, antd, disabled, type, onSearch} = this.props
        if (disabled) return
        this.setState({
            showSearch: true,
            value: ''
        })
        if (this.e && this.e.target) this.e.target.value = ''
        antd ? onChange?.(this.e) : onChange?.('', this.e)
        this.input.focus()
        // fix: 代码与文档不一致，input框清空时需要调用onSearch方法，参数为空
        if (type === 'search') onSearch?.('', e)
    }
    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const {onSearch, type, onKeyDown, onPressEnter} = this.props
        if (e.keyCode === 13) {
            onPressEnter?.(e)
            if (type === 'search') onSearch?.(this.input.value, e)
        }
        onKeyDown?.(e)
    }
    handleSearch = (e: React.MouseEvent<HTMLDivElement>) => {
        const {onSearch, disabled, loading} = this.props
        if (!disabled && !loading) onSearch?.(this.input.value, e)
    }
    focus = () => {
        this.input?.focus()
    }

    blur = () => {
        this.input?.blur()
    }
    handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const {value} = this.state
        const {onBlur, allowClear, antd} = this.props
        let _e = Object.assign({}, e)
        this.e = _e
        this.setState({focused: false})
        let callbackData: any = antd ? e : value
        if (onBlur) {
            if (allowClear && this.clickClearBtn) {
                this.clickClearBtn = false
                onBlur?.(callbackData, _e, true)
            } else {
                onBlur?.(callbackData, _e)
            }
        }
    }
    handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const {value} = this.state
        const {onFocus, antd, focusSelect} = this.props
        if (focusSelect) {
            this.input?.select()
        }
        let callbackData: any = antd ? e : value
        this.setState({focused: true})
        onFocus?.(callbackData, e)
    }
    handleClick: React.MouseEventHandler<HTMLInputElement> = e => {
        this.props.onClick?.(e)
    }
    onClearBtnMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        this.clickClearBtn = true
    }

    /**
     * 字数限制文本
     */
    renderLimitText({currentLength, maxLength}: {currentLength: number; maxLength?: number}) {
        const {type, componentClass, showMaxLabel} = this.props
        if (
            (type === 'textarea' || componentClass === 'textarea') &&
            (showMaxLabel ?? false) &&
            maxLength !== undefined
        ) {
            const currentCls = classnames(`${textareaPrefix}-limit-current`, {
                [`${textareaPrefix}-limit-current-warning`]: maxLength && currentLength >= maxLength
            })
            return (
                <span className={`${textareaPrefix}-limit`}>
                    {<span className={currentCls}>{currentLength}</span>}
                    {`/${maxLength}`}
                </span>
            )
        }
    }

    // TODO: renderInput，renderSearch, renderPassword应合并为同一实现
    renderInput = () => {
        const {
            id,
            fieldid,
            style,
            componentClass: Component,
            type,
            className,
            size,
            clsPrefix,
            debounceDelay,
            value: propsValue,
            readOnly,
            onChange,
            onSearch,
            onBlur,
            maxLength,
            allowClear,
            focusSelect,
            prefix,
            suffix,
            innerClassName,
            innerStyle,
            trigger,
            iconRender,
            passwordVisible,
            visibilityToggle,
            onVisibleChange,
            showMaxLabel,
            allowInputOverMax,
            ...others
        } = this.props
        let CurrentComp = Component
        // input[type="file"] 不应该有类名 .form-control.
        const {value: stateValue} = this.state
        const value = propsValue || stateValue
        let classes: Record<string, any> = {}
        if (size) {
            classes[size] = true
        }
        let isTextarea = false
        if (type === 'textarea' || Component === 'textarea') {
            isTextarea = true
            CurrentComp = TextArea
        }
        let classNames
        if (type !== 'file') {
            classNames = classnames(clsPrefix, classes)
        }
        if (prefix || suffix) classNames += ` ${clsPrefix}-prefix-suffix`

        // 加判断，是否有 前后缀，是否加 wrapper
        let focusClassName = this.state.focused ? `${clsPrefix}-affix-focus` : ''
        if (allowClear || suffix || prefix || (showMaxLabel ?? false)) {
            if (innerClassName) classNames += ' ' + innerClassName
            return (
                <div
                    id={id ? id + '_input' : undefined}
                    fieldid={fieldid ? fieldid + '_input' : undefined}
                    className={classnames(
                        `${clsPrefix}-close`,
                        `${clsPrefix}-affix-wrapper ${clsPrefix}-affix-wrapper-${size}`,
                        isTextarea ? textareaPrefix + '-affix-wrapper' : '',
                        focusClassName,
                        className,
                        {
                            [`${clsPrefix}-affix-wrapper-disabled`]: others.disabled
                        }
                    )}
                    style={style}
                    onClick={this.handleClick}
                >
                    {prefix && (
                        <span
                            id={id ? id + '_prefix' : undefined}
                            fieldid={fieldid ? fieldid + '_prefix' : undefined}
                            className={`${clsPrefix}-simple-prefix`}
                        >
                            {prefix}
                        </span>
                    )}
                    <CurrentComp
                        {...omit(others, ['antd', 'onClick'])}
                        {...(isTextarea
                            ? {
                                  prefixCls: textareaPrefix /* textarea使用rc，需要加前缀 */,
                                  showMaxLabel: showMaxLabel ?? false
                              }
                            : {type})}
                        id={id}
                        fieldid={fieldid}
                        // type={type}
                        style={innerStyle}
                        ref={(node: HTMLElement) => (this.input = node)}
                        value={value ?? ''}
                        readOnly={readOnly}
                        onCompositionStart={(e: React.CompositionEvent<HTMLInputElement>) => {
                            this.handleComposition(e, 'start')
                        }}
                        onCompositionEnd={(e: React.CompositionEvent<HTMLInputElement>) => {
                            this.handleComposition(e, 'end')
                        }}
                        onChange={isTextarea ? this.handleTextareaChange : this.handleChange}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onKeyDown={this.handleKeyDown}
                        className={classnames(classNames)}
                        maxLength={maxLength}
                    />
                    {
                        // textarea最长文本数字
                        this.renderLimitText({
                            // spread操作符用于处理表情等合成字符slice后错误或乱码等问题，如：一家三口'👨👨👨'.slice(0, 2) === '👨'
                            currentLength: [...(String(value).replace(/\n/g, '') ?? '')].length,
                            maxLength
                        })
                    }
                    {allowClear && (value || value === 0) && (
                        <div
                            className={`${clsPrefix}-suffix has-close`}
                            id={id ? id + '_clear' : undefined}
                            fieldid={fieldid ? fieldid + '_clear' : undefined}
                            onMouseDown={this.onClearBtnMouseDown}
                            onClick={this.clearValue}
                        >
                            <Icon type='uf-close-c' />
                        </div>
                    )}
                    {suffix && (
                        <span
                            className={`${clsPrefix}-simple-suffix`}
                            id={id ? id + '_suffix' : undefined}
                            fieldid={fieldid ? fieldid + '_suffix' : undefined}
                        >
                            {suffix}
                        </span>
                    )}
                </div>
            )
        } else {
            if (className) classNames += ' ' + className
            return (
                <CurrentComp
                    style={style} // TODO: 此处貌似应使用innerStyle
                    {...omit(others, ['antd', 'onPressEnter'])}
                    {...(isTextarea ? {prefixCls: textareaPrefix /* textarea使用rc，需要加前缀 */} : {type})}
                    id={id}
                    fieldid={fieldid}
                    // type={type}
                    ref={(node: HTMLElement) => (this.input = node)}
                    value={value ?? ''}
                    readOnly={readOnly}
                    onCompositionStart={(e: React.CompositionEvent<HTMLInputElement>) => {
                        this.handleComposition(e, 'start')
                    }}
                    onCompositionEnd={(e: React.CompositionEvent<HTMLInputElement>) => {
                        this.handleComposition(e, 'end')
                    }}
                    onChange={isTextarea ? this.handleTextareaChange : this.handleChange}
                    onBlur={this.handleBlur}
                    onClick={this.handleClick}
                    onFocus={this.handleFocus}
                    onKeyDown={this.handleKeyDown}
                    className={classnames(classNames)}
                    maxLength={maxLength}
                />
            )
        }
    }

    renderSearch = () => {
        const {
            id,
            fieldid,
            componentClass: Component,
            type,
            className,
            size,
            clsPrefix,
            debounceDelay,
            value: propsValue,
            readOnly,
            onChange,
            onSearch,
            onBlur,
            style,
            maxLength,
            allowClear,
            loading,
            wrapperClassName,
            innerClassName,
            innerStyle,
            trigger,
            iconRender,
            icon,
            enterButton = false,
            passwordVisible,
            visibilityToggle,
            onVisibleChange,
            showMaxLabel,
            allowInputOverMax,
            ...others
        } = this.props
        // input[type="file"] 不应该有类名 .form-control.
        const {value: stateValue} = this.state
        const value = propsValue || stateValue
        let classes: Record<string, boolean> = {}
        if (size) {
            classes[size] = true
        }
        let focusClassName = this.state.focused ? `${clsPrefix}-affix-focus` : ''
        classes[`${clsPrefix}-search`] = true

        const searchIcon = icon || <Icon type='uf-search-light-2' />
        const pureIcon = icon
            ? React.cloneElement(icon, {
                  onClick: () => {} // 图标的click上移到button执行，增加触发区域
              })
            : ''
        let iconProps: Record<string, string> = {}
        if (id) iconProps.id = `${id}-search`

        let button: React.ReactNode
        const enterButtonAsElement = (enterButton || {}) as React.ReactElement

        if (!enterButton && !loading) {
            button = React.cloneElement(searchIcon, {
                ...iconProps,
                onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    searchIcon?.props?.onClick?.(e)
                    this.handleSearch(e)
                }
            })
        } else if (
            enterButtonAsElement?.type === 'button' ||
            (enterButtonAsElement?.type as any)?.defaultProps?.clsPrefix === `${componentPrefix}-button`
        ) {
            button = React.cloneElement(enterButtonAsElement, {
                colors: 'primary',
                ...iconProps,
                key: 'enterButton',
                loading,
                onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    icon?.props?.onClick?.(e)
                    enterButtonAsElement?.props?.onClick?.(e)
                    this.handleSearch(e)
                },
                children: (
                    <>
                        {enterButtonAsElement?.props?.children ?? ''}
                        {pureIcon}
                    </>
                )
            })
        } else {
            button = (
                <Button
                    colors='primary'
                    {...iconProps}
                    key='enterButton'
                    loading={loading}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        icon?.props?.onClick?.(e)
                        this.handleSearch(e)
                    }}
                >
                    {enterButton}
                    {pureIcon}
                </Button>
            )
        }

        return (
            <div
                id={id ? id + '_input' : undefined}
                fieldid={fieldid ? fieldid + '_input' : undefined}
                className={classnames(`${clsPrefix}-search`, `${clsPrefix}-affix-wrapper`, focusClassName, className, {
                    [`${clsPrefix}-with-button`]: !!enterButton || !!loading,
                    [`${clsPrefix}-affix-wrapper-disabled`]: others.disabled,
                    [`${clsPrefix}-close`]: allowClear
                })}
                onClick={this.handleClick}
                style={style}
            >
                <Component
                    style={innerStyle}
                    {...omit(others, ['antd', 'onClick'])}
                    id={id}
                    fieldid={fieldid}
                    type={type}
                    readOnly={readOnly}
                    ref={(node: HTMLElement) => (this.input = node)}
                    onChange={this.handleSearchChange}
                    value={value ?? ''}
                    onKeyDown={this.handleKeyDown}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    className={classnames(clsPrefix, classes, innerClassName)}
                    maxLength={maxLength}
                />
                {allowClear && (value || value === 0) ? (
                    <div
                        className={`${clsPrefix}-suffix has-close`}
                        id={id ? id + '_clear' : undefined}
                        fieldid={fieldid ? fieldid + '_clear' : undefined}
                        onMouseDown={this.onClearBtnMouseDown}
                        onClick={this.clearValue}
                    >
                        <Icon type='uf-close-c' />
                    </div>
                ) : (
                    ''
                )}
                <div className={`${clsPrefix}-suffix`} fieldid={fieldid ? fieldid + '_search' : undefined}>
                    {button}
                </div>
            </div>
        )
    }

    getIcon = (prefixCls: string, icon: React.ReactNode) => {
        const {trigger, disabled, onVisibleChange} = this.props
        let {passwordVisible} = this.state
        const triggerVisibleChange = () => {
            if (disabled) {
                return
            }
            this.setState({
                passwordVisible: !passwordVisible
            })
            onVisibleChange?.(!passwordVisible)
        }
        const ActiconMap: Record<string, string> = {
            click: 'onClick',
            hover: 'onMouseOver'
        }
        const iconTrigger = ActiconMap[trigger] || ''
        const iconProps = {
            [iconTrigger]: triggerVisibleChange,
            className: `${prefixCls}-icon`,
            onMouseDown: (e: MouseEvent) => {
                e.preventDefault()
            },
            onMouseUp: (e: MouseEvent) => {
                e.preventDefault()
            }
        }

        return React.cloneElement(React.isValidElement(icon) ? icon : <span>{icon}</span>, iconProps)
    }

    renderPassword = () => {
        const {
            id,
            fieldid,
            componentClass: Component,
            type,
            className,
            size,
            prefix,
            suffix,
            clsPrefix,
            debounceDelay,
            value: propsValue,
            readOnly,
            onSearch,
            onChange,
            onBlur,
            maxLength,
            allowClear,
            wrapperClassName,
            innerClassName,
            innerStyle,
            style,
            trigger,
            iconRender,
            visibilityToggle,
            onVisibleChange,
            showMaxLabel,
            allowInputOverMax,
            ...others
        } = this.props

        const {value: stateValue, passwordVisible} = this.state
        const value = propsValue || stateValue
        let classes: Record<string, boolean> = {}
        if (size) {
            classes[size] = true
        }
        let focusClassName = this.state.focused ? `${clsPrefix}-affix-focus` : ''
        classes[`${clsPrefix}-password`] = true
        const toggleVisibleIcon = iconRender(passwordVisible)

        const passwordInput = (hasIcons = true) => (
            <Component
                style={hasIcons ? innerStyle : style}
                {...omit(others, ['antd', 'onClick', 'passwordVisible'])}
                id={id}
                fieldid={fieldid}
                type={passwordVisible ? 'text' : 'password'}
                readOnly={readOnly}
                ref={(node: HTMLElement) => (this.input = node)}
                onChange={this.handleChange}
                value={value ?? ''}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                className={classnames(clsPrefix, classes, innerClassName)}
                maxLength={maxLength}
            />
        )

        if (allowClear || suffix || prefix) {
            return (
                <div
                    id={id ? id + '_input' : undefined}
                    fieldid={fieldid ? fieldid + '_input' : undefined}
                    className={classnames(
                        `${clsPrefix}-password`,
                        `${clsPrefix}-affix-wrapper`,
                        focusClassName,
                        className,
                        {
                            [`${clsPrefix}-affix-wrapper-disabled`]: others.disabled,
                            [`${clsPrefix}-close`]: allowClear
                        }
                    )}
                    onClick={this.handleClick}
                    style={style}
                >
                    {prefix && (
                        <span
                            id={id ? id + '_prefix' : undefined}
                            fieldid={fieldid ? fieldid + '_prefix' : undefined}
                            className={`${clsPrefix}-simple-prefix`}
                        >
                            {prefix}
                        </span>
                    )}
                    {passwordInput()}
                    {allowClear && (value || value === 0) ? (
                        <div
                            className={`${clsPrefix}-suffix has-close`}
                            id={id ? id + '_clear' : undefined}
                            fieldid={fieldid ? fieldid + '_clear' : undefined}
                            onMouseDown={this.onClearBtnMouseDown}
                            onClick={this.clearValue}
                        >
                            <Icon type='uf-close-c' />
                        </div>
                    ) : (
                        ''
                    )}
                    {visibilityToggle && toggleVisibleIcon && (
                        <div className={`${clsPrefix}-suffix`} fieldid={fieldid ? fieldid + '_password' : undefined}>
                            {this.getIcon(`${clsPrefix}-password`, toggleVisibleIcon)}
                        </div>
                    )}
                    {/* 兼容4.3.2以下版本用户通过suffix生成小眼睛的方案 */}
                    {suffix && (
                        <span
                            className={`${clsPrefix}-simple-suffix`}
                            id={id ? id + '_suffix' : undefined}
                            fieldid={fieldid ? fieldid + '_suffix' : undefined}
                        >
                            {suffix}
                        </span>
                    )}
                </div>
            )
        } else {
            return passwordInput(false)
        }
    }

    render() {
        if (this.props.type === 'search') {
            return this.renderSearch()
        } else if (this.props.type === 'password') {
            return this.renderPassword()
        }

        return this.renderInput()
    }
}

// Input.propTypes = propTypes
export default Input as React.ComponentClass<InputProps>
