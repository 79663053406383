import React, {Component} from 'react';
import ViewerJs from 'viewerjs';
import {ImageProps} from './iImage';
import {getNid, Warning, WebUI} from "../../wui-core/src";
import { OrNull } from '../../wui-core/src/utils/type';
import cx from 'classnames';

const {isShouldUpdate} = Warning;
// const propTypes = {
//     ready: PropTypes.func,
//     show: PropTypes.func,
//     shown: PropTypes.func,
//     hide: PropTypes.func,
//     hidden: PropTypes.func,
//     view: PropTypes.func,
//     viewed: PropTypes.func,
//     zoom: PropTypes.func,
//     zoomed: PropTypes.func,
//     getPopupContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
//     asyncLoad: PropTypes.bool,
// };
const defaultProps: Partial<ImageProps> = {
    ready: () => {
    },
    show: () => {
    },
    shown: () => {
    },
    hide: () => {
    },
    hidden: () => {
    },
    view: () => {
    },
    viewed: () => {
    },
    zoom: () => {
    },
    zoomed: () => {
    },
    asyncLoad: false
};
@WebUI({name: "image", defaultProps})
class Viewer extends Component<ImageProps> {
    viewerCase?: ViewerJs;
    views: OrNull<HTMLDivElement> = null;
    static defaultProps = defaultProps;
    constructor(props: ImageProps) {
        super(props)
    }

    componentDidMount() {
        if (!this.props.asyncLoad) {
            const {container, getPopupContainer, viewed, ...other} = this.props
            let containerDom = getPopupContainer || container
            this.viewerCase = new ViewerJs(this.views as HTMLDivElement, {
                url: 'data-original',
                container: containerDom,
                viewed: (arg) => {
                    if (arg?.detail?.originalImage?.getAttribute('fieldid')) {
                        arg?.detail?.image?.setAttribute('fieldid', arg?.detail?.originalImage?.getAttribute('fieldid') + '_max');
                    }
                    viewed && viewed()
                },
                ...other
            })
        }

    }

    componentDidUpdate() {
        if (this.props.asyncLoad) {
            if (this.viewerCase) {
                this.viewerCase.update()
            } else {
                const {container, getPopupContainer, viewed, ...other} = this.props
                let containerDom = getPopupContainer || container
                this.viewerCase = new ViewerJs(this.views as HTMLDivElement, {
                    url: 'data-original',
                    container: containerDom,
                    viewed: (arg) => {
                        if (arg?.detail?.originalImage?.getAttribute('fieldid')) {
                            arg?.detail?.image?.setAttribute('fieldid', arg?.detail?.originalImage?.getAttribute('fieldid') + '_max');
                        }
                        viewed && viewed();
                    },
                    ...other
                })
            }
        }
    }

    componentWillUnmount() {
        this.viewerCase?.destroy?.()
    }

    render() {
        const {
            getPopupContainer,
            container,
            ready,
            show,
            shown,
            hide,
            hidden,
            view,
            viewed,
            zoom,
            zoomed,
            asyncLoad,
            clsPrefix,
            className,
            inline,
            button,
            title,
            navbar,
            toolbar,
            tooltip,
            movable,
            zoomable,
            rotatable,
            scalable,
            transition,
            fullscreen,
            keyboard,
            interval,
            zoomRatio,
            minZoomRatio,
            maxZoomRatio,
            zIndex,
            zIndexInline,
            url,
            ...other
        } = this.props
        isShouldUpdate("Image", this.props);
        let adapterNid = getNid(this.props) // 适配nid、uitype
        const wrapClassNames = cx({
	        [`${clsPrefix}`]: true
	    }, className);
        return (
            <div {...other} className={wrapClassNames} ref={(el) => {
                this.views = el
            }} {...adapterNid}>
                {this.props.children}
            </div>
        )
    }
}

// Viewer.propTypes = propTypes;
// Viewer.defaultProps = defaultProps;

export default Viewer as React.ComponentClass<Partial<ImageProps>>;
