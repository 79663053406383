// import classNames from 'classnames';
// import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
// import Button from '../../wui-button/src';
// import {prefix} from "../../wui-core/src/index"
import ConfirmModal from './ConfirmModal'
import {destroyFns} from './Modal';
// import i18n from './i18n';
// import {getLangInfo} from "../../wui-locale/src/tool";
import { ModalConfirmProps } from './iModal';
import {globalConfig} from "../../wui-provider/src";
const IS_REACT_16 = !!ReactDOM.createPortal;
// const propTypes = {
//     onCancel: PropTypes.func,
//     onOk: PropTypes.func,
//     close: PropTypes.string,
//     onHide: PropTypes.func,
//     show: PropTypes.bool,
//     keyboard: PropTypes.bool,
//     centered: PropTypes.bool,
//     getContainer: PropTypes.func,
//     backdropStyle: PropTypes.object,
//     okButtonProps: PropTypes.string,
//     cancelButtonProps: PropTypes.string,
//     iconType: PropTypes.string,
//     icon: PropTypes.element,
//     okType: PropTypes.string,
//     okCancel: PropTypes.string,
//     width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     style: PropTypes.object,
//     backdrop: PropTypes.bool,
//     backdropClosable: PropTypes.bool,
//     okText: PropTypes.string,
//     cancelText: PropTypes.string,
//     autoFocusButton: PropTypes.element,
//     transitionName: PropTypes.string,
//     maskTransitionName: PropTypes.string,
//     type: PropTypes.string,
//     locale: PropTypes.string,
//     content: PropTypes.any
// }

// class ConfirmDialog extends React.Component {
//     constructor(props) {
//         super(props)
//     }

//     render() {
//         const props = this.props;
//         const {
//             onCancel,
//             onOk,
//             close,
//             show,
//             locale,
//             keyboard,
//             centered,
//             backdropStyle
//         } = props;

//         const okType = props.okType || 'primary';
//         const prefixCls = props.prefixCls || `${prefix}-modal`;
//         const contentPrefixCls = `${prefixCls}-confirm`;
//         // 默认为 true，保持向下兼容
//         const okCancel = 'okCancel' in props ? props.okCancel : true;
//         const width = props.width || 400;
//         const backdrop = props.backdrop === undefined ? true : props.backdrop;
//         // 默认为 false，保持旧版默认行为
//         const backdropClosable = props.backdropClosable === undefined ? true : props.backdropClosable;
//         // const runtimeLocale = getConfirmLocale();
//         const local = getLangInfo(locale, i18n)
//         const okText = props.okText || (okCancel ? local.ok : local.gotit);
//         const cancelText = props.cancelText || local.cancel;

//         const classString = classNames(
//             contentPrefixCls,
//             `${contentPrefixCls}-${props.type}`,
//             props.className,
//         );

//         const cancelButton = okCancel && (
//             <Button
//                 onClick={() => {
//                     close();
//                     onCancel ? onCancel() : () => {
//                         return
//                     }
//                 }}
//                 colors="secondary"
//                 style={{marginRight: 8}}
//             >{cancelText}
//             </Button>
//         )

//         return (
//             <Modal
//                 width={width}
//                 className={classString}
//                 show={show}
//                 onHide={() => {
//                     close();
//                     onCancel ? onCancel() : () => {
//                         return
//                     }
//                 }}
//                 backdrop={backdrop}
//                 backdropClosable={backdropClosable}
//                 centered={centered}
//                 keyboard={keyboard}
//                 header={null}
//                 backdropStyle={backdropStyle}>
//                 <Modal.Body>
//                     <div className={`${contentPrefixCls}-content`}>{props.content}</div>
//                 </Modal.Body>

//                 <Modal.Footer>
//                     {cancelButton}
//                     <Button
//                         onClick={() => {
//                             close();
//                             onOk ? onOk() : () => {
//                                 return
//                             }
//                         }}
//                         colors={okType}
//                     >
//                         {okText}
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         );
//     }
// }

// ConfirmDialog.propTypes = propTypes;

export default function confirm(config: ModalConfirmProps) {
    const globalLocale = globalConfig().getGlobalLocale();
    if (globalLocale) {
        config.locale ??= globalLocale;
    }
    const div = document.createElement('div');
    document.body.appendChild(div);
    let currentConfig: ModalConfirmProps;
    function render(props: ModalConfirmProps) {
        ReactDOM.render(<ConfirmModal {...props}/>, div);
    }
    function destroy(...args: any[]) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);
        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }
        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];
            /* eslint-disable */
            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
    // 在react 16 + 的版本中 调用过程中 args 实际为[]
    function close(...args: any[]) {
        // const that = this as Window;
        currentConfig = {
            ...currentConfig,
            show: false,
            onHide: destroy.bind(this, ...args),
        };
        if (IS_REACT_16) {
            render(currentConfig);
        } else {
            destroy(...args);
        }
    }

    function update(newConfig: ModalConfirmProps) {
        currentConfig = {
            ...currentConfig,
            ...newConfig,
        };
        render(currentConfig);
    }
    currentConfig = {...config, close, show: true};

    render(currentConfig);

    destroyFns.push(close);

    return {
        destroy: close,
        update,
    };
}
