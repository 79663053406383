import classNames from 'classnames';
// import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Animate from '../../wui-animate/src';
import {WebUI} from "../../wui-core/src/index"
import Icon from '../../wui-icon/src';
// import { getComponentLocale } from '../../wui-locale/src/tool';
import {getLangInfo} from "../../wui-locale/src/tool";
import ProgressBar from '../../wui-progress/src';
import i18n from './i18n.js';
// import { UploadListProps } from './interface';
import { UploadListProps, DefaultListArr } from './iUpload'

// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
const previewFile = (file: any, callback: (v: any)=>void) => {
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(file);
};

// const propTypes = {
//     listType: PropTypes.oneOf(['text', 'picture', 'picture-card']),
//     onPreview: PropTypes.func,
//     onRemove: PropTypes.func,
//     onDownload: PropTypes.func,
//     items: PropTypes.array,
//     progressAttr: PropTypes.object,
//     clsPrefix: PropTypes.string,
//     downloadText: PropTypes.string,
//     removeText: PropTypes.string,
//     showRemoveIcon: PropTypes.bool,
//     showDownloadIcon: PropTypes.bool,
//     progress: PropTypes.any,
//     showPreviewIcon: PropTypes.bool,
//     locale: PropTypes.any,
//     fieldid: PropTypes.string
// }

const defaultProps = {
    listType: 'text', // or picture
    progressAttr: {
        strokeWidth: 3,
        showInfo: false,
    },
    clsPrefix: 'u-upload',
    showRemoveIcon: false,
    showDownloadIcon: false,
    showPreviewIcon: true,
};

@WebUI({name: "upload", defaultProps})
class UploadList extends Component<UploadListProps> {
	file?: DefaultListArr

	handleClose = (file: DefaultListArr) => {
	    const onRemove = this.props.onRemove;
	    if (onRemove) {
	        let newFile = this.file || file
	        onRemove(newFile);
	    }
	}
	handleDownLoad = (file: DefaultListArr) => {
	    let {onDownload} = this.props
	    if (onDownload) {
	        onDownload(file);
	    }
	}

	handlePreview = (file: DefaultListArr, e: React.MouseEvent<HTMLElement>) => {
	    const {onPreview} = this.props;
	    if (!onPreview) {
	        return;
	    }
	    e.preventDefault();
	    return onPreview(file);
	}

	componentDidUpdate() {
	    if (this.props.listType !== 'picture' && this.props.listType !== 'picture-card') {
	        return;
	    }
	    (this.props.items || []).forEach(file => {
	        if (typeof document === 'undefined' ||
				typeof window === 'undefined' ||
				// !(window as any).FileReader || !(window as any).File ||
				!window.FileReader || !window.File ||
				!(file.originFileObj instanceof File) ||
				file.thumbUrl !== undefined) {
	            return;
	        }
	        /*eslint-disable */
			file.thumbUrl = '';
			/* eslint-enable */
	        previewFile(file.originFileObj, (previewDataUrl: string) => {
	            /*eslint-disable */
				file.thumbUrl = previewDataUrl;
				/* eslint-enable */
	            this.forceUpdate();
	        });
	    });
	}

	render() {
	    const {
	        clsPrefix,
	        items = [],
	        listType,
	        showRemoveIcon,
	        showDownloadIcon,
	        showPreviewIcon,
	        progress,
	        locale,
	        fieldid,
	        showUploadList,
	        iconRender
	    } = this.props;
	    // const local = getComponentLocale(this.props, this.context, 'Upload', () => i18n);
	    const local = getLangInfo(locale, i18n);
	    const list = items.map(file => {
	        let progressNode;
	        let icon = <Icon type="uf-link"/>;

	        if (listType === 'picture' || listType === 'picture-card') {
	            if (file.status === 'uploading' || (!file.thumbUrl && !file.url)) {
	                if (listType === 'picture-card') {
	                    icon = iconRender ? iconRender() : <div
	                        className={`${clsPrefix}-list-item-uploading-text`}>{local.langMap.fileLoading}</div>;
	                } else {
	                    icon = iconRender ? iconRender() : <Icon className={`${clsPrefix}-list-item-thumbnail`} type="picture"/>;
	                }
	            } else {
	                // console.log('----file----',file)
	                icon = (
	                    <a
	                        className={`${clsPrefix}-list-item-thumbnail`}
	                        onClick={e => this.handlePreview(file, e)}
	                        href={file.url || file.thumbUrl}
	                        target="_blank"
	                        rel="noopener noreferrer"
	                    >
	                        <img src={file.thumbUrl || file.url} alt={file.name}/>
	                    </a>
	                );
	            }
	        } else {
	            icon = iconRender ? iconRender() : icon
	        }

	        if (file.status === 'uploading') {
	            progressNode = (
	                <ProgressBar size="sm" percent={file.percent} strokeWidth={progress?.strokeWidth}
								 strokeColor={progress?.strokeColor} format={progress?.format}/>
	            );
	        }
	        const infoUploadingClass = classNames({
	            [`${clsPrefix}-list-item`]: true,
	            [`${clsPrefix}-list-item-${file.status}`]: true,
	        });
	        const preview = file.url ? (
	            <a
	                href={file.url}
	                target="_blank"
	                rel="noopener noreferrer"
	                className={`${clsPrefix}-list-item-name`}
	                onClick={e => this.handlePreview(file, e)}
	                title={file.name}
	            >
	                {file.name}
	            </a>
	        ) : (
	            <span
	                className={`${clsPrefix}-list-item-name`}
	                onClick={e => this.handlePreview(file, e)}
	                title={file.name}
	            >
	                {file.name}
	            </span>
	        );
	        const style: React.CSSProperties | undefined = (file.url || file.thumbUrl) ? undefined : {
	            pointerEvents: 'none',
	            opacity: 0.5,
	        };
	        const previewIcon = showPreviewIcon ? (
	            <a
	                href={file.url || file.thumbUrl}
	                target="_blank"
	                rel="noopener noreferrer"
	                style={style}
	                onClick={e => this.handlePreview(file, e)}
	            >
	                <Icon fieldid={fieldid ? fieldid + "-preview" : undefined} title={local.langMap.toview} type="uf-eye-o"/>
	            </a>
	        ) : null;
	        let newRemoveIcon = null
	        if (showUploadList && typeof showUploadList != "boolean" && showUploadList.removeIcon && typeof showUploadList.removeIcon != "string") {
	            this.file = file // 存储file，自定义时点击事件使用的参数
	            newRemoveIcon = React.cloneElement(showUploadList.removeIcon, {
	                title: this.props.removeText || local.langMap.remove,
	                onClick: this.handleClose,
	                className: `${clsPrefix}-customize-del-icon`
	            })
	        }
	        const removeIcon = showRemoveIcon ? (
	            newRemoveIcon ? newRemoveIcon :
	            <Icon type="uf-del" title={this.props.removeText || local.langMap.remove} fieldid={fieldid ? fieldid + "-del" : undefined}
					  onClick={() => this.handleClose(file)}/>
	        ) : null;
	        const downloadIcon = showDownloadIcon ? (
	            <Icon type="uf-download" title={this.props.downloadText || local.langMap.down} fieldid={fieldid ? fieldid + "-download" : undefined}
					  onClick={() => this.handleDownLoad(file)}/>
	        ) : null;
	        const removeIconClose = !showRemoveIcon ? (
	            <Icon type="uf-close" title={this.props.removeText || local.langMap.remove} fieldid={fieldid ? fieldid + "-close" : undefined}
					  onClick={() => this.handleClose(file)}/>
	        ) : null;
	        const actions = (listType === 'picture-card' && file.status !== 'uploading') ? (
	            <span className={`${clsPrefix}-list-item-actions`}>
	                {previewIcon}
	                {removeIcon}
	            </span>
	        ) : (
	            <span className={`${clsPrefix}-list-item-actions`}>
	                {removeIcon}
	                {file.status === 'done' && downloadIcon}
	                {removeIconClose}
	            </span>
	        );

	        return (
	            <div className={infoUploadingClass} key={file.uid}>
	                <div className={`${clsPrefix}-list-item-info`}>
	                    {icon}
	                    {preview}
	                    {actions}
	                </div>
	                {progressNode}
	            </div>
	        );
	    });
	    const listClassNames = classNames({
	        [`${clsPrefix}-list`]: true,
	        [`${clsPrefix}-list-${listType}`]: true,
	    });
	    return (
	        <Animate
	            transitionName={`${clsPrefix}-margin-top`}
	            component="div"
	            className={listClassNames}
	        >
	            {list}
	        </Animate>
	    );
	}
}

// UploadList.propTypes = propTypes;
export default UploadList;
