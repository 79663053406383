
// 生成num个0的字符串
function _makeZero(num:number) {
    let str = '';
    for (let i = 0; i < num; i++) {
        str += '0';
    }
    return str;
}


/**
 * 通过移动小数点  扩大倍数或缩小倍数(解决出现e+、e-的问题)
 *
 * @param value 数字字符串
 */
function scienceNum(value:string) {
    if (!value) return value;
    if (typeof value === 'number') value = value + "";
    let minusStr = '';// 是否有负号，例如：-5e-8、-2.3e-8
    if (value.substring(0, 1) === '-') {
        minusStr = '-';
        value = value.substring(1, value.length);
    }
    let eIndex = value.indexOf('E');
    if (eIndex == -1) eIndex = value.indexOf('e');
    if (eIndex != -1) {
        let doubleStr = value.substring(0, eIndex);
        let eStr:string|number = value.substring(eIndex + 1, value.length);
        eStr = parseInt(eStr);
        let doubleStrList = doubleStr.split('.');
        let doubleStr1 = doubleStrList[0] || "";
        let doubleStr2 = doubleStrList[1] || "";

        if (eStr < 0) { // e- 很小的数
            let str1Len = doubleStr1.length;
            let eStrs = Math.abs(eStr);
            if (str1Len > eStrs) {
                let nums = doubleStr1.substring(0, eStrs);
                let nume = doubleStr1.substring(eStrs, str1Len);
                doubleStr = nums + "." + nume + nume;
            } else if (str1Len < eStrs) {
                let indexNum = eStrs - str1Len;
                let str = _makeZero(indexNum); // 用0补齐
                doubleStr = '0.' + str + doubleStr1 + doubleStr2;
            } else {
                doubleStr = '0.' + doubleStr1 + doubleStr2;
            }
        } else { // e+ 很大的数
            let str2Len = doubleStr2.length;
            if (str2Len > eStr) {
                let nums = doubleStr2.substring(0, eStr);
                let nume = doubleStr2.substring(eStr, str2Len);
                doubleStr = doubleStr1 + nums + '.' + nume;
            } else if (str2Len < eStr) {
                let indexNum = eStr - str2Len;
                let str = _makeZero(indexNum); // 用0补齐
                doubleStr = doubleStr1 + doubleStr2 + str;
            } else {
                doubleStr = doubleStr1 + doubleStr2;
            }
        }
        value = doubleStr;
    }
    return minusStr + value;
}

/**
 * 默认toFixed方法为四舍六入五成双算法
 * 例如：
 * 0.015.toFixed(2)=>"0.01"
 * 0.105.toFixed(2)=>"0.10"
 * 0.5255.toFixed(3)=>"0.525"
 * 重写toFixed方法调整为四舍五入算法
 */
Number.prototype.toFixed = function(d) {
    let s = this + "";
    if (!d) d = 0;
    if (typeof d == 'string') {
        d = Number(d)
    }

    s = scienceNum(s);// 处理e+、e-情况
    if (s.indexOf(".") == -1) {
        s += "."
    }

    s += new Array(d + 1).join("0");
    if (new RegExp("^(-|\\+)?(\\d+(\\.\\d{0," + (d + 1) + "})?)\\d*$").test(s)) {
        let s = "0" + RegExp.$2,
            pm = RegExp.$1,
            a:any = RegExp.$3.length,
            b = true;
        if (a == d + 2) {
            a = s.match(/\d/g);
            if (parseInt(a[a.length - 1]) > 4) {
                for (let i = a.length - 2; i >= 0; i--) {
                    a[i] = parseInt(a[i]) + 1;
                    if (a[i] == 10) {
                        a[i] = 0;
                        b = i != 1;
                    } else break;
                }
            }
            s = a.join("").replace(new RegExp("(\\d+)(\\d{" + d + "})\\d$"), "$1.$2");
        }
        if (b) {
            s = s.substr(1)
        }

        return (pm + s).replace(/\.$/, "");
    }
    return this + "";
}

/*
 * 功能：字符串之间的数字运算
 * @param c,d 字符串数值
 * @param n 运算类型： 0为加 1为减 2为乘 3为除
 */
function executeStr(c:string, d:string, n:number) {
    // 去掉空相
    let re = /\s/g;
    let a = c.replace(re, "");
    let b = d.replace(re, "");

    let cutN = "10000000000000";
    let cutZero = "00000000000";

    let dot = ".";

    // 将小数输入为整数
    let adot = a.indexOf(dot) > 0 ? a.length - a.indexOf(dot) : 0;
    let bdot = b.indexOf(dot) > 0 ? b.length - b.indexOf(dot) : 0;

    let cutA = adot > 0 ? parseInt(cutN.substr(0, adot)) : 1;
    let cutB = bdot > 0 ? parseInt(cutN.substr(0, bdot)) : 1;

    let maxcutAB = Math.max(cutA, cutB);
    let mincutAB = Math.min(cutA, cutB);

    let cutAB = maxcutAB / mincutAB;
    let numA = 0;
    let numB = 0;

    let inzero = cutZero.substr(0, cutAB.toString().length - 1);
    let cutLen = maxcutAB.toString().length - 1;

    if (adot == bdot) {
        numA = parseInt(a.replace(dot, ""), 10);
        numB = parseInt(b.replace(dot, ""), 10);
    } else if (adot > bdot) {
        numA = parseInt(a.replace(dot, ""), 10);
        numB = parseInt(b.replace(dot, "") + inzero, 10);
    } else {
        numB = parseInt(b.replace(dot, ""), 10);
        numA = parseInt(a.replace(dot, "") + inzero, 10);
    }

    let numAB = "0.0";

    switch (n) {
        case 0:
        // 加
            numAB = ((numA + numB) / maxcutAB).toString();
            break;
        case 1:
        // 减
            numAB = ((numA - numB) / maxcutAB).toString();
            break;
        case 2:
        // 乘
            numAB = Math.abs(numA * numB).toString();
            cutLen = cutLen * 2;
            break;
        case 3:
        // 除
            numAB = (numA / numB).toString();
            break;
        default:
            break;
    }
    // 对乘法的特别处理
    if (n == 2) {
        if (numAB.length <= cutLen) {
            numAB = cutZero.substr(0, cutLen - numAB.length + 1) + numAB;
        }
        if (Math.abs(numA * numB) != numA * numB) {
            numAB = "-" + numAB;
        }
        numAB = numAB.slice(0, numAB.length - cutLen) + "." + numAB.slice(numAB.length - cutLen);
    }
    return parseFloat(numAB).toString();
}

/**
 * 判断是否为整数，字符整数也返回true
 *
 * @param num
 * @returns
 */
function isInteger(num:number) {
    return Math.floor(num) === Number(num);
}

/**
 * 科学计数法
 *
 * @param num
 * @returns
 */
function science(num:number) {
    let re = {
        num: 0,
        times: 1
    };
    if (isInteger(num)) {
        re.num = num;
        return re;
    }
    let snum = scienceNum(num + "");// 处理0.123e-10类似问题
    let dotPos = snum.indexOf(".");
    let len = snum.substr(dotPos + 1).length;
    let times = Math.pow(10, len);
    let intNum = parseInt(snum.replace(".", ""));
    re.times = times;
    re.num = intNum;
    return re;
}

/**
 * 四则运算
 *
 * @param x
 * @param y
 * @param op 操作符，0：加；1：减；2：乘；3：除
 * @param acc 保留小数位个数，进行四舍五入
 * @param sci 默认false，是否处理含e+、e-科学计数法的值，注意：科学计数法数值处理后的结果为string类型。
 */
function execute(x:number|string, y:number|string, op:number, acc:number, sci:boolean) {
    let xx = Number(x == undefined ? 0 : x);
    let yy = Number(y == undefined ? 0 : y);

    let a = science(xx);
    let b = science(yy);

    let na = a.num;
    let nb = b.num;
    let ta = a.times;
    let tb = b.times;
    let maxt = ta > tb ? ta : tb;

    let result = 0;
    switch (parseInt("" + op, 10)) {
        case 0: // 加
            if (ta == tb) {
                result = na + nb;
            } else if (ta > tb) {
                result = na + nb * (ta / tb);
            } else {
                result = na * (tb / ta) + nb;
            }
            result = result / maxt;
            break;
        case 1: // 减
            if (ta === tb) {
                result = na - nb;
            } else if (ta > tb) {
                result = na - nb * (ta / tb);
            } else {
                result = na * (tb / ta) - nb;
            }
            result = result / maxt;
            break;
        case 2: // 乘
            result = na * nb / (ta * tb);
            break;
        case 3: // 除
            result = (na / nb) * (tb / ta);
            break;
        default:
            break;
    }

    if (acc != undefined && acc != null) {
        let num = Number(result).toFixed(parseInt("" + acc));
        if (sci === true) {
            return scienceNum('' + num);
        } else {
            return Number(num);
        }
    } else {
        if (sci === true) {
            return scienceNum('' + result);
        } else {
            return Number(result);
        }
    }
}

// 加法运算
function add(x:number|string, y:number|string, acc:number, sci:boolean) {
    return execute(x, y, 0, acc, sci);
}

// 减法运算
function subtract(x:number|string, y:number|string, acc:number, sci:boolean) {
    return execute(x, y, 1, acc, sci);
}

// 乘法运算
function multiply(x:number|string, y:number|string, acc:number, sci:boolean) {
    return execute(x, y, 2, acc, sci);
}

// 除法运算
function divide(x:number|string, y:number|string, acc:number, sci:boolean) {
    return execute(x, y, 3, acc, sci);
}

export default {
    execute,
    executeStr,
    isInteger,
    add,
    subtract,
    multiply,
    divide,
    scienceNum
};
