// 确保toFixed原型四舍五入算法被修正
import MathUtil from './MathUtil';

export function IEVersion() {
    let userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    let isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; // 判断是否IE<11浏览器
    let isEdge = userAgent.indexOf("Edge") > -1 && !isIE; // 判断是否IE的Edge浏览器
    let isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        let reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        let fIEVersion = parseFloat(RegExp.$1);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6;// IE版本<=7
        }
    } else if (isEdge) {
        return 12;// edge
    } else if (isIE11) {
        return 11; // IE11
    } else {
        return -1;// 不是ie浏览器
    }
}

function isString(value:any) {
    return typeof value == 'string'
}

const formatUtils = {
    /**
	 * 每三位数字用逗号分隔
	 * @param num 实际数值(number类型)（支持小于0的值）
	 * @param digits 小数位位数，默认值：2，即保留两位小数（值范围：0~20），或者保留小数位范围[最小小数位，最大小数位]
	 * @param sp 每隔几位数进行逗号分隔，默认值：3，即千分位逗号分隔
	 * @param defaultValue 当值不存在的情况下显示的默认值，默认为空字符串
	 * @param zeroFilling 当小数值不够保留位数时，是否自动补0，默认true
	 * @returns {*}
	 */
    formatNumber(num:any, digits:number|number[], sp:number, _defaultValue?:number|string, zeroFilling?:boolean) {
        if (isNaN(num) || num === '' || num === undefined || num === null) {
            if (arguments.length == 4) return arguments[3];
            return '';
        }
        if (sp == null) sp = 3;
        if (!(digits instanceof Array)) {
            digits = (digits !== undefined && digits !== null && digits >= 0 && digits <= 20) ? digits : 2;
        }
        num = MathUtil.scienceNum(num);// 处理e+、e-情况
        num = parseFloat((num + "").replace(/[^\d.-]/g, ""));
        let flag = false;// 是否为负数
        if (num < 0) {
            flag = true;
            num = Math.abs(num);
        }
        if (digits instanceof Array) {
            let temp = num.toString().split(".")[1];
            let minDigits = digits[0], maxDigits = digits[1];
            if (typeof temp === "undefined" || temp.length <= minDigits) {
                num = num.toFixed(minDigits) + "";
            } else {
                num = num.toFixed(maxDigits) + "";
            }
        } else {
            num = num.toFixed(digits) + "";
        }
        zeroFilling = zeroFilling === undefined || zeroFilling === null ? true : zeroFilling;
        if (!zeroFilling) num = parseFloat(num) + "";
        let tem = num.split(".");// 按小数点分离
        let l = tem[0].split("").reverse();// 左侧数字
        let r = tem[1] && tem[1].length >= 1 ? tem[1] : "";// 右侧数字
        let scienceNum;
        if (digits == 0) {// 去除小数位
            scienceNum = (flag ? "-" : "") + l.reverse().join("");
        } else {// 补充小数位
            scienceNum = (flag ? "-" : "") + l.reverse().join("") + "." + r;
            if (!zeroFilling && r.length == 0) {// 非自动补零模式下，去除多余小数点
                scienceNum = scienceNum.split(".")[0];
            }
        }
        // 修正"-0.00"显示为"0.00"
        if (Number(scienceNum) === 0 && scienceNum.substring(0, 1) === '-') scienceNum = scienceNum.substring(1, scienceNum.length);
        let result = MathUtil.scienceNum(scienceNum);// 处理e+、e-情况
        // 处理千分位逗号
        if (sp > 0) {
            result = result.substring(0, 1) === '-' ? result.substring(1, result.length) : result;// 忽略负号
            tem = result.split(".");
            l = tem[0].split("").reverse();
            r = tem[1] && tem[1].length >= 1 ? tem[1] : "";
            let leftNum = "";
            for (let i = 0; i < l.length; i++) {
                leftNum += l[i] + ((i + 1) % sp == 0 && (i + 1) != l.length ? "," : "");
            }
            result = (flag ? "-" : "") + leftNum.split("").reverse().join("") + "." + r;// 恢复负号
            if (digits == 0 || (!zeroFilling && r.length == 0)) {// 非自动补零模式下，去除多余小数点
                result = result.split(".")[0];
            }
        }
        return result;
    },
    formatMoney(money:any) {
        return this.formatNumber(money, 2, 3);
    },
    formatDate(date:any, format:string) {
        if (date === undefined || date === null || !(date instanceof Date)) {
            if (IEVersion() !== -1)
                return isString(date) ? date.replace(/-/g, '/') : date;
            else
                return date;
        }
        let o:any = {
            "M+": date.getMonth() + 1,
            "d+": date.getDate(),
            "(H|h)+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds(),
            "q+": Math.floor((date.getMonth() + 3) / 3),
            "S": date.getMilliseconds()
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
            if (new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k])
                    .substr(("" + o[k]).length));
            }
        }
        if (IEVersion() !== -1) {
            format = isString(format) ? format.replace(/-/g, '/') : format;
        }
        return format;
    },
    parseAntDate(value:any) {
        if (value) {
            value = new Date(value.replace(/-/g, '/'));
        }
        return value;
    },
    parseAntDateWithFormat(value:any, format:string) {
        if (value) {
            if (format === "yyyy" || format === "YYYY") {
                value = new Date(this.formatDate(value, format)).getFullYear();
            } else {
                if (!(value instanceof Date)) {
                    value = new Date(value.replace(/-/g, '/'));
                }
                return new Date(this.formatDate(value, format));
            }
        }
        return value;
    },
    // 格式化日期控件值
    formatAntDate(date:Date|string) {
        if (date && date instanceof Date) {
            let mm:number|string = (date.getMonth() + 1);
            let dd:number|string = date.getDate();
            mm = mm < 10 ? '0' + mm : mm;// 补0
            dd = dd < 10 ? '0' + dd : dd;// 补0
            date = date.getFullYear() + '-' + mm + '-' + dd;
        }
        return date;
    }
    // value:100---->100台
    , formatUnitTA(value:number|string) {
        return formatUtils.formatText(value, '台');
    }
    // value:100---->100元
    , formatMoneyCN(value:number|string) {
        return formatUtils.formatText(value, '元');
    }
    // value:100---->100元/人
    , formatMoneyPeopleCN(value:number|string) {
        return formatUtils.formatText(value, '元/人');
    }
    // value:100---->100元/天
    , formatMoneyDayCN(value:number|string) {
        return formatUtils.formatText(value, '元/天');
    }
    // value:100---->￥100
    , formatMoneyEN(value:number|string) {
        return formatUtils.formatText(value, '￥', 'left');
    }
    // value:22---->22%
    , formatRatio(value:number|string) {
        return formatUtils.formatText(value, '%');
    }
    /**
	 * 格式化单位
	 * @param value 原始值
	 * @param unit 单位名称，如：元、台
	 * @param pos 单位位置，'right'-默认值，放在右侧(如:"100元")，'left'-放在左侧(如:"￥100")
	 */
    , formatText(value:number|string, unit:string, pos?:'right'|'left'|undefined) {
        if (value != '') {
            return pos == 'left' ? unit + value : value + unit;
        }
        return '';
    }
    /**
	 * 将对象中 指定的boolean类型转换为string   true-->Y | false-->N
	 * @param obj   要转换的对象
	 * @param formatPropertiesArray 要转换的属性数组
	 */
    , formatBooleanToString: function(obj:any, formatPropertiesArray:string[]) {
        if (!(obj instanceof Object) || !(formatPropertiesArray instanceof Array)) {
            return {};
        }
        if (formatPropertiesArray && formatPropertiesArray.length) {
            for (let i = 0; i < formatPropertiesArray.length; i++) {
                let property = formatPropertiesArray[i];
                obj[property] = obj[property] == true ? "Y" : "N";
            }
        }
        return obj;
    }
    /**
	 * 格式化日期
	 * @param {Number|Date} time
	 * @param {String} fmt
	 * @return {String}
	 */
    , formatDateTime: function(time:number|Date, fmt:string) {
        time = time instanceof Date ? time : new Date(time);
        const o:any = {
            "M+": time.getMonth() + 1, // 月份
            "d+": time.getDate(), // 日
            "h+": time.getHours(), // 小时
            "m+": time.getMinutes(), // 分
            "s+": time.getSeconds(), // 秒
            "q+": Math.floor((time.getMonth() + 3) / 3), // 季度
            "S": time.getMilliseconds() // 毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    }
    /**
	 * 格式化日期 - （人性化）(附加时间)
	 * @param {Number,Date} time
	 * @return {string}
	 */
    , formatSmartTime: function(time:number|Date) {
        time = time instanceof Date ? time.getTime() : time;
        let now = new Date();
        let diffTime = now.getTime() - time + 20000;
        // 今天凌晨时间戳
        const toDayTime = new Date().setHours(0, 0, 0);
        // 昨天凌晨时间戳
        const yesterDayTime = toDayTime - 86400000;
        // 明天凌晨时间戳
        const tomorrowTime = toDayTime + 86400000;
        // 前天凌晨时间戳
        const beforeYesterdayTime = yesterDayTime - 86400000;
        // 后天凌晨时间戳
        const afterTomorrowTime = tomorrowTime + 86400000;

        if (diffTime < 0) {
            diffTime = Math.abs(diffTime);
            // 大于一分钟
            if (diffTime < 60000) return "一会儿";
            // 大于一分钟小于一小时
            if (diffTime >= 60000 && diffTime < 3600000) return Math.floor(diffTime / 60000) + "分钟后";
            // 今天
            if (time < tomorrowTime) return "今天" + formatUtils.formatDateTime(time, "hh:mm");
            // 明天
            if (time < afterTomorrowTime) return "明天" + formatUtils.formatDateTime(time, "hh:mm");
            // 后天
            if (time < afterTomorrowTime + 86400000) return "后天" + formatUtils.formatDateTime(time, "hh:mm");
        } else {
            // 小于一分钟
            if (diffTime < 60000) return "刚刚";
            // 大于一分钟小于一小时
            if (diffTime >= 60000 && diffTime < 3600000) return Math.floor(diffTime / 60000) + "分钟前";
            // 今天
            if (time > toDayTime) return "今天" + formatUtils.formatDateTime(time, "hh:mm");
            // 昨天
            if (time > yesterDayTime) return "昨天" + formatUtils.formatDateTime(time, "hh:mm");
            // 前天
            if (time > beforeYesterdayTime) return "前天" + formatUtils.formatDateTime(time, "hh:mm");
        }
        // 月份/日 大于今年开始时间
        const toYearTime = new Date();
        toYearTime.setMonth(0, 0);
        toYearTime.setHours(0, 0, 0, 0);
        const toYearTime2 = new Date(time);
        toYearTime2.setMonth(0, 0);
        toYearTime2.setHours(0, 0, 0, 0);
        if (toYearTime.getTime() === toYearTime2.getTime())
            return formatUtils.formatDateTime(time, "M月d日 hh:mm");
        return formatUtils.formatDateTime(time, "yyyy年M月d日 hh:mm");
    }
    , format2ShowTime: function(format: string) {
        // if (!format || !/[hH]+|(?<![AaPp])m+|[sS]+/g.test(format)) return false  // bug: QDJCJS-10695, IE及iOS、safira不兼容正则组
        if (!format || !(/[hH]+|[^AaPp]m+|[sS]+/g.test(format) || /^m+/g.test(format))) return false
        let showHour = /[hH]+/g.test(format),
            // showMinute = /(?<![AaPp])m+/g.test(format),
            showMinute = /[^AaPp]m+/g.test(format) || /^m+/g.test(format),
            showSecond = /[sS]+/g.test(format)
        return {
            showHour,
            showMinute,
            showSecond
        }
    }
};

export default formatUtils;
