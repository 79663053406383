import PropTypes from 'prop-types';
import React, {Component} from 'react';
import zhCN from './zh_CN';
import {Locale as TypeLocale} from './iLocale';

// const propTypes = {
//     locale: PropTypes.object
// };
const defaultProps = {
    locale: zhCN
};

const childContextTypes = {
    beeLocale: PropTypes.object
};

class Locale extends Component<{locale: TypeLocale}> {
    static defaultProps = defaultProps;
    static childContextTypes = childContextTypes;
    getChildContext() {
        return {
            beeLocale: {
                ...this.props.locale,
                exist: true,
            },
        };
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

Locale.defaultProps = defaultProps;
Locale.childContextTypes = childContextTypes;

export default Locale;
