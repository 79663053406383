export default {
    lang: 'zh-cn',
    'zh-cn': {
        ok: '确定',
        now: '此刻',
        clear: '清除'
    },
    en: {
        ok: 'OK',
        now: 'Now',
        clear: 'clear'
    },
    'en-us': {
        ok: 'OK',
        now: 'Now',
        clear: 'clear'
    },
    'zh-tw': {
        ok: '確定',
        now: '此刻',
        clear: '清除'
    },
    'vi-vn': {
        ok: 'Chắc chắn rồi',
        now: 'Hiện nay',
        clear: 'Làm sạch'
    }
} as {[language: string]: {ok: string; now: string; clear: string} | string}
