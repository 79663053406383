import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { WebUI } from "../../wui-core/src/index";
import Icon from '../../wui-icon/src';
// import Prefixer from 'inline-style-prefixer';
// import stylePropType from 'react-style-proptype';

import Pane from './SpliterPane';
import Resizer from './SpliterResizer';
import { SpliterProps, SpliterState } from './iLayout';

const defaultProps: SpliterProps = {
    minSize: 50,
    primary: 'first',
    direction: 'vertical',
    allowResize: true,
    // 目前不对外抛出
    step: 5,
};

function unFocus(document: any, window: any) {
    if (document.selection) {
        document.selection.empty();
    } else {
        try {
            window.getSelection().removeAllRanges();
        } catch (e) {}
    }
}

@WebUI({name: "spliter", defaultProps})
class Spliter extends React.Component<SpliterProps, SpliterState> {

  static defaultProps = defaultProps;

  pane1: Pane | null;
  pane2: Pane | null;
  spliter: HTMLDivElement | null;
  resizer: Resizer | null;

  constructor(props: SpliterProps) {
      super(props);
      this.state = {
          active: false,
          resized: false,
          position: 0,
          draggedSize: null,
          collapsed: props.collapsed ?? props.defaultCollapsed ?? false,
      };
      this.spliter = null;
      this.pane1 = null;
      this.pane2 = null;
      this.resizer = null;
  }

  componentDidMount() {
      this.setSize(this.props, this.state);
      document.addEventListener('mouseup', this.onMouseUp);
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('touchmove', this.onTouchMove);
  }

  componentWillReceiveProps(props: SpliterProps) {
      this.setSize(props, this.state);
  }

  componentWillUnmount() {
      document.removeEventListener('mouseup', this.onMouseUp);
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('touchmove', this.onTouchMove);
  }

    onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
        const eventWithTouches = {
            ...event,
            touches: [{ clientX: event.clientX, clientY: event.clientY }],
        };
        this.onTouchStart(eventWithTouches);
    }

    onTouchStart = (event: any) => {
        const { onDragStarted, direction } = this.props;
        unFocus(document, window);
        // 记录开始位置
        const position =
        direction === 'vertical'
            ? event.touches[0].clientX
            : event.touches[0].clientY;

        if (typeof onDragStarted === 'function') {
            onDragStarted();
        }
        this.setState({
            active: true,
            position,
        });
    }

    onMouseMove = (event: MouseEvent) => {
        const eventWithTouches = Object.assign({}, event, {
            touches: [{ clientX: event.clientX, clientY: event.clientY }],
        });
        this.onTouchMove(eventWithTouches);
    }

    onTouchMove = (event: MouseEvent & { touches: { clientX: number; clientY: number; }[]; }) => {
        const { allowResize, maxSize, minSize, onDragMove, direction, step } = this.props;
        const { active, position } = this.state;
        if (allowResize && active) {
            unFocus(document, window);
            const isPrimaryFirst = this.props.primary === 'first';
            const ref = isPrimaryFirst ? this.pane1 : this.pane2;
            if (ref) {
                const node = ReactDOM.findDOMNode(ref) as Element;

                if (node.getBoundingClientRect) {
                    const width = node.getBoundingClientRect().width;
                    const height = node.getBoundingClientRect().height;
                    // 当前鼠标移动位置
                    const current =
                    direction === 'vertical'
                        ? event.touches[0].clientX
                        : event.touches[0].clientY;
                    const size = direction === 'vertical' ? width : height;
                    let positionDelta = position! - current;
                    if (step) {
                        if (Math.abs(positionDelta) < step) {
                            return;
                        }
                        // Integer division
                        // eslint-disable-next-line no-bitwise
                        positionDelta = ~~(positionDelta / step) * step;
                    }
                    let sizeDelta = isPrimaryFirst ? positionDelta : -positionDelta;

                    let newMaxSize = maxSize;
                    if (maxSize !== undefined && maxSize <= 0) {
                        const splPane = this.spliter as HTMLDivElement;
                        if (direction === 'vertical') {
                            newMaxSize = splPane.getBoundingClientRect().width + Number(maxSize);
                        } else {
                            newMaxSize = splPane.getBoundingClientRect().height + Number(maxSize);
                        }
                    }

                    let newSize = size - sizeDelta;
                    const newPosition = position - positionDelta;

                    if (newSize < minSize) {
                        newSize = minSize as number;
                    } else if (newMaxSize !== undefined && newSize > newMaxSize) {
                        newSize = newMaxSize as number;
                    } else {
                        this.setState({
                            position: newPosition,
                            resized: true,
                        });
                    }

                    if (onDragMove) onDragMove(newSize);
                    this.setState({ draggedSize: newSize });
                    ref.setState({ size: newSize });
                }
            }
        }
    }

    onMouseUp = () => {
        const { allowResize, onDragFinished } = this.props;
        const { active, draggedSize } = this.state;
        if (allowResize && active) {
            if (typeof onDragFinished === 'function') {
                onDragFinished(draggedSize!);
            }
            this.setState({ active: false });
        }
    }

    setSize = (props: SpliterProps, state: SpliterState) => {
        const { primary } = this.props;
        let { collapsed } = this.state;
        // 同步props collapsed
        if ('collapsed' in props && props.collapsed !== undefined && props.collapsed !== state.collapsed) {
            collapsed = props.collapsed;
            this.setState({collapsed: props.collapsed})
        }
        const ref = primary === 'first' ? this.pane1 : this.pane2;
        let newSize;
        if (ref) {
            newSize =
        props.size ??
        (state && state.draggedSize) ??
        props.defaultSize ??
        props.minSize;
            newSize = collapsed ? 0 : newSize;
            ref.setState({
                size: newSize,
            });
            if (props.size !== state.draggedSize) {
                this.setState({
                    draggedSize: newSize,
                });
            }
        }
    }

    handleChangeCollapsed = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: boolean) => {
        e.stopPropagation()
        const { onCollapse } = this.props;
        if (!('collapsed' in this.props)) {
            this.setState({
                collapsed: value
            }, () => this.setSize(this.props, this.state))
        }
        onCollapse && onCollapse(value);
    };

    getTriggerDom = () => {
        const { trigger, primary, direction, clsPrefix } = this.props;
        const { collapsed } = this.state;
        const reverseArrow = primary === 'second';
        const iconObj = {
            verticalExpanded: reverseArrow ? <Icon type="uf-youjiantou_shuang" /> : <Icon type="uf-daoshouye" />,
            verticalCollapsed: reverseArrow ? <Icon type="uf-daoshouye" /> : <Icon type="uf-youjiantou_shuang" />,
            horizontalExpanded: reverseArrow ? <Icon type="uf-zhankai" /> : <Icon type="uf-shouqi" />,
            horizontalCollapsed: reverseArrow ? <Icon type="uf-shouqi" /> : <Icon type="uf-zhankai" />,
        };
        const styleObj = {
            verticalExpanded: reverseArrow ? {left: '1px', right: 'unset'} : {left: 'unset', right: '1px'},
            verticalCollapsed: reverseArrow ? {left: 'unset', right: '1px'} : {left: '1px', right: 'unset'},
            horizontalExpanded: reverseArrow ? {top: '1px', bottom: 'unset'} : {top: 'unset', bottom: '1px'},
            horizontalCollapsed: reverseArrow ? {top: 'unset', bottom: '1px'} : {top: '1px', bottom: 'unset'},
        };
        const status = collapsed ? `${direction}Collapsed` : `${direction}Expanded`;
        const defaultTrigger = iconObj[status];
        const style = styleObj[status];
        return trigger !== null ? (
            <div
                onClick={(e) => this.handleChangeCollapsed(e, !collapsed)}
                style={{...style}}
                className={classNames(
                    `${clsPrefix}-resizer-trigger`,
                    `${clsPrefix}-resizer-trigger-${collapsed ? 'collapsed' : 'expanded'}`,
                )}
            >
                {trigger || defaultTrigger}
            </div>
        ) : null;
    }

    render() {
        const {
            allowResize,
            children,
            className,
            clsPrefix,
            defaultSize,
            minSize,
            onResizerClick,
            onResizerDoubleClick,
            // paneClassName,
            // pane1ClassName,
            // pane2ClassName,
            // paneStyle,
            // pane1Style: pane1StyleProps,
            // pane2Style: pane2StyleProps,
            primary,
            resizerClassName,
            resizerStyle,
            size,
            direction,
            collapsible,
            style: styleProps,
        } = this.props;

        const { active } = this.state;

        const style: React.CSSProperties = {
            display: 'flex',
            flex: 1,
            height: '100%',
            // position: 'absolute',
            outline: 'none',
            overflow: 'hidden',
            MozUserSelect: 'text',
            WebkitUserSelect: 'text',
            msUserSelect: 'text',
            userSelect: 'text',
            ...styleProps || {},
        }


        if (direction === 'vertical') {
            Object.assign(style, {
                flexDirection: 'row',
                left: 0,
                right: 0,
            });
        } else {
            Object.assign(style, {
                bottom: 0,
                flexDirection: 'column',
                minHeight: '100%',
                top: 0,
                width: '100%',
            });
        }

        const classes = classNames(clsPrefix, {
            [`${clsPrefix}-disabled`]: !allowResize,
            [`${clsPrefix}-collapsed`]: collapsible && !active, // 增加collapsed 动画
        }, className);
        const pane1Classes = classNames(`${clsPrefix}-first`);
        const pane2Classes = classNames(`${clsPrefix}-second`);

        const triggerDom = this.getTriggerDom()
        return (
            <div
                className={classes}
                ref={node => {
                    this.spliter = node;
                }}
                style={style}
            >
                <Pane
                    className={pane1Classes}
                    key="pane1"
                    ref={node => {
                        this.pane1 = node;
                    }}
                    size={
                        primary === 'first' ? size ?? defaultSize ?? minSize : undefined
                    }
                    direction={direction}
                >
                    {children && children.length > 0 ? children[0] : null }
                </Pane>
                <Resizer
                    onClick={onResizerClick}
                    onDoubleClick={onResizerDoubleClick}
                    onMouseDown={this.onMouseDown}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onMouseUp}
                    key="resizer"
                    ref={node => {
                        this.resizer = node;
                    }}
                    resizerClassName={resizerClassName}
                    direction={direction}
                    style={resizerStyle}
                    trigger={collapsible ? triggerDom : null}
                />
                <Pane
                    className={pane2Classes}
                    key="pane2"
                    ref={node => {
                        this.pane2 = node;
                    }}
                    size={
                        primary === 'second' ? size ?? defaultSize ?? minSize : undefined
                    }
                    direction={direction}
                >
                    {
                        React.Children.map(children, (child, index) => {
                            if (index === 0) return null;
                            return child
                        })
                    }
                </Pane>
            </div>
        );
    }
}

export default Spliter as React.ComponentClass<Partial<SpliterProps>>;
