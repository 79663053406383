import enUS from 'rc-calendar/lib/locale/en_US';
import viVN from 'rc-calendar/lib/locale/vi_VN';
import zhCN from 'rc-calendar/lib/locale/zh_CN';
import zhTW from 'rc-calendar/lib/locale/zh_TW';

const i18n = {
    'lang': 'zh-cn',
    'zh-cn': {...zhCN, clickSelectColomn: '点击此处选择纵列', clickSelect: '点击此处选中', date: '日', toToday: '回到今天', toLastDay: '上一天', toNextDay: '下一天', toLastMonth: '上个月', toNextMonth: '下个月', allDay: '全天'},
    'en-us': {...enUS, clickSelectColomn: 'click here to select the colomn', clickSelect: 'click here to select', date: 'date', toToday: 'Back to today', toLastDay: 'toLastDay', toNextDay: 'toNextDay', toLastMonth: 'toLastMonth', toNextMonth: 'toNextMonth', allDay: 'allDay'},
    'zh-tw': {...zhTW, clickSelectColomn: '點擊此處選擇縱列', clickSelect: '點擊此處選中', date: '日'},
    'vi-vn': {...viVN, clickSelectColomn: 'Bấm vào đây để chọn một cột', clickSelect: 'Bấm vào đây để chọn', date: 'ngày', toToday: 'Quay về hôm nay', toLastDay: 'Ngày cuối', toNextDay: 'ngày hôm sau', toLastMonth: 'cuối tháng', toNextMonth: 'tháng tới', allDay: 'cả ngày'},
}
export default i18n
