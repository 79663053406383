/*
 * @Author: Mr.mjc
 * @Date: 2022-06-15 17:39:30
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2023-02-03 10:57:29
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/lib/singleFilter.tsx
 */
// import PropTypes from 'prop-types';
import List from 'rc-virtual-list';
import React, {Component} from "react";
import Button from "../../../wui-button/src";
import Checkbox from "../../../wui-checkbox/src";
import RadioWrapper from "../../../wui-radio/src";
import {prefix} from "../../../wui-core/src/index"
import Icon from "../../../wui-icon/src";
import Input from "../../../wui-input/src";
import Popover from "../../../wui-popover/src";
import i18n from "./i18n";
import {getLangInfo} from "../../../wui-locale/src/tool";
import { ObjectAssign} from "./util";
import {getItemKey} from './utils';
import { TableProps, ISingleFilterState, ColumnType} from '../iTable';
import { DefaultRecordType, DataIndex, FilterDropdownDataType } from '../interface';
import { TableInterface } from "../index";

// const CheckboxGroup = Checkbox.Group;
/**
 * 参数：prefixCls，默认bee-table,用于设置图标的样式
 * @param {*} Table
 * @param {*} Icon
 */

export default function singleFilter(Table: React.ComponentClass<Partial<TableProps>> | TableInterface) {
    return class SingleFilterTable extends Component<TableProps, ISingleFilterState> {
		key: string | number;
		handleKey: string | number;
		handleSrc: boolean;
		data: DefaultRecordType[];
		handleIcon: HTMLElement | undefined | EventTarget | null;
		// data: DefaultRecordType[];
		constructor(props: TableProps) {
		    super(props);
		    let flatColumns = this._toFlatColumn(props.columns, -1);
		    this.state = {
		        data: this.props.data,
		        columns: props.columns,
		        flatColumns: flatColumns,
		        filterObj: {},
		        showTop: false
		    };
		    this.key = ''; // 当前点击的key
		    this.handleKey = ''; // 当前过滤操作的key
		    this.handleSrc = false; // 触发当前过滤事件的入口
		    this.data = ObjectAssign(this.props.data) as DefaultRecordType[]; // 缓存一份未过滤初始的数据
		}

		static defaultProps = {
		    prefixCls: `${prefix}-table`, // 默认是前端排序，值为true为后端排序
		    rowKey: 'key',
		    filterDropdownAuto: 'auto',
		    filterDropdownData: []
		};

		// static propTypes = {
		//     columns: PropTypes.any,
		//     sort: PropTypes.any,
		//     data: PropTypes.any,
		//     onDropBorder: PropTypes.func,
		//     rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		//     _onDataChange: PropTypes.func,
		// }

		componentWillReceiveProps(nextProps: TableProps) {

		    if (nextProps.columns !== this.props.columns) {
		        let flatColumns = this._toFlatColumn(nextProps.columns, -1);
		        this.setState({columns: nextProps.columns, flatColumns});
		    }

		    if (nextProps.data !== this.state.data) {
		        this.setState({
		            data: nextProps.data,
		            filterObj: {},
		            showTop: false
		        }, () => {
		            this.key = ''; // 当前点击的key
		            this.handleSrc = false; // 数据变化引起的过滤
		            this.handleKey = ''; // 重新拉去数据后清空之前点击缓存的column的key
		            this.data = ObjectAssign(nextProps.data) as DefaultRecordType[];
		        });
		    }

		}

		// // 获取每行的唯一键
		// getRowKey = (record:DefaultRecordType, index: number) => {
		//     return getValueByRowKey(this.props.rowKey, record, index);
		// }

		/**
		 *column扁平化处理，适应多表头避免递归操作
		 *
		 */
		_toFlatColumn(columns: ColumnType[], parentIndex: number = -1, flatColumns: ColumnType[] = []):ColumnType[] {
		    const that = this;
		    let children = [];
		    flatColumns = flatColumns || [];
		    // const flatColumns = that.state;
		    columns.forEach((item) => {
		        item.parentIndex = parentIndex;
		        children = item.children as ColumnType[];
		        flatColumns.push(item);
		        if (children) {
		            // item.children = [];
		            that._toFlatColumn(children, flatColumns.length - 1, flatColumns);
		        }
		    });
		    return flatColumns;
		}

		/**
		 * 初始化数据
		 * */
		initData = (key: string | number) => {
		    const {columns = []} = this.state;
		    let filterObj = {...this.state.filterObj}
		    if (filterObj[key]) return;
		    let currentColumn = columns.find(item => item.dataIndex === key) as ColumnType;
		    const { filterMultiple = true } = currentColumn;
		    if (!currentColumn) return;
		    // 自定义数据
		    if (currentColumn.filterDropdownAuto && currentColumn.filterDropdownAuto === 'manual') {
		        filterObj[key] = {
		            dataIndex: key,
		            searchStr: '', // 输入框搜索的值
		            filterAllList: ((currentColumn.filterDropdownData || []) as FilterDropdownDataType[]).map(item => item.value) || [], //  选框所有的数据集合
		            filterCheckedList: ((currentColumn.filterDropdownData || []) as FilterDropdownDataType[]).map(item => item.value) || [], // 选框选中的数据集合
		            originFilterAllList: ((currentColumn.filterDropdownData || []) as FilterDropdownDataType[]).map(item => item.value) || [], // 初始化缓存的一份所有数据的集合（针对搜索功能清空还原数据）
		            // filterList: [], // 启用选中的item
		            isFilter: false, // 是否启用过滤
		            checkAll: true,
		            indeterminate: false
		        }
		    } else {
		        filterObj[key] = {
		            dataIndex: key,
		            searchStr: '', // 输入框搜索的值
		            filterAllList: [],
		            filterCheckedList: [],
		            // filterList: [], // 启用选中的item
		            originFilterAllList: [],
		            isFilter: false, // 是否启用过滤
		            checkAll: true,
		            indeterminate: false
		        };
		        this.data.forEach(item => {
		            let itemKey = getItemKey(item, key);
		            // let itemKey = filterData && typeof filterData === 'function' ? filterData(item, key, i) : getItemKey(item, key);
		            if (filterObj[key].filterAllList.indexOf(itemKey) === -1 && (typeof itemKey === 'number' || typeof itemKey === 'string')) {
		                filterObj[key].filterAllList.push(itemKey);
		                if (filterMultiple) {
		                    filterObj[key].filterCheckedList.push(itemKey);
		                }
		                filterObj[key].originFilterAllList.push(itemKey);
		            }
		        })
		    }
		    this.setState({filterObj})
		}
		// 每个column上添加orderNum属性，不排序时为“”。
		// 点击时orderNum有值则不重新赋值，如果没有值，则取当前column下的有oderNum的length值。并排序
		// 点击置为“”时，动态的设置相关column的orderNum值。并排序
		renderColumnsDropdown = (columns: ColumnType[]) => {
		    let tempColumns = [], rsColumns: ColumnType[] = [];
		    tempColumns = columns.map((originColumn: ColumnType) => {
		        let column = Object.assign({}, originColumn);
		        return this.filterColumn(column);
		    });
		    rsColumns = this._flatToColumn(tempColumns);
		    return rsColumns;
		};

		/**
		 * input 有过滤值时， selectList是否包含 filterAllList,判断全选状态, 参数：ture是全选判断，false是半选判断
		 * */
		isContainerArr = (v: boolean) => {
		    const {filterObj} = this.state;
		    const {key} = this;
		    let newArr = filterObj[key].filterAllList.filter((item: string) => {
		        return filterObj[key].filterCheckedList.indexOf(item) !== -1;
		    })
		    if (v) {
		        return newArr.length === filterObj[key].filterAllList.length && newArr.length !== 0;
		    } else {
		        return newArr.length > 0;
		    }
		}
		/**
		 * check onchange 事件判断
		 * */
		checkChange = (v: boolean, item:string) => {
		    const {filterObj} = this.state;
		    const {key} = this;
		    if (v) {
		        filterObj[key].filterCheckedList = filterObj[key].filterCheckedList.concat(item)
		    } else {
		        filterObj[key].filterCheckedList = filterObj[key].filterCheckedList.filter((_item:string) => _item !== item)
		    }
		    filterObj[key].checkAll = this.isContainerArr(true);
		    filterObj[key].indeterminate = this.isContainerArr(false);
		    this.setState({
		        filterObj: this.state.filterObj
		    })
		};

		clickRadio = (_event: React.ChangeEvent<HTMLInputElement>, value: string | boolean | number, item: string) => {
		    const {filterObj} = this.state;
		    const {key} = this;
		    filterObj[key].filterCheckedList = []
		    if (value) {
		        filterObj[key].filterCheckedList.push(item)
		    }
		    this.setState({
		        filterObj: this.state.filterObj
		    })
		};

		iconClick = (e:React.MouseEvent<HTMLElement>, key:string | number) => {
		    this.handleSrc = true; // 主动触发的过滤
		    this.handleIcon = e.target;
		    this.key = key;
		    this.initData(key);
		    this.setState({
		        showTop: true
		    })
		}
		/**
		 * input 输入框改变时，触发的事件
		 * */
		inputChange = (v: string) => {
		    const {filterObj} = this.state;
		    const {key} = this;
		    filterObj[key].searchStr = v;
		    if (v) {
		        filterObj[key].filterAllList = filterObj[key].originFilterAllList.filter((item: string | number) => ((item || item === 0) && item.toString().indexOf(v) !== -1));
		    } else {
		        filterObj[key].filterAllList = filterObj[key].originFilterAllList.concat();
		    }
		    filterObj[key].checkAll = this.isContainerArr(true);
		    filterObj[key].indeterminate = this.isContainerArr(false);
		    this.setState({
		        filterObj: this.state.filterObj
		    })
		}

		/**
		 * 全选触发事件, 参数bool
		 * */
		isAllCheck = (v: boolean) => {
		    const {filterObj} = this.state;
		    const {key} = this;
		    filterObj[key].checkAll = v;
		    filterObj[key].indeterminate = v;
		    if (v) {
		        filterObj[key].filterCheckedList = this.data.map(item => getItemKey(item, key));
		    } else {
		        filterObj[key].filterCheckedList = []
		    }
		    this.setState({
		        filterObj: this.state.filterObj
		    })
		};

		filter = (arr: DefaultRecordType[], k: string | number) => {
		    const {filterObj, columns = []} = this.state;
		    let currentColumn: ColumnType = columns.find(item => item.dataIndex === k) as ColumnType;
		    const { filterMultiple = true } = currentColumn;
		    let filterCheckedList = filterObj[k].filterCheckedList; // 已选中的弹框内容
		    let filterAllList = filterObj[k].filterAllList;
		    arr.splice(0); // 清空数据
		    let i = 0;
		    if (currentColumn && currentColumn.onFilter && typeof currentColumn.onFilter === 'function') {
		        // => antd相关源码
		        // this.data.filter(function (record) {
		        // 	return filterCheckedList.some(function (key) {
		        // 		var keys = flattenKeys(filters);
		        // 		var keyIndex = keys.findIndex(function (k) {
		        // 			return String(k) === String(key);
		        // 		});
		        // 		var realKey = keyIndex !== -1 ? keys[keyIndex] : key;
		        // 		return onFilter(realKey, record);
		        // 	});
		        // });
		        if (filterMultiple) { // 多选
		            let filterCheckedListSort: string[] = [];
		            filterAllList.forEach((item: string) => {
		                if (filterCheckedList.includes(item)) {
		                    filterCheckedListSort.push(item)
		                }
		            })
		            this.data.forEach((item) => {
		                for (let j = 0; j <= filterCheckedListSort.length - 1; j++) {
		                    let flag = currentColumn && currentColumn.onFilter && currentColumn.onFilter(filterCheckedListSort[j], item);
		                    if (flag) {
		                        arr[i] = item;
		                        i++
		                        break;
		                    }
		                }
		            })
		        } else {
		            this.data.forEach((item) => {
		                let flag = currentColumn && currentColumn.onFilter && currentColumn.onFilter(filterCheckedList[0], item);
		                if (flag) {
		                    arr[i] = item;
		                    i++
		                }
		            })
		        }
		    } else {
		        this.data.forEach((item) => {
		            let itemKey = getItemKey(item, k);
		            if (filterCheckedList.includes(itemKey)) {
		                arr[i] = item;
		                i++
		            }
		        })
		    }
		    return arr
		}

		onHide = () => {
		    (this.handleIcon as HTMLElement).click()
		}

		save = () => {
		    const {filterObj} = this.state;
		    if (this.handleSrc) { // 点击触发的过滤，更新操作的key
		        this.handleKey = this.key;
		    }
		    const {handleKey: key} = this;
		    let tempData = this.state.data;
		    tempData = this.filter(tempData, key);
		    this.setState({
		        data: tempData,
		        filterObj
		    })
		    this.props._onDataChange && this.props._onDataChange(tempData)
		    this.handleSrc && this.onHide()
		};

		getCloumItem = (key: string | number, column: ColumnType) => {
		    const {filterObj} = this.state;
		    const {prefixCls} = this.props;
		    const { filterMultiple = true } = column;
		    return (
		        <List data={filterObj[key]?.filterAllList || []} height={300} itemHeight={30} itemKey="item">
		            {
		                (item: string, index: number) => (
		                    <div className={`${prefixCls}-pop-check-wrap`}>
		                        {filterMultiple ? <Checkbox value={item} key={`${item}_${index}`}
										  checked={filterObj[key]?.filterCheckedList.includes(item)}
										  onChange={(v: boolean) => this.checkChange(v, item)}>
		                            {item === undefined ? '空值' : item}
		                        </Checkbox> :
		                            <RadioWrapper value={item} checked={filterObj[key]?.filterCheckedList.includes(item)} onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string | boolean | number) => this.clickRadio(event, value, item)}>{item === undefined ? '空值' : item}</RadioWrapper>}
		                    </div>
		                )
		            }
		        </List>
		    );
		};

		showTop = () => {
		    this.setState({
		        showTop: true
		    })
		}

		onVisibleChangeTop = (visible: boolean) => {
		    if (!visible) {
		        this.key = ''
		    }
		    this.setState({
		        showTop: visible
		    })
		}

		filterColumn = (column: ColumnType) => {
		    if (column.dataIndex === 'checkbox' || column.dataIndex === 'radio' || column.singleFilter === false) {
		        return column
		    }
		    let locale = getLangInfo(this.props.locale, i18n);
		    const {dataIndex, filterMultiple = true} = column;
		    const {prefixCls, fieldid} = this.props;
		    let fieldidAttr = fieldid ? { fieldid: `${fieldid}_single_filter_icon` } : {}
		    const {filterObj} = this.state;
		    let content = this.key === dataIndex ? (
		        <div className={`${prefixCls}-pop-cont-single-filter`}>
		            <div>
		                <Input type="search" showClose value={filterObj[dataIndex]?.searchStr}
							   onChange={this.inputChange}/>
		            </div>
		            <div className={`${prefixCls}-pop-cont-single-filter-check`}>
		                {this.getCloumItem(dataIndex, column)}
		            </div>
		            <div className={`${prefixCls}-pop-footer`}>
		                {filterMultiple ? <span className={`${prefixCls}-clear-setting`}>
		                    <Checkbox indeterminate={filterObj[dataIndex]?.indeterminate} checked={filterObj[dataIndex]?.checkAll}
		                        onChange={(checked: boolean) => {
		                            this.isAllCheck(checked)
		                        }}>{locale.langMap.all || '全部'}</Checkbox>
		                </span> : null}
		                <Button colors="secondary" size="sm" onClick={this.onHide}>{locale.langMap.cancel || '取消'}</Button>
		                <Button colors="primary" size="sm" onClick={this.save}>{locale.langMap.ok || '确定'}</Button>
		            </div>
		        </div>
		    ) : null;
		    let wrapClassName = (filterObj[dataIndex as DataIndex] && filterObj[dataIndex as DataIndex].isFilter) || (this.state.showTop && this.key === dataIndex) ? `${prefix}-table-column-single-filter ${prefix}-table-column-single-filter-active` : `${prefix}-table-column-single-filter`;
		    let filterButton = <div className={`${wrapClassName} ${prefix}-table-title-icon`}>
		        <Popover rootClose arrowPointAtCenter placement="top" content={content} trigger="click" show={this.state.showTop}
				        onVisibleChange={this.onVisibleChangeTop}>
		            <Icon type="uf-shaixuan1-copy" {...fieldidAttr} onClick={(e) => this.iconClick(e, dataIndex as DataIndex)}/>
		        </Popover>
		    </div>;

		    column.title = <>
		        {typeof column.title === 'string' ? <span className={`${prefix}-table-title-text-span`}
														  title={column.title}>{column.title}</span> : column.title}
		        {filterButton}
		    </>;
		    return column;
		};

		_flatToColumn(flatColumns: ColumnType[]) {
		    const colLen = flatColumns.length;
		    let parentIndex, rsColumns = [];
		    // 每次渲染需要将父类的children置空，避免重复
		    flatColumns.forEach(item => {
		        if (item.children) {
		            item.children = [];
		        }
		    })
		    for (let i = colLen - 1; i >= 0; i--) {
		        parentIndex = flatColumns[i].parentIndex;
		        if (parentIndex && parentIndex >= 0) {
		            // @ts-ignore
		            flatColumns[parentIndex].children.unshift(flatColumns[i]);
		        }
		    }
		    rsColumns = flatColumns.filter(item => {
		        return item.parentIndex == -1
		    })
		    return rsColumns;
		}

		// 列宽度拖拽后需要更新sort组件的state.columns数据（否则点了排序后被拖拽的列宽度会被还原）
		handleDropBorder = (event:React.MouseEvent<HTMLElement>, newWidth: number, newColumn: ColumnType, newColumns: ColumnType[]) => {
		    const {onDropBorder} = this.props;
		    let flatColumn = this.state.flatColumns.find(column => column.dataIndex == newColumn.dataIndex) as ColumnType;
		    flatColumn.width = newWidth;
		    this.setState({flatColumns: this.state.flatColumns});
		    typeof onDropBorder == 'function' && onDropBorder(event, newWidth, newColumn, newColumns);
		}

		_onDataChange = (data: DefaultRecordType[]) => {
		    this.setState({data})
		    this.props._onDataChange && this.props._onDataChange(data)
		}

		render() {
		    let columns = this.renderColumnsDropdown(this.state.flatColumns.concat());
		    return <Table {...this.props} columns={columns} data={this.state.data} onDropBorder={this.handleDropBorder}
						  _onDataChange={this._onDataChange}/>;
		}
    } as React.ComponentClass<Partial<TableProps>> | TableInterface;
}
