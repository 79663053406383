import classNames from 'classnames';
import React from 'react';
// import {elementType} from '../../wui-core/src';
import {prefix, WebUI} from "../../wui-core/src/index"
// import PropTypes from 'prop-types';
import { AnyObject, ModalBodyProps } from './iModal';

// const propTypes = {
//     componentClass: elementType,
//     fieldid: PropTypes.string
// };

const defaultProps: ModalBodyProps = {
    componentClass: 'div',
    clsPrefix: `${prefix}-modal-body`
};

@WebUI({name: "modal-body", defaultProps})
class ModalBody extends React.Component<ModalBodyProps> {
    static defaultProps = defaultProps;
    render() {
        const {componentClass: Component, clsPrefix, className, ...props} = this.props;
        const classes: AnyObject = {};
        classes[`${clsPrefix}`] = true;

        return (
            <Component
                {...props}
                className={classNames(className, classes)}
            />
        );
    }
}

// ModalBody.propTypes = propTypes;

export default ModalBody as React.ComponentClass<Partial<ModalBodyProps>>;
