/*
 * @Author: Mr.mjc
 * @Date: 2022-07-05 14:09:58
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2022-11-03 18:29:05
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/index.tsx
 */
import Column from "./Column";
import ColumnGroup from "./ColumnGroup";
import AntdTable from './lib/AntdTable';
import bigData from './lib/bigData';
import dragColumn from './lib/dragColumn';
import filterColumn from './lib/filterColumn';
import multiSelect from './lib/multiSelect';
import singleFilter from './lib/singleFilter';
import singleFind from "./lib/singleFind";
import singleSelect from './lib/singleSelect';
import sort from './lib/sort';
import sum from './lib/sum';
import InternalTable from "./IsSticky";
type InternalTableType = typeof InternalTable;
export interface TableInterface extends InternalTableType {
    multiSelect: typeof multiSelect;
    singleSelect: typeof singleSelect;
    bigData: typeof bigData;
    dragColumn: typeof dragColumn;
    filterColumn: typeof filterColumn;
    sort: typeof sort;
    sum: typeof sum;
    singleFilter: typeof singleFilter;
    singleFind: typeof singleFind;
    Column: typeof Column;
    ColumnGroup: typeof ColumnGroup;
    AntdTable: typeof AntdTable;
}
const Table = InternalTable as TableInterface;
Table.multiSelect = multiSelect;
Table.singleSelect = singleSelect;
Table.bigData = bigData;
Table.dragColumn = dragColumn;
Table.filterColumn = filterColumn;
Table.sort = sort;

Table.sum = sum;
Table.singleFilter = singleFilter;
Table.singleFind = singleFind;
Table.Column = Column;
Table.ColumnGroup = ColumnGroup;
Table.AntdTable = AntdTable;
export default Table;
