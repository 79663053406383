import classnames from 'classnames';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Animate from '../../wui-animate/src';
import {Warning} from "../../wui-core/src";
import createChainedFunction from '../../wui-core/src/createChainedFunction';
import {WebUI} from "../../wui-core/src/index";
import addEventListener from '../../wui-overlay/src/utils/addEventListener';
import {AddEventLReturn} from '../../wui-overlay/src/utils/IUtils';
import ownerDocument from '../../wui-overlay/src/utils/ownerDocument';
import Notice from './Notice';
import {NewInstanceCb, NoticeProps, NotificationProps} from './iNotification';
import { OrNull } from '../../wui-core/src/utils/type';

const {isShouldUpdate} = Warning;
let seed = 0;
const now = Date.now();

function getUuid() {
    return `uNotification_${now}_${seed++}`;
}

// const propTypes = {
//     show: PropTypes.bool,
//     clsPrefix: PropTypes.string,
//     style: PropTypes.object,
//     position: PropTypes.oneOf(['topRight', 'bottomRight', 'top', 'bottom', 'topLeft', 'bottomLeft', '']),
//     transitionName: PropTypes.string,
//     keyboard: PropTypes.bool, // 按esc键是否关闭notice
//     onEscapeKeyUp: PropTypes.func, // 设置esc键特殊钩子函数
//     animation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
// };

const defaultProps = {
    animation: 'fade',
    keyboard: true,
    position: 'topRight',
    show: false,
    clsPrefix: "",
    transitionName: "",
    onEscapeKeyUp: () => {
    }
}

@WebUI({name: "notification", defaultProps})
class Notification extends Component<NotificationProps, {notices: NoticeProps[]}> {
    // notice(noticeProps) {
    //     notification.add(noticeProps);
    // },
    // removeNotice(key) {
    //     notification.remove(key);
    // },
    // component: notification,
    // destroy() {
    //     ReactDOM.unmountComponentAtNode(div);
    //     container.removeChild(div);
    // },
    static defaultProps = defaultProps;
    static clear: () => void;
    static handleClose: (key: string) => void;
    static add: (notice: NoticeProps) => void;
    static newInstance: (notice: NotificationProps, callback: NewInstanceCb) => void;
    static success: (notice: NoticeProps) => void;
    static info: (notice: NoticeProps) => void;
    static warning: (notice: NoticeProps) => void;
    static warn: (notice: NoticeProps) => void;
    static error: (notice: NoticeProps) => void;
    static open: (notice: NoticeProps) => void;
    static close: (key: string) => void;
    static destroy: () => void;
    _onDocumentKeyupListener: OrNull<AddEventLReturn> = null;
    constructor(props: NotificationProps) {
        super(props);
        this.state = {
            notices: []
        };
        this.add = this.add.bind(this);
        this.remove = this.remove.bind(this);
        Notification.clear = this.clear.bind(this)
        Notification.handleClose = this.handleClose.bind(this)
        Notification.add = this.add.bind(this)

    }

    componentDidMount() {
        // 给document绑定keyup事件
        let doc = ownerDocument(this);
        this._onDocumentKeyupListener =
			addEventListener(doc, 'keyup', this.handleDocumentKeyUp);
    }

    componentWillUnmount() {
        this._onDocumentKeyupListener!.remove();
        this._onDocumentKeyupListener = null;
    }

    getTransitionName() {
        const props = this.props;
        let transitionName = props.transitionName;
        if (!transitionName && props.animation) {
            transitionName = `${props.clsPrefix}-${props.animation}`;
        }
        return transitionName;
    }

    add(notice: NoticeProps) {
        const key = notice.key = notice.key || getUuid();
        this.setState(previousState => {
            const notices = previousState.notices;
            if (!notices.filter(v => v.key === key).length) {
                return {
                    notices: notices.concat(notice),
                };
            }
        });
    }

    remove(key: string | number) {
        this.setState(previousState => {
            return {
                notices: previousState.notices.filter(notice => notice.key !== key),
            };
        });
    }

    handleClose(key: string) {
        let that = this
        if (key && that.state.notices.length > 0) {
            let newArr = that.state.notices
            // console.log(newArr.findIndex(item => item.key === key))
            let keyArr = newArr.map(res => res.key)
            if (keyArr.includes(key)) {
                newArr.splice(newArr.findIndex(item => item.key === key), 1)
            }
            that.setState({
                notices: newArr
            })
        }
    }

    clear() {
        let that = this
        that.setState({
            notices: []
        })
    }

	/**
	 * 处理绑定在document上的keyup事件
	 */
	handleDocumentKeyUp = (e: React.KeyboardEvent) => {
	    if (this.props.keyboard && e.keyCode === 27 && this.state.notices.length) {
	        this.setState(previousState => {
	            previousState.notices.shift()
	            return {
	                notices: previousState.notices,
	            };
	        });
	        if (this.props.onEscapeKeyUp) {
	            this.props.onEscapeKeyUp(e);
	        }
	    }
	}

	render() {
	    const {
	        clsPrefix,
	        className,
	        position,
	        fieldid,
	        style,
	    } = this.props;
	    isShouldUpdate("Notification", this.props);
	    const noticeNodes = this.state.notices.map((notice) => {
	        const onClose = createChainedFunction(this.remove.bind(this, notice.key), notice.onClose);
	        return (<Notice
	            key={notice.content as string}
	            clsPrefix={clsPrefix}
	            {...notice}
	            onClose={onClose}
	        >
	            {notice.content}
	        </Notice>);
	    });
	    const classes = {
	        [clsPrefix as string]: 1,
	        [className ?? ""]: !!className,
	    };
	    if (position) {
	        classes[`${clsPrefix}-${position}`] = !!position;
	    }

	    return (
	        <div className={classnames(classes)} fieldid={fieldid || this.state.notices?.[0]?.fieldid} style={style}>
	            <Animate transitionName={this.getTransitionName()}>{noticeNodes}</Animate>
	        </div>
	    );
	}
}

// Notification.propTypes = propTypes;

Notification.newInstance = function newNotificationInstance(properties, callback) {

    if (typeof callback !== 'function') {
        // eslint-disable-next-line no-console
        console.error('You must introduce callback as the second parameter of Notification.newInstance().')
        return
    }
    const props = properties || {};
    let container = props.getPopupContainer || props.container || props.getContainer || document.body;
    if (typeof container == 'function') {
        container = (container as (node?: HTMLElement | undefined) => HTMLElement)()
    }

    const div = document.createElement('div');
    container.appendChild(div);

    let called = false;

    function ref(notification: Notification) {
        if (called) {
            return;
        }
        called = true;
        callback({
            notice(noticeProps) {
                notification.add(noticeProps);
            },
            removeNotice(key) {
                notification.remove(key);
            },
            component: notification,
            destroy() {
                ReactDOM.unmountComponentAtNode(div);
                (container as HTMLElement).removeChild(div);
            },
        });
    }

    ReactDOM.render(<Notification {...props} ref={ref}/>, div);

};
Notification.success = function(properties) {
    Notification.add(Object.assign(properties, {color: 'success'}))
}
Notification.info = function(properties) {
    Notification.add(Object.assign(properties, {color: 'info'}))
}
Notification.warning = function(properties) {
    Notification.add(Object.assign(properties, {color: 'warning'}))
}
Notification.warn = function(properties) {
    Notification.add(Object.assign(properties, {color: 'warning'}))
}
Notification.error = function(properties) {
    Notification.add(Object.assign(properties, {color: 'danger'}))
}
Notification.open = function(properties) {
    Notification.add(properties)
}
Notification.close = function(properties) {
    Notification.handleClose(properties)
}
Notification.destroy = function() {
    Notification.clear()
}

export default Notification;
