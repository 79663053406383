const themeConfig = {
    default: {},
    red: {
        '--wui-primary-color': '#EB5230',
        '--wui-primary-color-hover': '#D7401E',
        '--wui-primary-color-active': '#D7401E',
        '--wui-primary-color-light': 'rgba(235, 82, 48, 0.1)'
    },
    green: {
        '--wui-primary-color': '#02A14B',
        '--wui-primary-color-hover': '#069346',
        '--wui-primary-color-active': '#069346',
        '--wui-primary-color-light': 'rgba(2, 161, 75, 0.1)'
    },
    blue: {
        '--wui-primary-color': '#2A82FF',
        '--wui-primary-color-hover': '#2271E1',
        '--wui-primary-color-active': '#2271E1',
        '--wui-primary-color-light': 'rgba(42, 130, 255, 0.1)'
    },
    yellow: {
        '--wui-primary-color': '#D89649',
        '--wui-primary-color-hover': '#CF8329',
        '--wui-primary-color-active': '#CF8329',
        '--wui-primary-color-light': 'rgba(216, 150, 73, 0.1)'
    }
}

// export default themeConfig // 该资源将在postbuild被以require方式引用，不可改为export default的es module形式
module.exports = themeConfig
