import activeElement from 'dom-helpers/activeElement';
import contains from 'dom-helpers/query/contains';
import canUseDom from 'dom-helpers/util/inDOM';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import warning from 'warning';

import {prefix, KeyCode, cssUtil} from "../../wui-core/src/index";
import Portal from '../../wui-overlay/src/Portal';
import addEventListener from '../../wui-overlay/src/utils/addEventListener';
import addFocusListener from '../../wui-overlay/src/utils/addFocusListener';

import ownerDocument from '../../wui-overlay/src/utils/ownerDocument';
import { ModalPortalProps } from './iModal';
const keyboardTransfer = {
    esc: KeyCode.ESC,
    cancel: KeyCode.N,
    ok: KeyCode.Y,
}
let openModalCount = 0;
/**
 * 基础模态框
 */
// const propTypes = {
//     /**
// 	 * 是否显示
// 	 */
//     show: PropTypes.bool,
//     /**
// 	 * 容器
// 	 */
//     container: Portal.propTypes.container,
//     /**
// 	 * 当模态框打开时的钩子函数
// 	 */
//     onShow: PropTypes.func,
//     /**
// 	 * 当show参数为false时触发的模态框关闭时的钩子函数
// 	 */
//     onHide: PropTypes.func,
//     /**
// 	 * 设置esc键特殊钩子函数
// 	 */
//     onEscapeKeyUp: PropTypes.func,
//     /**
// 	 * 设置按键钩子函数
// 	 */
//     onKeyUp: PropTypes.func,
//     /**
// 	 * 按esc键是否关闭模态框
// 	 */
//     keyboard: PropTypes.bool,
//     /**
// 	 *容器的class
// 	 */
//     className: PropTypes.string,
//     /**
// 	 *容器的style
// 	 */
//     style: PropTypes.object,
//     /**
// 	 * 是否自动设置焦点
// 	 */
//     autoFocus: PropTypes.bool,
//     /**
// 	 * 防止焦点离开模态框
// 	 */
//     enforceFocus: PropTypes.bool,
//     fieldid: PropTypes.string,
//     clsPrefix: PropTypes.string,
//     // /**
//     //  * 模态框进入时的钩子函数
//     //  */
//     // onEnter: PropTypes.func,
//     // /**
//     //  * 模态框开始进入时的钩子函数
//     //  */
//     // onEntering: PropTypes.func,
//     // /**
//     //  * 模态框进入后的钩子函数
//     //  */
//     // onEntered: PropTypes.func,
//     // /**
//     //  * 模态框退出时的钩子函数
//     //  */
//     // onExit: PropTypes.func,
//     // /**
//     //  * 模态框开始退出时的钩子函数
//     //  */
//     // onExiting: PropTypes.func,
//     // /**
//     //  * 模态框推出后的钩子函数
//     //  */
//     // onExited: PropTypes.func,
// };

const defaultProps = {
    show: false,
    destroyOnClose: true,
    backdrop: true,
    keyboard: null,
    autoFocus: true,
    enforceFocus: true,
    onShow: null,
    onHide: null,
    onEscapeKeyUp: null,
    onKeyUp: null,
};
const contextTypes = {
    $modal: PropTypes.shape({
        onCancel: PropTypes.func,
        onOk: PropTypes.func,
        handleHide: PropTypes.func,
    }),
};

class ModalPortal extends Component<ModalPortalProps> {
    static defaultProps = defaultProps;
    isInitModal: boolean;
    modal: HTMLDivElement | null = null;
    lastFocus: HTMLDivElement | null = null;
    _onDocumentKeyupListener: any = null;
    _onFocusinListener: any = null;
    static contextTypes = contextTypes;
    constructor(props: ModalPortalProps, _content: {}) {
        super(props);
        this.isInitModal = true;
    }
    /* eslint-disable */
	UNSAFE_componentWillUpdate(nextProps: ModalPortalProps) {
		if (!this.props.show && nextProps.show) {
			this.checkForFocus();
		}
	}
	/* eslint-disable */
	UNSAFE_componentWillReceiveProps(props: ModalPortalProps) {
		if (props.show) {
			this.isInitModal = false;
		}
	}

	componentDidMount() {
		if (this.props.show) {
			this.onShow();
		}
	}

	componentWillUnmount() {
		if (this.props.show) {
			this.onHide();
		}
	}

	componentDidUpdate(prevProps: ModalPortalProps) {
		if (prevProps.show && !this.props.show) {
			// Otherwise handleHidden will call this.
			this.onHide();
		} else if (!prevProps.show && this.props.show) {
			this.onShow();
		} else if (prevProps.show && this.props.show) {
            // 无遮罩变成有遮罩 更新背部是否滚动
            if (!prevProps.backdrop && this.props.backdrop) {
                this.addScrolling();
            // 有遮罩变成无遮罩 更新背部是否滚动
            } else if (prevProps.backdrop && !this.props.backdrop) {
                this.removeScrolling();
            }
        }
	}
    addScrolling = () => {
        openModalCount ++;
        if (openModalCount !== 1) {
            return;
        }
        const { clsPrefix } = this.props;
        const scrollingClassName = clsPrefix + '-body-overflow';
        document.body.className += ' ' + scrollingClassName;
    }
    removeScrolling = () => {
        openModalCount --;
        if (openModalCount !== 0) {
            return;
        }
        const { clsPrefix } = this.props;
        const scrollingClassName = clsPrefix + '-body-overflow';
        const body = document.body;
        body.className = body.className.replace(scrollingClassName, '');
    }
	onShow = () => {
        if (this.props.backdrop) {
            this.addScrolling();
        }
	    this._onDocumentKeyupListener = addEventListener(document, 'keyup', this.handleDocumentKeyUp);
	    this._onFocusinListener = addFocusListener(this.enforceFocus);

	    this.focus();

	    if (this.props.onShow) {
	        this.props.onShow(this.modal);
	    }
	}

	onHide = () => {
        if (this.props.backdrop) {
            this.removeScrolling();
        }
	    if (this._onDocumentKeyupListener) {
	        this._onDocumentKeyupListener.remove();
	        this._onDocumentKeyupListener = null;
	    }
	    if (this._onDocumentKeyupListener) {
	        this._onFocusinListener.remove();
	        this._onFocusinListener = null;
	    }
	    this.restoreLastFocus();

	    if (this.props.onHide) {
	        this.props.onHide();
	    }
	}

	// 在document下按esc键
	handleDocumentKeyUp = (e : React.KeyboardEvent) => {
		const {keyboard, onKeyUp} = this.props;
		const active = activeElement(ownerDocument(this));
		const modal = this.getDialogElement();

		if (modal && modal !== active && !contains(modal, active)) {
			return
		}
		onKeyUp && onKeyUp(e, this.modal as HTMLDivElement);
        if (keyboard instanceof Array) {
            keyboard.forEach(key => {
                this.keyboardEvents(e, keyboardTransfer[key])
            })
            return;
        }
		this.keyboardEvents(e, keyboard);
	}
    keyboardEvents = (e: React.KeyboardEvent, allowKey?: number | boolean) => {
        const KeyUpEsc = (e: React.KeyboardEvent) => {
		    const {onEscapeKeyUp} = this.props;
            if (e.keyCode === KeyCode.ESC /*&& this.isTopModal()*/) {
                if (onEscapeKeyUp) {
                    onEscapeKeyUp(e);
                }
                const modal = this.context && this.context.$modal;
                if (modal) {
                    modal.onCancel();
                }
            }
        }
        const KeyUpAltN = (e: React.KeyboardEvent) => {
            if (e.keyCode === KeyCode.N && e.altKey) {
                const modal = this.context && this.context.$modal;
                modal.onCancel();
            }
        }
        const KeyUpAltY = (e: React.KeyboardEvent) => {
            if (e.keyCode === KeyCode.Y && e.altKey) {
                const modal = this.context && this.context.$modal;
                modal.onOk(e);
            }
        }
        switch (allowKey) {

            case KeyCode.ESC:
                KeyUpEsc(e);
                break;
            case KeyCode.Y:
                KeyUpAltY(e)
                break;
            case KeyCode.N:
                KeyUpAltN(e)
                break;
            case true:
                KeyUpEsc(e);
                KeyUpAltY(e);
                KeyUpAltN(e);
                break;
            case null :
                KeyUpEsc(e);
                break;
            default:
                break;
        }
    }

	checkForFocus = () => {
	    if (canUseDom) {
	        this.lastFocus = activeElement();
	    }
	}

	focus = () => {
	    let autoFocus = this.props.autoFocus;
		let focusElement = this.getModalBody(this.modal as HTMLDivElement) as HTMLElement;
		if(typeof autoFocus === 'string'){ 
			const selector = (autoFocus ==='ok' || autoFocus ==='cancel') ? `.${prefix}-modal-${autoFocus}-button` : autoFocus;
			focusElement = this.modal?.querySelector(selector) as HTMLElement;
		}
	    let current = activeElement(ownerDocument(this));
	    let focusInModal = focusElement && current && contains(focusElement, current);

	    if (focusElement && autoFocus && !focusInModal) {
	        this.lastFocus = current;

	        if (!focusElement.hasAttribute('tabIndex')) {
	            focusElement.setAttribute('tabIndex', "-1");
	            warning(false,
	                'The modal content node does not accept focus. ' +
					'For the benefit of assistive technologies, the tabIndex of the node is being set to "-1".');
	        }

	        focusElement.focus();
	    }
	}

	restoreLastFocus = () => {
	    // Support: <=IE11 doesn't support `focus()` on svg elements (RB: #917)
	    if (this.lastFocus && this.lastFocus.focus) {
	        this.lastFocus.focus();
	        this.lastFocus = null;
	    }
	}

	enforceFocus = () => {
	    let {enforceFocus} = this.props;

	    if (!enforceFocus /* || !this.mounted || !this.isTopModal()*/) {
	        return;
	    }

	    let active = activeElement(ownerDocument(this));
	    let modal = this.getDialogElement() as HTMLDivElement;

	    if (modal && modal !== active && !contains(modal, active)) {
	        modal.focus();
	    }
	}

	// instead of a ref, which might conflict with one the parent applied.
	getDialogElement = () => {
	    let node = this.modal;
	    return node && node.lastChild;
	}

	getModalBody = (node: HTMLDivElement) => {
	    return node?.getElementsByClassName?.(`${prefix}-modal-body`)?.[0];
	}

	// 是否顶层弹窗
	// isTopModal=()=> {
	//    return this.props.manager.isTopModal(this);
	// }

	// checkEffect = (wr: any) => {
	// 	if (wr?.firstEffect?.elementType?.name && !wr?.firstEffect?.elementType?.name?.startsWith('Modal')) {
	// 		return this.findChildElement(wr.firstEffect)
	// 	} else if (wr?.lastEffect?.elementType?.name && !wr?.lastEffect?.elementType?.name?.startsWith('Modal')) {
	// 		return this.findChildElement(wr?.lastEffect);
	// 	} else {
	// 		return this.findChildElement(wr);
	// 	}
	// }
	findChildElement: any = (parent: any) => {
		if (!parent) {
			return undefined
		}
		if (parent?.stateNode?.nodeName && parent?.stateNode.nodeType) {
			return parent.stateNode.firstChild || parent.stateNode
		}
		return this.findChildElement(parent.return)
	}
	getPopContainer = () => {
		const {container} = this.props;
		if ( typeof container === 'object' && (container as HTMLElement)?.nodeType === 1 && typeof (container as HTMLElement)?.nodeName === 'string') {
			return container as HTMLElement
		}
	    if (typeof container === 'function') {
	        return (container as (node?: HTMLElement) => HTMLElement)()
	    } else {
			// 通过虚拟dom的方式  查找到 Modal 的父组件节点
			const dom = this.findChildElement((this as any)._reactInternalFiber.return);
	        return cssUtil.parentsUntil(dom, [`[tinper-next-role=container]`]);
	    }
	}
	render() {
	    const {
	        destroyOnClose,
	        show,
	        children,
	        className,
            fieldid,
	        style,
			forceRender,
	    } = this.props;

	    if (!show && this.isInitModal && !forceRender) {
	        return null;
	    }
	    if (!show && destroyOnClose && !this.isInitModal) {
	        return null;
	    }

	    return (
	        <Portal
	            container={this.getPopContainer}
	        >
	            <div
	                ref={(el) => {
	                    this.modal = el
	                }}
	                role={'dialogRoot'}
                    fieldid={fieldid}
	                style={style}
	                className={className}
	            >
	                {children}
	            </div>
	        </Portal>
	    );
	}
}

// ModalPortal.propTypes = propTypes;
ModalPortal.contextTypes = contextTypes;
// ModalPortal.defaultProps = defaultProps;


export default ModalPortal;
