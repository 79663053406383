module.exports = {
    'lang': 'zh-cn',
    'remove': '移除文件',
    'down': '下载文件',
    'fileLoading': '文件上传中',
    'toview': '查看大图',
    'zh-cn': {
        'remove': '移除文件',
        'down': '下载文件',
        'fileLoading': '文件上传中',
        'toview': '查看大图',
    },
    'en-us': {
        'remove': 'delete',
        'down': 'download',
        'fileLoading': 'The file on the cross',
        'toview': 'To View',
    },
    'zh-tw': {
        'remove': '移除文件',
        'down': '下載文件',
        'fileLoading': '文件上傳中',
        'toview': '查看大圖',
    },
    'vi-vn': {
        'remove': 'Loại bỏ tập tin',
        'down': 'Tải về tập tin',
        'fileLoading': 'Giấy tờ được chuyển đi',
        'toview': 'Xem hình ảnh',
    },
}
