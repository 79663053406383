/*
 * @Author: Mr.mjc
 * @Date: 2022-08-17 16:43:47
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2023-01-13 11:13:45
 * @Description: 区分表格模式出口文件
 * @FilePath: /next-ui/packages/wui-table/src/IsSticky.tsx
 */
import React, { useMemo, Fragment } from 'react';
import omit from 'omit.js';
import Table from './Table';
import StickyTable from './stickyTable/StickyTable';
import { TableProps } from './iTable';
import TablePage from './lib/tablePage';
import { sticky } from './lib/util';
import { prefix } from "../../wui-core/src/index"
import usePagination, { getPaginationParam } from './hooks/usePagination';


const renderTable: React.FC<Partial<TableProps>> = React.forwardRef((props: TableProps, ref) => {
    let { scrollMode, pagination = false, data: innerData = [], antd, onChange: onOutChange, fieldid } = props;

    // https://github.com/ant-design/ant-design/blob/master/components/table/Table.tsx#L463

    const changeEventInfo:any = {} // 缓存后续一些改变的值sort,filter,pagination

    // 内部一些如sort排序无改变引用地址，故监听不到，需处理
    let data = useMemo(() => {
        return [
            ...innerData
        ]
    }, [innerData, [...innerData]])

    const dataLength = useMemo(() => {
        return data.length
    }, [data])

    const triggerOnChange = (
        info: any,
        action: string,
        reset: boolean = false
    ) => {

        const changeInfo = {
            ...changeEventInfo,
            ...info
        }
        if (reset) {
            changeEventInfo.resetPagination!();
        }

        onOutChange?.(changeInfo.pagination!, {}, {}, {
            currentDataSource: data,
            action,
        });
    }

    const onPaginationChange = (current: number, pageSize: number) => {
        triggerOnChange(
            {
                pagination: { ...changeEventInfo.pagination, current, pageSize },
            },
            'paginate',
        );
    };

    const [mergedPagination, resetPagination] = usePagination(
        data.length,
        pagination,
        onPaginationChange,
    );

    changeEventInfo.pagination = pagination === false ? {} : getPaginationParam(pagination, mergedPagination);

    changeEventInfo.resetPagination = resetPagination;
    let _scrollMode = useMemo(() => {
        if (scrollMode) {
            return scrollMode;
        } else {
            let hasSticky = sticky();
            if (!hasSticky) { // 不支持
                return 'table'
            } else {
                return 'sticky'
            }
        }
    }, [scrollMode])

    // 展示的数据，涉及前端分页
    const showData = useMemo(() => {
        if (pagination === false || !mergedPagination.pageSize) {
            return data
        }
        const {current = 1, pageSize = 10, total} = mergedPagination || {};

        if (data.length < total!) {
            if (data.length > pageSize) {
                return (data || []).slice((current - 1) * pageSize, current * pageSize);
            }
            return data
        }
        return (data || []).slice((current - 1) * pageSize, current * pageSize);
    }, [
        !!pagination,
        data,
        mergedPagination && mergedPagination.current,
        mergedPagination && mergedPagination.pageSize,
        mergedPagination && mergedPagination.total,
    ])

    let hasPageTop = useMemo(() => {
        if (pagination === false || mergedPagination === false) {
            return null
        } else {
            let {
                position = ['bottomRight'],
                ref
            } = mergedPagination;
            if (Object.prototype.toString.call(mergedPagination) === '[object Object]') {
                let pageProps = Object.assign({}, {...mergedPagination}, { total: dataLength })
                let topPos = position.find((item: string) => item.includes('top'))
                if (topPos) {
                    let currentTop = topPos.toLowerCase().replace('top', '')
                    let fieldidAttr = fieldid ? { fieldid } : {}
                    let refAttr = ref ? { ref } : {}
                    return <TablePage {...omit(pageProps, ['position'])} className={`${prefix}-table-page ${prefix}-table-page-${currentTop}`} {...fieldidAttr} {...refAttr}/>
                }
                return null
            }
            return null
        }
    }, [mergedPagination, pagination, dataLength])

    let hasPageBottom = useMemo(() => {
        if (pagination === false || mergedPagination === false) {
            return null
        } else {
            let {
                position = ['bottomRight'],
                ref
            } = mergedPagination;
            if (Object.prototype.toString.call(mergedPagination) === '[object Object]') {
                let pageProps = Object.assign({}, {...mergedPagination}, { total: dataLength })
                let bottomPos = position.find((item: string) => item.includes('bottom'))
                if (bottomPos) {
                    let currentBottom = bottomPos.toLowerCase().replace('bottom', '')
                    let fieldidAttr = fieldid ? { fieldid } : {}
                    let refAttr = ref ? { ref } : {}
                    return <TablePage {...omit(pageProps, ['position'])} className={`${prefix}-table-page ${prefix}-table-page-${currentBottom}`} {...fieldidAttr} {...refAttr}/>
                }
                return null
            }
            return null
        }
    }, [mergedPagination, pagination, dataLength])

    let renderContent = useMemo(() => {
        let renderProps = {
            ...props,
            data: showData
        }
        let tableContent = _scrollMode === 'sticky' ?
            // @ts-ignore
            <StickyTable {...renderProps} ref={ref}></StickyTable> :
            // @ts-ignore
            <Table {...renderProps} ref={ref}></Table>;
        let showContent = <Fragment>
            { !antd && hasPageTop }
            { tableContent }
            { !antd && hasPageBottom }
        </Fragment>
        return showContent;
    }, [_scrollMode, hasPageTop, hasPageBottom, antd, showData])

    return renderContent;
})
export default renderTable;