/**
 * Refact by liuxiangyang on 2021/9/29.
 */
import React, {Component} from 'react'
import {findDOMNode} from 'react-dom'
import classnames from 'classnames'
import moment from 'moment'
import omit from 'omit.js'
// import PropTypes from 'prop-types'
import {RangePicker as RCRangePicker} from 'rc-picker'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import {addEventListener, cssUtil, Warning} from '../../wui-core/src'
import Icon from '../../wui-icon/src'
import {getDefaultFormat, splitDate, findMatchIndexs} from './_utils/autoFix'
import {getLangInfo} from './_utils/lang'
import {_PREFIX_CLS /* , propTypes as datePickerPropTypes */} from './DatePicker'
import type {FocusEvent} from 'react'
import type {Moment} from 'moment'
import type {RangeValue} from 'rc-picker/lib/interface'
import type {RangePickerProps, RangePickerState} from './iPicker'
import {getHalfYearArr, halfYearFormat} from './_utils/halfYearUtils'
import DatePickerHeaderSelect from './DatePickerHeaderSelect'

const {isShouldUpdate /* 废弃API调用时提示更新 */} = Warning

/* const propTypes = {
    ...datePickerPropTypes,
    antd: PropTypes.bool,
    mode: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    placeholder: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    defaultPickerValue: PropTypes.array,
    format: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    disabled: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    allowEmpty: PropTypes.array,
    showRangeLabel: PropTypes.bool, // 是否在输入框中显示快捷键选中的日期范围的文本
    activePresetKey: PropTypes.string,
    onPresetChange: PropTypes.func
} */

const _handleProps: (keyof RangePickerProps)[] = [
    'className',
    'dropdownClassName',
    'popupClassName',
    'getPopupContainer',
    'transitionName',
    'locale',
    'format',
    'value',
    'defaultPickerValue',
    'disabledDateValid',
    'placeholder',
    'mode',
    'ranges',
    'onFocus',
    'onBlur',
    'onChange',
    'onCalendarChange',
    'onOpenChange',
    'onSelect',
    'onStartInputBlur',
    'onEndInputBlur',
    'onPresetChange',
    'activePresetKey',
    'renderExtraFooter',
    'renderFooter'
]

const defaultProps = {
    antd: false,
    clearIcon: <Icon type='uf-close-c' />,
    suffixIcon: <Icon type='uf-calendar' />,
    // mode: ['date', 'date'],
    allowClear: true,
    // locale: 'zh-cn', //4.x版本
    showToday: false,
    showRangeLabel: false,
    // placeholder: ['开始', '结束'],
    validatorFunc: () => {
        return true
    },
    isHeaderSelect: true
}

/**
 * @desc 删除日期字符串的label
 * @param {string} dateStr 含label日期字符串
 * @returns string
 */
const _deleteLabel = (dateStr: string) => {
    if (typeof dateStr === 'string') {
        return dateStr.replace(/^\S*\((.*)\)$/g, '$1')
    } else {
        return dateStr
    }
}

const cn = typeof window !== 'undefined' ? location.search.indexOf('cn') !== -1 : true
const now = moment()

if (cn) {
    now.locale('zh-cn').utcOffset(8)
} else {
    now.locale('en-gb').utcOffset(0)
}

class RangePicker extends Component<RangePickerProps, RangePickerState> {
    static defaultProps = defaultProps
    constructor(props: RangePickerProps) {
        super(props)
        const langInfo = getLangInfo(props.locale) // 获取语言包信息
        this.state = {
            langInfo,
            value: this.initValue(props),
            format: this.generateRangeFormat(props.format),
            labelFormat: this.generateRangeFormat(props.format),
            ranges: this.initRanges(props.ranges),
            activePresetLabel: this.initActivePresetLabel(props),
            placeholder: this.initPlaceholder(props),
            clearIcon: this.generateClearIcon(),
            suffixIcon: this.generateSuffixIcon(),
            open: props.open,
            size: '', // 输入框紧凑模式
            modeArr: this.initMode(props),
            lastSelectDate: null,
            diffValue: [0, 0],
            maskShow: false,
            rightValue: '',
            leftValue: '',
            inputActiveIndex: 0
        }
    }
    private startInputBlur!: any // 原生监听函数，无对应类型
    private endInputBlur!: any
    private node!: RCRangePicker<Moment>
    leftInputValue?: string | number
    rightInputValue?: string | number

    componentDidMount() {
        this.onInputBlurAdapt()
        const pickerRef = findDOMNode(this.node) as HTMLElement
        const styleWidth = this.props?.style?.width ?? ''
        let width: number
        if (typeof styleWidth === 'number') {
            width = styleWidth
        } else if (/^[\d.]+(px)?$/gi.test(styleWidth)) {
            width = parseFloat(styleWidth)
        } else {
            width = pickerRef.getBoundingClientRect().width
        }
        this.setState({
            /**
             * UE紧凑型日期范围标准:
             *   小于105：无法放下任何日期区间,不再做紧凑处理; 105为YYYYMM范围的最小宽度
             *   小于180：xs;
             *   小于200：sm;
             */
            size: width < 105 ? '' : width < 180 ? 'xs' : width < 200 ? 'sm' : ''
        })
        // <--------- 仅用于自动化测试 start ------->
        const {fieldid, id} = this.props
        if (fieldid !== undefined || id !== undefined) {
            const pickerInput = pickerRef.querySelectorAll(`.${_PREFIX_CLS}-input`)
            if (fieldid !== undefined) {
                pickerInput[0].querySelectorAll('input')[0].setAttribute('fieldid', `${fieldid}_start_input`)
                pickerInput[1].querySelectorAll('input')[0].setAttribute('fieldid', `${fieldid}_end_input`)
            }
            if (id !== undefined) {
                pickerInput[0].querySelectorAll('input')[0].setAttribute('id', `${id}_start_input`)
                pickerInput[1].querySelectorAll('input')[0].setAttribute('id', `${id}_end_input`)
            }
        }
        // <--------- 仅用于自动化测试 end ------->
        // this.defaultPanel()
    }

    componentWillUnmount() {
        this.removeInputBlurAdapt()
    }

    componentWillReceiveProps(nextProps: RangePickerProps) {
        if ('value' in nextProps) {
            this.setState({
                value: this.initValue(nextProps)
            })
        }
        if ('open' in nextProps) {
            this.setState({
                open: nextProps.open
            })
        }
        if ('format' in nextProps) {
            const format = this.generateRangeFormat(nextProps.format)
            this.setState({
                format,
                labelFormat: format
            })
        }
        if ('mode' in nextProps) {
            this.setState({
                modeArr: this.initMode(nextProps)
            })
        }
        if ('placeholder' in nextProps || 'dateInputPlaceholder' in nextProps) {
            this.setState({
                placeholder: this.initPlaceholder(nextProps)
            })
        }
        if ('ranges' in nextProps) {
            this.setState({ranges: this.initRanges(nextProps.ranges)})
        }
        if ('locale' in nextProps) {
            let langInfo = getLangInfo(nextProps.locale) // 获取语言包信息
            this.setState({langInfo})
        }
    }

    initValue = (props: RangePickerProps): RangeValue<Moment> => {
        const {format, defaultValue, value: initialValue, disabledDate, disabledDateValid = true, onChange} = props
        const formats = this.generateRangeFormat(format)
        let valueProp = this.splitRangeStr2Arr(initialValue) || this.splitRangeStr2Arr(defaultValue) || []
        let values: (Moment | null)[] = []
        for (let i = 0; i < 2; i++) {
            let value = valueProp[i] || ''
            if (value) {
                if (typeof value == 'string') {
                    if (moment(value, formats[i]).isValid()) {
                        values.push(moment(value, formats[i]))
                    } else {
                        console.error('value is not in the correct format')
                        const validValue = moment(moment(value), formats[i]) // 如果字符串值和格式生成的moment对象不合法，将字符串的值转换为moment对象，再强制转换一下
                        if (validValue.isValid()) {
                            values.push(validValue)
                        } else {
                            values.push(null)
                        }
                    }
                } else if (value.isValid?.()) {
                    values.push(value)
                } else {
                    console.error('value is not in the correct format')
                    values = []
                }
            } else {
                values.push(null)
            }
        }

        if (!disabledDateValid) {
            let isDisableStart = values[0] && disabledDate?.(values[0])
            let isDisableEnd = values[1] && disabledDate?.(values[1])
            if (isDisableStart || isDisableEnd) {
                if (isDisableStart) {
                    values[0] = null
                }
                if (isDisableEnd) {
                    values[1] = null
                }
                onChange?.(values as RangeValue<Moment>, [
                    values[0] === null ? '' : values[0]?.format(formats[0]),
                    values[1] === null ? '' : values[1]?.format(formats[1])
                ])
                return values as RangeValue<Moment>
            }
        }

        return values as RangeValue<Moment>
    }

    initMode = (props: RangePickerProps): RangePickerState['modeArr'] | undefined => {
        if (props.mode) {
            return Array.isArray(props.mode) ? props.mode : [props.mode, props.mode]
        }
    }

    /**
     * @desc 生成RangePicker的format数组
     * @param {String|Array} customFormat
     * @returns [startFormat, endFormat]
     */
    generateRangeFormat = (customFormat?: RangePickerProps['format']): [string, string] => {
        const {format, picker, showTime} = this.props
        const mergeFormat = customFormat || format || getDefaultFormat(picker || 'date', showTime, this.props)
        // 兼容string、array类型format，并转换为[startFormat, endFormat]数组
        return Array.isArray(mergeFormat)
            ? [mergeFormat[0], mergeFormat?.[1] || mergeFormat[0]]
            : [mergeFormat, mergeFormat]
    }

    generateClearIcon = () => {
        // closeIcon更新为clearIcon，兼容函数式写法
        const {clearIcon, closeIcon, id, fieldid} = this.props
        return (
            <span id={id ? id + '_clear' : undefined} fieldid={fieldid ? fieldid + '_clear' : undefined}>
                {(typeof clearIcon === 'function' && clearIcon()) ||
                    (typeof closeIcon === 'function' && closeIcon()) ||
                    closeIcon ||
                    clearIcon}
            </span>
        )
    }

    generateSuffixIcon = () => {
        // 兼顾 iconClick处理
        const {suffixIcon, renderIcon, iconClick, id, fieldid} = this.props
        return (
            <span
                id={id ? id + '_suffix' : undefined}
                fieldid={fieldid ? fieldid + '_suffix' : undefined}
                onClick={iconClick}
            >
                {(typeof suffixIcon === 'function' && suffixIcon()) ||
                    suffixIcon ||
                    (typeof renderIcon === 'function' && renderIcon()) ||
                    renderIcon}
            </span>
        )
    }

    /**
     * string类型输入转换为数组
     * @param {String | Array} str Tinper老版本value、placeholder等的string类型输入
     * @param {String} seperator 分隔符
     * @returns {Array}
     */
    splitRangeStr2Arr = (str: string | RangePickerProps['value'], seperator?: string) => {
        if (typeof str !== 'string') return str // 只处理string类型
        let result: string[] = []
        const seperators = seperator !== undefined ? [seperator, '~', 'to', '至', '-'] : ['~', 'to', '至', '-']
        for (let i = 0; i < seperators.length; i++) {
            if (str.indexOf(seperators[i]) > -1) {
                seperator = seperators[i]
                result = str.split(seperator)
                break
            }
            if (i === seperators.length) {
                console.error('[param error]: `value` `defaultValue` `placeholder` of RangePicker must has an Array.')
            }
        }
        if (seperator !== undefined && str.indexOf(seperator) > -1) {
            result = str.split(seperator)
        }
        // 日期分隔符与起止分隔符相同情况兼容
        // 如： '2022-01-01 - 2030-05-12'，两者均为 '-'
        if (result.length > 2) {
            result = [result.slice(0, 3).join(seperator), result.slice(3).join(seperator)]
        }
        return result.map(item => item.trim())
    }

    initPlaceholder = (props: RangePickerProps): [string, string] | undefined => {
        let {placeholder, dateInputPlaceholder} = props
        placeholder = placeholder || dateInputPlaceholder
        if (!placeholder) return
        if (typeof placeholder === 'string') {
            return this.splitRangeStr2Arr(placeholder) as [string, string]
        } else if (Array.isArray(placeholder)) {
            return placeholder
        } else {
            console.error('`placeholder` must be an Array.')
        }
    }

    initRanges = (ranges: RangePickerProps['ranges']) => {
        if (Array.isArray(ranges)) {
            let formatRanges: Record<string, any> = {}
            ranges.map(range => {
                formatRanges[range.label] = range.value
            })
            return formatRanges
        } else if (typeof ranges === 'object') {
            return ranges
        } else {
            return {}
        }
    }

    initActivePresetLabel = ({ranges, activePresetKey}: RangePickerProps) => {
        let activePresetLabel = ''
        if (Array.isArray(ranges)) {
            ranges.map(range => {
                if (range.key === activePresetKey || range.label === activePresetKey) {
                    activePresetLabel = range.label
                }
            })
        } else if (typeof ranges === 'object') {
            if (activePresetKey && ranges[activePresetKey]) {
                activePresetLabel = activePresetKey
            }
        }
        return activePresetLabel
    }

    onInputBlurAdapt = () => {
        // eslint-disable-next-line react/no-find-dom-node
        let startInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[0]
            .querySelectorAll('input')[0]
        this.startInputBlur = (e: FocusEvent<Element, Element>) => {
            this.onInputBlur(e, 'start')
        }
        addEventListener(startInput, 'blur', this.startInputBlur)

        // eslint-disable-next-line react/no-find-dom-node
        let endInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[1]
            .querySelectorAll('input')[0]
        this.endInputBlur = (e: FocusEvent<Element, Element>) => {
            this.onInputBlur(e, 'end')
        }
        addEventListener(endInput, 'blur', this.endInputBlur)
    }

    removeInputBlurAdapt = () => {
        // eslint-disable-next-line react/no-find-dom-node
        let startInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[0]
            .querySelectorAll('input')[0]
        startInput.removeEventListener('blur', this.startInputBlur)

        // eslint-disable-next-line react/no-find-dom-node
        let endInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[1]
            .querySelectorAll('input')[0]
        endInput.removeEventListener('blur', this.endInputBlur)
    }

    // focus时，如有label，需移除，便于用户修改
    handleFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
        const {showRangeLabel, onFocus} = this.props
        let {format} = this.state
        if (showRangeLabel) {
            this.setState({labelFormat: format}, () => {
                onFocus?.(e)
            })
        } else {
            onFocus?.(e)
        }
    }

    // blur时，如有showRangeLabel，需添加
    handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
        const {showRangeLabel, onBlur} = this.props
        let {labelFormat, format, activePresetLabel} = this.state
        if (showRangeLabel) {
            const presetFormat: [string, string] =
                showRangeLabel && activePresetLabel
                    ? [activePresetLabel + '(' + format[0] + ')', activePresetLabel + '(' + format[1] + ')']
                    : labelFormat
            this.setState({labelFormat: presetFormat})
        }
        onBlur?.(e)
    }

    onInputBlur = (e: FocusEvent<Element, Element>, type: 'start' | 'end') => {
        const {onStartInputBlur, onEndInputBlur, onChange, picker} = this.props
        let {value, format, langInfo} = this.state
        const startValue = value?.[0]?.format(format[0]) ?? ''
        const endValue = value?.[1]?.format(format[1]) ?? ''

        // QDJCJS-9244，删除到空视为有效输入。解决手动清除结束日期后，起始日期无法选择被清除的结束日期以后日期的问题
        if ((e.target as any).value === '') {
            let _value
            if (type === 'start' && value?.[0] !== null) {
                _value = [null, value?.[1] || null] as RangePickerState['value']
                // 有效输入需触发值变更、onChange回调
                this.setState({
                    value: _value
                })
                if (picker === 'halfYear') {
                    const halfYearArr = getHalfYearArr(langInfo, endValue)
                    onChange?.(_value, `["" , "${endValue}"]`, ['', endValue], [['', ''], halfYearArr])
                } else {
                    onChange?.(_value, `["" , "${endValue}"]`, ['', endValue])
                }
            } else if (type === 'end' && value?.[1] !== null) {
                _value = [value?.[0] || null, null] as RangePickerState['value']
                // 有效输入需触发值变更、onChange回调
                this.setState({
                    value: _value
                })
                if (picker === 'halfYear') {
                    const halfYearArr = getHalfYearArr(langInfo, startValue)
                    onChange?.(_value, `["${startValue}", ""]`, [startValue, ''], [halfYearArr, ['', '']])
                } else {
                    onChange?.(_value, `["${startValue}", ""]`, [startValue, ''])
                }
            }
        }

        type === 'start'
            ? onStartInputBlur?.(e, startValue, [startValue, endValue])
            : onEndInputBlur?.(e, endValue, [startValue, endValue])
    }

    /**
     * 日期范围变化的回调
     * @param {Array[moment]} value 选中的日期范围值
     * @param {Array[String]} formatString 数组字符串
     * @param {[String]} label 快捷按钮文本，非快捷按钮选中日期无此项
     */
    handleChange = (value: RangePickerState['value'], formatString: [string, string], label?: string) => {
        formatString = [_deleteLabel(formatString[0]), _deleteLabel(formatString[1])]
        const {showRangeLabel, onChange, antd, onPanelChange, picker} = this.props
        let {format, langInfo, modeArr} = this.state
        if (antd) {
            // 兼容antd参数
            this.setState({
                value
            })
            onChange?.(value, formatString)
        } else {
            if (!label) {
                this.setState({
                    value,
                    labelFormat: format,
                    activePresetLabel: ''
                })
            } else {
                this.setState(() => {
                    const presetFormat: [string, string] = showRangeLabel
                        ? [label + '(' + format[0] + ')', label + '(' + format[1] + ')']
                        : format
                    return {
                        value,
                        labelFormat: presetFormat
                    }
                })
            }
            /**
             * 1. 保持与4.2.0重构前版本一致，在change事件前触发onPanelChange，以确保start/end均已取到
             * 2. 增加第二个参数mode
             * 3. clear的空值不触发
             */
            if (value) {
                onPanelChange?.(value, modeArr)
                if (picker === 'halfYear') {
                    const halfYearStartArr = getHalfYearArr(langInfo, formatString?.[0])
                    const halfYearEndArr = getHalfYearArr(langInfo, formatString?.[1])
                    onChange?.(value, `["${formatString?.[0]}" , "${formatString?.[1]}"]`, formatString, [
                        halfYearStartArr,
                        halfYearEndArr
                    ])
                } else {
                    // 老版本参数为三个，需保证新旧版本参数一致
                    onChange?.(value, `["${formatString?.[0]}" , "${formatString?.[1]}"]`, formatString)
                }
            } else {
                // 老版本参数为三个，需保证新旧版本参数一致
                onChange?.(value, '', null)
            }
        }
    }

    handleCalendarChange = (
        value: [moment.Moment, moment.Moment] | null,
        formatString: [string, string],
        info: {range: string}
    ) => {
        formatString = [_deleteLabel(formatString[0]), _deleteLabel(formatString[1])]
        // 记录上次用户选择日期，用于快捷按钮点击时的回调参数
        if (value === null) {
            // clear事件
            this.setState({
                lastSelectDate: null
            })
        } else {
            this.setState({
                lastSelectDate: info.range === 'start' ? value?.[0] : value?.[1]
            })
        }

        const {onCalendarChange, onSelect} = this.props
        onCalendarChange?.(value, formatString, info)
        if (info.range === 'start') {
            onSelect?.(value?.[0] as moment.Moment)
        } else if (info.range === 'end') {
            onSelect?.(value?.[1] as moment.Moment)
        }
    }

    handleValue = (val: string) => {
        let valueJsonIcon = findMatchIndexs(val, /[^YyMmDdHhSsWwGgOoAa\d]+/g)
        let newDateArr = splitDate(val, valueJsonIcon?.joinIcon)
        return newDateArr
    }

    onInputFocus = (val: number, val2: string, e: FocusEvent<HTMLInputElement, Element>) => {
        // 左右input触发回调
        this.handleFocus(e)
        const {isHeaderSelect, picker} = this.props
        if (isHeaderSelect) {
            if (val2 && val === 0) {
                // 触发左面板赋值
                if (!picker || picker === 'date' || picker === 'week') {
                    let dateArr = this.handleValue(val2)
                    // if (val2.includes('-')) {
                    //     dateArr = val2.split('-')
                    // } else if (val2.includes('/')) {
                    //     dateArr = val2.split('/')
                    // }
                    this.leftInputValue = dateArr![0] + dateArr![1]
                    this.setState({
                        rightValue: dateArr![0] + '年 ' + parseInt(dateArr![1]) + '月',
                        leftValue: dateArr![0] + '年 ' + (parseInt(dateArr![1]) + 1) + '月'
                    })
                } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
                    this.leftInputValue = parseInt(val2)
                    this.setState({
                        rightValue: parseInt(val2) + 1 + '年',
                        leftValue: val2 + '年'
                    })
                } else if (picker === 'year') {
                    let yearNum = parseInt(val2)
                    let currentYear = yearNum - parseInt(yearNum.toString().slice(3, 4))
                    this.setState({
                        rightValue: currentYear + 10 + '-' + (currentYear + 19),
                        leftValue: currentYear + '-' + (currentYear + 9)
                    })
                }
            }
            if (val2 && val === 1) {
                // 触发右面板赋值
                if (!picker || picker === 'date' || picker === 'week') {
                    let dateArr = this.handleValue(val2)
                    // if (val2.includes('-')) {
                    //     dateArr = val2.split('-')
                    // } else if (val2.includes('/')) {
                    //     dateArr = val2.split('/')
                    // }
                    this.rightInputValue = dateArr![0] + dateArr![1]
                    if (this.rightInputValue !== this.leftInputValue) {
                        this.setState({
                            rightValue: dateArr![0] + '年 ' + (parseInt(dateArr![1]) - 1) + '月',
                            leftValue: dateArr![0] + '年 ' + parseInt(dateArr![1]) + '月'
                        })
                    }
                } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
                    this.rightInputValue = parseInt(val2)
                    if (this.rightInputValue !== this.leftInputValue) {
                        this.setState({
                            rightValue: val2,
                            leftValue: parseInt(val2) - 1 + '年'
                        })
                    }
                } else if (picker === 'year') {
                    let yearNum = parseInt(val2)
                    let currentYear = yearNum - parseInt(yearNum.toString().slice(3, 4))
                    this.setState({
                        rightValue: currentYear + '-' + (currentYear + 9),
                        leftValue: currentYear - 10 + '-' + (currentYear - 19)
                    })
                }
            }
            this.setState({
                inputActiveIndex: val
            })
        }
    }

    // 点击翻页拉下值更新
    handlePanelChange = (
        value: RangePickerState['value'],
        mode: RangePickerState['modeArr'],
        type?: string,
        diff?: number
    ) => {
        const {onPanelChange, antd, picker, isHeaderSelect} = this.props
        this.setState({modeArr: mode})
        antd && onPanelChange?.(value, mode)
        if (!isHeaderSelect) return
        if (!picker || picker === 'date' || picker === 'week') {
            if (type == 'year' && (diff as number) > 0) {
                // 年度右翻页
                let firstValue = ''
                let lastmonth = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    // 左面的input触发
                    firstValue = (value![0] || value![1])?.year() + '年 ' + (value![0] || value![1])?.month() + '月'
                    lastmonth = (value![0] || value![1])!?.month() + 1 + '月'
                    lastValue = (value![0] || value![1])?.year() + '年 ' + lastmonth
                } else {
                    firstValue = (value![1] || value![0])?.year() + '年 ' + (value![1] || value![0])?.month() + '月'
                    lastmonth = (value![1] || value![0])!?.month() + 1 + '月'
                    lastValue = (value![1] || value![0])?.year() + '年 ' + lastmonth
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            } else if (type == 'year' && (diff as number) < 0) {
                // 年度左翻页
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    firstValue =
                        (value![0] || value![1])?.year() + '年 ' + ((value![0] || value![1])!?.month() + 1) + '月'
                    lastValue =
                        (value![0] || value![1])?.year() + '年 ' + ((value![0] || value![1])!?.month() + 2) + '月'
                } else {
                    firstValue =
                        (value![1] || value![0])?.year() + '年 ' + ((value![1] || value![0])!?.month() + 1) + '月'
                    lastValue =
                        (value![1] || value![0])?.year() + '年 ' + ((value![1] || value![0])!?.month() + 2) + '月'
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            } else if (type == 'month' && (diff as number) > 0) {
                // 月度右翻页
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    firstValue = value![0]?.year() + '年 ' + value![0]?.month() + '月'
                    lastValue = value![0]?.year() + '年 ' + (value![0]!?.month() + 1) + '月'
                } else {
                    firstValue = value![1]?.year() + '年 ' + value![1]?.month() + '月'
                    lastValue = value![1]?.year() + '年 ' + ((value![1] || value![0])!?.month() + 1) + '月'
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            } else if (type == 'month' && (diff as number) < 0) {
                // 月度左翻页
                let firstValue = value![0]?.year() + '年 ' + value![0]?.month() + '月'
                let lastValue =
                    (value![1]?.year() || value![0]?.year()) + '年 ' + ((value![0] || value![1])!?.month() + 1) + '月'
                if (this.state.inputActiveIndex === 0) {
                    firstValue = value![0]?.year() + '年 ' + (value![0]!?.month() + 1) + '月'
                    lastValue =
                        (value![0]?.year() || value![1]?.year()) +
                        '年 ' +
                        ((value![0] || value![1])!?.month() + 2) +
                        '月'
                } else {
                    firstValue = value![1]?.year() + '年 ' + (value![1]!?.month() + 1) + '月'
                    lastValue = value![1]?.year() + '年 ' + ((value![1] || value![0])!?.month() + 2) + '月'
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            }
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            if (type == 'year' && (diff as number) > 0) {
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    // 左面的input触发
                    firstValue = (value![0] || value![1])?.year() + '年 '
                    lastValue = (value![0] || value![1])!?.year() - 1 + '年 '
                } else {
                    firstValue = (value![1] || value![0])?.year() + '年 '
                    lastValue = (value![1] || value![0])!?.year() - 1 + '年 '
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            } else if (type == 'year' && (diff as number) < 0) {
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    firstValue = (value![0] || value![1])!?.year() + '年 '
                    lastValue = (value![0] || value![1])!?.year() - 1 + '年 '
                } else {
                    firstValue = (value![1] || value![0])!?.year() + '年 '
                    lastValue = (value![1] || value![0])!?.year() - 1 + '年 '
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            }
        } else if (picker === 'year') {
            if (type == 'year' && (diff as number) > 0) {
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    // 左面的input触发
                    let yearNum = (value![0] || value![1])?.year()
                    let currentYear = (yearNum as number) - parseInt((yearNum as number).toString().slice(3, 4))
                    firstValue = currentYear + '-' + (currentYear + 9)
                    lastValue = currentYear - 10 + '-' + (currentYear - 1)
                } else {
                    let yearNum = (value![1] || value![0])?.year()
                    let currentYear = (yearNum as number) - parseInt((yearNum as number).toString().slice(3, 4))
                    firstValue = currentYear + '-' + (currentYear + 9)
                    lastValue = currentYear - 10 + '-' + (currentYear - 1)
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            } else if (type == 'year' && (diff as number) < 0) {
                let firstValue = ''
                let lastValue = ''
                if (this.state.inputActiveIndex === 0) {
                    // 左面的input触发
                    let yearNum = (value![0] || value![1])?.year()
                    let currentYear = (yearNum as number) - parseInt((yearNum as number).toString().slice(3, 4))
                    firstValue = currentYear + '-' + (currentYear + 9)
                    lastValue = currentYear - 10 + '-' + (currentYear - 1)
                } else {
                    let yearNum = (value![1] || value![0])?.year()
                    let currentYear = (yearNum as number) - parseInt((yearNum as number).toString().slice(3, 4))
                    firstValue = currentYear + '-' + (currentYear + 9)
                    lastValue = currentYear - 10 + '-' + (currentYear - 1)
                }
                this.setState({
                    rightValue: firstValue,
                    leftValue: lastValue
                })
            }
        }
    }

    handleOpenChange = (open: boolean) => {
        const {onOpenChange} = this.props
        this.setState({open})
        onOpenChange?.(open)
    }

    /**
     * @desc 选中快捷日期的回调
     * @param {String} label 快捷选项文本
     * @param {[moment||null, moment||null]} values 新选中的值
     */
    handlePresetChange = (label: string, values: RangeValue<Moment>) => {
        let {onPresetChange, ranges} = this.props
        if (Array.isArray(ranges)) {
            let item = ranges.filter(range => range.label === label)[0]
            this.setState({activePresetLabel: label})
            onPresetChange?.(label, values, item, this.state.lastSelectDate)
        } else if (typeof ranges === 'object') {
            this.setState({
                activePresetLabel: label
            })
            onPresetChange?.(label, values, ranges[label], this.state.lastSelectDate)
        }
        // 快捷键选中日期范围后触发失焦
        let startInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[0]
            .querySelectorAll('input')[0]
        startInput.blur()
        let endInput = (findDOMNode(this.node) as HTMLElement)
            .querySelectorAll(`.${_PREFIX_CLS}-input`)[1]
            .querySelectorAll('input')[0]
        endInput.blur()
    }

    getPopupContainerDom = (dom: HTMLElement) => {
        const {getPopupContainer, getCalendarContainer} = this.props
        if (typeof getPopupContainer === 'function') {
            return getPopupContainer(dom)
        } else if (typeof getCalendarContainer === 'function') {
            return getCalendarContainer(dom)
        } else {
            return cssUtil.parentsUntil(dom)
        }
    }

    getDiffValueHanle = (value: [number, number], val2: string, ismask: boolean) => {
        // 选择下拉之后更新面板
        let {picker} = this.props
        if (!picker || picker === 'date' || picker === 'week') {
            let dateArr = val2.split(/\s+/)
            let newRightValue = dateArr[0] + ' ' + (parseInt(dateArr[1]) + 1) + '月'
            this.setState({
                rightValue: val2,
                leftValue: newRightValue
            })
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            let newRightValue = parseInt(val2) + '年'
            this.setState({
                rightValue: parseInt(val2) + 1 + '年',
                leftValue: newRightValue
            })
        } else if (picker === 'year') {
            let newArr = val2.split('-')
            this.setState({
                rightValue: parseInt(newArr[0]) + 10 + '-' + (parseInt(newArr[1]) + 10),
                leftValue: val2
            })
        }
        this.setState({
            diffValue: value,
            maskShow: ismask
        })
    }
    getDiffValueHandleRight = (value: [number, number], val2: string, ismask: boolean) => {
        let {picker} = this.props
        if (!picker || picker === 'date' || picker === 'week') {
            let dateArr = val2.split(/\s+/)
            let newRightValue = dateArr[0] + ' ' + (parseInt(dateArr[1]) - 1) + '月'
            this.setState({
                leftValue: val2,
                rightValue: newRightValue
            })
        } else if (picker === 'month' || picker === 'quarter' || picker === 'halfYear') {
            let newRightValue = parseInt(val2) + '年'
            this.setState({
                leftValue: parseInt(val2) - 1 + '年',
                rightValue: newRightValue
            })
        } else if (picker === 'year') {
            let newArr = val2.split('-')
            this.setState({
                rightValue: val2,
                leftValue: parseInt(newArr[0]) - 10 + '-' + (parseInt(newArr[1]) - 10)
            })
        }
        this.setState({
            diffValue: value,
            maskShow: ismask
            // rightValue: val2
        })
    }

    onPanelshow = (val: boolean) => {
        this.setState({
            maskShow: !val
        })
    }

    render() {
        const {
            picker,
            className,
            dropdownClassName,
            popupClassName,
            value,
            defaultValue,
            defaultPickerValue,
            superPrevIcon,
            superNextIcon,
            prevIcon,
            nextIcon,
            renderExtraFooter,
            renderFooter,
            dateRender,
            dateCellRender,
            isHeaderSelect
        } = this.props
        const state = this.state
        let {
            size,
            activePresetLabel,
            langInfo,
            labelFormat,
            open,
            ranges,
            placeholder,
            clearIcon,
            suffixIcon,
            leftValue,
            rightValue,
            modeArr
        } = state
        let _defaultPickerValue = (!value && !defaultValue && defaultPickerValue) || undefined
        isShouldUpdate('DatePicker', this.props)

        let leftPanel = null
        let rightPanel = null
        if (isHeaderSelect) {
            leftPanel = (
                <DatePickerHeaderSelect
                    lang={langInfo}
                    onPanelshow={this.onPanelshow}
                    valueLeft={leftValue}
                    picker={picker}
                    getDiffValueHanle={this.getDiffValueHanle}
                    defaultValue={defaultValue}
                    positionFlag={'left'}
                ></DatePickerHeaderSelect>
            )
            rightPanel = (
                <DatePickerHeaderSelect
                    lang={langInfo}
                    onPanelshow={this.onPanelshow}
                    value={rightValue}
                    picker={picker}
                    getDiffValueHanle={this.getDiffValueHandleRight}
                    defaultValue={defaultValue}
                    positionFlag={'right'}
                ></DatePickerHeaderSelect>
            )
        }

        return (
            <RCRangePicker
                {...omit(this.props, _handleProps)}
                /* 内置配置 */
                ref={node => {
                    this.node = node as RCRangePicker<Moment>
                }}
                className={classnames(size ? `${_PREFIX_CLS}-range-${size}` : '', className)}
                dropdownClassName={dropdownClassName || popupClassName}
                generateConfig={momentGenerateConfig}
                prefixCls={_PREFIX_CLS}
                transitionName='slide-up'
                locale={Object.assign({}, langInfo.langMap, {
                    halfYearFormat: (date: Moment) => halfYearFormat(date, langInfo, false)
                })} // 语言包必须提供
                value={state.value ?? undefined}
                format={picker === 'halfYear' ? (date: Moment) => halfYearFormat(date, langInfo) : labelFormat}
                open={open ?? undefined}
                defaultPickerValue={
                    // 无value且无defaultValue时才可使用defaultPickerValue
                    _defaultPickerValue
                }
                mode={modeArr}
                placeholder={placeholder}
                ranges={ranges}
                activePresetLabel={activePresetLabel}
                // onFocus={this.handleFocus} // onFocus整合进onInputFocus
                onBlur={this.handleBlur}
                onPresetChange={this.handlePresetChange}
                onChange={this.handleChange}
                onCalendarChange={this.handleCalendarChange}
                onOpenChange={this.handleOpenChange}
                onPanelChange={this.handlePanelChange}
                getPopupContainer={this.getPopupContainerDom as (node: HTMLElement) => HTMLElement}
                renderExtraFooter={renderExtraFooter || renderFooter}
                dateRender={dateRender || dateCellRender}
                clearIcon={clearIcon}
                suffixIcon={suffixIcon}
                superPrevIcon={superPrevIcon ?? <Icon type='uf-daoshouye' />} // UE 需求
                superNextIcon={superNextIcon ?? <Icon type='uf-youjiantou_shuang' />}
                prevIcon={prevIcon ?? <Icon type='uf-arrow-left' />}
                nextIcon={nextIcon ?? <Icon type='uf-arrow-right' />}
                // @ts-ignore
                diffValue={isHeaderSelect && this.state.diffValue}
                headerSelectLeft={leftPanel}
                headerSelectRight={rightPanel}
                showSelectMask={isHeaderSelect ? this.state.maskShow : false}
                onInputFocus={this.onInputFocus}
            ></RCRangePicker>
        )
    }
}

// RangePicker.propTypes = propTypes
export default RangePicker
