import classNames from 'classnames';
import React from 'react';
// import {elementType} from '../../wui-core/src';
import {prefix, WebUI} from "../../wui-core/src/index"
// import PropTypes from 'prop-types';
import { AnyObject, ModalFooterProps } from './iModal';

// const propTypes = {
//     componentClass: elementType,
//     fieldid: PropTypes.string
// };

const defaultProps = {
    componentClass: 'div',
    clsPrefix: `${prefix}-modal-footer`,
};

@WebUI({name: "modal-footer", defaultProps})
class ModalFooter extends React.Component<ModalFooterProps> {
    static defaultProps = defaultProps;
    render() {
        const {componentClass: Component, clsPrefix, className, children, onCustomRender, ...props} = this.props;
        const footerChildren = typeof onCustomRender === 'function' ? onCustomRender(children) : children;

        const classes: AnyObject = {};
        classes[`${clsPrefix}`] = true;
        return (
            <Component
                {...props}
                className={classNames(className, classes)}
            >
                {footerChildren}
            </Component>
        );
    }
}

// ModalFooter.propTypes = propTypes;

export default ModalFooter as React.ComponentClass<Partial<ModalFooterProps>>;
