export default {
    'lang': 'zh-cn',
    'text': '更多',
    'zh-cn': {
        'text': '更多',
    },
    'en-us': {
        'text': 'More',
    },
    'zh-tw': {
        'text': '更多',
    },
    'vi-vn': {
        'text': 'thêm',
    },
}
