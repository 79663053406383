/**
 * 此处维护各组件旧api升级新api的关系
 */
export default {
    DatePicker: {
        showClear: "allowClear",
        showClose: "allowClear",
        getCalendarContainer: "getPopupContainer",
        closeIcon: "clearIcon",
        dateCellRender: "dateRender",
        monthCellContentRender: "monthCellRender",
        renderIcon: "suffixIcon",
        renderFooter: "renderExtraFooter",
        defaultPanelShown: "defaultOpen",
        placement: "dropdownAlign",
        outInputKeydown: "onKeyDown",
        onDateInputBlur: "onBlur",
        outInputFocus: "onFocus",
        keyboardInput: "inputReadOnly"
    },
    TimePicker: {
        showClear: "allowClear",
        onOpen: "onOpenChange",
        onClose: "onOpenChange",
        addon: "renderExtraFooter"
    },
    Select: {
        onKeyDown: "onInputKeyDown",
        data: "options",
        multiple: "mode",
        tags: "mode"
    },
    Modal: {
        show: "visible",
        tempHide: "destroyOnClose",
        backdrop: "mask",
        onHide: "onCancel",
        onEnter: "onShow",
        onEntering: "onShow",
        onEntered: "onShow",
        onExit: "onHide",
        onExiting: "onHide",
        onExited: "onHide",
        onBackdropClick: "onMaskClick",
        dialogClassName: "wrapClassName",
        backdropStyle: "maskStyle",
        container: "getPopupContainer",
        getContainer: "getPopupContainer",
        backdropClosable: "maskClosable"
    },
    Tag: {
        colors: "color",
        deleted: "closable",
        tagClick: "onClick",
    },
    Dropdown: {
        delayShow: "mouseEnterDelay",
        delayHide: "mouseLeaveDelay",
    },
    Collapse: {
        onSelect: "onChange"
    },
    Tabs: {
        onPrevClick: "onTabScroll",
        onNextClick: "onTabScroll",
        extraContent: "tabBarExtraContent",
        tabBarPosition: "tabPosition"
    },
    Tooltips: {
        onMouseOut: "onMouseLeave",
        rootClose: "maskClosable",
        container: "getPopupContainer",
        show: "visible"
    },
    Spin: {
        show: 'spinning',
        container: 'getPopupContainer'
    },
    Affix: {
        container: 'getPopupContainer'
    },
    Drawer: {
        container: 'getPopupContainer',
        getPopupContainer: 'getPopupContainer'
    },
    Image: {
        container: 'getPopupContainer'
    },
    Notification: {
        container: 'getPopupContainer'
    },
    Table: {
        isShow: 'ifshow',
        haveExpandIcon: 'showExpandIcon'
    },
    AutoComplete: {
        show: 'open',
        onSelectOption: 'onSelect'
    }
}
