import cx from 'classnames';
import React, {Component} from 'react';
import List from '../../wui-list/src/index';
import { CalendarSliderProps, EventObjectInput } from './iCalendar';
import moment, {Moment} from 'moment';

// function noop() {
// }
// 显示整点的偏移高度
const offsetHeight = 8;
class CalendarSider extends Component<CalendarSliderProps> {
	getMonthName = (month: Moment) => {
	    const localeData = month.locale(this.props.lang).localeData()
	    return localeData.monthsShort(month);
	}
	onMonthChange = (month: number) => {
	    const newValue = this.props.value.clone();
	    newValue.month(parseInt(month + "", 10));
	    this.props.onMonthChange(newValue);
	}

	monthSiderElement = (month: number) => {
	    const props = this.props;
	    const t = props.value.clone();
	    const {prefixCls, fieldid} = props;
	    const dataSource = [];
	    // 渲染12个月份
	    for (let index = 0; index < 12; index++) {
	        const className = cx({
	            [`${prefixCls}-active-sider-month`]: index === month,
	        })
	        t.month(index);
	        dataSource.push(
	            <List.Item fieldid={fieldid ? `${fieldid}_${this.getMonthName(t)}` : undefined} key={`${index}`} className={className} onClick={() => this.onMonthChange(index)}>
	                {this.getMonthName(t)}
	            </List.Item>
	        );
	    }

	    return (
	        <List
	            size="small"
	            dataSource={dataSource}
	            renderItem={item => item}
	        />
	    );
	}
	// type='hour'时点击sider对应时间触发的事件
	onTimeEventsClick = (e: React.MouseEvent<HTMLElement>, value: EventObjectInput, time: Moment) => {
	    if (this.props.onTimeEventsClick) {
	        this.props.onTimeEventsClick(e, value, time)
	    }
	}
	// type='hour'时sider渲染内容
	hourSiderElement = () => {
	    const {prefixCls, fieldid, showTimeLine, hourCellRender, hourCellContentRender} = this.props;
	    const value = moment(this.props.value)
	    const dataSource: JSX.Element[] = [];
	    // 渲染24小时
	    [...Array(24).keys()].forEach(item => {
	        let [time, hour] = Array(2).fill('');
	        if (item < 10 && item > 0) {
	            time = "0" + item + ":00";
	            hour = "0" + item;
	        } else if (item > 9) {
	            time = item + ":00"
	            hour = String(item)
	        }
	        const currentTop = Number(moment().format('HH')) * 60 + Number(moment().format('mm')) - offsetHeight;
	        const top = item * 60 - offsetHeight;
	        if (value.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
	            time = currentTop + 15 > top && currentTop - 15 < top && showTimeLine !== false ? '' : time;
	        }
	        const outPutValue = {start: value.format('YYYY-MM-DD') + ' ' + hour + ':' + '00'};
	        const hourRender = () => {
	            if (hourCellRender) {
	                return hourCellRender(item, !!time);
	            } else if (hourCellContentRender) {
	                return <span className={`${prefixCls}-time-item-span`}>{hourCellContentRender(item, !!time)}</span>;
	            } else {
	                return <span className={`${prefixCls}-time-item-span`}>{time}</span>;
	            }
	        }
	        // 每小时分成4份，一份15分钟
	        for (let i = 1; i < 5; i++) {
	            // 只显示整点时间
	            if (i == 1) {
	                dataSource.push(
	                    <List.Item fieldid={fieldid ? `${fieldid}_siderTime_${item}_${i}` : undefined} className={`${prefixCls}-time-item`} onClick={(e) => this.onTimeEventsClick(e, outPutValue, moment(value.format('YYYY-MM-DD') + ' ' + outPutValue.start))}>
	                        {hourRender()}
	                    </List.Item>
	                )
	            } else {
	                const outPutValueT = {start: value.format('YYYY-MM-DD') + ' ' + hour + ':' + String((i - 1) * 15)};
	                dataSource.push(
	                    <List.Item fieldid={fieldid ? `${fieldid}_siderTime_${item}_${i}` : undefined} className={`${prefixCls}-time-item`} onClick={(e) => this.onTimeEventsClick(e, outPutValueT, moment(value.format('YYYY-MM-DD') + ' ' + outPutValueT.start))}>
	                		<span></span>
	            		</List.Item>
	                )
	            }
	        }
	    })

	    return (
	        <List
	            size="small"
	            dataSource={dataSource}
	            renderItem={item => item}
	        />
	    );
	}

	render() {
	    const {value, prefixCls, fieldid, type} = this.props;
	    const month = value.month();
	    const monthSider = type !== 'hour' ? this.monthSiderElement(month) : this.hourSiderElement();

	    return (
	        <div className={`${prefixCls}-sider`} fieldid={fieldid ? `${fieldid}_slider` : undefined}>
	            {monthSider}
	        </div>
	    );
	}
}

// CalendarSider.propTypes = {
//     value: PropTypes.object,
//     lang: PropTypes.string,
//     onMonthChange: PropTypes.func,
//     prefixCls: PropTypes.string,
// };

export default CalendarSider;
