module.exports = {
    lang: 'zh-cn',
    ok: '确定',
    cancel: '取消',
    'zh-cn': {
        ok: '确认',
        cancel: '取消'
    },
    'en-us': {
        ok: 'ok',
        cancel: 'cancel'
    },
    'zh-tw': {
        cancel: '取消',
        ok: '確認'
    },
    'vi-vn': {
        ok: 'Chắc chắn',
        cancel: 'Hủy bỏ'
    }
};
