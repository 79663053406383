/* eslint-disable react/prop-types */
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import FieldForm, {List} from 'rc-field-form';
import * as React from 'react';
import {useMemo} from 'react';
import {ConfigContext} from '../../wui-provider/src/context';
import SizeContext, {SizeContextProvider} from '../../wui-provider/src/SizeContext';
import {FormContext} from './context';
import useForm from './hooks/useForm';
import type {FormInstance, FormProps, ValidateErrorEntity, FormContextProps} from './iForm'

const InternalForm: React.ForwardRefRenderFunction<FormInstance, FormProps> = (props, ref) => {
    const contextSize = React.useContext(SizeContext);
    const {getPrefixCls, direction, form: contextForm} = React.useContext(ConfigContext) as any;

    const {
        prefixCls: customizePrefixCls,
        className = '',
        size = contextSize || 'md',
        form,
        colon,
        labelAlign,
        labelWrap,
        labelCol,
        wrapperCol,
        hideRequiredMark,
        layout = 'horizontal',
        scrollToFirstError,
        requiredMark,
        onFinishFailed,
        name,
        ...restFormProps
    } = props;
    const mergedRequiredMark = useMemo(() => {
        if (requiredMark !== undefined) {
            return requiredMark;
        }

        if (contextForm && contextForm.requiredMark !== undefined) {
            return contextForm.requiredMark;
        }

        if (hideRequiredMark) {
            return false;
        }

        return true;
    }, [hideRequiredMark, requiredMark, contextForm]);

    const prefixCls = getPrefixCls('form', customizePrefixCls);

    const formClassName = classNames(
        prefixCls,
        {
            [`${prefixCls}-${layout}`]: true,
            [`${prefixCls}-hide-required-mark`]: mergedRequiredMark === false,
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-${size}`]: size
        },
        className
    );

    const [wrapForm] = useForm(form);
    const {__INTERNAL__} = wrapForm;
    __INTERNAL__.name = name;

    const formContextValue = useMemo<FormContextProps>(
        () => ({
            name,
            labelAlign,
            labelWrap,
            labelCol,
            wrapperCol,
            vertical: layout === 'vertical',
            colon,
            requiredMark: mergedRequiredMark,
            itemRef: __INTERNAL__.itemRef
        }),
        [name, labelAlign, labelCol, wrapperCol, layout, colon, mergedRequiredMark]
    ) as any;

    React.useImperativeHandle(ref, () => wrapForm);

    const onInternalFinishFailed = (errorInfo: ValidateErrorEntity) => {
        onFinishFailed?.(errorInfo);

        let defaultScrollToFirstError; // Options = { block: 'nearest' };

        if (scrollToFirstError && errorInfo.errorFields.length) {
            if (typeof scrollToFirstError === 'object') {
                defaultScrollToFirstError = scrollToFirstError;
            }
            wrapForm.scrollToField(errorInfo.errorFields[0].name, defaultScrollToFirstError);
        }
    };
    return (
        <SizeContextProvider size={size}>
            <FormContext.Provider value={formContextValue}>
                <FieldForm
                    id={name}
                    {...restFormProps}
                    name={name}
                    onFinishFailed={onInternalFinishFailed}
                    form={wrapForm}
                    className={formClassName}
                />
            </FormContext.Provider>
        </SizeContextProvider>
    );
};

const Form = React.forwardRef(InternalForm);
/* Form.propTypes = {
    prefixCls: PropTypes.string,
    colon: PropTypes.bool,
    name: PropTypes.string,
    layout: PropTypes.oneOf(['horizontal', 'inline', 'vertical']),
    labelAlign: PropTypes.oneOf(['left', 'right']),
    form: PropTypes.any, // FormInstance<Values>
    size: PropTypes.oneOf(['small', 'middle', 'large', undefined]),
    requiredMark: PropTypes.oneOf([PropTypes.bool, 'optional']),
    labelCol: PropTypes.object,
    wrapperCol: PropTypes.object,
    labelWrap: PropTypes.bool,
    hideRequiredMark: PropTypes.any,
    scrollToFirstError: PropTypes.func,
    onFieldsChange: PropTypes.func,
    onValuesChange: PropTypes.func,
    initialValues: PropTypes.object
}; */
export {useForm, List};

export default Form;
