import cx from 'classnames';
import moment, {Moment} from 'moment';
// import PropTypes from 'prop-types';
import FullCalendar from 'rc-calendar/lib/FullCalendar';
import {getTodayTime} from 'rc-calendar/lib/util/';
import React, {Component, ReactElement} from 'react';
import {WebUI, delayEvent, fillSpace as fillSpaceFunc, detectZoom} from "../../wui-core/src/index"
import { OrNull } from '../../wui-core/src/utils/type';
import {getLangInfo} from '../../wui-locale/src/tool';
import Checkbox from './../../wui-checkbox/src/index';
import Select from './../../wui-select/src/index';
import CalendarHourBody from './CalendarHourBody';
import CalendarHeader from './CalendarHeader';
import CalendarSider from './CalendarSider';
import i18n from './i18n/lang';
import type {CalendarProps, CalendarState, CalendarType, EventObject, EventObjectInput} from './iCalendar';
import {splitFirst} from './util/utils'

const isSameDay = (one: Moment, two: Moment) => {
    return one && two && one.isSame(two, 'day');
}

// const propTypes = {
//     onChange: PropTypes.func,
//     value: PropTypes.object,
//     defaultValue: PropTypes.object,
//     defaultType: PropTypes.string,
//     type: PropTypes.string,
//     locale: PropTypes.string,
//     onTypeChange: PropTypes.func,
//     fullscreen: PropTypes.bool,
//     monthCellRender: PropTypes.func,
//     dateCellRender: PropTypes.func,
//     disabledDate: PropTypes.object,
//     monthCellContentRender: PropTypes.func,
//     dateCellContentRender: PropTypes.func,
//     mutiple: PropTypes.bool,
//     dateCellHeaderReader: PropTypes.func,
//     onSelect: PropTypes.func,
//     headerRender: PropTypes.element,
//     fieldid: PropTypes.string,
//     onYearChange: PropTypes.func,
//     getDateCellAttr: PropTypes.func,
//     fillSpace: PropTypes.bool,
//     quickSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
//     onQuickSelect: PropTypes.func,
//     headerComponent: PropTypes.element,
// };

const dateHeight = 81;
const defaultColomns = 54;
const defaultProps = {
    defaultType: 'date',
    quickSelect: false,
    locale: 'en-us'
};

function beforeCurrentDate(current: Moment, today: Moment) {
    if (current.year() < today.year()) {
        return true;
    } else if (current.year() === today.year()) {
        if (current.month() < today.month()) {
            return true;
        } else if (current.month() === today.month()) {
            return current.date() < today.date()
        }
    }
    return false
}

// 预制 禁用的日期
const prefabricatedDisabledDate = new Map([
    // 1. 预制今天之前的日期为禁用日期
    ['beforeCurrentDate', beforeCurrentDate],
    // // 2.当月之前的日期为禁用日期
    // ['beforeCurrentMonth', beforeCurrentMonth],

]);
const _disabledDate = (disabledDate?: string | ((current: Moment, value?: Moment) => boolean)) => {
    return (current: Moment) => {
        if (typeof disabledDate === 'string') {
            return prefabricatedDisabledDate.get(disabledDate)?.(current, getTodayTime(moment())) || false;
        } else if (typeof disabledDate === 'function') {
            return disabledDate(current);
        }
        return false;
    }

}
@WebUI({name: "calendar", defaultProps})
class Calendar extends Component<CalendarProps<null>, CalendarState> {
    static defaultProps = defaultProps;
    private rootRef: OrNull<HTMLDivElement> = null;
    private scrollDiv: OrNull<HTMLDivElement> = null;
    private scrollDom: OrNull<HTMLDivElement> = null;
    // 屏幕缩放时 触发了滚动 事件
    private scalingRatio = 1;
    // 切换年时 防止 切回的开关
    private inChangeYearDir = false;
    // eslint-disable-next-line no-undef
    private tomer?: NodeJS.Timeout;
    // fillSpace ：点击12月时自动滚动后 阻止 重复通过 自动计算 滚动高度 重设value
    private siderToScroll: boolean = false;
    constructor(props: CalendarProps<null>, context: {}) {
        super(props, context);
        const {value, defaultValue, mutiple, scrollIntoValue, defaultScrollIntoValue} = this.props as CalendarProps<Array<string>>;
        let type: string;
        let selectValuesMap = new Map();
        if ('type' in props) {
            type = props.type as string;
        } else {
            type = props.defaultType;
        }
        if (mutiple) {
            let _value: Moment[] = defaultValue || [];
            if ('value' in props) {
                _value = value as Moment[];
            }
            _value?.forEach(v => {
                selectValuesMap.set(v.format('YYYY-MM-DD'), true)
            })
        }
        this.state = {
            selectValuesMap: selectValuesMap,
            value: scrollIntoValue?.clone() || defaultScrollIntoValue?.clone() || moment(),
            fillSpaceStyle: {},
            highLightDate: [],
            type,
            current: moment(),
            // 多选模式下焦点态日期
            focusedDay: '',
        }
    }
    static getDerivedStateFromProps(nextProps: Readonly<CalendarProps<Array<string>>>, state: CalendarState) {

        let newState: Partial<CalendarState> = {};
        const {value, mutiple, scrollIntoValue} = nextProps;

        if ('value' in nextProps && mutiple) {
            const _value = value || nextProps.defaultValue;
            const selectValuesMap = new Map();
            (_value as Moment[])?.forEach((v: Moment) => {
                selectValuesMap.set(v.format('YYYY-MM-DD'), true)
            })
            newState.selectValuesMap = selectValuesMap;
        }
        if ('scrollIntoValue' in nextProps && (nextProps.scrollIntoValue as Moment).format('YYYY-MM-DD') !== state.value.format('YYYY-MM-DD') && mutiple) {
            newState.value = scrollIntoValue!.clone() as Moment;
        }
        return newState
    }
    componentDidUpdate(_prevProps: Readonly<CalendarProps<Array<string>>>, prevState: CalendarState) {
        if (prevState.value && prevState.value.format('YYYY-MM-DD') !== this.state.value.format('YYYY-MM-DD')) {
            this.setScroll();
        }
        if (this.props.fillSpace && this.props.mutiple && prevState.fillSpaceStyle !== this.state.fillSpaceStyle) {
            this.setScroll();
        }
    }

    componentDidMount() {
        const { fillSpace, mutiple } = this.props;
        this.scalingRatio = detectZoom();
        // 保证时间变化时，当前时间线实时更新
        this.timeFunc()
        // 当前时间在body内的位置top
        const top = Number(moment().format('HH')) * 60 + Number(moment().format('mm'));
        // 初始时保持当前时间位于中间位置显示
        if (this.scrollDom) {
            this.scrollDom!.scrollTop = top > 1170 ? 900 : top - 270
        }
        if (fillSpace) {
            fillSpaceFunc(this.rootRef!, undefined, (_element, _parent, _noPaddingMaxW, noPaddingMaxH) => {
                // tip: 需要设置整体Table的宽高（通过变量加入style最妥当），需要设置scroll.y = noPaddingMaxH - 表头高度（考虑多表头）
                this.setState({fillSpaceStyle: {
                    // width: noPaddingMaxW + 'px',
                    height: (noPaddingMaxH || 450) + 'px'
                }})
                if (mutiple) {
                    this.setScroll();
                }
            })
        } else {
            if (mutiple) {
                this.setScroll();
            }
        }
    }
    // 保证时间变化时，当前时间线实时更新
    timeFunc = () => {
        // temp后更新一次
        let temp = (60 - Number(moment().format('ss'))) * 1000;
        this.tomer = setTimeout(() => {
            this.setState({ current: moment() })
            clearTimeout(this.tomer)
            this.timeFunc();
        }, (temp))
    }
    componentWillUnmount() {
        clearTimeout(this.tomer)
    }
	setScroll = (v?: Moment) => {
	    const {value} = this.state;
	    const {clsPrefix} = this.props;
	    const scalingRatio = detectZoom();
	    const value1 = v?.clone?.() || value!.clone();
	    value1.date(1);
	    const year = value1.year();
	    const firstDays = moment(`${year}-01-01`).days();
	    const scrollDates = value1.dayOfYear() + firstDays - 1;
	    const scrollRows = parseInt(scrollDates / 7 + "");
	    if (this.scrollDiv) {
	        const tbody = this.scrollDiv.getElementsByClassName(`${clsPrefix}-tbody`)[0];
	        let scrollTop = scrollRows * dateHeight + Math.floor(10 * scalingRatio);
	        // 显示区域大于整月时 fillSpace 特殊计算
	        if (tbody.scrollHeight && tbody.clientHeight && scrollTop > tbody.scrollHeight - tbody.clientHeight - Math.floor(10 * scalingRatio)) {
	            scrollTop = tbody.scrollHeight - tbody.clientHeight - Math.floor(10 * scalingRatio);
	            this.siderToScroll = true;
	        }
	        tbody.scrollTop = scrollTop;
	    }
	}
	onMutiChange = (value: Moment, flag: boolean) => {
	    const { onChange } = this.props;
	    const {selectValuesMap: values} = this.state;
	    if (flag === undefined || flag === null) return;
	    if (flag) {
	        values.set(value.format('YYYY-MM-DD'), flag);
	    } else {
	        values.delete(value.format('YYYY-MM-DD'));
	    }
	    this.setState({selectValuesMap: values})
	    onChange && onChange(value, flag, [...values.keys()])
	}
    headerChangeYear = async(value: Moment) => {
        const {onSelect, onYearChange} = this.props;
        await delayEvent(onYearChange, ()=> {
            this.setScroll(value);
            onSelect && onSelect(value)
    	    this.setState({value})
        })
    }
	onChange = (value: Moment) => {
	    const {onChange, onSelect} = this.props;
	    if (this.state.type === 'date' && this.props.mutiple) {
	        this.setScroll(value);
	        onSelect && onSelect(value)
	    }
	    this.setState({value})
	    if (!this.props.mutiple) {
	        onChange && onChange(value);
	    }
	}
	typeChange = (type: CalendarType) => {
	    if (type === 'date' && this.props.mutiple) {
	        setTimeout(() => {
	            this.setScroll(this.state.value);
	        }, 200)
	    }
	    this.setState({type})
	    this.props.onTypeChange && this.props.onTypeChange(type)
	}
	getMonthFormDates = (dates: number, year: number): number | undefined => {
	    for (let i = 1; i <= 12; i++) {
	        if (moment(`${year}-${i < 10 ? '0' + i : i}-15`).dayOfYear() > dates) {
	            return i
	        }
	    }
	}
    setYear = (value: Moment, year: number, month: number) => {
        value.year(year);
        value.month(month);
    }
    getScrollRows = () => {
        const {clsPrefix} = this.props;
        const { scrollTop } = this.scrollDiv!.getElementsByClassName(`${clsPrefix}-tbody`)[0];
        return scrollTop % dateHeight ? parseInt(scrollTop / dateHeight + 1 + "") : scrollTop / dateHeight;
    }
    getScrollDates = () => {
        const {value: stateValue} = this.state;
        const value = stateValue.clone();
        const year = value.year();
        const firstDateOfYear = moment(`${year}-01-01`).locale('en-us')
        const firstDays = firstDateOfYear.days();
        return this.getScrollRows() * 7 - firstDays;
    }

	orderScroll = async(e: React.UIEvent<HTMLDivElement>) => {
	    const { onYearChange, scrollIntoValue, mutiple} = this.props;

	    if (!mutiple) return;
	    if (this.siderToScroll) {
	        this.siderToScroll = false;
	        return
	    }
	    const {clientHeight, scrollHeight, scrollTop} = e.currentTarget;
	    const {value: stateValue} = this.state;
	    const value = stateValue.clone();
	    const year = value.year();
	    const scrollDates = this.getScrollDates();
	    const _month = this.getMonthFormDates(scrollDates, year);
	    _month && value.month(_month - 1);
	    //  缩放页面时 更新实际滚动高度
	    const scalingRatio = detectZoom()
	    if (scalingRatio !== this.scalingRatio) {
	        this.scalingRatio = scalingRatio;
	        this.setState({value});
	        return;
	    }

	    if (scrollHeight < clientHeight + scrollTop + 10) {

	        //  向下滚动时 如果为 up 则 不向上翻年
	        if (!this.inChangeYearDir) {
	            this.inChangeYearDir = true;
	            await delayEvent(onYearChange, this.setYear, value, year + 1, 0);
	        }
	    } else if (Math.round(scrollTop) < Math.floor(10 * scalingRatio)) {
	        // 向上 翻年时 不允许重复触发 翻年操作
	        if (!this.inChangeYearDir) {
	            this.inChangeYearDir = true;
	            await delayEvent(onYearChange, this.setYear, value, year - 1, 11);
	        }
	    } else {
	        // 滚动到 非翻页区域 后方可再次 翻页
	        this.inChangeYearDir = false;
	    }
	    if (!stateValue.isSame(value, 'month')) {
	        if (scrollIntoValue) {
	            this.props.onSelect && this.props.onSelect(value)
	        } else {
	            this.setState({value}, () => this.props.onSelect && this.props.onSelect(value));
	        }
	    }
	}
    getRowHightlightDates = (firstDay: Moment) => {
        const { disabledDate } = this.props;
        const changeDatesFormat = []
        for (let i = 0; i < 7; i++) {
            const current = firstDay.clone().add(i, 'day');
            const isCurrentDisabled = _disabledDate(disabledDate)(current);
            if (!isCurrentDisabled) {
                changeDatesFormat.push(current.format('YYYY-MM-DD'));
            }

        }
        return changeDatesFormat
    }
    selectRow = (firstDay: Moment) => {
        const {selectValuesMap} = this.state;
        const {quickSelect, onQuickSelect } = this.props;
        if (!quickSelect) return;
        const changeDatesFormat = this.getRowHightlightDates(firstDay);
        let flag = true;
        // 如果都是被选中 则 再次点击 清除
        if (changeDatesFormat.every(date => selectValuesMap.get(date) === true)) {
            flag = false;
        }
        onQuickSelect && onQuickSelect({changeValues: changeDatesFormat, isChecked: flag, value: selectValuesMap, direction: 'horizontal'})

    }
    setRowHighLight = (firstDay: Moment) => {
        const {quickSelect } = this.props;
        if (!quickSelect) return;
        const changeDatesFormat = this.getRowHightlightDates(firstDay);
        this.setState({
            highLightDate: changeDatesFormat
        })
    }
    public getColumnHightlightDates = (index: number) => {
        const {quickSelect, disabledDate } = this.props;
        this.removeCellHighLight();
        const {value} = this.state;
        const _value = value.clone().locale('en-us');
        // 默认 剩余周数
        const scrollRows = this.getScrollRows();
        const columnSelectNumber = typeof quickSelect === 'number' ? quickSelect : defaultColomns - scrollRows + 1;
        const changeDatesFormat = [];
        const scrollDates = this.getScrollDates();
        for (let i = 0; i < columnSelectNumber; i++) {
            // 找到此星期下的第一个日期
            let current = _value.clone().dayOfYear(scrollDates - (7 - 1 - index)).add(i * 7, 'day');
            const isCurrentDisabled = _disabledDate(disabledDate)(current);
            if (!isCurrentDisabled && current.year() === _value.year())
                changeDatesFormat.push(current.format('YYYY-MM-DD'))
        }
        return changeDatesFormat
    }
    selectColumn = (index: number) => {
        const { quickSelect, onQuickSelect } = this.props;
        if (!quickSelect) return;

        const {selectValuesMap} = this.state;
        const changeDatesFormat = this.getColumnHightlightDates(index);
        let flag = true;
        // 如果都是被选中 则 再次点击 清除
        if (changeDatesFormat.every(date => selectValuesMap.get(date) === true)) {
            flag = false;
        }
        // 业务决定是否 选择
        onQuickSelect && onQuickSelect({changeValues: changeDatesFormat, isChecked: flag, value: selectValuesMap, direction: 'vertical' })

    }
    setColomnHighLight = (index: number) => {
        const {quickSelect } = this.props;
        if (!quickSelect) return;
        const changeDatesFormat = this.getColumnHightlightDates(index);
        this.setState({
            highLightDate: changeDatesFormat
        })
    }
    removeCellHighLight= () => {
        this.setState({
            highLightDate: []
        })
    }
    getDateCellAttr = (current: Moment, value: Moment) => {
        const { getDateCellAttr, clsPrefix } = this.props;
        const customAttrs = getDateCellAttr?.(current, value) || {};
        if (this.state.highLightDate.some(date => date === current.format('YYYY-MM-DD'))) {
            customAttrs.className = `${clsPrefix}-high-light` + (customAttrs.className ? ` ${customAttrs.className}` : '')
        }
        return customAttrs
    }
    renderDateHeaderCell = (day: string, xindex: number, clsPrefix: string) => {
        const { quickSelect, locale, mutiple, renderDateHeaderCell, markWeekend } = this.props;
	    const _locale = getLangInfo(locale, i18n)
        const renderDay = renderDateHeaderCell?.(day, xindex) || day;
        // 是否为日历周末头部添加背景色
        const headerMark = !!(markWeekend && (xindex === 6 || xindex === 0));
        if (mutiple && quickSelect) {
            // 快选模式下 对齐
            if (xindex === 0) {
                return [
                    <th style={{width: '22px'}} key={`${xindex}-0`}></th>,
                    <th
                        key={xindex}
                        role="columnheader"
                        title={_locale.langMap.clickSelectColomn}
                        onClick={()=> this.selectColumn(xindex)}
                        onMouseEnter={()=> this.setColomnHighLight(xindex)}
                        onMouseLeave={this.removeCellHighLight}
                        className={
                            cx({
                                [`${clsPrefix}-column-header`]: true,
                                [`${clsPrefix}-column-select-header`]: quickSelect
                            })}
                    >
                        <span className={`${clsPrefix}-column-header-inner`}>
                            {renderDay}
                        </span>
                        <span className={`${clsPrefix}-column-header-inner-select`}>
                            {_locale.langMap.clickSelectColomn}
                        </span>
                    </th>
                ]
            }
            return [
                <th
                    key={xindex}
                    role="columnheader"
                    title={_locale.langMap.clickSelectColomn}
                    onClick={()=> this.selectColumn(xindex)}
                    onMouseEnter={()=> this.setColomnHighLight(xindex)}
                    onMouseLeave={this.removeCellHighLight}
                    className={
                        cx({
                            [`${clsPrefix}-column-header`]: true,
                            [`${clsPrefix}-column-select-header`]: quickSelect
                        })}
                >
                    <span className={`${clsPrefix}-column-header-inner`}>
                        {renderDay}
                    </span>
                    <span className={`${clsPrefix}-column-header-inner-select`}>
                        {_locale.langMap.clickSelectColomn}
                    </span>
                </th>
            ]
        }
        const columnheader = (
            <th
                key={xindex}
                role="columnheader"
                className={
                    cx({
                        [`${clsPrefix}-column-header`]: true,
                        [`${clsPrefix}-column-header-mark`]: headerMark
                    })}
            >
                <span className={`${clsPrefix}-column-header-inner`}>
                    {renderDay}
                </span>
            </th>
        )
        if (xindex === 0 && mutiple) {
            return [
                <th style={{width: '22px'}} key={`${xindex}-0`}></th>,
                columnheader
            ]
        }
        return columnheader
    }
    renderSelectRow = (_xindex: number, date: Moment) => {
        const { quickSelect, clsPrefix, locale} = this.props;
	    const _locale = getLangInfo(locale, i18n)

        return quickSelect ? (
            <td onClick={()=>this.selectRow(date.clone())}
                onMouseEnter={()=> this.setRowHighLight(date.clone())}
                onMouseLeave={this.removeCellHighLight}
                style={{width: '16px', border: 0}}>
                <div
                    className={
                        cx({
                            [`${clsPrefix}-date`]: true,
                            [`${clsPrefix}-row-select`]: quickSelect
                        })}><span>{_locale.langMap.clickSelect}</span></div>
            </td>
        ) : undefined;
    }
	// value   本组件内 value 取值为数组， 内部子组件取值为 对象
	renderDefaultDate = (current: Moment, value: Moment) => {
	    const {selectValuesMap} = this.state;
	    const selectValues = [...selectValuesMap.keys()].map(v => moment(v));
	    const {dateCellHeaderReader, dateCellContentRender, mutiple, clsPrefix, disabledDate, fieldid} = this.props;
	    const dateClass = `${clsPrefix}-date`;
	    let today;
	    let checked = false;
	    let isFocusedDay = current.format('YYYY-MM-DD') == this.state.focusedDay;
	    if (mutiple) {
	        today = getTodayTime(selectValues[0] || value);
	        checked = selectValues.some(v => isSameDay(v, current));
	    } else {
	        today = getTodayTime(value);
	    }
	    const isToday = isSameDay(current, today);
	    let header;
	    let isEdit = true;
	    const isCurrentDisabled = _disabledDate(disabledDate)(current);
	    const headerChilds =
			[mutiple ? <Checkbox disabled={isCurrentDisabled} key={current?.format('YYYY-MM-DD') + "_checkbox"}
								 onClick={() => !isCurrentDisabled && this.onMutiChange(current, !checked)}
								 checked={checked} fieldid={fieldid ? fieldid + '_input_' + current.format('YYYY_MM_DD') : undefined}/> : null,
			<span key={current.format('YYYY-MM-DD') + "_span"} className={`${clsPrefix}-cell-header-date`}>{isToday ? '今' : current.date()}</span>,
			mutiple && current.date() === 1 ? <span key={current.format('YYYY-MM-DD') + 'firstDay'} className={`${clsPrefix}-cell-header-date ${clsPrefix}-month-first-day`}>{current.format('MMM')}</span> : null
			]
	    if (dateCellHeaderReader) {
	        const dateCellHeaderReaderArr = dateCellHeaderReader(current, selectValues, headerChilds);
	        if (dateCellHeaderReaderArr[0].props.clsPrefix !== 'wui-checkbox') {
	            isEdit = false;
	        }
	        header = <div className={`${clsPrefix}-cell-header`}>{dateCellHeaderReaderArr}</div>
	    }

	    let content: ReactElement | string | null = "";
	    if (dateCellContentRender) {
	        content = dateCellContentRender(current, selectValues);
	    }
	    const body = <div className={`${clsPrefix}-cell-body`}>{content}</div>
	    return (
	        <div onClick={() => {
	            this.setState({ focusedDay: current.format('YYYY-MM-DD') })
	            if (!isEdit || isCurrentDisabled) return;
	            this.onMutiChange(current, !checked)
	        }
	        } className={
	            cx({
	                [dateClass]: true,
	                [`${clsPrefix}-mutiple-selected-day`]: true,
	                [`${clsPrefix}-before-today`]: isCurrentDisabled,
	                [`${clsPrefix}-mutiple-selected-day-edit`]: checked && !isEdit,
	                [`${clsPrefix}-mutiple-focused-day`]: isFocusedDay && !isCurrentDisabled,
	            })}>
	            {header}
	            {body}
	        </div>
	    )
	}
    onTimeEventsClick = (e: React.MouseEvent<HTMLElement>, value: EventObjectInput, time: Moment) => {
        if (this.props.onTimeEventsClick) {
            this.props.onTimeEventsClick(e, value, time)
        }
    }
    // 渲染全天事件
    renderAllDayEvents = () => {
        const {clsPrefix, timeEvents, fieldid, locale} = this.props;
        let allDayArr: EventObject[] = [];
        // 获取所有全天事件存入数组allDayArr
        timeEvents?.forEach(item => {
            splitFirst(moment(item.start).format('YYYY-MM-DD HH:mm'))[0] !== splitFirst(moment(item.end).format('YYYY-MM-DD HH:mm'))[0] ? allDayArr.push(item) : null
        })
        if (allDayArr.length !== 0) {
            return (
                <div className={`${clsPrefix}-allDay-container`} fieldid={fieldid ? `${fieldid}_allDay_container` : undefined}>
                    <div className={`${clsPrefix}-allDay-container-left`}>{getLangInfo(locale, i18n).langMap.allDay}</div>
                    <ul className={`${clsPrefix}-allDay-container-right`}>
                        {allDayArr.map((allItem: EventObject, allIndex: number) => {
                            // 全天事件渲染时显示的文本信息，包括时间和事件
                            let content = moment(allItem.start).format('YYYY-MM-DD HH:mm') + ' - ' + moment(allItem.end).format('YYYY-MM-DD HH:mm') + ' ' + allItem.content;
                            // 点击全天事件时打印出的参数，包括起始时间和事件名称
                            let valueTemp = {start: moment(allItem.start).format('YYYY-MM-DD HH:mm'), end: moment(allItem.end).format('YYYY-MM-DD HH:mm'), content: allItem.content}
                            return (
                                <li key={allIndex} fieldid={fieldid ? `${fieldid}_allDayEvents_${allIndex}` : undefined} onClick={(e) => this.onTimeEventsClick(e, valueTemp, moment(this.state.value.format('YYYY-MM-DD') + ' 00:00:00'))}>{content}</li>
                            )
                        })}
                    </ul>
                </div>
            );
        }

    }

    renderFullBody = () => {
        const {clsPrefix, fieldid, mutiple, dateCellRender, fillSpace, locale, renderDateHeaderCell, timeEvents, showTimeLine, onTimeEventsClick, layout, headerRender, ...others} = this.props;
        const {value: stateValue, fillSpaceStyle} = this.state;
        const _locale = getLangInfo(locale, i18n)
	    stateValue.locale(_locale.lang)
	    const valueYear = stateValue.year();
	    const value1 = moment(`${valueYear}-01-01`);
	    let dataRender = dateCellRender || this.renderDefaultDate;

        if (this.state.type === 'date') {
            return (
	            <div className={`${clsPrefix}-full-body`}>
	                <div ref={(ref) => this.scrollDiv = ref}>
	                    <FullCalendar
	                        prefixCls={clsPrefix}
	                        Select={Select}
	                        fieldid={fieldid}
	                        renderDateHeaderCell={this.renderDateHeaderCell}
	                        renderSelectRow={this.renderSelectRow}
	                        dateCellRender={dataRender}
	                        disabledDate={() => false}
                            headerRender={() => null}
	                        locale={_locale.langMap}
	                        lang={_locale.lang}
	                        getDateCellAttr={this.getDateCellAttr}
                            onTimeEventsClick={onTimeEventsClick}
	                        {...others}
	                        value={value1}
	                        mutiple={mutiple}
	                        scrollAttrs={{onScroll: this.orderScroll, style: fillSpaceStyle, className: fillSpace ? `${clsPrefix}-fill-space-init` : ``}}
	                        onChange={this.onChange}
	                    />
	                </div>
	            </div>
	        )
        } else if (this.state.type === 'hour') {
            return (
                <CalendarHourBody
                    clsPrefix={clsPrefix}
                    fieldid={fieldid}
                    value={this.state.value}
                    timeEvents={timeEvents}
                    showTimeLine={showTimeLine}
                    current={this.state.current}
                    onTimeEventsClick={onTimeEventsClick}
                    layout={layout}
                />
            )
        } else {
            return (
	            <FullCalendar
	                prefixCls={clsPrefix}
	                Select={Select}
	                fieldid={fieldid}
	                headerRender={() => null}
	                locale={_locale.langMap}
	                lang={_locale.lang}
	                {...others}
	                value={stateValue}
	                type="month"
	                onChange={this.onChange}
	            />
	        )
        }
    }
    mutiRender = () => {
        const {clsPrefix, layout, quickSelect, fieldid, showTimeLine, onTimeEventsClick, locale, timeEvents, headerRender, hourCellRender, hourCellContentRender} = this.props;
        const {value: stateValue} = this.state;
        const _locale = getLangInfo(locale, i18n)
	    stateValue.locale(_locale.lang)
        const classPosition = cx({
	        [`${clsPrefix}-root-right`]: layout !== 'left',
	        [`${clsPrefix}-root-left`]: layout === 'left',
	    });
        const classTime = cx({[`${clsPrefix}-root-hour`]: this.state.type === 'hour'});
        return (
            <div className={cx(classPosition, classTime, {[`${clsPrefix}-root`]: true, [`${clsPrefix}-quick-select`]: quickSelect})} fieldid={fieldid} ref={(ref) => this.rootRef = ref}>
	            <CalendarHeader
	                key="calendar-header"
	                {...this.props}
	                prefixCls={`${clsPrefix}-full`}
	                type={this.state.type}
	                locale={_locale.langMap}
	                Select={Select}
	                fieldid={fieldid}
	                value={stateValue}
                    headerComponents={headerRender}
	                onTypeChange={this.typeChange}
	                onValueChange={this.headerChangeYear}
	                showTypeSwitch={false}
	            />
	            <div className={`${clsPrefix}-schedule`}>
                    {timeEvents && this.state.type == 'hour' ? this.renderAllDayEvents() : null}
                    <div className={`${clsPrefix}-scrollWrap`} ref={(ref) => this.scrollDom = ref}>
                        {this.renderFullBody()}
                        <CalendarSider key="calendar-sider"
                            prefixCls={clsPrefix}
                            lang={_locale.lang}
                            value={stateValue}
                            fieldid={fieldid}
	                        layout={layout}
                            onMonthChange={this.onChange}
                            type={this.state.type == 'hour' ? 'hour' : undefined}
                            onTimeEventsClick={onTimeEventsClick}
                            showTimeLine={showTimeLine}
                            hourCellRender={hourCellRender}
                            hourCellContentRender={hourCellContentRender}
	                    />
                    </div>
                </div>
	        </div>
        )
    }

    render() {
	    const {clsPrefix, mutiple, onSelect, locale, fieldid, disabledDate, getDateCellAttr, onTimeEventsClick, headerComponent, markWeekend, style, fillSpace, className} = this.props;
	    const {value: stateValue, fillSpaceStyle} = this.state;
	    const _locale = getLangInfo(locale, i18n)
	    stateValue.locale(_locale.lang)
        let styleRoot = style || {};
        let classNameRoot = className || "";
        if (fillSpace) {
            styleRoot = {height: 0, ...styleRoot, ...fillSpaceStyle};
            classNameRoot = cx(`${clsPrefix}-fill-space`, className)
        }
        
	    return (
	        mutiple ? this.mutiRender() : <FullCalendar
	            prefixCls={clsPrefix}
	            Select={Select}
	            onSelect={onSelect}
	            fieldid={fieldid}
	            getDateCellAttr={getDateCellAttr}
                onTimeEventsClick={onTimeEventsClick}
	            {...this.props}
	            disabledDate={disabledDate ? _disabledDate(disabledDate) : disabledDate}
	            locale={_locale.langMap}
	            lang={_locale.lang}
	            onChange={this.onChange}
	            headerComponent={headerComponent}
	            renderDateHeaderCell={this.renderDateHeaderCell}
                markWeekend={markWeekend}
                ref={(r: any) => {this.rootRef = r?.rootInstance}}
                style={styleRoot}
                className={classNameRoot}
	        />
	    )
    }
}

export default Calendar as React.ComponentClass<Partial<CalendarProps<null>>>;
