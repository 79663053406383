export default {
    'lang': 'zh-cn',
    'resetSettings': '还原设置',
    'include': '包含',
    'exclusive': '不包含',
    'equal': '等于',
    'unequal': '不等于',
    'begin': '以开始',
    'end': '以结尾',
    'greater_than': '大于',
    'great_than_equal_to': '大于等于',
    'less_than': '小于',
    'less_than_equal_to': '小于等于',
    'be_equal_to': '等于',
    'not_equal_to': '不等于',
    "no_data": '暂无数据',
    "bool_true": "是",
    "bool_false": "否",
    "total": "合计",
    'ok': '确定',
    'cancel': '取消',
    'show_row_num': '序号',
    'all': '全部',
    'en-us': {
        'resetSettings': 'Reset',
        'include': 'Include',
        'exclusive': 'Not include',
        'equal': 'Equal to',
        'unequal': 'Not equal to',
        'begin': 'Begin with',
        'end': 'End with',
        'greater_than': 'Greater than',
        'great_than_equal_to': 'Greater than or equal to',
        'less_than': 'Less than',
        'less_than_equal_to': 'Less than or equal to',
        'be_equal_to': 'Equal to',
        'not_equal_to': 'Not equal to',
        "no_data": 'No data',
        "bool_true": "true",
        "bool_false": "false",
        "total": "total",
        'ok': 'OK',
        'cancel': 'Cancel',
        'show_row_num': 'Serial number',
        'all': 'All',
    },
    'zh-tw': {
        'resetSettings': '還原設置',
        'include': '包含',
        'exclusive': '不包含',
        'equal': '等於',
        'unequal': '不等於',
        'begin': '以開始',
        'end': '以結尾',
        'greater_than': '大於',
        'great_than_equal_to': '大於等於',
        'less_than': '小於',
        'less_than_equal to': '小於等於',
        'be_equal_to': '等於',
        'not_equal_to': '不等於',
        "no_data": '暫無數據',
        "bool_true": "是",
        "bool_false": "否",
        "total": "合計",
        'cancel': '取消',
        'ok': '確認',
        'show_row_num': '序號',
        'all': '全部',
    },
    'vi-vn': {
        'resetSettings': 'Thiết lập lại',
        'include': 'Có chứa',
        'exclusive': 'Không bao gồm',
        'equal': 'Có nghĩa là',
        'unequal': 'Không bằng',
        'begin': 'Để bắt đầu',
        'end': 'Để kết thúc',
        'greater_than': 'Lớn hơn',
        'great_than_equal_to': 'Lớn hơn hoặc bằng',
        'less_than': 'Nhỏ hơn',
        'less_than_equal_to': 'Ít hơn hoặc bằng',
        'be_equal_to': 'Có nghĩa là',
        'not_equal_to': 'Không bằng',
        "no_data": 'Không có dữ liệu',
        "bool_true": "là",
        "bool_false": "Nếu không",
        "total": "Tổng",
        'ok': 'Chắc chắn',
        'cancel': 'Hủy bỏ',
        'show_row_num': 'Số seri',
        'all': 'Tất cả',
    },
}
