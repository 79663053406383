import classnames from 'classnames';
// import {get} from "lodash-es"
import omit from 'omit.js';
import PropTypes from 'prop-types';
import React, { ReactNode } from 'react';
import {prefix, WebUI} from "../../wui-core/src/index"
import { RadioGroupProps, RadioGroupState, RadioValue, MockRadio, RadioContext } from './iRadio';
import Radio from './Radio';
import RadioButton from './RadioButton';

const noop = () => {
}
// export const propTypes = {
//     name: PropTypes.string,
//     /**
// 	 * 默认选中的值
// 	 */
//     defaultValue: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number,
//         PropTypes.bool,
//     ]),
//     /**
// 	 * 选中的值
// 	 */
//     selectedValue: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number,
//         PropTypes.bool,
//     ]),
//     /**
// 	 * 选中的值,作用与selectedValue一致，添加value属性是为了配合form表单校验初始化等一起使用
// 	 */
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number,
//         PropTypes.bool,
//     ]),
//     /**
// 	 * 暴露给用户，且与子Radio通信的方法
// 	 */
//     onChange: PropTypes.func,
//     /**
// 	 * radio 大小
// 	 */
//     size: PropTypes.oneOf(['lg', 'sm', 'small', 'large', 'default']),

//     // 是否兼容ant design模式
//     antd: PropTypes.bool,

//     children: PropTypes.node.isRequired,

//     Component: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.func,
//         PropTypes.object
//     ]),
//     disabled: PropTypes.bool,
//     readOnly: PropTypes.bool
// };

const defaultProps = {
    Component: 'div',
    clsPrefix: `${prefix}-radio-group`,
    defaultValue: '',
    antd: false,
    onChange: noop,
    name: '',
    children: <div></div>,
    readOnly: false
};

/**
 * 与子Radio通信
 */
const childContextTypes = {
    radioGroup: PropTypes.object
}

@WebUI({name: "radio-group", defaultProps})
class RadioGroup extends React.Component<RadioGroupProps, RadioGroupState> {
	static defaultProps = {...defaultProps}
	static childContextTypes = childContextTypes;

	constructor(props: RadioGroupProps, context: RadioContext) {
	    super(props, context);
	    const initValue = props.value ?? (props.selectedValue ?? props.defaultValue);
	    this.state = {
	        focusValue: '',
	        selectedValue: initValue
	    }
	}

	getValues = (): RadioValue[] => {
	    let array: RadioValue[] = []
	    let children: any = this.props.children;
	    if (!children || (Array.isArray(children) && children.length === 0)) {
	        return array;
	    }
	    if (!Array.isArray(children)) { // 如果只有一个元素，不是数组，则放到数组中
	        children = [children]
	    } else if (children.some((child: any) => Array.isArray(child))) { // 如果数组中还有数组
	        let res: (React.ReactElement | MockRadio)[] = []
	        children.forEach((child: any) => {
	            if (Array.isArray(child)) {
	                res = res.concat(child)
	            } else {
	                res.push(child)
	            }
	        })
	        children = res
	    }
	    children = children.map((child: any) => {
	        if (child) return child
	        return {
	            props: {
	                value: ''
	            }
	        }
	    })
	    if (children.length > 1) {
	        children.map((item: any) => {
	            array.push(item.props.value)
	        })
	    } else if (children.length === 1) {
	        array.push(children[0].props.value)
	    } else {
	        array.push(children.props.value);
	    }
	    return array;
	}

	componentDidMount() {
	    let array = this.getValues();
	    if (array.indexOf(this.props.selectedValue as RadioValue) == -1) {
	        this.setState({
	            focusValue: array[0]
	        })
	    }
	}

	componentWillReceiveProps(nextProps: RadioGroupProps) {
	    let array = this.getValues();
	    if (array.indexOf(this.props.selectedValue as RadioValue) == -1 || this.props.value && array.indexOf(this.props.value) == -1) {
	        this.setState({
	            focusValue: array[0]
	        })
	    } else {
	        this.setState({
	            focusValue: ''
	        })
	    }
	    if ('selectedValue' in nextProps || 'value' in nextProps) {
	        this.setState({
	            selectedValue: nextProps.selectedValue !== undefined ? nextProps.selectedValue : nextProps.value
	        })
	    }
	}

	handleChange = (value: RadioValue | React.ChangeEvent, event?: React.ChangeEvent) => {
	    let {onChange, antd, selectedValue: preVal, onClick} = this.props;
	    let val = value
	    let currentVal = antd ? (value as React.ChangeEvent<HTMLInputElement>).target.value : value;
	    onClick && onClick(value, event)

	    if (currentVal === preVal) return;
	    if (!('selectedValue' in this.props)) {
	        this.setState({
	            selectedValue: val
	        })
	    }

	    onChange && onChange(value, event);
	}

	/**
	 * 一旦外层change方法触发本身props发生改变，则调用getChildContext更新与子Radio的通信信息（radioGroup）
	 */

	getChildContext() {
	    const {name, size} = this.props;
	    let {selectedValue} = this.state;
	    let onChange = this.handleChange;
	    return {
	        radioGroup: {
	            name, selectedValue, onChange, size, focusValue: this.state.focusValue
	        }
	    }
	}

	render() {
	    const {Component, children, clsPrefix, className, disabled, readOnly, antd, options, optionType, ...others} = this.props;
		let childs: ReactNode = null;
		if (options?.length) {
			childs = options.map((option) => {
				if (typeof option === 'string') {
					return optionType === "button" ? <RadioButton value={option} >{option}</RadioButton> : <Radio value={option}>{option}</Radio>;
				}
				const {value, label, ...otherProps} = option;
				return optionType === "button" ? <RadioButton value={value} {...otherProps} >{label}</RadioButton> : <Radio value={value} {...otherProps}>{label}</Radio>;
			})
		} else {
			childs = React.Children.map(children, child => {
				if (!child) return null
				return React.cloneElement(child as React.ReactElement,
					{
						// disabled: get(child, "props.disabled") || disabled,
						disabled: (child as React.ReactElement)?.props?.disabled || disabled,
						readOnly: readOnly, // RadioGroup下的Radio，都只使用父节点的readOnly属性，子节点不能覆盖父节点的readOnly。单独使用的Radio使用自己的readOnly
						antd,
					}
				)
			})
		}
	    return (
	        <Component className={classnames(clsPrefix, className)} {...omit(others, ['onChange', 'selectedValue', 'onClick'])} focusValue={this.state.focusValue}>
	            {
	                childs
	            }
	        </Component>
	    );
	}
}

// RadioGroup.childContextTypes = childContextTypes;
// RadioGroup.propTypes = propTypes;
export default RadioGroup as React.ComponentClass<Partial<RadioGroupProps>>;
