import classNames from 'classnames';
// import PropTypes from 'prop-types';
import * as React from 'react';
import {ConfigContext} from "../../wui-provider/src/context";
import { GridProps } from './iCard';

// const propTypes = {
//     // prefixCls: PropTypes.string,
//     className: PropTypes.string,
//     hoverable: PropTypes.bool,
//     style: PropTypes.object,
// }

const defaultProps = {
    className: '',
    hoverable: false,
    style: {},
}

const Grid: React.FC<GridProps> = ({className, hoverable, ...props}) => (
    <ConfigContext.Consumer>
        {({getPrefixCls}) => {
            const prefixCls = getPrefixCls('card');
            const classString = classNames(`${prefixCls}-grid`, className, {
                [`${prefixCls}-grid-hoverable`]: hoverable,
            });

            return <div {...props} className={classString}/>;
        }}
    </ConfigContext.Consumer>
);

// Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
