import React from 'react';
import { PaneProps, PaneState } from './iLayout';
import classNames from 'classnames';
import { WebUI } from "../../wui-core/src/index";
// import Prefixer from 'inline-style-prefixer';
// import stylePropType from 'react-style-proptype';

@WebUI({name: "spliter-pane"})
class Pane extends React.Component<PaneProps, PaneState> {
    constructor(props: PaneProps) {
        super(props);
        this.state = { size: props.size };
    }

    render() {
        const {
            children,
            className,
            clsPrefix,
            direction,
        } = this.props;
        const { size } = this.state;
        const classes = classNames(clsPrefix, className);

        const style: React.CSSProperties = {
            flex: 1,
            position: 'relative',
            outline: 'none',
        };

        if (size !== undefined) {
            if (direction === 'vertical') {
                style.width = size;
            } else {
                style.height = size;
                style.display = 'flex';
            }
            style.flex = 'none';
        }

        return (
            <div className={classes}
                style={style}
            >
                {children}
            </div>
        );
    }
}

export default Pane;
