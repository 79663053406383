import classNames from 'classnames';
import events from 'dom-helpers/events';
import omit from "omit.js"
// import PropTypes from 'prop-types';
import Resizable from 're-resizable'
import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import Draggable, { DraggableEventHandler, DraggableData, DraggableEvent } from 'react-draggable';
import ResizeObserver from 'resize-observer-polyfill';
import {prefix, WebUI} from "../../wui-core/src/index"
import { OrNull } from '../../wui-core/src/utils/type';
import { Bounds, ModalDialogProps, ModalDialogState, NumberSize, ResizableDirection } from './iModal';

// function getPos(obj){
//   var pos={left:0,top:0}
//   while(obj){
//       pos.left+=obj.offsetLeft?obj.offsetLeft:0;
//       pos.top+=obj.offsetTop?obj.offsetTop:0;
//       obj=obj.offsetParent;
//   }
//   return pos;
// }

// const propTypes = {
//     wrapClassName: PropTypes.string,
//     dialogClassName: PropTypes.string,
//     maskClassName: PropTypes.string,
//     contentStyle: PropTypes.object,
//     onStart: PropTypes.func,
//     onStop: PropTypes.func,
//     show: PropTypes.bool,
//     resizable: PropTypes.bool,
//     draggable: PropTypes.bool,
//     centered: PropTypes.oneOf([true, false, 'once']),
//     onResizeStart: PropTypes.func,
//     // clearCenteredCls: PropTypes.func,
//     onResize: PropTypes.func,
//     onResizeStop: PropTypes.func,
//     maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     minHeight: PropTypes.number,
//     resizeClassName: PropTypes.string,
//     size: PropTypes.string,
//     style: PropTypes.object,
//     maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     minWidth: PropTypes.number,
//     bounds: PropTypes.string,
//     zIndex: PropTypes.number,
//     destroyOnClose: PropTypes.bool,
//     onMaskClick: PropTypes.func,
//     isMaximize: PropTypes.bool,
//     maximizeDOM: PropTypes.element,
//     backdropStyle: PropTypes.object,
//     showPosition: PropTypes.object,

//     backdrop: PropTypes.bool,
//     renderBackdrop: PropTypes.func
// };

const defaultProps: ModalDialogProps = {
    minHeight: 150,
    minWidth: 200,
    dragCtrlId: 0,
    clsPrefix: `${prefix}-modal`,
    bounds: null,
    contentStyle: {},
    onStart: () => {
    },
    onStop: () => {
    },
    isMaximize: false,
    maximizeDOM: null,
    showPosition: {x: null, y: null}
};

@WebUI({name: "modal", defaultProps})
class ModalDialog extends React.Component<ModalDialogProps, Partial<ModalDialogState>> {
    static defaultProps = defaultProps;
	isdraged = false;
    modalDialog: OrNull<HTMLDivElement> = null;
    resize: OrNull<HTMLDivElement> = null;
    resizable: OrNull<Resizable> = null;
    modelWrap: OrNull<HTMLDivElement> = null;
    resizeObserver: OrNull<ResizeObserver> = null;
    dialogWidth: number = 0;
    oldDialogWidth: number | undefined = undefined;
    position: null | {x: number; y: number} = null;
    resizeY = 0;
    resizeHeight: number = 0;
	state: ModalDialogState = {
	    // draging:false,
	    // draged:false,
	    original: {
	        x: 0,
	        y: 0
	    },
	    isScroll: false,
	    maxWidth: Number.MAX_SAFE_INTEGER,
	    maxHeight: Number.MAX_SAFE_INTEGER,
	    width: null,
	    height: null,
	    bottomEdgeTrim: false,
	    rightEdgeTrim: false,
	};

	componentDidUpdate(prevProps: Readonly<ModalDialogProps>) {
	    const {resizable, draggable, centered, contentStyle: { width }, showPosition} = this.props;
	    if (resizable) {
	        let {maxWidth, maxHeight} = this.getMaxSizesFromProps();
	        if (maxWidth != this.state.maxWidth || maxHeight != this.state.maxHeight) {
	            this.setState({
	                maxWidth,
	                maxHeight
	            })
	        }
	        // 强制窗体居中设置；处理一些特殊情况导致的 窗体变化 如 通过事件增加 内容导致窗体变化
	        if (!draggable && centered === true) {
	            this.setDialogPosition(false);
	        }
	    }
	    // 强制窗体居中设置；处理一些特殊情况导致的 窗体变化 如 通过事件 、异步 增加 或通过contentStyle 设置width 内容导致窗体变化 视为初始化
	    if (!draggable && centered === true || prevProps.contentStyle.width !== width) {
	        this.setDialogPosition(true);
	    }
	    if (prevProps.showPosition.x !== showPosition.x || prevProps.showPosition.y !== showPosition.y) {
	        this.setDialogPosition(true);
	    }
	    // 处理初始化最大化,缩小后位置不准确的问题,没拖拽就居中，拖拽了就不居中
	    if (prevProps.isMaximize && !this.props.isMaximize && !this.isdraged) {
	        this.setDialogPosition(true);
	    }
	    if (prevProps.show && !this.props.show) {
	        this.isdraged = false;
	    }
	}

	componentDidMount() {
	    this.setScrollState();
	    this.setDialogPosition(true);
	    if (this.modalDialog) {
	        this.resizeObserver = new ResizeObserver(() => {
	            this.setScrollState()
	        });
	        this.resizeObserver.observe(this.modalDialog)
	    }
	    events.on(window, 'resize', this.setSomeState); // 浏览器窗口大小变化时重置弹窗显示位置
	}

	componentWillUnmount() {
	    events.off(window, 'resize', this.setSomeState);
	    if (this.resizeObserver) {
	        this.resizeObserver.disconnect();
	    }
	}
	setSomeState = (isInit : boolean) => {
	    const dialogDom: HTMLDivElement = ReactDOM.findDOMNode(this.modalDialog!) as HTMLDivElement;
	    // 判断 框体是否被 因窗口大小变化导致移出屏幕，此时重置modal位置
	    if (typeof this.props.showPosition.x !== 'number' && dialogDom && (dialogDom.scrollWidth > dialogDom.offsetWidth || dialogDom.scrollHeight > dialogDom.offsetHeight) || this.props.centered === true) {

	        this.setDialogPosition(isInit);
	    }
	    this.setScrollState();
	}
	// 存储 当前的scroll 状态 (判断是否穿透条件)
	setScrollState = () => {
	    const dialogDom = ReactDOM.findDOMNode(this.modalDialog)as HTMLDivElement;
	    if (!this.state.isScroll && (dialogDom.scrollHeight > dialogDom.clientHeight || dialogDom.scrollWidth > dialogDom.clientWidth)) {
	        this.setState({
	            isScroll: true
	        })
	    }
	    if (this.state.isScroll && dialogDom.scrollHeight <= dialogDom.clientHeight && dialogDom.scrollWidth <= dialogDom.clientWidth) {
	        this.setState({
	            isScroll: false
	        })
	    }
	}
    hasOffsetTop = (bounds: Bounds | null) => {
        let _bounds = bounds;
        // 兼容 工作台的方法
        _bounds = this.setWorkbenchBounds(_bounds);
	    if (_bounds && typeof _bounds === 'string') {
	        const boundDom = document.querySelector(_bounds);
	        if (boundDom) {
	            return this.calcOffsets(document.querySelector(_bounds), {}).y
	        }
	    }
        return 0
    }

	// 设置弹窗的显示位置
	setDialogPosition = (isInit: boolean) => {
	    let {centered, showPosition: {x, y}, bounds, draggable} = this.props;
	    let dialogDom = ReactDOM.findDOMNode(this.modalDialog as HTMLDivElement) as HTMLDivElement;
	    let contentDom = ReactDOM.findDOMNode(this.resize as HTMLDivElement) as HTMLDivElement;
	    let rectParent = dialogDom.getBoundingClientRect();
	    let rectChild = contentDom.getBoundingClientRect();
	    if (rectParent.width === 0) return // modalDialog宽度为零时，不做居中处理
	    // 初始化固定位置
	    if (typeof x === 'number' || typeof y === 'number') {
	        if (!isInit) return;
	        if (typeof x !== 'number') {
	            // 默认 仅仅输入y时 ；x 默认居中
	            x = parseInt((rectParent.width - rectChild.width) / 2 + "");// 水平距中
	        }
	        if (typeof y !== 'number') {
	            const val = parseInt((rectParent.height - rectChild.height) / 2 + "")
	            y = val < 0 ? 0 : val
	        }
	        this.setState({original: {x, y}})
	        return
	    }
	    if (centered !== true && isInit !== true) return;
	    let showX = parseInt((rectParent.width - rectChild.width) / 2 + "");// 水平距中
	    // 处理 宽度大于屏幕宽度 情况
	    showX = showX < 0 ? 0 : showX;
	    // 如果因为设置了bounds 导致存在Y轴偏移量 则将默认偏移量修正为30
	    const offsetTop = this.hasOffsetTop(bounds);
	    let showY = draggable ? offsetTop ? Math.abs(100 - offsetTop) : 100 : 100;// 默认距离顶部高度

	    if (centered === true || centered === 'once') {
	        const val = parseInt((rectParent.height - rectChild.height) / 2 + "")
	        showY = val < 0 ? 0 : val;// 垂直距中
	    }
	    // 包含初始化时默认显示的位置
	    if (this.state.original.x !== showX || this.state.original.y !== showY) {
	        // 初始化 / 更新位置 时 拖拽边角不隐藏;
	        this.setState({original: {x: showX, y: showY}, bottomEdgeTrim: false, rightEdgeTrim: false})
	    }
	};
	// 处理一种特殊情况 QDJCJS-6829
	// 处理异步打开modal时 因 某个父元素 设置了 display none ， dialog width 丢失 使得 居中失效
	// 此种情况视为异步初始化
	listenDialog = (ref : null | HTMLDivElement) => {
	    if (!ref) return;
	    this.dialogWidth = (ReactDOM.findDOMNode(ref as HTMLDivElement) as Element).getBoundingClientRect().width;
	    // dialog Width 由0 变100%时触发（第一次获取到值时触发）
	    if (this.oldDialogWidth === 0 && this.dialogWidth) {
	        this.setDialogPosition(true);
	    }
	    this.oldDialogWidth = ref && (ReactDOM.findDOMNode(ref as HTMLDivElement) as Element).getBoundingClientRect().width;
	}
	onStart: DraggableEventHandler = (event: DraggableEvent, delta: DraggableData) => {
	    let {onStart} = this.props;
	    // this.setState({
	    //   draging:true
	    // })
	    // const transfromDom = (this.resizable && this.resizable.resizable ) || this.resize
	    // let transform =  transfromDom.style && transfromDom.style.transform;
	    // reg1 = /translate\((\d+)?px, (\d+)?px\)/;
	    // 'translate(420px, 30px)'.match(reg1)
	    // 兼容含有拖拽功能，点击模态框头部，开始突然往左上角抖一下的问题
	    // 如果是resize了，那么就会有一定的偏移，这个transform也有值了
	    // if(!transform || transform == 'translate(0px, 0px)' || transform == 'translate(0px)' || this.resized) {
	    //   // const styleObj = transfromDom.getBoundingClientRect()
	    //   // transfromDom.style.transform = `translate(${Math.floor(styleObj.x)}px, ${Math.floor(styleObj.y)}px)`
	    //   this.resized = false
	    // }
	    typeof onStart == 'function' && onStart(event, delta);
	    if (event) {
	        event.stopPropagation() // 两个可拖拽modal嵌套的时候，事件冒泡会引起两个可拖拽modal一起动
	    }
	    // return draggable;
	}
	// 当ModalDialog留在可视区的宽度 < 50px 时，拖拽不生效
	onStop: DraggableEventHandler = (e: DraggableEvent, delta: DraggableData) => {
	    // 处于全屏/最大化状态下时 禁止拖动
	    if (this.props.isMaximize) {
	        return
	    }
	    // let dialogWidth = this.modalDialog && this.modalDialog.offsetWidth;
	    // let clientWidth = e && e.target && e.target.clientWidth;
	    // if(delta.x > 0 && clientWidth - delta.x < 50){
	    //   return
	    // }
	    // if(delta.x < 0 && dialogWidth + delta.x < 50){
	    //   return
	    // }
	    // if(delta.y < 0 ){
	    //   return
	    // }
	    const newState = {
	        original: {
	            x: Math.floor(delta.x),
	            y: Math.floor(delta.y)
	        }
	    }
	    this.isdraged = true;
	    this.setState(newState)
	    this.props.onStop(e, delta)
	}
	onDrag = (_e: DraggableEvent, delta: DraggableData) => {
	    const { resizable } = this.props;
	    if (resizable) {
	        this.setScrollState();
	        this.handleResizScrollbar(delta);
	    }
	}
    handleResizScrollbar = (delta: Pick<DraggableData, 'x' | 'y'>) => {
        const {rightEdgeTrim, bottomEdgeTrim} = this.state;
        // 处理re-resize 生成的多余dom在边角时产生的 滚动条 ： 在距离右侧、下侧边缘10px以内 去除对应边框外边缘的resize区域 仅保留内部resize 区域（折中方案：保证拖拽过程中无滚动条的闪动）
        if (!bottomEdgeTrim && delta.y + (this.resize as HTMLDivElement).clientHeight + 10 >= (this.modalDialog as HTMLDivElement).clientHeight) {
            this.setState({
                bottomEdgeTrim: true
            })
        }
        if (bottomEdgeTrim && delta.y + (this.resize as HTMLDivElement).clientHeight + 10 < (this.modalDialog as HTMLDivElement).clientHeight) {
            this.setState({
                bottomEdgeTrim: false
            })
        }
        if (!rightEdgeTrim && delta.x + (this.resize as HTMLDivElement).clientWidth + 10 >= (this.modalDialog as HTMLDivElement).clientWidth) {
            this.setState({
                rightEdgeTrim: true
            })
        }
        if (rightEdgeTrim && delta.x + (this.resize as HTMLDivElement).clientWidth + 10 < (this.modalDialog as HTMLDivElement).clientWidth) {
            this.setState({
                rightEdgeTrim: false
            })
        }
    }
	/* 开始resize */
	onResizeStart = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>, dir: ResizableDirection, elementRef: HTMLDivElement) => {
	    let {onResizeStart} = this.props;

	    typeof onResizeStart === "function" && onResizeStart(e, dir, elementRef);
	    // this.props.clearCenteredCls && this.props.clearCenteredCls();
	};

    firstResizing = true;

	/* resizing */
	onResize = (e: MouseEvent | TouchEvent, direction: ResizableDirection, elementRef: HTMLDivElement, delta: NumberSize) => {
	    // 仅仅点击边框 不会触发此事件 会触发onResizeStart和onResizeStop
	    if (this.firstResizing) {
	        let newState: Partial<ModalDialogState> = {};
	        if (direction === 'right' || direction === 'left') {
	            newState.width = elementRef.offsetWidth;
	        } else if (direction === 'top' || direction === 'bottom') {
	            newState.height = elementRef.offsetHeight;
	        } else {
	            newState.width = elementRef.offsetWidth;
	            newState.height = elementRef.offsetHeight;
	        }
	        this.setState(newState)
	        this.firstResizing = false;
	    }
	    let {onResize} = this.props;
	    let {original} = this.state;
	    /* resize 之前的值 */
	    let originX = original.x;
	    let originY = original.y;

	    /* 移动的位移 */
	    let moveW = delta.width;
	    let moveH = delta.height;

	    /* 移动的位移 */
	    let x = null, y = null;

	    /* 处理左上边缘 */
	    if (/topLeft/i.test(direction)) {
	        x = originX - moveW;
	        y = originY - moveH;
	        this.position = {x: Math.max(x, 0), y: Math.max(y, 0)}
	    } else if (/left/i.test(direction)) {
	        x = originX - moveW;
	        y = originY;
	        this.position = {x: Math.max(x, 0), y}

	        /* 处理左边缘 */
	    } else if (/top/i.test(direction)) {
	        x = originX;
	        y = originY - moveH;
	        this.position = {x, y: Math.max(y, 0)}

	    } else {
	        this.position = null;
	    }

	    if (x || y) {
	        const modifiedX = Math.floor(Math.max(x as number, 0));
	        const modifiedY = Math.max(y as number, 0);
	        elementRef.style.transform = `translate(${modifiedX}px, ${modifiedY}px)`;
	        // 由于re-resize 不能限制鼠标向左侧和上侧屏幕之外拖拽，此处在鼠标拖拽到屏幕外后对高度和位置作出改变originY < 0时 位置置零
	        // x 方向 出现概率较小  暂不优化
	        if (typeof y === 'number' && y >= 0) {
	            this.resizeHeight = elementRef.clientHeight;
	            // 设置this.resizeY 的原因是 很大概率 onResize 不能不能捕捉 y === 0 的瞬间，此时y>0且接近0 ，下一个y会小于0，此时 this.resizeHeight 比真是的height少了一个y的距离
	            // 即elementRef.style.height = this.resizeHeight + this.resizeY
	            this.resizeY = y as number;
	        } else if (typeof y === 'number' && y < 0) {
	            elementRef.style.height = this.resizeHeight + this.resizeY + 'px';
	        }
	    }
	    if (delta.height) {
	        this.updateBodyH()
	    }

	    typeof onResize === "function" && onResize(e, direction, elementRef, delta);
	}


	/* resize 结束 */
	// e 的 类型源自 re-resizble(内部有类型错误) 此处补充 layerX/Y ;
	onResizeStop = (e: (MouseEvent | TouchEvent) & {layerX: number; layerY:number}, direction: ResizableDirection, elementRef: HTMLDivElement, delta: NumberSize) => {
	    if (this.props.isMaximize) return;
	    const {onResizeStop, centered} = this.props;
	    // 鼠标点击边框情况 未拉伸
	    if (this.firstResizing) {
	        return
	    }
	    this.firstResizing = true;
	    let newState: Partial<ModalDialogState> = {};
	    if (direction === 'right' || direction === 'left') {
	        newState.width = elementRef.offsetWidth;
	    } else if (direction === 'top' || direction === 'bottom') {
	        newState.height = elementRef.offsetHeight;
	    } else {
	        newState.width = elementRef.offsetWidth;
	        newState.height = elementRef.offsetHeight;
	    }
	    if (this.position) {
	        newState.original = this.position
	    }

	    this.setState(newState)
	    typeof onResizeStop === "function" && onResizeStop(e, direction, elementRef, delta);
	    if (centered === true) {
	        this.setDialogPosition(false)
	    }
	    this.handleResizScrollbar({x: e.layerX, y: e.layerY});
	}

	/**
	 * 更新Modal.Body的高度
	 */
	updateBodyH = () => {
	    let $resizable = ReactDOM.findDOMNode(this.resizable as Resizable) as HTMLDivElement;
	    let $header = $resizable.querySelector(`.${prefix}-modal-header`) as HTMLDivElement;
	    let $body = $resizable.querySelector(`.${prefix}-modal-body`) as HTMLDivElement;
	    let $footer = $resizable.querySelector(`.${prefix}-modal-footer`) as HTMLDivElement;

	    let totalH: number | string = $resizable.style.height;
	    totalH = Number(totalH.replace("px", ""))
	    if ($header) {
	        totalH -= $header.offsetHeight;
	    }
	    if ($footer) {
	        totalH -= $footer.offsetHeight;
	    }
	    if ($body) {
	        $body.style.height = `${totalH}px`;
	    }
	}

	/**
	 * 获取最大宽度和高度
	 */
	getMaxSizesFromProps = () => {
	    let backDropW = this.modelWrap && this.modelWrap.offsetWidth ? this.modelWrap.offsetWidth : Number.MAX_SAFE_INTEGER;
	    let backDropH = this.modelWrap && this.modelWrap.offsetHeight ? this.modelWrap.offsetHeight : Number.MAX_SAFE_INTEGER;
	    // 小屏幕时 会有modal content 超过  背景的情况
	    const contentW = this.resize && this.resize.offsetWidth || 0;
	    const contentH = this.resize && this.resize.offsetHeight || 0;
	    const maxWidth = typeof this.props.maxWidth === "undefined" ? Math.max(backDropW, contentW) : this.props.maxWidth;
	    const maxHeight = typeof this.props.maxHeight === "undefined" ? Math.max(backDropH, contentH) : this.props.maxHeight;
	    return {maxWidth, maxHeight};
	}

	handleWH = (value: string | number) => {
	    let size = value;
	    if (typeof value === "string" && value.endsWith("px")) {
	        size = Number(value.replace("px", ""));
	    } else if (typeof Number(value) === "number" && !Number.isNaN(Number(value))) {
	        size = Number(value)
	    }
	    return size;
	}
	// 窗体全屏显示
	// renderMaximizContent = () => {
	//     const {clsPrefix, children, contentStyle, maximizeDOM} = this.props;
	//     // 全屏时覆盖用户自定义 width height transition
	//     const _width = maximizeDOM?.offsetWidth ? `${maximizeDOM.offsetWidth}px` : '100%'
	//     const _height = maximizeDOM?.offsetHeight ? `${maximizeDOM.offsetHeight}px` : '100%'
	//     const _transitionY = maximizeDOM?.getBoundingClientRect().top || 0
	//     const _transitionX = maximizeDOM?.getBoundingClientRect().left || 0
	//     const _contentStyle = {
	//         ...contentStyle,
	//         width: _width,
	//         height: _height,
	//         transform: `translate(${_transitionX}px, ${_transitionY}px)`
	//     }
	//     return (
	//         <div style={_contentStyle} className={classNames([`${clsPrefix}-content`, `${clsPrefix}-content-maximize`])}
	// 			 role="document" ref={ref => this.resize = ref}>
	//             {children}
	//         </div>
	//     )
	// }
	renderModalContent = () => {
	    let {
	        clsPrefix,
	        children,
	        resizable,
	        contentStyle,
	        minHeight,
	        minWidth,
	        isMaximize,
	        maximizeDOM,
	        resizeClassName
	    } = this.props;
	    let {maxWidth, maxHeight, original, width: _width, height: _height, bottomEdgeTrim, rightEdgeTrim} = this.state;
	    const _minWidth = this.handleWH(minWidth);
	    const _minHeight = this.handleWH(minHeight);
	    let propsStyle: CSSProperties = Object.assign({minHeight: _minHeight, minWidth: _minWidth}, contentStyle);
	    let _contentStyle = {transform: `translate(${original.x}px, ${original.y}px)`};
	    // if (isMaximize) return this.renderMaximizContent()
	    if (isMaximize) {
	        const width1 = maximizeDOM?.offsetWidth ? `${maximizeDOM.offsetWidth}px` : '100%'
	        const _height = maximizeDOM?.offsetHeight ? `${maximizeDOM.offsetHeight}px` : '100%'
	        const _transitionY = this.getMaximizePosition().y
	        const _transitionX = this.getMaximizePosition().x
	        propsStyle = {
	            ...contentStyle,
	            width: width1,
	            height: _height,
	            // transform: `translate(${_transitionX}px, ${_transitionY}px)`,
	        }
	        _contentStyle = {transform: `translate(${_transitionX}px, ${_transitionY}px)`};

	    }
	    if (!resizable) {
	        return (
	            <div style={{..._contentStyle, ...propsStyle}} className={classNames([`${clsPrefix}-content`])}
					 role="document" ref={ref => this.resize = ref}>
	                {children}
	            </div>
	        )
	    }
	    const {width: initWidth, height: initHeight} = contentStyle;

	    let maxH = this.handleWH(maxHeight);
	    let maxW = this.handleWH(maxWidth);
	    propsStyle = {...propsStyle, maxWidth: `${maxW}px`, maxHeight: `${maxH}px`}
	    // 特殊处理 优先 从state取值 经过变化的宽高
	    _width ??= initWidth;
	    _height ??= initHeight;
	    let enable: {
			top?: boolean;
			right?: boolean;
			bottom?: boolean;
			left?: boolean;
			topRight?: boolean;
			bottomRight?: boolean;
			bottomLeft?: boolean;
			topLeft?: boolean;
		} | undefined = {
		    top: true,
		    right: true,
		    bottom: true,
		    left: true,
		    topRight: true,
		    bottomRight: true,
		    bottomLeft: true,
		    topLeft: true,
		};
	    if (isMaximize) {
	        _width = '100%';
	    	_height = '100%';
	        enable = {
	            top: false,
	            right: false,
	            bottom: false,
	            left: false,
	            topRight: false,
	            bottomRight: false,
	            bottomLeft: false,
	            topLeft: false,
	        }
	        maxH = '100%';
	    	maxW = '100%';
	        propsStyle = {...propsStyle, maxWidth: '100%', maxHeight: '100%'}

	    }
	    // 没有其他宽高属性时，使用默认 css 宽高
	    const contentWidth = _width ? '100%' : undefined;
	    const contentHeight = _height ? '100%' : undefined;
	    return (
	        <Resizable
	            className={classNames(`${clsPrefix}-resizbox`, resizeClassName)}
	            ref={c => {
	                this.resizable = c;
	            }}
	            style={{position: 'absolute', ..._contentStyle}}
	            onResizeStart={this.onResizeStart}
	            onResize={this.onResize}
	            onResizeStop={this.onResizeStop}
	            bounds={"window"}
	            minWidth={_minWidth}
	            minHeight={_minHeight}
	            maxWidth={maxW}
	            enable={enable}
	            maxHeight={maxH}
	            size={{width: _width, height: _height}}
	            handleWrapperClass={classNames({
	                [`${clsPrefix}-resize-bottom-trim`]: bottomEdgeTrim,
	                [`${clsPrefix}-resize-right-trim`]: rightEdgeTrim,
	            })}
	        >
	            <div style={{
	                ...propsStyle,
	                width: contentWidth,
	                height: contentHeight,
	                position: "relative",
	            }} className={classNames([`${clsPrefix}-content`])} role="document" ref={ref => this.resize = ref}>
	                {children}
	            </div>
	        </Resizable>
	    )
	}
	// 渲染遮罩
	_renderBackdrop = () => {
	    const {clsPrefix, backdrop, renderBackdrop, maskClassName, onMaskClick, backdropStyle} = this.props;
	    const _maskClick = () => {
	        onMaskClick && onMaskClick()
	        // const modal = this.context && this.context.$modal;
	        // if (!modal) {
	        //   return
	        // }
	        // // if () {

	        // // }
	        // modal.onCancel();
	    }
	    if (backdrop) {
	        if (typeof renderBackdrop == 'function') {
	            return renderBackdrop();
	        } else {
	            return <div className={classNames(`${clsPrefix}-mask`, maskClassName)} style={backdropStyle}
	                onClick={_maskClick}></div>;
	        }
	    } else {
	        return null;
	    }
	}

    calcOffsets = (dom: Element | null, {x, y}: {x?: number ; y?: number}): {x: number; y:number} => {
        x = x || 0;
        y = y || 0;
        if (['BODY', null].includes((dom as HTMLElement)?.offsetParent?.nodeName || null)) {
            x += (dom as HTMLElement).offsetLeft;
            y += (dom as HTMLElement).offsetTop;
            return {x, y};
        } else {
            x += (dom as HTMLElement).offsetLeft;
            y += (dom as HTMLElement).offsetTop;
            return this.calcOffsets((dom as HTMLElement).offsetParent, {x, y})
        }
    }

    setWorkbenchBounds = (_bounds : Bounds | null) => {
        if (!_bounds && document.querySelector('.diwork-content-fixed')) {
            _bounds = '.diwork-content-fixed';
            if (document.querySelector('.workbench-side-menu-wrap') && document.querySelector('.workbench-menu-and-content')) {
                _bounds = '.workbench-menu-and-content';
            }
        }
        return _bounds
    }
	getMaximizePosition = () => {
	    const {maximizeDOM } = this.props;
	    const _transitionY = maximizeDOM?.getBoundingClientRect().top || 0;
	    const _transitionX = maximizeDOM?.getBoundingClientRect().left || 0;
	    return {x: _transitionX, y: _transitionY};
	}

	render() {
	    const {
	        dialogClassName,
	        wrapClassName,
	        clsPrefix,
	        size,
	        style,
	        // onMaskClick,
	        // contentStyle,
	        // children,
	        draggable,
	        // resizable,
	        // resizeClassName,
	        // minHeight,
	        // minWidth,
	        bounds,
	        // clearCenteredCls,
	        zIndex,
	        show,
	        centered,
	        destroyOnClose,
	        // backdrop,
	        isMaximize,
	        dragCtrlId,
	        // maximizeDOM,
	        ...props
	    } = this.props;
	    let {
	        original,
	        isScroll
	        // maxWidth,
	        // maxHeight,
	        // draging,
	        // draged
	    } = this.state;

	    const wrapClassNames = classNames({
	        [`${clsPrefix}`]: true,
	        [`${clsPrefix}-open`]: show,
	        [`${clsPrefix}-centered`]: centered,
	    }, wrapClassName);


	    // const modalStyle = {display:'block',visibility : show?'visible':'hidden'};
	    const modalStyle: React.CSSProperties = {display: show ? 'block' : 'none'};
	    if (destroyOnClose) {
	        modalStyle.display = show ? 'block' : 'none';
	    } else {// 关闭时不销毁仅隐藏元素
	        modalStyle.visibility = show ? 'visible' : 'hidden';// 使用visibility以确保元素可计算
	    }
	    if (zIndex) {
	        modalStyle.zIndex = zIndex
	    }

	    const dialogClasses = classNames({
	        [`${clsPrefix}-dialog`]: true,
	        [`${clsPrefix}-${size}`]: size,
	        [`${clsPrefix}-maximize`]: isMaximize,
	        [`${clsPrefix}-draggable`]: draggable
	    }, dialogClassName);

	    const dialogStyle: React.CSSProperties = {...style, pointerEvents: isScroll ? 'all' : 'none'}
	    // if(draging)dialogClasses[`${clsPrefix}-draging`]=true;

	    // if(draged)dialogClasses[`${clsPrefix}-draged`]=true;
	    let positionOffset;
	    let _bounds = bounds;

	    // 兼容工作台 内modal 拖动范围
	    _bounds = this.setWorkbenchBounds(_bounds);

	    if (_bounds && typeof _bounds === 'string') {
	        const boundDom = document.querySelector(_bounds);
	        if (boundDom) {
	            positionOffset = this.calcOffsets(document.querySelector(_bounds), {})
	        }
	    }
	    return (
	        <div
	            {...omit(props, ["onStart", "onStop", "onMaskClick", "renderBackdrop", "maskClassName", "onResizeStart", "onResize", "onResizeStop", "backdropStyle"])}
	            tabIndex={-1}
	            role="dialog"
	            style={modalStyle}
	            ref={ref => this.modelWrap = ref}
	            className={wrapClassNames}
	        >
	            {this._renderBackdrop()}
	            <div className={dialogClasses} style={dialogStyle} ref={ref => {
	                this.modalDialog = ref;
	                // 处理一种特殊情况 QDJCJS-6829
	                this.listenDialog(ref);
	            }}>
	                {
	                    // isMaximize === true ? this.renderMaximizContent() :
	                        draggable ? (
	                            <Draggable
	                                handle={`.${clsPrefix}-header-handle-${dragCtrlId}`}
	                                cancel=".dnd-cancel"
	                            	// 默认值设为body，存在 多个 modal 第一个modal 为mask = false 情况
	                            	bounds={ _bounds || 'body'} // 防止拖拽时，Header 被导航栏覆盖
	                            	positionOffset={positionOffset}
	                            	disabled={isMaximize}
	                                onStart={this.onStart}
	                                onStop={this.onStop}
	                                onDrag={this.onDrag}
	                                position={isMaximize ? this.getMaximizePosition() : original}
	                            >
	                                {this.renderModalContent()}
	                            </Draggable>
	                        ) : this.renderModalContent()
	                }
	            </div>
	        </div>
	    );
	}
}
// ModalDialog.propTypes = propTypes;

export default ModalDialog as React.ComponentClass<Partial<ModalDialogProps>>;
