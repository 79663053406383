import omit from 'omit.js';
// import PropTypes from 'prop-types';
// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import {prefix} from "../../wui-core/src/index"
import defaultRenderEmpty from './renderEmpty';
import type {IReactComponent, ConsumerConfig, ConfigConsumerProps, ConstructorProps} from './iProvider'
// import { RequiredMark } from '../form/Form';

// export interface ConfigConsumerProps {
//   getTargetContainer?: () => HTMLElement;
//   getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
//   rootPrefixCls?: string;
//   iconPrefixCls?: string;
//   getPrefixCls: (suffixCls?: string, customizePrefixCls?: string) => string;
//   renderEmpty: RenderEmptyHandler;
//   csp?: CSPConfig;
//   autoInsertSpaceInButton?: boolean;
//   input?: {
//     autoComplete?: string;
//   };
//   locale?: Locale;
//   pageHeader?: {
//     ghost: boolean;
//   };
//   direction?: DirectionType;
//   space?: {
//     size?: SizeType | number;
//   };
//   virtual?: boolean;
//   dropdownMatchSelectWidth?: boolean;
//   form?: {
//     requiredMark?: RequiredMark;
//   };
// }

const defaultGetPrefixCls = (suffixCls?: string, customizePrefixCls?: string) => {
    if (customizePrefixCls) return customizePrefixCls;
    return suffixCls ? `${prefix}-${suffixCls}` : `${prefix}`;
};

export const ConfigContext = React.createContext<ConfigConsumerProps>({
    // We provide a default function for Context without provider
    getPrefixCls: defaultGetPrefixCls,
    renderEmpty: defaultRenderEmpty,
    locale: undefined,
    size: undefined,
    antd: undefined
});

export const ConfigConsumer = ConfigContext.Consumer;

export function WithConfigConsumer(config: ConsumerConfig = {}) {
    return function WithConfigConsumerFunc(Component: IReactComponent): any {
        // Wrap with ConfigConsumer. Since we need compatible with react 15, be care when using ref methods
        /* const SFC = ((props) => (
		  <ConfigConsumer>
			{(configProps) => {
			  return <Component {...configProps} {...props} />;
			}}
		  </ConfigConsumer>
		));*/

        const SFC = React.forwardRef((props, ref) => (
            <ConfigConsumer>
                {(configProps: ConfigConsumerProps) => {
                    const providerProps = config.name ? configProps?.provider?.[config.name] : {};
                    return <Component {...omit(config, ["name"])} {...omit(configProps, ["getPrefixCls", "renderEmpty", "provider"])} {...providerProps} {...props}
									  ref={ref}/>;
                }}
            </ConfigConsumer>
        ))

        const cons: ConstructorProps = Component.constructor as ConstructorProps;
        const name = (cons && cons.displayName) || Component.name || 'Component';
        const clsPrefix = Component.defaultProps ? Component.defaultProps.clsPrefix : undefined;
        SFC.displayName = `WithConfigConsumer(${name})`;
        // SFC.propTypes = {
        //     clsPrefix: PropTypes.string
        // }
        SFC.defaultProps = {clsPrefix} as any
        return SFC;
    };
}
