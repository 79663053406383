// import {get, isEqual} from 'lodash-es';
// import PropTypes from 'prop-types';
import React from 'react';
import {WebUI} from "../../wui-core/src/index";
import Checkbox from './Checkbox';
import shallowequal from 'shallowequal';
import { CheckboxGroupState, CheckboxGroupProps, CheckboxValueType, CheckboxOtherGroups } from './iCheckbox.js';

// const propTypes = {
//     clsPrefix: PropTypes.string,
//     className: PropTypes.string,
//     value: PropTypes.array,
//     onChange: PropTypes.func,
//     disabled: PropTypes.bool,
//     readOnly: PropTypes.bool,
//     options: PropTypes.array,
//     defaultValue: PropTypes.array,
//     name: PropTypes.string
// };

const defaultProps = {
    className: '',
    value: [],
    defaultValue: [],
    onChange: () => {
    },
    disabled: false,
    options: [],
    name: '',
    readOnly: false
};

@WebUI({name: "checkbox-group", defaultProps})
class CheckboxGroup extends React.Component<CheckboxGroupProps, CheckboxGroupState> {
    constructor(props: CheckboxGroupProps) {
        super(props);
        this.state = {
            values: props.value?.length ? props.value
                : props.defaultValue?.length ? props.defaultValue : [],
        }
    }

    componentWillReceiveProps(nextProps: CheckboxGroupProps) {
        // if (nextProps.value && !isEqual(nextProps.value, this.state.values)) {
        if (nextProps.value && !shallowequal(nextProps.value, this.state.values)) {
            this.setState({
                values: nextProps.value
            })
        }
    }

	changeHandle = (v: CheckboxValueType) => {
	    let values = this.state.values as CheckboxValueType[];
	    if (values.indexOf(v) != -1) {
	        values.splice(values.indexOf(v), 1)
	    } else {
	        values.push(v)
	    }
	    this.setState({
	        values
	    })
	    const {onChange} = this.props;
	    if (onChange) {
	        const options = this.getOptions();
	        onChange(
	            values
	                .filter(val => values.indexOf(val) !== -1)
	                .sort((a, b) => {
	                    const indexA = options.findIndex(opt => opt.value === a);
	                    const indexB = options.findIndex(opt => opt.value === b);
	                    return indexA - indexB;
	                }),
	        );
	    }
	}

	getOptions() {
	    const {options} = this.props;
	    return (options).map(option => {
	        if (typeof option === 'string') {
	            return {
	                label: option,
	                value: option,
	            }
	        }
	        return option;
	    });
	}

	modifyChildren(data: React.ReactElement[]) {
	    let {disabled, readOnly} = this.props;
	    if (data && !Array.isArray(data)) {
	        data = [data]
	    }
	    for (let i = 0; i < data.length; i++) {
	        // let children = get(data[i], ['props', 'children'])
	        let children = data[i].props?.children // 可能是数组，可能是单个对象，可能是字符串
	        // if (get(data[i], ['props', 'clsPrefix']) === (Checkbox.defaultProps as any).clsPrefix) {
	        if (data[i].props?.clsPrefix === (Checkbox.defaultProps as any).clsPrefix) { // 如果是checkbox // TODO: ts define type
	            data[i] = React.cloneElement(data[i],
	                {
	                    onChange: () => {
	                        this.changeHandle(data[i].props.value)
	                    },
	                    checked: (this.state.values as CheckboxValueType[]).includes(data[i].props.value),
	                    disabled: data[i].props.disabled || disabled,
	                    readOnly: readOnly // 只用CheckboxGroup的readOnly
	                }
	            )
	        } else if (Array.isArray(children) || typeof children === 'object') { // 如果是子组件的集合或者单个的子组件
	            data[i] = {
	                ...data[i],
	                props: {
	                    ...data[i].props,
	                    children: this.modifyChildren(children)
	                }
	            }
	        }
	    }
	    return data
	}

	render() {
	    let state = this.state;
	    let props = this.props;
	    let {clsPrefix, className, children, options, name, readOnly, fieldid} = props;
	    const otherProps: CheckboxOtherGroups = {}
	    if (name) {
	        otherProps.name = name
	    }
	    let classes = clsPrefix;
	    // console.log('state.values: ',state.values)
	    if (className) classes += ' ' + className;
	    if (options && options.length > 0) {
	        children = this.getOptions().map((option, index) => (
	            <Checkbox
	                key={(option.value as CheckboxValueType).toString()}
	                disabled={'disabled' in option ? option.disabled : props.disabled}
	                readOnly={readOnly}
	                value={option.value}
	                checked={(state.values as CheckboxValueType[]).indexOf((option.value || '')) !== -1}
	                onChange={option.onChange}
	                fieldid={fieldid ? `${fieldid}-${option.label}-${index}` : undefined}
	                {...otherProps}
	            >
	                {option.label}
	            </Checkbox>
	        ));
	    }
	    const originalChildren: React.ReactElement[] = React.Children.toArray(children) as React.ReactElement[]
	    return (
	        <div className={classes} {...otherProps}>
	            {this.modifyChildren(originalChildren)}
	        </div>
	    );
	}
}

// CheckboxGroup.propTypes = propTypes;

export default CheckboxGroup as React.ComponentClass<Partial<CheckboxGroupProps>>;
