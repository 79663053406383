import React from 'react';
// import {WebUI} from "../../wui-core/src/index";
import CreateView from './CreateView';
import GlobalParser from "./GlobalParser.js";
import { DynamicViewProps } from './iDynamicView'

const defaultProps = {
    // appCode: null,
    // pageCode: null,
    // billTypeCode: null, // 单据类型标识
    // jsPluginUrl: null, // 二开扩展的js代码文件路径
    // baseHost: null, // 缺省的域名地址,如果jsPluginUrl使用的相对地址则默认使用此域名地址
    uiMeta: null, // UI元数据 1
    uiParser: null, // UI组件解析器{"ComponentName":Component,...} 1
    uiEvent: null, // UI事件处理器{"uikey":{"eventName":Function,...},...} 1
    // uiParams: null, // UI参数解析器{"ParamName":Function,...}
    // uiRunMode: "runtime", // 运行模式，默认为运行态-"runtime"，设计态-"design"
    // onCreateParser: null, // 自定义动态注册视图解析器
    // onViewAuthValidate: null, // 视图权限校验的回调函数
    // globalParser: null, // 全局缺省视图解析器object|function ***
    // globalEvent: null, // 全局缺省事件解析器object|function
    // globalParams: null, // 全局缺省参数解析器object|function
    // globalActions: null, // 全局缺省动作解析器object|function
    // onWillReceiveProps: null, // 可扩展生命周期逻辑-WillReceiveProps
    // onWillMount: null, // 可扩展生命周期逻辑-WillMount
    // onWillUpdate: null, // 可扩展生命周期逻辑-WillUpdate
    // onDidMount: null, // 可扩展生命周期逻辑-DidMount
    // onDidUpdate: null, // 可扩展生命周期逻辑-DidUpdate
};
class DynamicView extends React.Component<DynamicViewProps> {
    static defaultProps = {...defaultProps};

    render() {
        let { uiMeta, uiParser, uiEvent } = this.props
        return (
            <CreateView
                uiMeta={uiMeta}
                globalParser={GlobalParser}
                uiParser={uiParser}
                // uiParams={uiParams}
                uiEvent={uiEvent}
                // onCreateParser={onCreateParser}
                // onDidMount={onDidMount}
            />
        )
    }
}

export default DynamicView