import React from 'react';
import Calendar from './Calendar';
import { CalendarProps } from './iCalendar';
import SwitchYearHeader from './SwitchYearHeader';
import {WithConfigConsumer} from "../../wui-provider/src/context";

@WithConfigConsumer()
class CalendarWrapper extends React.Component<Partial<CalendarProps<null>>> {
    static SwitchYearHeader = SwitchYearHeader;
    render() {
        return <Calendar {...this.props}></Calendar>
    }
}
CalendarWrapper.SwitchYearHeader = SwitchYearHeader;

export default CalendarWrapper;
