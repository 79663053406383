import classnames from 'classnames'
// import PropTypes from 'prop-types'
import React, {Component} from 'react'
import omit from 'omit.js'
import {KeyCode, WebUI} from '../../wui-core/src/index'
import InputGroup from '../../wui-input-group/src'
import Input from '../../wui-input/src'
import {getLangInfo} from '../../wui-locale/src/tool'
import Message from '../../wui-message/src'
import {WithConfigConsumer} from '../../wui-provider/src/context'
import i18n from './i18n.js'
import type {
    InputNumberProps,
    InputNumberState,
    InputNumberDefaultProps,
    InputNumberWithDefaultProps
} from './iInputNumber'
import InputNumberGroup from './InputNumberGroup'

const defaultProps: InputNumberDefaultProps = {
    value: '',
    step: 1,
    iconStyle: 'double',
    autoFix: false,
    autoWidth: false,
    min: Number.MIN_SAFE_INTEGER,
    max: Number.MAX_SAFE_INTEGER,
    // precision:null,
    readOnly: false,
    decimalSeparator: '.',
    // format:() => {},
    // formatter: () => {},
    delay: 300,
    autoFocus: false,
    disabled: false,
    toThousands: false,
    locale: 'zh-cn',
    toNumber: true,
    displayCheckPrompt: false,
    minusRight: false,
    keyboard: true,
    // onPressEnter: () => {},
    // onStep: () => {},
    // handleBtnClick: () => {},
    // hideActionButton: false,
    rootClassName: ''
}

// 校验提示
function prompt(content?: string) {
    Message.destroy()
    Message.create({content, color: 'warninglight'})
}

/**
 * 千分符
 * @param {要转换的数据} number
 */
function toThousands(number: number | string): string {
    if (number === '') return ''
    if (number === '0') return '0'
    let num = (number || 0).toString()
    let integer = num.split('.')[0]
    let decimal = num.split('.')[1] || ''
    let result = ''
    while (integer.length > 3) {
        result = ',' + integer.slice(-3) + result
        integer = integer.slice(0, integer.length - 3)
    }
    if (integer) {
        result = integer + result
        if (num == '.' || num.indexOf('.') == num.length - 1) {
            result = result + '.' + decimal
        } else if (decimal) {
            result = result + '.' + decimal
        }
    }
    if (result[0] == '-') {
        result = result.replace('-,', '-')
    }
    return result
}

function setCaretPosition(ctrl: HTMLInputElement, pos: number, need: boolean) {
    if (ctrl && need) {
        if (ctrl.setSelectionRange) {
            ctrl.focus()
            ctrl.setSelectionRange(pos, pos)
            // IE8 and below，兼容IE场景，单测不覆盖
        } else if ((ctrl as any).createTextRange) {
            let range = (ctrl as any).createTextRange()
            range.collapse(true)
            range.moveEnd('character', pos)
            range.moveStart('character', pos)
            range.select()
        }
    }
}

@WithConfigConsumer()
@WebUI({name: 'input-number', defaultProps})
class InputNumber extends Component<InputNumberProps, InputNumberState> {
    static InputNumberGroup: typeof InputNumberGroup = InputNumberGroup

    inputRef!: any
    timer!: ReturnType<typeof setTimeout>
    focus: boolean = false

    constructor(props: InputNumberWithDefaultProps) {
        super(props)
        // 初始化状态，加减按钮是否可用，根据当前值判断
        let data = this.judgeValue(props)
        this.state = {
            precision: props.precision,
            value: data.value,
            max: props.max === 0 ? 0 : props.max || Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1,
            min: props.min === 0 ? 0 : props.min || Number.MIN_SAFE_INTEGER || (Math.pow(2, 53) - 1) * -1,
            minusDisabled: data.minusDisabled,
            plusDisabled: data.plusDisabled,
            showValue: toThousands(data.value),
            placeholderShow: true,
            preValue: '' // 输入的合法的数据, onChange事件触发此值的修改更新
        }
    }

    /**
     * 校验value
     * @param {*} props
     * @param {原来的值} oldValue
     * @param {精度} precision
     */
    judgeValue = (
        props: InputNumberProps,
        oldValue?: InputNumberState['value'],
        precision?: InputNumberProps['precision']
    ): {
        value: string | number
        minusDisabled: boolean
        plusDisabled: boolean
    } => {
        let currentValue
        let currentMinusDisabled = false
        let currentPlusDisabled = false
        let {value, min, max, minusRight, defaultValue, displayCheckPrompt, locale} = props
        if (!value && value !== 0) {
            // undefined, null, 空字符串''，但不包括带空格的空字符串 '  '
            return {
                value: '',
                minusDisabled: false,
                plusDisabled: false
            }
        }
        value = this.deleteSpaces(value)
        precision = precision ?? props.precision
        if (!max && max !== 0 && this.state) max = this.state.max
        if (!min && min !== 0 && this.state) min = this.state.min
        if (minusRight) {
            value = value?.toString() ?? ''
            if (value.indexOf('-') != -1) {
                // 所有位置的负号转到前边
                value = value.replace('-', '')
                value = '-' + value
            }
            value = Number(value)
        }
        // value = this.changeMinusRightPosition(value?.toString() ?? '', 'left', 'number')
        if (value != undefined && value != null) {
            if (value === '') {
                currentValue = ''
                return {
                    value: '',
                    minusDisabled: false,
                    plusDisabled: false
                }
            } else {
                // fix: 用户传入超长字符串数字时，类型转换会丢失精度，+"12345678901234.54663344"  ==> 12345678901234.547
                currentValue =
                    (typeof value === 'string' &&
                    (value.replace(/[^\d]/g, '').length >
                        16 /** 没超出安全数字，但长度超过16也会超出js数字可用的存储空间 */ ||
                        +value >= +max! /** 超出安全数字但没超过16位 */ ||
                        +value <= +min!)
                        ? value // 超长值不改变类型
                        : +value) ?? 0
            }
        } else if (defaultValue !== undefined && defaultValue !== null) {
            // 无效API
            currentValue = defaultValue
            // } else if (value === '0' || value === 0) { // 该判断多余，第一个if后此处无效
            //     currentValue = 0
        } else {
            // NaN
            if (oldValue || oldValue === 0 || oldValue === '0') {
                currentValue = oldValue
            } else {
                // value为空
                return {
                    value: '',
                    minusDisabled: false,
                    plusDisabled: false
                }
            }
        }
        if (currentValue == -Infinity) {
            return {
                value: min ?? '',
                minusDisabled: true,
                plusDisabled: false
            }
        }
        if (currentValue == Infinity) {
            return {
                value: max ?? '',
                minusDisabled: false,
                plusDisabled: true
            }
        }

        const local = getLangInfo(locale, i18n)
        if (min !== undefined && currentValue <= min) {
            if (displayCheckPrompt) prompt(local.langMap.msgMin)
            currentMinusDisabled = true
            currentValue = min
        }
        if (max !== undefined && currentValue >= max) {
            if (displayCheckPrompt) prompt(local.langMap.msgMax)
            currentPlusDisabled = true
            currentValue = max
        }

        if (precision !== undefined) {
            currentValue = this.getPrecision(currentValue, precision)
        }
        if (minusRight) {
            currentValue = '' + currentValue
            if (currentValue.indexOf('-') != -1) {
                // 负号转到后边
                currentValue = currentValue.replace('-', '')
                currentValue = currentValue + '-'
            }
        }
        // currentValue = this.changeMinusRightPosition(currentValue.toString(), 'right')
        return {
            value: currentValue,
            minusDisabled: currentMinusDisabled,
            plusDisabled: currentPlusDisabled
        }
    }

    componentDidMount() {
        let data = this.judgeValue(this.props)
        this.setState({
            value: data.value,
            preValue: data.value,
            showValue: toThousands(data.value)
        })
        if (this.props.autoFocus) {
            this.inputRef.input.focus()
        }
    }

    componentWillReceiveProps(nextProps: InputNumberProps) {
        const {max, min, step, value, precision} = nextProps
        const state = this.state
        if (max !== undefined && max !== state.max) {
            this.setState({
                max,
                plusDisabled:
                    max !== undefined && (state.value >= max || (step !== undefined && +state.value + step > max))
            })
        }
        if (min !== undefined && min !== state.min) {
            this.setState({
                min,
                minusDisabled:
                    min !== undefined && (state.value <= min || (step !== undefined && +state.value - +step < min))
            })
        }
        if (precision !== undefined && precision !== state.precision) {
            let data = this.judgeValue(nextProps, state.value, precision)
            this.setState({
                precision,
                value: data.value,
                preValue: data.value,
                showValue: toThousands(data.value)
            })
        }

        if (value !== state.value) {
            if (this.focus) {
                if (value !== Infinity && value !== -Infinity) {
                    let nextValue = this.deleteSpaces(value ?? '')
                    let isThousand = this.isThousandth(nextValue) // 是否是千分位
                    if (isThousand) {
                        nextValue = this.delcommafy(nextValue)
                    }
                    this.setState({
                        value: nextValue,
                        preValue: nextValue,
                        showValue: toThousands(nextValue)
                    })
                }
            } else {
                let data = this.judgeValue(nextProps, state.value)
                this.setState({
                    value: data.value,
                    preValue: data.value,
                    showValue: toThousands(data.value),
                    minusDisabled: data.minusDisabled,
                    plusDisabled: data.plusDisabled
                })
            }
        }
    }

    componentWillUnmount() {
        this.clear()
    }

    /**
     *  @memberof InputNumber
     * round 是否要四舍五入(此参数无效,超长不让输入)
     */
    numToFixed = (value: string | number, precision?: InputNumberProps['precision'], round?: boolean): string => {
        value = String(value)
        if (!value && value !== '0') return value
        if (!precision && String(precision) !== '0') return value
        let preIndex = value.indexOf('.')
        if (value.indexOf('.') === -1) return value
        preIndex++
        let endIndex = preIndex + (precision ?? 0)
        let precValue = value.substr(preIndex, endIndex) + '0000000000'
        if (round) {
            return (Number(value) + 1e-14).toFixed(precision)
        }
        return value.split('.')[0] + '.' + precValue.substr(0, precision)
    }

    /**
     * 删除输入的空格
     * @param {Number|String} value 输入数字
     */
    deleteSpaces = (value: string | number) => {
        return typeof value === 'string' ? value.replace(/\s/g, '') : value.toString()
    }

    handleChange = (value: string | number, e: React.MouseEvent<HTMLInputElement>) => {
        const {onChange, toNumber, minusRight, antd, min, max, step, autoFix, round} = this.props
        const {precision} = this.state
        let selectionStart =
            this.inputRef.selectionStart === undefined
                ? this.inputRef?.input.selectionStart
                : this.inputRef.selectionStart
        if (antd) {
            /** antd模式下value与e顺序对调 */
            e = value as unknown as React.MouseEvent<HTMLInputElement>
            value = (e as any).target.value as string
        }
        value = this.deleteSpaces(value as string)
        // if (this.inputRef.isComposition === true) return // QDJCJS-9776，禁止中文输入，否则windows下change先于composition事件触发会导致额外删除数字问题
        if (this.inputRef.isComposition === true) {
            this.inputRef.blur()
            this.inputRef.focus()
            return
        }
        value = value.replace(/。/g, '.') // QDJCJS-11472 搜狗中文输入法下，小数点和句号混淆问题
        const removeDotValue = value.split('.')
        if (removeDotValue.length > 2) {
            value = removeDotValue[0] + '.' + removeDotValue.slice(1).join('') // 移除多余小数点
        }
        if (/[^e+-.\d]+/gi.test(value.slice(-1))) return // 后置非法校验，修复QDJCJS-9919 用户Excel直接copy单元格数字时自带尾部空格无法粘贴问题
        if (value === '') {
            onChange?.(value, e)
            this.setState({
                value,
                preValue: '',
                showValue: ''
            })
            return
        }
        if (minusRight) {
            if ((value.match(/-/g)?.length ?? 0) > 1) return
        }
        let thousandth = this.isThousandth(value) // 是否是千分位
        if (this.props.toThousands) {
            value = value.replace(/,/g, '')
        }
        if (isNaN(+value) && value !== '.' && value !== '-' && !thousandth) {
            // #QDJCJS-7818  手动输入非数字时 输入一位 数字框原有长度减少一位
            this.setState({
                value,
                showValue: minusRight ? String(value).replace('-', '') + '-' : value,
                preValue: minusRight ? String(value).replace('-', '') + '-' : value // 增加前值记录
            })
            return
        }
        if (value.indexOf('.') !== -1) {
            // 小数最大值处理
            let prec = String(value.split('.')[1]).replace('-', '')
            if (precision === 0 && (prec === '' || prec != '')) return
            if (precision && prec.length > precision) {
                value = this.numToFixed(value, precision, round)
            }
        }
        if (autoFix && /\d/.test(value[value.length - 1])) {
            if (min === undefined || max === undefined) {
                value = this.fixExceedNum(value).value
            } else {
                if (
                    (max <= 0 && +value < max) ||
                    (min >= 0 && +value > min) ||
                    (max * min < 0 && (+value < min || +value > max))
                ) {
                    value = this.fixExceedNum(value).value
                }
            }
        }
        this.setState({
            value,
            preValue: value, // 更新合法的数据
            plusDisabled: max !== undefined && (+value >= max || +value + +step! > max),
            minusDisabled: min !== undefined && (+value <= min || +value - step! < min)
        })
        if (value === '-') {
            onChange?.(value, e)
        } else if (value == '.' || value.indexOf('.') == value.length - 1) {
            // 当输入小数点的时候
            onChange?.(value, e)
        } else if (value[value.indexOf('.') + 1] === '0') {
            // 当输入 d.0 的时候，不转换Number
            onChange?.(value, e)
        } else {
            toNumber ? onChange?.(Number(value), e) : onChange?.(value, e)
        }
        if (this.props.toThousands) {
            let stateShowValue = toThousands(this.state.value)
            let showValue = toThousands(value)
            let addNumber = 0
            let delNumber = 0
            let reg = /[0-9]/
            for (let i = 0; i < selectionStart; i++) {
                if (!reg.test(showValue[i])) addNumber += 1
            }
            for (let j = 0; j < selectionStart; j++) {
                if (stateShowValue[j]) {
                    if (!reg.test(stateShowValue[j])) delNumber += 1
                }
            }
            let position = selectionStart + addNumber - delNumber
            setCaretPosition(this.inputRef.input, position, true)
        }
    }

    handleFocus = (_value: string, e: React.FocusEvent<HTMLInputElement>) => {
        this.focus = true
        let {onFocus, precision} = this.props
        if (onFocus) {
            onFocus(this.getPrecision(this.state.value, precision), e)
        } else {
            this.forceUpdate()
        }
    }
    /**
     * 恢复科学技术法的问题
     */
    getFullNum = (num: string | number): string => {
        // 处理非数字
        if (isNaN(+num)) {
            return num + ''
        }

        // 处理不需要转换的数字
        const str = '' + num
        if (!/e/i.test(str)) {
            return str
        }
        return Number(num)
            .toFixed(this.state.precision ?? 18)
            .replace(/\.?0+$/, '')
    }

    /**
     * @desc 超过最大值最小值情况处理
     * @param v 输入值
     * @returns {value, showValue, placeholderShow}
     */
    fixExceedNum = (v: string | number): {value: string; showValue: string; placeholderShow: boolean} => {
        let {max, min, displayCheckPrompt, round, minusRight, precision} = this.props
        if (!max && max !== 0) max = this.state.max
        if (!min && min !== 0) min = this.state.min
        const local = getLangInfo(this.props.locale, i18n)

        let value: number | string = this.numToFixed(v, precision, round)
        if (minusRight) {
            if (value.indexOf('-') != -1) {
                // 所有位置的负号转到前边
                value = value.replace('-', '')
                value = '-' + value
            }
        }
        // value = this.changeMinusRightPosition(value) as string
        if (isNaN(parseFloat(value))) {
            // 空字符串等
            value = 0
        } else if (typeof value === 'string' && /\D/g.test(value[value.length - 1])) {
            // 末尾非数字
            value = parseFloat(value)
        } // 其他情况不做处理，原值下传
        if ((max || max === 0) && +value > +max) {
            if (displayCheckPrompt) prompt(local.langMap.msgMax)
            value = max
        }
        if ((min || min === 0) && +value < +min) {
            if (displayCheckPrompt) prompt(local.langMap.msgMin)
            value = min
        }
        if (precision !== undefined) {
            value = this.getPrecision(value, precision)
        }
        value = value.toString()
        if (minusRight && value.indexOf('-') != -1) {
            // 负号转到后边
            value = value.replace('-', '')
            value = value + '-'
        }
        // value = this.changeMinusRightPosition(value, 'right') as string
        return {
            value,
            showValue: toThousands(value),
            placeholderShow: true
        }
    }

    handleBlur = (v: string, e: React.FocusEvent<HTMLInputElement>) => {
        this.focus = false
        let {onBlur, onChange, toNumber, minusRight} = this.props
        // v = this.state.value // 在onBlur的时候不需要获取输入框的值，而是要获取state中的值，因为有format。
        v = this.isEqual(this.state.value).toString()
        if (v === '' || !v) {
            this.setState({
                value: v,
                showValue: toThousands(v)
            })
            onChange?.(v)
            onBlur?.(v, e)
            return
        }
        let value = this.fixExceedNum(v).value
        this.setState({
            value,
            showValue: toThousands(value),
            placeholderShow: true
        })
        this.detailDisable(value)
        if (toNumber && !minusRight) {
            onChange?.(Number(value))
            onBlur?.(Number(value), e)
        } else {
            onChange?.(value, e)
            onBlur?.(value, e)
        }
    }
    // 去掉千分位
    delcommafy = (num: string) => {
        // 去除千分位中的‘，’
        if (num && num != 'undefined' && num != 'null') {
            let numS = num
            numS = numS.toString()
            numS = numS.replace(/,/gi, '')
            return numS
        } else {
            return num
        }
    }
    /**
     * 设置增加减少按钮是否可用
     */
    detailDisable = (value: string | number) => {
        let {max, min, step} = this.props
        if (!max && max !== 0) max = this.state.max
        if (!min && min !== 0) min = this.state.min
        this.setState({
            plusDisabled: value === '' || (max !== undefined && (+value >= max || +value + +step! > max)),
            minusDisabled: value === '' || (min !== undefined && (+value <= min || +value - step! < min))
        })
    }
    /**
     * 减法
     */
    minus = (value: number, e: React.MouseEvent<HTMLElement>) => {
        let {min, max, step, onChange, toNumber} = this.props
        if (!max && max !== 0) max = this.state.max
        if (!min && min !== 0) min = this.state.min
        // value = value === '-' ? 0 : value
        if (typeof min === 'undefined') {
            value = +this.detail(value, step, 'reduce')
        } else {
            if ((min || min === 0) && value < +min) {
                value = +min
            } else {
                let reducedValue = +this.detail(value, step, 'reduce')
                if (+reducedValue >= +min) {
                    value = reducedValue
                }
            }
        }
        if (max || max === 0) {
            max = Number(max)
            if (value > max) {
                value = max
            }
        }
        this.setState(
            {
                value,
                preValue: value,
                showValue: toThousands(value)
            },
            () => {
                this.inputRef.input?.focus()
            }
        )
        toNumber ? onChange?.(Number(value), e) : onChange?.(value, e)
        this.handleBtnClick('down', value)
        this.detailDisable(value)
    }
    /**
     * 加法
     */
    plus = (value: number, e: React.MouseEvent<HTMLElement>) => {
        let {max, min, step, onChange, toNumber} = this.props
        if (!max && max !== 0) max = this.state.max
        if (!min && min !== 0) min = this.state.min
        // value = value === '-' ? 0 : value
        if (typeof max === 'undefined') {
            value = +this.detail(value, step, 'add')
        } else {
            if ((max || max === 0) && value > +max) {
                value = +max
            } else {
                let addedValue = +this.detail(value, step, 'add')
                if ((max || max === 0) && addedValue <= +max) {
                    value = addedValue
                }
            }
        }
        if (min || min === 0) {
            min = Number(min)
            if (value < min) {
                value = min
            }
        }
        this.setState(
            {
                value,
                preValue: value,
                showValue: toThousands(value)
            },
            () => {
                this.inputRef.input?.focus()
            }
        )
        toNumber ? onChange?.(Number(value), e) : onChange?.(value, e)
        this.handleBtnClick('up', value)
        this.detailDisable(value)
    }

    detail = (value: string | number, step: InputNumberProps['step'], type: 'add' | 'reduce') => {
        let {precision} = this.props

        let valueFloat = this.separate(value)
        let stepFloat = step !== undefined ? this.separate(step) : ''

        let ans: number | undefined
        let stepFloatLength = stepFloat.toString().length
        let valueFloatLength = valueFloat.toString().length

        if (typeof precision === 'undefined') {
            precision = Math.max(stepFloatLength, valueFloatLength)
        }
        let coefficient = Math.pow(10, Math.abs(stepFloatLength - valueFloatLength))
        if (step !== undefined) {
            if (type === 'add') {
                ans = (+value * coefficient + step * coefficient) / coefficient
            } else {
                ans = (+value * coefficient - step * coefficient) / coefficient
            }
        }
        return (ans as number).toFixed(precision)
    }
    // 是否是千分位
    isThousandth = (value: string) => {
        let regex =
            /(?:^[-]?[1-9]\d{0,2}(?:$|(?:,\d{3})*(?:$|(\.\d{1,2}$))))|(?:(?:^[0](\.\d{1,2})?)|(?:^[-][0]\.\d{1,2}))$/
        return regex.test(value)
    }

    /**
     * 分离小数和整数
     * @param value
     * @returns {*}
     */
    separate = (value: string | number) => {
        if (value == null || value == undefined) {
            return ''
        } else {
            value = value.toString()
            if (value.indexOf('.') > -1) {
                return value.split('.')[1]
            } else {
                return ''
            }
        }
    }

    clear = () => {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }

    // 字符串且有减号，可调整减号位置，数据类型保持不变
    changeMinusRightPosition = (value: string | number): string | number => {
        let {minusRight} = this.props
        if (minusRight) {
            if (typeof value === 'string' && value.indexOf('-') !== -1) {
                value = '-' + value.replace('-', '')
            } else if (typeof value === 'number' && value < 0) {
                value = '-' + String(value).replace('-', '')
            }
        }
        return value
    }

    handlePlusMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.persist()
        e.nativeEvent.stopPropagation()
        e.nativeEvent.preventDefault()
        let {delay, disabled, readOnly} = this.props
        let {value} = this.state
        if (disabled || readOnly) return
        value = this.isEqual(value)
        value = this.changeMinusRightPosition(value)
        value = isNaN(parseFloat(value.toString())) ? 0 : parseFloat(value.toString())
        this.plus(value, e)
        this.clear()
        this.timer = setTimeout(() => {
            this.handlePlusMouseDown(e)
        }, delay)
    }

    handleReduceMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.persist()
        e.nativeEvent.stopPropagation()
        e.nativeEvent.preventDefault()
        let {delay, disabled, readOnly} = this.props
        let {value} = this.state
        if (disabled || readOnly) return
        value = this.isEqual(value)
        value = this.changeMinusRightPosition(value)
        value = isNaN(parseFloat(value.toString())) ? 0 : parseFloat(value.toString())
        this.minus(value, e)
        this.clear()
        this.timer = setTimeout(() => {
            this.handleReduceMouseDown(e)
        }, delay)
    }

    onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
        const {which} = e
        const {keyboard, onPressEnter, onKeyDown} = this.props

        if (which === KeyCode.ENTER) {
            this.clear()
            onPressEnter?.(e)
        }

        if (keyboard === true) {
            // 按上下箭头， step 更新值
            if (KeyCode.UP === which) {
                this.handlePlusMouseDown(e as any)
                e.preventDefault()
            } else if (KeyCode.DOWN === which) {
                this.handleReduceMouseDown(e as any)
                e.preventDefault()
            }
        }

        onKeyDown?.(e)
    }

    onKeyUp = () => {
        this.clear()
    }

    getPrecision = (value: InputNumberState['value'], precision: InputNumberProps['precision']) => {
        if (value == null || value == undefined) return value
        if (!value && value === '') return value
        value = String(value)
        value = value.indexOf('e') !== -1 ? this.getFullNum(value) : value
        if (precision == undefined || (value.indexOf('.') !== -1 && String(value.split('.')[1]).length === precision)) {
            return value
        }
        let before = value.substring(0, 1),
            len = value.length,
            after = value.substring(len - 1, len)
        before = before === '-' ? before : ''
        after = after === '-' ? after : ''
        // 是科学计数法，不replace -
        if (before) value = value.substring(1, len)
        if (after) value = value.substring(0, len - 1)
        // value = value.replace("-",'');
        let precV = '000000000000000000000000000000000000000000000000000000000000000000000000'
        if (value.indexOf('.') === -1) {
            precV = precV.substr(0, precision)
            precV = precV ? '.' + precV : precV
            if (!isNaN(+value) && (value.indexOf('-') != -1 || value.indexOf('+') != -1) && value.indexOf('e') != -1) {
                // 是科学计数法，不拼接0000000
            } else {
                value = value + precV
            }
        }
        return before + Number(value).toFixed(precision) + after
    }

    handleBtnClick = (type: 'up' | 'down', value: number) => {
        const {onStep, handleBtnClick, step} = this.props
        handleBtnClick?.(type, value)
        onStep?.(value, {offset: step!, type})
    }
    isIE = () => {
        if (window) {
            if (!!(window as any).ActiveXObject || 'ActiveXObject' in window) return true
        }
        return false
    }
    placeholderClick = () => {
        this.inputRef?.input.focus()
        this.setState({
            placeholderShow: false
        })
    }

    isEqual = (value: InputNumberState['value']) => {
        // 如果当前数据不合法，之前合法数据替换当前数据
        const {preValue} = this.state
        if (value !== preValue && preValue !== undefined) {
            value = preValue
        }
        return value
    }

    // 输入中文处理
    handleComposition = (e: React.CompositionEvent<HTMLInputElement>, composition: 'start' | 'end') => {
        const {onCompositionStart, onCompositionEnd} = this.props
        if (composition === 'start') {
            this.inputRef.isComposition = true
            onCompositionStart?.(e)
        } else if (composition === 'end') {
            this.inputRef.isComposition = false
            onCompositionEnd?.(e)
            // if (type === 'search') {
            //     this.handleSearchChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            // } else if (type === 'textarea' || componentClass === 'textarea') {
            //     this.handleTextareaChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            // } else {
            //     this.handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>)
            // }
        }
    }

    render() {
        let {
            id,
            fieldid,
            toThousands,
            minusRight,
            precision,
            step,
            disabled,
            readOnly,
            clsPrefix,
            className,
            delay,
            onCompositionStart,
            onCompositionEnd,
            onBlur,
            onFocus,
            onKeyDown,
            onKeyUp,
            iconStyle,
            autoWidth,
            onChange,
            format,
            toNumber,
            hideActionButton,
            controls,
            handleBtnClick,
            onStep,
            addonBefore,
            addonAfter,
            displayCheckPrompt,
            onPressEnter,
            formatter,
            style,
            rootClassName,
            autoFix,
            round,
            ...others
        } = this.props
        hideActionButton = hideActionButton ?? controls ?? false // 兼容antd API，并赋默认值false
        let classes = {
            [`${clsPrefix}-auto`]: autoWidth,
            [`${clsPrefix}`]: true,
            [`${clsPrefix}-${others.size}`]: others.size
        }
        let addonClasses = {
            [`${clsPrefix}-addon-${others.size}`]: others.size
        }

        let {value, minusDisabled, plusDisabled, showValue} = this.state
        value = precision != null && !this.focus ? this.getPrecision(value, precision) : value
        if (!format && formatter) format = formatter // 兼容处理
        value = format && !this.focus ? format(value) : value
        value = String(value).indexOf('e') !== -1 ? this.getFullNum(value) : value
        if (minusRight && String(value).indexOf('-') !== -1) {
            value = String(value).replace('-', '') + '-'
        }
        // value = this.changeMinusRightPosition(String(value), 'right')
        let disabledCursor = disabled ? ' disabled-cursor' : ''
        let disabledCon = disabled ? ' disabled-con' : ''
        return (
            <div
                id={id ? id + '_input-number' : undefined}
                fieldid={fieldid ? fieldid + '_input-number' : undefined}
                className={classnames(`${clsPrefix}-out`, rootClassName)}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
            >
                {addonBefore ? (
                    <div
                        className={classnames(
                            className ? `${className}-addonBefore` : '',
                            `${clsPrefix}-addonBefore`,
                            addonClasses
                        )}
                    >
                        {addonBefore}
                    </div>
                ) : null}
                {iconStyle === 'double' ? (
                    <InputGroup style={style} className={classnames(className, classes, disabledCon)}>
                        {hideActionButton ? (
                            ''
                        ) : (
                            <InputGroup.Addon
                                id={id ? id + '_minus' : undefined}
                                fieldid={fieldid ? fieldid + '_minus' : undefined}
                                className={(minusDisabled ? 'disabled' : '') + disabledCursor}
                                onMouseDown={this.handleReduceMouseDown}
                                onClick={(e: MouseEvent) => e.stopPropagation()}
                                onMouseLeave={() => this.clear()}
                                onMouseUp={() => this.clear()}
                            >
                                -
                            </InputGroup.Addon>
                        )}
                        <Input
                            // {...others}
                            {...omit(others, ['decimalSeparator'])}
                            id={id}
                            fieldid={fieldid}
                            value={toThousands ? showValue : value}
                            disabled={disabled}
                            readOnly={readOnly}
                            onPressEnter={onPressEnter}
                            onBlur={this.handleBlur}
                            onFocus={this.handleFocus}
                            onChange={this.handleChange}
                            onCompositionStart={e => {
                                this.handleComposition(e, 'start')
                            }}
                            onCompositionEnd={e => {
                                this.handleComposition(e, 'end')
                            }}
                            ref={(ref: HTMLInputElement) => (this.inputRef = ref)}
                        />
                        {hideActionButton ? (
                            ''
                        ) : (
                            <InputGroup.Addon
                                id={id ? id + '_plus' : undefined}
                                fieldid={fieldid ? fieldid + '_plus' : undefined}
                                className={(plusDisabled ? 'disabled' : '') + disabledCursor}
                                onMouseDown={this.handlePlusMouseDown}
                                onClick={(e: MouseEvent) => e.stopPropagation()}
                                onMouseLeave={() => this.clear()}
                                onMouseUp={() => this.clear()}
                            >
                                +
                            </InputGroup.Addon>
                        )}
                    </InputGroup>
                ) : (
                    <InputGroup style={style} className={classnames(className, classes, disabledCon)} simple>
                        {this.isIE() && !value ? (
                            <div
                                onClick={this.placeholderClick}
                                style={{
                                    display: this.state.placeholderShow ? 'block' : 'none'
                                }}
                                className={`${clsPrefix}-placeholder`}
                            >
                                {this.props.placeholder}
                            </div>
                        ) : (
                            ''
                        )}
                        <Input
                            // {...others}
                            {...omit(others, ['decimalSeparator'])}
                            id={id}
                            fieldid={fieldid}
                            value={toThousands ? showValue : value}
                            disabled={disabled}
                            readOnly={readOnly}
                            onBlur={this.handleBlur}
                            onFocus={this.handleFocus}
                            onChange={this.handleChange}
                            onPressEnter={onPressEnter}
                            ref={(ref: HTMLInputElement) => (this.inputRef = ref)}
                        />
                        {hideActionButton ? (
                            ''
                        ) : (
                            <InputGroup.Button>
                                <div className={classnames('icon-group')}>
                                    <span
                                        id={id ? id + '_plus' : undefined}
                                        fieldid={fieldid ? fieldid + '_plus' : undefined}
                                        onMouseDown={this.handlePlusMouseDown}
                                        onClick={e => e.stopPropagation()}
                                        onMouseLeave={() => this.clear()}
                                        onMouseUp={() => this.clear()}
                                        className={classnames('plus', {
                                            disabled: plusDisabled,
                                            'disabled-cursor': disabledCursor
                                        })}
                                    >
                                        <span className='uf uf-arrow-up' />
                                    </span>
                                    <span
                                        id={id ? id + '_minus' : undefined}
                                        fieldid={fieldid ? fieldid + '_minus' : undefined}
                                        onMouseDown={this.handleReduceMouseDown}
                                        onClick={e => e.stopPropagation()}
                                        onMouseLeave={() => this.clear()}
                                        onMouseUp={() => this.clear()}
                                        className={classnames('reduce', {
                                            disabled: minusDisabled,
                                            'disabled-cursor': disabledCursor
                                        })}
                                    >
                                        <span className=' uf uf-arrow-down' />
                                    </span>
                                </div>
                            </InputGroup.Button>
                        )}
                    </InputGroup>
                )}
                {addonAfter ? (
                    <div
                        className={classnames(
                            className ? `${className}-addonAfter` : '',
                            `${clsPrefix}-addonAfter`,
                            addonClasses
                        )}
                    >
                        {addonAfter}
                    </div>
                ) : null}
            </div>
        )
    }
}
InputNumber.InputNumberGroup = InputNumberGroup

export default InputNumber
