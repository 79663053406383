/**
 * This source code is quoted from rc-tabs.
 * homepage: https://github.com/react-component/tabs
 */
export default {
    /**
	 * LEFT
	 */
    LEFT: 37, // also NUM_WEST
    /**
	 * UP
	 */
    UP: 38, // also NUM_NORTH
    /**
	 * RIGHT
	 */
    RIGHT: 39, // also NUM_EAST
    /**
	 * DOWN
	 */
    DOWN: 40, // also NUM_SOUTH
};
