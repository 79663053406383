/*
 * @Author: Mr.mjc
 * @Date: 2022-06-20 17:52:03
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2022-11-10 15:33:45
 * @Description: 常量声明文件
 * @FilePath: /next-ui/packages/wui-table/src/constant.tsx
 */
import React from 'react';
import Icon from '../../wui-icon/src';

export const DRAGHANDLE = 'dragHandle';
export const MULTISELECT = 'multiselect';

export const columnType = {
    DEFAULTTYPE: 'data',
    ROWDRAG: 'rowdrag',
    MULTISELECT: 'multiselect'
}

export const defaultDragHandleColumn = {
    className: "drag-handle-column",
    title: "",
    key: DRAGHANDLE,
    dataIndex: DRAGHANDLE,
    width: 40,
    draggable: true,
    columnType: columnType.ROWDRAG,
    render: () => {
        return <Icon type="uf-navmenu"/>
    }
}


export const preCol = (width: number) => ({
    className: "pre",
    title: "",
    key: '_pre',
    dataIndex: '_pre',
    width,
})

export const sufCol = (width: number) => ({
    className: "suf",
    title: "",
    key: '_suf',
    dataIndex: '_suf',
    width,
})