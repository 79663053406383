import classNames from 'classnames';
// import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Affix from '../../wui-affix/src'
import {createChainedFunction} from '../../wui-core/src';
import {getNid, WebUI} from "../../wui-core/src/index"
import { AntdAnchorProps, AntAnchorState } from './iAnchor'
import AntdAnchorLink from './AntdAnchorLink';

// const propTypes = {
//     getContainer: PropTypes.func, // 指定滚动的容器
//     affix: PropTypes.bool, // 固定模式
//     offsetTop: PropTypes.number, // 距离窗口顶部达到指定偏移量后触发
//     showInkInFixed: PropTypes.bool, // affix={false} 时是否显示小圆点
//     onChange: PropTypes.func, // 监听锚点链接改变
//     onClick: PropTypes.func, // click 事件的回调
//     style: PropTypes.oneOfType([
//         PropTypes.object,
//         PropTypes.string
//     ]),
//     fieldid: PropTypes.string,
//     activeKey: PropTypes.string
// };
const defaultProps = {
    // getContainer: () => window,
    prefixCls: 'wui-anchor',
    affix: true,
    offsetTop: 0,
    showInkInFixed: false,
    onChange: null,
    onClick: null,
};

@WebUI({name: "anchor", defaultProps})
class AntdAnchor extends Component<AntdAnchorProps, AntAnchorState> {
	hrefArr: string[]
	isScroll: boolean
	onscrollTo?: () => void;
	wrapperRef!: React.RefObject<HTMLDivElement>
	constructor(props: AntdAnchorProps) {
	    super(props)
	    this.state = {
	        activeKey: props.activeKey
	    }
	    // this.wrapperRef = React.createRef();
	    this.handleSelect = this.handleSelect.bind(this);
	    this.hrefArr = [];
	    this.isScroll = true;
	}

	componentDidMount() {
	    let {children, activeKey, items} = this.props
	    // React.Children.map(children, (child: React.ReactElement, index) => {
	    //     this.hrefArr.push(child.props.href)
	    //     if (index === 0) {
	    //         this.setState({
	    //             activeKey: activeKey || child!.props.href
	    //         })
	    //     }
	    // })
	    if (items && items.length > 0) {
	        this.setState({
	            activeKey: activeKey || items[0]?.href
	        })
	        items.map((item: {key: string, href: string, title: string}) => {
	            this.hrefArr.push(item.href)
	        })
	    } else {
	        React.Children.map(children, (child: React.ReactElement, index) => {
	            this.hrefArr.push(child.props.href)
	            if (index === 0) {
	                this.setState({
	                    activeKey: activeKey || child.props.href
	                })
	            }
	        })
	    }
	    if (this.props?.getContainer === undefined) {
	        document.addEventListener('scroll', this.scrollHand)
	    } else {
	        this.props?.getContainer()?.addEventListener('scroll', this.scrollHand)
	    }
	}

	componentWillUnmount() {
	    // document.removeEventListener('scroll', this.scrollHand)
	    if (this.props?.getContainer === undefined) {
	        document.removeEventListener('scroll', this.scrollHand)
	    } else {
	        this.props?.getContainer()?.removeEventListener('scroll', this.scrollHand)
	    }
	}

	scrollHand = () => {
	    if (this.isScroll) {
	        this.hrefArr.forEach((item) => {
	            if (this.props.getContainer === undefined) {
	                if (-document.getElementById(item)!.getBoundingClientRect().height < document.getElementById(item)!.getBoundingClientRect().y && document.getElementById(item)!.getBoundingClientRect().y <= 0) {
	                    this.setState({
	                        activeKey: item
	                    })
	                }
	            } else {
	                if (typeof this.props?.getContainer === 'function') {
	                    if (this.props.getContainer().getBoundingClientRect().y - document.getElementById(item)!.getBoundingClientRect().y <= document.getElementById(item)!.getBoundingClientRect().y && document.getElementById(item)!.getBoundingClientRect().y <= this.props.getContainer().getBoundingClientRect().y) {
	                        this.setState({
	                            activeKey: item
	                        })
	                    }
	                }
	            }

	        })
	    }
	    setTimeout(() => {
	        this.isScroll = true
	    }, 10);

	}
	// componentDidUpdate () {
	//     this.updateInk();
	// }
	// updateInk = () => {
	//     const { prefixCls, wrapperRef } = this;
	//     const anchorNode = wrapperRef.current;
	//     const linkNode = anchorNode?.getElementsByClassName(`${prefixCls}-link-title-active`)[0];

	//     if (linkNode) {
	//       this.inkNode.style.top = `${(linkNode).offsetTop + linkNode.clientHeight / 2 - 4.5}px`;
	//     }
	// };
	// saveInkNode = (node) => {
	//     this.inkNode = node;
	// };
	// onscrollTo = () => {
	//     const { prefixCls, wrapperRef } = this;
	//     const anchorNode = wrapperRef.current;
	//     const linkNode = anchorNode?.getElementsByClassName(`${prefixCls}-link-title-active`)[0];

	//     if (linkNode) {
	//       this.inkNode.style.top = `${(linkNode).offsetTop + linkNode.clientHeight / 2 - 4.5}px`;
	//     }
	// }
	handleSelect(val: Record<string, any>) {
	    this.setState({activeKey: val.href});
	    this.isScroll = false;
	    // let { title, href } = val
	    if (this.props.onClick) {
	        this.props.onClick(val)
	    }
	    if (this.props.onChange) {
	        this.props.onChange(val.href)
	    }
	    // const { prefixCls, wrapperRef } = this;
	    // const anchorNode = wrapperRef.current;
	    // const linkNode = document.getElementsByClassName(`${prefixCls}-link-title-active`)[0];
	    // if (linkNode) {
	    //     this.inkNode.style.top = `${(linkNode).offsetTop + linkNode.clientHeight / 2 - 4.5}px`;
	    //   }
	}
	handleItems = () => {
	    let { items, children, fieldid } = this.props
	    let { activeKey } = this.state
	    let linkArr: any[] = []
	    if (items && items.length > 0) {
	        items.map((item: {key: string, href: string, title: string}) => {
	            let linkItem = <AntdAnchorLink
	                // onscrollTo={this.onscrollTo}
	                // eventKey={item.href}
	                expanded={item.href === activeKey}
	                fieldid={fieldid}
	                onSelect={this.handleSelect}
	                href={item.href}
	                title={item.title}
	                key={item.key}
	            />
	            linkArr.push(linkItem)
	        })
	    } else {
	        React.Children.map(children, (child: React.ReactElement) => {
	            let linkHref = child.props.href
	            let childProps = {
	                onscrollTo: this.onscrollTo,
	                eventKey: linkHref,
	                expanded: linkHref === activeKey,
	                onSelect: createChainedFunction(
	                    this.handleSelect, child.props.onSelect
	                ),
	                fieldid
	            }
	            linkArr.push(React.cloneElement(child, childProps))
	            // return React.cloneElement(child, childProps);
	        })
	    }
	    return linkArr
	}

	render() {
	    let {
	        // children,
	        style,
	        className = '',
	        prefixCls,
	        affix,
	        offsetTop,
	        getContainer,
	        showInkInFixed,
	        fieldid
	    } = this.props
	    // let {activeKey} = this.state;
	    const wrapperClass = classNames(
	        `${prefixCls}-wrapper`,
	        className,
	    );
	    const wrapperStyle = {
	        maxHeight: offsetTop ? `calc(100vh - ${offsetTop}px)` : '100vh',
	        ...style,
	    };
	    const anchorClass = classNames(prefixCls, {
	        fixed: !affix && !showInkInFixed,
	    });
	    // const inkClass = classNames(`${prefixCls}-ink-ball`, {
	    //     visible: '',
	    // });
	    let adapterNid = getNid(this.props) // 适配nid、uitype
	    const anchorContent = (
	        <div ref={this.wrapperRef} className={wrapperClass} style={wrapperStyle}>
	            <div className={anchorClass}>
	                <div className={`${prefixCls}-ink`}>
	                    {/* <span className={inkClass} ref={this.saveInkNode} /> */}
	                </div>
	                {/* {children} */}
	                {
	                    // React.Children.map(children, (child: React.ReactElement) => {
	                    //     let linkHref = child.props.href
	                    //     let childProps = {
	                    //         onscrollTo: this.onscrollTo,
	                    //         eventKey: linkHref,
	                    //         expanded: linkHref === activeKey,
	                    //         onSelect: createChainedFunction(
	                    //             this.handleSelect, child.props.onSelect
	                    //         ),
	                    //         fieldid
	                    //     }
	                    //     return React.cloneElement(child, childProps);
	                    // })
	                    this.handleItems()
	                }
	            </div>
	        </div>
	    );
	    return (
	        <div {...adapterNid} fieldid={fieldid}>
	            {!affix ? (
	                anchorContent
	            ) : (
	                <Affix offsetTop={offsetTop} target={getContainer}>
	                    {anchorContent}
	                </Affix>
	            )}
	        </div>
	    )
	}
}

// AntdAnchor.propTypes = propTypes;
export default AntdAnchor
