/*
 * @Author: Mr.mjc
 * @Date: 2022-08-24 09:48:57
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2022-10-26 16:58:43
 * @Description: rows
 * @FilePath: /next-ui/packages/wui-table/src/stickyTable/StickyRows.tsx
 */

import React, {Component} from 'react';
import classnames from 'classnames';
import ExpandIcon from '../ExpandIcon';
import TableCell from './StickyCells';
import { TableRowProps, TableRowState } from '../iTableRow';
import { DefaultRecordType } from '../interface';

const defaultProps = {
    onRowClick() {
    },
    onDestroy() {
    },
    expandIconColumnIndex: 0,
    leftColumnsLength: 0,
    centerColumnsLength: 0,
    expandRowByClick: false,
    columns: [],
    onHover() {
    },
    className: '',
    rowDraggAble: false,
    syncRowHeight: false,
    onRow() {
    },
    selectedRowKeys: [],
    findRowKeys: [],
    isExpandedRow: false,
    expanded: false
};

class TableRow extends Component<TableRowProps<DefaultRecordType>, TableRowState> {

	static defaultProps = {...defaultProps};
	_timeout: any = null;
	expandHeight: number = 0;
	event: boolean = false;
	cacheCurrentIndex: any = null;
	canBeTouch: boolean = true;
	unsubscribe: any;
	element: HTMLTableRowElement | undefined;
	constructor(props: TableRowProps<DefaultRecordType>) {
	    super(props);
	    this.state = {
	        hovered: false,
	    };
	}


	componentDidMount() {
	    const {store, hoverKey, syncHover} = this.props;
	    this.unsubscribe = store.subscribe(() => {
	        if (store.getState().currentHoverKey !== null &&
				store.getState().currentHoverKey !== undefined &&
				store.getState().currentHoverKey === hoverKey &&
				syncHover
	        ) {
	            this.setState({hovered: true});
	        } else if (syncHover) { // 显示hover状态，加hoverclass类名
	            if (this.state.hovered === true && store.getState().currentHoverKey !== hoverKey) { // 原本是hover状态然后离开该行
	                this.setState({hovered: false});
	            }
	        }
	    });
	    this.setRowHeight()
	}

	componentDidUpdate() {
	    this.setRowHeight()
	}

	componentWillUnmount() {
	    const {record, onDestroy, index} = this.props;
	    onDestroy && onDestroy(record, index);
	    if (this.unsubscribe) {
	        this.unsubscribe();
	    }
	}


	setRowHeight() {
	    const {setRowHeight, expandedContentHeight = 0, fixed, fixedIndex} = this.props
	    if (!setRowHeight || !this.element || fixed) return
	    setRowHeight(this.element.clientHeight + expandedContentHeight, fixedIndex, this.getRecordKey())
	}

	onRowClick = (event: React.MouseEvent<HTMLElement>) => {
	    // fix: 解决 onRowClick 回调函数中，事件对象属性均为 null 的问题
	    // 异步访问事件属性
	    // 调用 event.persist() 会从事件池中移除该合成函数并允许对该合成事件的引用被保留下来。
	    event.persist();
	    const {
	        record,
	        // index,
	        onRowClick,
	        expandable,
	        expandRowByClick,
	        expanded,
	        onExpand,
	        fixedIndex,
	        onRowDoubleClick
	    } = this.props;
	    if (expandable && expandRowByClick) {
	        onExpand && onExpand(!expanded, record, fixedIndex, event);
	    }
	    if (!onRowDoubleClick) {
	        onRowClick && onRowClick(record, fixedIndex, event);
	        return;
	    }
	    this.set(() => {
	        onRowClick && onRowClick(record, fixedIndex, event);
	    });
	}

	onRowDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
	    const {record, onRowDoubleClick, fixedIndex} = this.props;
	    this.clear();
	    onRowDoubleClick && onRowDoubleClick(record, fixedIndex, event);
	}

	onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
	    const {onHover, hoverKey, fixedIndex, syncHover, record} = this.props;
	    if (syncHover) {
	        this.setState({hovered: true});
	    }
	    onHover && onHover(true, hoverKey, e, fixedIndex, record);
	}

	onMouseLeave = (e: React.MouseEvent<HTMLElement>) => {

	    const {onHover, hoverKey, fixedIndex, syncHover, record} = this.props;
	    if (syncHover) {
	        this.setState({hovered: false});
	    }
	    onHover && onHover(false, hoverKey, e, fixedIndex, record);
	}

	// TODO column.notRowDrag供TableCell禁用行拖拽，待优化
	stopRowDrag = (isStop: boolean) => {
	    const {rowDraggAble} = this.props;
	    const {notRowDrag} = this.state;
	    if (rowDraggAble && isStop !== notRowDrag) {
	        this.setState({
	            notRowDrag: isStop
	        })
	    }
	}

	set = (fn: (e: React.MouseEvent<HTMLElement>) => void) => {
	    this.clear();
	    this._timeout = window.setTimeout(fn, 300);
	}

	clear = () => {
	    if (this._timeout) {
	        window.clearTimeout(this._timeout);
	    }
	}

	bindElement = (el:HTMLTableRowElement) => {
	    this.element = el
	}

	// 获取当前行唯一键值
	getRecordKey() {
	    let {record, hoverKey} = this.props;
	    return record && record.key ? record.key : hoverKey
	}

	render() {
	    const {
	        clsPrefix,
	        columns,
	        record,
	        height,
	        visible,
	        index,
	        onPaste,
	        expandIconColumnIndex,
	        expandIconAsCell,
	        expanded,
	        useDragHandle,
	        rowDraggAble,
	        expandable,
	        onExpand,
	        needIndentSpaced,
	        indent,
	        indentSize,
	        isShowExpandIcon,
	        bodyDisplayInRow,
	        expandedIcon,
	        collapsedIcon,
	        hoverKey,
	        lazyStartIndex,
	        lazyEndIndex,
	        expandIconCellWidth,
	        getCellClassName,
	        expandIcon: _expandIcon,
	        onRow,
	        selectedRowKeys,
	        fixedIndex,
	        findRowKeys,
	        fieldid,
	        locale,
	        isExpandedRow,
	        className
	    } = this.props;
	    const {notRowDrag, hovered} = this.state;
	    let showSum = false;
	    let clsObj = {
	        [`selected`]: (hoverKey || hoverKey == 0) && record && (selectedRowKeys || []).includes(hoverKey), // 单选是通过className传入，后期优化成一致的selectedRowKeys实现
	        [`${className}`]: !!className,
	        [`${clsPrefix}-hover`]: hovered,
	        [`find-selected`]: (hoverKey || hoverKey == 0) && record && (findRowKeys || []).includes(hoverKey),
	        [`row-dragg-able`]: rowDraggAble && !useDragHandle && !notRowDrag,
	        [`${clsPrefix}-level-${indent}`]: clsPrefix && indent,
	    };
	    let classes = classnames(clsPrefix, clsObj);
	    // 判断是否为合计行
	    if (className && className.indexOf('sumrow') > -1) {
	        showSum = true;
	    }
	    const cells = [];
	    const expandIcon = (
	        <ExpandIcon
	            expandable={expandable}
	            clsPrefix={clsPrefix}
	            onExpand={onExpand}
	            needIndentSpaced={needIndentSpaced}
	            expanded={expanded}
	            record={record}
	            fixedIndex={fixedIndex as number}
	            expandedIcon={expandedIcon}
	            expandIcon={_expandIcon}
	            collapsedIcon={collapsedIcon}
	            isShowExpandIcon={isShowExpandIcon}
	            fieldid={fieldid}
	            oldMode={this.props.oldMode}
	        />
	    );

	    let expandIndexInThisTable = expandIconColumnIndex;
	    for (let i = 0; i < columns.length; i++) {
	        let column = columns[i];
	        if (i == 0 && expandIconAsCell) {
	            let cellStyle = {
	                maxWidth: column.width,
	                ...column.style,
	            }
	            let cellClasses = '';
	            if (column && (column.fixed === 'right' || column.fixed === 'left' || column.fixed === true)) {
	                cellStyle.position = 'sticky';
	                cellStyle.zIndex = 2;
	                if (column.fixed === 'right') {
	                    // 解决和头部一像素错位的问题
	                    cellStyle.right = this.props.bordered ? column.offsetWidth : (column.offsetWidth) as number + 1;
	                    cellClasses += ` ${clsPrefix}-cell-fix-right`
	                    if (column.isFirstRight) {
	                        cellClasses += ` ${clsPrefix}-cell-fix-right-first`
	                    }
	                } else {
	                    cellStyle.left = column.offsetWidth
	                    cellClasses += ` ${clsPrefix}-cell-fix-left`
	                    if (column.isLastLeft) {
	                        cellClasses += ` ${clsPrefix}-cell-fix-left-last`
	                    }
	                    if (column.isAllLeft) {
	                        cellClasses += ` ${clsPrefix}-cell-fix-left-all`
	                    }
	                }
	            } else {
	                cellStyle.position = 'relative';
	            }
	            showSum ? cells.push(<td width={expandIconCellWidth} className={cellClasses} style={cellStyle}
	                dangerouslySetInnerHTML={{__html: '&nbsp;'}}></td>) :
	                cells.push(
	                    <td
	                        className={`${clsPrefix}-expand-icon-cell ${cellClasses}`}
	                        key={`${clsPrefix}-expand-icon-cell-${i}`}
	                        width={expandIconCellWidth}
	                        style={cellStyle}
	                    >
	                        {expandIcon}
	                    </td>
	                );
	        } else {
	            let isColumnHaveExpandIcon = (expandIconAsCell || showSum)
	            ? false : (i === expandIndexInThisTable);

	            const {onCell} = columns[i];

	            cells.push(
	                <TableCell
	                    clsPrefix={clsPrefix}
	                    record={record}
	                    indentSize={indentSize as number}
	                    indent={indent as number}
	                    index={index as number}
	                    column={columns[i]}
	                    key={index + "_" + (columns[i].key || columns[i].dataIndex || i)}
	                    showSum={showSum}
	                    expandIcon={(isColumnHaveExpandIcon) ? expandIcon : null}
	                    bodyDisplayInRow={bodyDisplayInRow}
	                    lazyStartIndex={lazyStartIndex}
	                    lazyEndIndex={lazyEndIndex}
	                    onPaste={onPaste}
	                    stopRowDrag={this.stopRowDrag}
	                    col={i}
	                    getCellClassName={getCellClassName}
	                    onCell={onCell}
	                    fieldid={fieldid}
	                    locale={locale}
	                    isExpandedRow={isExpandedRow}
	                />
	            );
	        }
	    }
	    const style = {height, ...record ? record.style : undefined};
	    if (!visible) {
	        style.display = 'none';
	    }
	    const onRowObj = onRow && typeof onRow === 'function' ? onRow(record, index) : {}
	    const { key = null } = record || {};
	    let fieldidAttr = fieldid && key === 'table_sum' ? { fieldid: 'total-row' } : fieldid && isExpandedRow ? { fieldid: 'expanded-row' } : {}
	    return (
	        <tr
	            draggable={rowDraggAble && !useDragHandle && !notRowDrag}
	            onClick={this.onRowClick}
	            onDoubleClick={this.onRowDoubleClick}
	            onMouseEnter={this.onMouseEnter}
	            onMouseLeave={this.onMouseLeave}
	            className={classes}
	            style={style}
	            data-for-table={this.props.tableUid}
	            data-row-key={this.getRecordKey()}
	            data-row-index={this.props.fixedIndex}
	            // key={hoverKey}
	            ref={this.bindElement}
	            {...fieldidAttr}
	            {...onRowObj}
	        >
	            {cells.length > 0 ? cells : <td style={{width: 0, padding: 0}}></td>}
	            {/* {cells.length > 0 ? cells : isEmptyTr ? // loading暂时去掉，还原*/}
	            {/*    <td style={{width: 0,padding: 0}}>*/}
	            {/*    </td> : <td style={{width: 0,padding: 0}}>*/}
	            {/*    </td>}*/}
	        </tr>
	    );
	}
}

export default TableRow;
