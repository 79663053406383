import cx from 'classnames';
import moment, {Moment} from 'moment';
import React, {Component} from 'react';
import { OrNull } from '../../wui-core/src/utils/type';
import type {EventObject, CalendarNode, EventObjectT, EventObjectO, EventObjectInput, CalendarHourBodyProps} from './iCalendar';
import {compare, separaTime, upDateItemsFrame, splitFirst} from './util/utils'
import ResizeObserver from 'resize-observer-polyfill';

// 日视图中事件最小高度
const minHeight = 13;
// 日视图事件宽度小于28px时不显示内容
const nullWidth = 28;
// 日视图事件不足以显示两个字时不显示内容显示省略号
const elsWidth = 58;
class CalendarHourBody extends Component<CalendarHourBodyProps, {divWidth: number}> {
    // 存储计算非全天事件渲染百分比时对应的节点
    private widthDiv: OrNull<HTMLDivElement> = null;
    resizeObserver: OrNull<ResizeObserver> = null;
    constructor(props: CalendarHourBodyProps, context: {}) {
        super(props, context);

        this.state = {
            divWidth: 0
        }
    }
    // 确保初次渲染时widthDiv不为null
    componentDidMount() {
        this.setState({ divWidth: this.widthDiv ? this.widthDiv.offsetWidth : this.state.divWidth})
        if (this.widthDiv) {
	        this.resizeObserver = new ResizeObserver(() => {
                this.setState({ divWidth: this.widthDiv ? this.widthDiv.offsetWidth : this.state.divWidth})
	        });
	        this.resizeObserver.observe(this.widthDiv)
	    }
    }
    componentWillUnmount() {
        if (this.resizeObserver) {
	        this.resizeObserver.disconnect();
	    }
    }
    componentDidUpdate() {
        if (this.widthDiv?.offsetWidth !== this.state.divWidth) {
            this.setState({ divWidth: this.widthDiv ? this.widthDiv.offsetWidth : this.state.divWidth})
        }
    }
    // 渲染当前时间线
    renderCurrentTime = () => {
        const {current, clsPrefix} = this.props;
        const currTime = current.format('HH:mm')
        // 当前时间线对应的位置
        const top = Number(current.format('HH')) * 60 + Number(current.format('mm'))
        // 分别渲染当前时间线，当前时间线边缘点，当前时间
        const line = (<div className={`${clsPrefix}-curr-line`} style={{top: top}}/>)
        const circle = (<div className={`${clsPrefix}-curr-circle`} style={{top: top}}/>)
        const time = (<div className={`${clsPrefix}-curr-time`} style={{top: top - 8}}>{currTime}</div>)
        return (
            <React.Fragment key={currTime}>
                {line}
                {circle}
                {time}
            </React.Fragment>
        )
    }
    // 渲染非全天事件
    renderDayEvents = (timeEvents: EventObject[]) => {
        const {clsPrefix, layout} = this.props;
        const value = moment(this.props.value).format('YYYY-MM-DD');
        let dayArr: EventObjectT[] = [];
        // 获取所有非全天事件
        timeEvents?.forEach((item: EventObject) => {
            let startDate = splitFirst(moment(item.start).format('YYYY-MM-DD HH:mm'))[0],
                endDate = splitFirst(moment(item.end).format('YYYY-MM-DD HH:mm'))[0],
                startTime = splitFirst(moment(item.start).format('YYYY-MM-DD HH:mm'))[1],
                endTime = splitFirst(moment(item.end).format('YYYY-MM-DD HH:mm'))[1];
            startDate == endDate && value == startDate ? dayArr.push({date: startDate, start: startTime, end: endTime, content: item.content}) : null
        })
        if (dayArr.length !== 0) {
            let dom: JSX.Element[] = [];
            // 通过setAlgor算法计算所有事件width，left
            let temp = this.setAlgor(dayArr);
            let style = layout == 'left' ? {width: 'calc(100% - 85px)'} : {width: 'calc(100% - 78px)'}
            return (
                <div className={`${clsPrefix}-day-events`} style={style}>
                    {
                        temp.map((item: CalendarNode, index: number) => {
                            let tempp = (item: CalendarNode) => {
                                let startPos = item.data[0].hour * 60 + item.data[0].minute + 1;
                                let height = item.data[1].hour * 60 + item.data[1].minute - (item.data[0].hour * 60 + item.data[0].minute) - 2;
                                let content;
                                // 设置最小高度minHeight
                                height = height < minHeight ? minHeight : height;
                                if (item.arguments.width / 100 < nullWidth / (this.widthDiv ? this.widthDiv.offsetWidth : this.state.divWidth)) {
                                    // 小于一定宽度，内部不显示任何文本
                                    content = null
                                } else if (item.arguments.width / 100 < elsWidth / (this.widthDiv ? this.widthDiv.offsetWidth : this.state.divWidth)) {
                                    // 小于两个字宽度时，显示...
                                    content = '...'
                                } else {
                                    content = item.arguments.content
                                }
                                // 设置layout不同取值下对应的style样式
                                let style = layout == "left" ? {width: item.arguments.width + '%', left: `calc(${item.arguments.left}% + 75px)`, top: startPos, height: height, lineHeight: `${height}px`}
                                    : {width: item.arguments.width + '%', right: `calc(${item.arguments.left}% + 68px)`, top: startPos, height: height, lineHeight: `${height}px`};
                                // 点击非全天事件时打印的参数
                                let itemTemp = {start: value + ' ' + item.data[4][0], end: value + ' ' + item.data[4][1], content: item.data[3]}
                                if (item.children.length == 0) {
                                    dom.push(
                                        <div key={item.level + startPos} className={`${clsPrefix}-day-events-item`} onClick={(e) => this.onTimeEventsClick(e, itemTemp, moment(moment(this.props.value).format('YYYY-MM-DD') + ' ' + item.data[4][0]))}
                                            style={style}
                                        >{content}</div>
                                    )
                                } else {
                                    item.children.forEach((i: CalendarNode) => tempp(i))
                                    dom.push(
                                        <div key={item.level + startPos} className={`${clsPrefix}-day-events-item`} onClick={(e) => this.onTimeEventsClick(e, itemTemp, moment(moment(this.props.value).format('YYYY-MM-DD') + ' ' + item.data[4][0]))}
                                            style={style}
                                        >{content}</div>
                                    )
                                }
                            }
                            tempp(item);
                            if (index == temp.length - 1) return dom
                        })
                    }
                </div>
            )
        }
    }
    // 通过该算法，获得对应非全天事件的width、left
    setAlgor = (timeEvents: EventObjectO[]) => {
        // 根据开始时间将所有非全天事件排序
        timeEvents.sort(function(x, y) {
            let a = separaTime(x.start, x.end).begin.hour + ':' + separaTime(x.start, x.end).begin.minute;
            let b = separaTime(y.start, y.end).begin.hour + ':' + separaTime(y.start, y.end).begin.minute;
            return compare(a, b)
        })
        let nodeArr: CalendarNode['data'][] = [];
        timeEvents.forEach((item, index) => {
            let temp = separaTime(item.start, item.end);
            nodeArr.push([temp.begin, temp.end, index, item.content, [temp.begin.beginTime, temp.end.endTime]])
        })
        let domWidth = this.widthDiv?.offsetWidth || this.state.divWidth;
        return upDateItemsFrame(nodeArr, domWidth)
    }
    onTimeEventsClick = (e: React.MouseEvent<HTMLElement>, value: EventObjectInput, time: Moment) => {
        if (this.props.onTimeEventsClick) {
            this.props.onTimeEventsClick(e, value, time)
        }
    }
    // 渲染body内部，每一份代表一小时，每小时又均分为15分钟
    renderFullBody = () => {
        const {clsPrefix, fieldid, timeEvents, showTimeLine} = this.props;
        const value = moment(this.props.value);
        return (
            <div className={`${clsPrefix}-full-body`}>
                <div ref={(ref) => this.widthDiv = ref ? ref : this.widthDiv} className={`${clsPrefix}-full-body-container`}>
                    {value.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && showTimeLine !== false ? this.renderCurrentTime() : null}
                    <ul>
                        {[...Array(24).keys()].map(item => {
                            // 每小时分为4份，一份15分钟
                            let time = item < 10 ? "0" + item : String(item);
                            return [":00", ":15", ":30", ":45"].map((it: string) => {
                                // 点击每一块区域打印的参数，包含这块区域开始的事件
                                let outPutValue = {start: value.format('YYYY-MM-DD') + ' ' + (time + it)};
                                return (
                                    <li key={item + it} fieldid={fieldid ? `${fieldid}_bodyTime_${time}_${it.slice(1)}` : undefined} className={cx({[`${clsPrefix}-last-item`]: it == ':45'})} onClick={(e) => this.onTimeEventsClick(e, outPutValue, moment(value.format('YYYY-MM-DD') + ' ' + outPutValue.start))}></li>
                                )
                            })
                        })}
                        {timeEvents ? this.renderDayEvents(timeEvents) : null}
                    </ul>
                </div>
            </div>
        )
    }
    render() {
	    return (
            this.renderFullBody()
        )
    }
}

export default CalendarHourBody;