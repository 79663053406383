module.exports = {
    lang: 'zh-cn',
    msgMax: '值不能大于最大值',
    msgMin: '值不能小于最小值',
    'zh-cn': {
        msgMax: '值不能大于最大值',
        msgMin: '值不能小于最小值'
    },
    'en-us': {
        msgMax: 'Cannot be greater than the Max value',
        msgMin: 'Cannot be less than the Min value'
    },
    'zh-tw': {
        msgMax: '值不能大於最大值',
        msgMin: '值不能小於最小值'
    },
    'vi-vn': {
        msgMax: 'Giá trị không thể lớn hơn mức tối đa',
        msgMin: 'Giá trị không thể thấp hơn giá trị tối thiểu'
    }
};
