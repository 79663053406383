export default {
    'lang': 'zh-cn',
    'loading': '加载中...',
    'zh-cn': {
        'loading': '加载中...',
    },
    'en-us': {
        'loading': 'Loading...',
    },
    'zh-tw': {
        'loading': '加載中...',
    },
    'vi-vn': {
        'loading': 'Đang tải...',
    },
}
