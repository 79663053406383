/**
 * Created by Dio.Zhu on 2016/9/3.
 */
import React, { ReactInstance, ReactNode } from 'react';
// import _cloneDeep from 'clone';
// import TreeUtils from "../../wui-core/src/TreeUtils";
import Utils from './Utils';
import { CreateViewProps, CreateViewState } from './iDynamicView';
const _assign = Utils.assign;
const _isArray = Utils.isArray;
// const _isFunction = Utils.isFunction;
const _forEach = Utils.forEach;
// const _startsWith = Utils.startsWith;
const _isEmpty = Utils.isEmpty;

//  是否存在运行参数表达式
// const hasParamPatten = (str: string) => {
//     var patten = new RegExp('!{.+}', 'gm');
//     return patten.test(str);
// };

//  格式化URL路径
// const formatUrlPath = function(baseHost: string, url?: string) {
//     if (baseHost && url) {
//         if (_startsWith(url, 'http') || _startsWith(url, 'https')) {
//             return url;
//         } else {
//             return baseHost + (_startsWith(url, '/') ? '' : '/') + url;
//         }
//     } else {
//         return url;
//     }
// };

//  存放原始数据的View，界面不可见。
class EmptyView extends React.Component {
    render() {
        return null;
    }
}

export default class CreateView extends React.Component<CreateViewProps, CreateViewState> {
     static RUN_MODE_RUNTIME = 'runtime';//  运行态
     static RUN_MODE_DESIGN = 'design';//  设计态
     static defaultProps = {
         appCode: null,
         pageCode: null,
         billTypeCode: null, // 单据类型标识
         jsPluginUrl: null, // 二开扩展的js代码文件路径
         baseHost: null, // 缺省的域名地址,如果jsPluginUrl使用的相对地址则默认使用此域名地址
         uiMeta: null, // UI元数据
         uiParser: null, // UI组件解析器{"ComponentName":Component,...}
         uiEvent: null, // UI事件处理器{"uikey":{"eventName":Function,...},...}
         uiParams: null, // UI参数解析器{"ParamName":Function,...}
         uiRunMode: "runtime", // 运行模式，默认为运行态-"runtime"，设计态-"design"
         // --------------------------------------
         onCreateParser: null, // 自定义动态注册视图解析器
         onViewAuthValidate: null, // 视图权限校验的回调函数
         globalParser: null, // 全局缺省视图解析器object|function
         globalEvent: null, // 全局缺省事件解析器object|function
         globalParams: null, // 全局缺省参数解析器object|function
         globalActions: null, // 全局缺省动作解析器object|function
         onWillReceiveProps: null, // 可扩展生命周期逻辑-WillReceiveProps
         onWillMount: null, // 可扩展生命周期逻辑-WillMount
         onWillUpdate: null, // 可扩展生命周期逻辑-WillUpdate
         onDidMount: null, // 可扩展生命周期逻辑-DidMount
         onDidUpdate: null, // 可扩展生命周期逻辑-DidUpdate
     };
     private refItems = {};
     public refs: {
         [key: string]: ReactInstance
     } = {};
     constructor(props: CreateViewProps) {
         super(props);
         let uiParams = this._processUIParams(props.uiParams!);
         let uiParser = this._processUIParser(props.uiParser);
         let uiEvent = this._processUIEvent(props.uiEvent);
         let uiMeta = this._processUIMeta(props.uiMeta);
         this.refItems = {};
         // this.refs = {};
         this.state = {
             uiParams, uiMeta, uiParser, uiEvent,
             refreshTime: 0// 记录刷新次数
         }
     }

     componentWillReceiveProps(nextProps: CreateViewProps) {
         if (('uiParams' in nextProps) || ('uiParser' in nextProps) || ('uiEvent' in nextProps) || ('uiMeta' in nextProps)) {
             let uiParams = this._processUIParams(nextProps.uiParams!);
             let uiParser = this._processUIParser(nextProps.uiParser);
             let uiEvent = this._processUIEvent(nextProps.uiEvent);
             let uiMeta = this._processUIMeta(nextProps.uiMeta);
             this.setState({
                 uiParams, uiMeta, uiParser, uiEvent
                 , refreshTime: this.state.refreshTime! + 1
             });
         }
         //  if (typeof this.props.onWillReceiveProps == 'function') {
         //      this.props.onWillReceiveProps.call(this, nextProps);
         //  }
     }

     // 性能优化，只有当refreshTime发生变更时才触发执行render方法
     shouldComponentUpdate(_nextProps: CreateViewProps, nextState: CreateViewState) {
         return this.state.refreshTime !== nextState.refreshTime;
     }

     //  componentWillMount() {
     //      // console.log('YYCreateView will mount');
     //      let { uiMeta, uiEvent } = this.state;
     //      this._callEvent('onViewWillMount', { uiMeta, uiEvent });
     //      if (typeof this.props.onWillMount == 'function') {
     //          this.props.onWillMount.call(this);
     //      }
     //  }

     componentDidMount() {
         // console.log('YYCreateView did mount');
         let { uiMeta } = this.state;
         // let page = this as any;// 注意eval里面会使用到此变量
         // 运行逻辑公式
         if (uiMeta && uiMeta.logicFormula) {
             try {
                 let runTime = [];
                 runTime.push("this._logicFormulaRunTime = function(yy){");
                 //  runTime.push("console.log('yy-->',this,yy);");
                 runTime.push(uiMeta.logicFormula);
                 runTime.push("};");
                 runTime.push("this._logicFormulaRunTime({page:page});");
                 let jsCodeStr = runTime.join("");
                 //  console.log('------运行代码-----');
                 //  console.log(jsCodeStr);
                 eval(jsCodeStr);
             } catch (e) {
                 //  console.error('逻辑公式运行出错', e);
             }
         }
         // 可扩展生命周期不提供
         //  this._callEvent('onViewDidMount', { uiMeta, uiEvent });
         //  if (typeof this.props.onDidMount == 'function') {
         //      this.props.onDidMount();
         //  }
     }

     //  componentWillUpdate(nextProps: CreateViewProps, nextState: CreateViewState) {
     //      // console.log('YYCreateView will update');
     //      let { uiMeta, uiEvent } = nextState;
     //      this._callEvent('onViewWillUpdate', { uiMeta, uiEvent });
     //      if (typeof this.props.onWillUpdate == 'function') {
     //          this.props.onWillUpdate(nextProps, nextState);
     //      }
     //  }

     //  componentDidUpdate(prevProps: CreateViewProps, prevState: CreateViewState) {
     //      // console.log('YYCreateView did update');
     //      let { uiMeta, uiEvent } = prevState;
     //      this._callEvent('onViewDidUpdate', { uiMeta, uiEvent });
     //      if (typeof this.props.onDidUpdate == 'function') {
     //          this.props.onDidUpdate(prevProps, prevState);
     //      }
     //  }

     // 回调指定的事件
     //  _callEvent(eventName: string, { uiMeta, uiEvent }: any) {
     //      if (!uiMeta || !uiEvent) return;
     //      for (let uikey in uiEvent) {
     //          let itemEvents = uiEvent[uikey];
     //          if (itemEvents && _isFunction(itemEvents[eventName])) {
     //              let itemUI = this._findUI({ uiMeta, uikey });// 匹配到组件才调用事件
     //              if (itemUI) {
     //                  try {
     //                      let _itemUI = itemEvents[eventName].call(this, { uiMeta, item: itemUI });
     //                      if (_itemUI) itemUI = _itemUI;
     //                  } catch (err) {
     //                      //  console.error('[事件回调失败]' + uikey + '.' + eventName, err);
     //                  }
     //              }
     //          }
     //      }
     //  }

     // 环境参数助手方法
     _processUIParams(uiParams: {[key: string]: any}) {
         const { globalParams } = this.props;
         let _globalParams = typeof globalParams == 'function' ? globalParams() : globalParams;
         let _uiParams = _assign({}, _globalParams || {}, uiParams);
         // globalParams、uiParams属性先不下沉所以这里一直是空对象，下面操作没有意义
         //  for (let key in _uiParams) {
         //      let param = _uiParams[key];
         //      if (_isFunction(param)) {// 处理函数类型的动态参数
         //          _uiParams[key] = param.call(this);
         //      }
         //  }
         return _uiParams;
     }

     // 元数据助手方法
     _processUIMeta(uiMeta: any) {
         let _uiMeta = uiMeta;
         return _uiMeta;
     }

     // 解析器助手方法
     _processUIParser(uiParser: any) {
         let { globalParser } = this.props;
         let _globalParser = (typeof globalParser == 'function') ? globalParser() : globalParser;
         let _uiParser = _assign({}, _globalParser || {}, uiParser);
         return _uiParser;
     }

     // 深度克隆
     //  deepClone = (val: any) => {
     //      let ret = new Object();
     //      for (let p in val) {
     //          ret[p] = this.deepClone.call(val[p], null);
     //      }
     //      return ret
     //  }

     // 事件助手方法
     _processUIEvent(uiEvent: any) {
         let { globalEvent } = this.props;
         let _globalEvent = (typeof globalEvent == 'function') ? globalEvent() : globalEvent;
         // 注意此次需要克隆，以防止引用共享
         let copyDefault = _globalEvent ? {..._globalEvent} : {};
         let copyUiEvent = {...uiEvent}
         let _uiEvent = _assign(copyDefault, copyUiEvent);
         for (let uikey in _uiEvent) {
             let itemEvents = _uiEvent[uikey];
             for (let name in itemEvents) {
                 try {
                     itemEvents[name] = itemEvents[name].bind(this);
                 } catch (err) {
                     //  console.error('[绑定事件失败]' + uikey + '.' + name + ',检查是否为有效的Function', err);
                 }
             }
         }
         return _uiEvent;
     }

     /**
      * 执行多个表单的验证
      * @param options{
      *  formKeys:[]  // 需要进行验证的表单键值集合
      *  keepAll:true // 当设置为false时，验证过程中任意一表单存在错误则不再继续进行验证
      *  success:null // 所有表单都验证通过时触发的回调
      *  error:null   // keepAll=true时，所有表单验证后存在错误则触发回调；keepAll=false时，当任意一个表单验证不通过时触发回调；
      * }
      */
     // 未使用的方法
     //  validateForms(options: any) {
     //      let page = this as any;
     //      let { formKeys, keepAll, _index, formValues, formErrors, success, error } = _assign({
     //          formKeys: [],
     //          keepAll: true,
     //          _index: 0,
     //          formValues: {},
     //          formErrors: {},
     //          success: null,
     //          error: null
     //      }, options);
     //      if (_index >= formKeys.length) { //  所有表单校验完毕
     //          if (_isEmpty(formErrors)) {
     //              if (_isFunction(success)) success({ formValues: formValues });
     //          } else {
     //              if (_isFunction(error)) error({ formErrors, formValues });
     //          }
     //          return;
     //      }
     //      let formKey = formKeys[_index];
     //      let form = page.findUI(formKey);
     //      if (form && form.api && form.api.validateFields) {
     //          form.api.validateFieldsAndScroll((errors: any, values: any) => {
     //              formValues[formKey] = values;
     //              if (errors) {// 验证失败
     //                  formErrors[formKey] = errors;
     //                  if (keepAll === true) {// 任意表单出错任继续验证
     //                      page.validateForms(_assign(options, {
     //                          formValues: formValues,
     //                          formErrors: formErrors,
     //                          keepAll,
     //                          _index: _index + 1
     //                      }));
     //                  } else {// 任意表单出错则终止
     //                      if (_isFunction(error)) {
     //                          error({ formErrors, formValues, formKey, formIndex: _index });
     //                      }
     //                      return;
     //                  }
     //              } else {// 验证通过，则继续验证
     //                  page.validateForms(_assign(options, {
     //                      formValues: formValues,
     //                      formErrors: formErrors,
     //                      keepAll,
     //                      _index: _index + 1
     //                  }));
     //              }
     //          });
     //      }
     //  }

     // /**
     //  * 刷新重绘页面
     //  */
     // refresh(callback, state) {
     //     this.setState(_assign({ refreshTime: this.state.refreshTime + 1 }, state), callback);
     // };

     // /**
     //  * 获取当前页面
     //  */
     // getPage() {
     //     return this
     // }

     // /**
     //  * 获取应用编码
     //  */
     // getAppCode() {
     //     return this.props.appCode;
     // }

     // /**
     //  * 获取页面编码
     //  */
     // getPageCode() {
     //     return this.props.pageCode;
     // }

     // /**
     //  * 获取页面单据类型
     //  */
     // getBillTypeCode() {
     //     return this.props.billTypeCode;
     // }

     // /**
     //  * 获取扩展的js插件文件路径
     //  */
     // getJsPluginUrl(format = true) {
     //     return format ? formatUrlPath(this.props.jsPluginUrl) : this.props.jsPluginUrl;
     // }

     // /**
     //  * 路由到指定页面
     //  * @param url   路由路径
     //  * @param query URL附加的参数（显示参数）
     //  * @param state 路由附加的参数（隐藏参数）
     //  */
     // routeTo = (url: string, query: string, state: any) => {
     //     if (!this.props.router) {
     //         console.error('调用routeTo失败,router不存在');
     //         return
     //     }
     //     let location = {};
     //     if (url) location.pathname = url;
     //     if (query) location.query = query;
     //     if (state) location.state = state;
     //     // this.props.history.push(url,_params);
     //     //  this.props.history.push(location);
     //     this.props.router.push(location); //  Router版本升级修改
     // };
     /**
      * 获取路由传递的参数值（隐藏参数）
      */
     // getRouteParams = () => {
     //     if (!this.props.location && !this.props.params) {
     //         console.error('调用getRouteParams失败,location和params均不存在');
     //         return
     //     }
     //     return _isEmpty(this.props.location.state) ? (_isEmpty(this.props.params) ? null : this.props.params) : this.props.location.state;
     // };
     /**
      * 获取路由传递的URL参数值（直接链接在URL的?后面的参数列表）（显示参数）
      */
     // getRouteQuery = () => {
     //     if (!this.props.location) {
     //         console.error('调用getRouteQuery失败,location不存在');
     //         return
     //     }
     //     return _isEmpty(this.props.location.query) ? null : this.props.location.query;
     // };
     /**
      * 后退上一个页面
      */
     // goBack = () => {
     //     if (!this.props.router) {
     //         console.error('调用goBack失败,router不存在');
     //         return
     //     }
     //     this.props.router.goBack();
     // };
     /**
      * 前进下一个页面
      */
     // goForward = () => {
     //     if (!this.props.router) {
     //         console.error('调用goForward失败,router不存在');
     //         return
     //     }
     //     this.props.router.goForward();
     // };
     /**
      * 后退或前进到第N个页面
      * @param to n为正数则为前进，n为负数则为后退
      */
     // goTo = (to) => {
     //     if (!this.props.router) {
     //         console.error('调用goTo失败,router不存在');
     //         return
     //     }
     //     this.props.router.go(to);
     // };
     /**
      * 通过键值查找UI对象模型
      * @param uikey
      */
     //  findUI = (uikey: string) => {
     //      if (this.state.uiMeta) {
     //          let result = this._findUI({ uiMeta: this.state.uiMeta, uikey: uikey });
     //          //  if (!result) console.warn('[api.findUI]找不到：' + uikey);
     //          return result;
     //      }
     //      return null;
     //  }

     //  _findUI(options: {uiMeta: CreateViewState['uiMeta']; uikey: string}) {
     //      let { uiMeta, uikey } = options;
     //      let _uiMeta = _isArray(uiMeta) ? uiMeta : [uiMeta];
     //      let results = TreeUtils.findWithPropName(_uiMeta, 'uikey', uikey, true, true);
     //      if (results && results.length > 0) {
     //          results[0].api = this._findByRef(results[0].nid);// 扩展API对象
     //          return results[0];
     //      }
     //      return null;
     //  }

     /**
      * 通过类型查找UI数据模型
      * @param uitype 解析器名称
      * @param options {
      *     loop 默认：true，是否递归遍历子集合
      *     only 默认：false，是否匹配到第一个就终止
      * }
      */
     // findUIByType = (uitype, options) => {
     //     let opts = _assign({ loop: true, only: false }, options);
     //     if (this.state.uiMeta) {
     //         let _uiMeta = _isArray(this.state.uiMeta) ? this.state.uiMeta : [this.state.uiMeta];
     //         let results = TreeUtils.findWithPropName(_uiMeta, 'uitype', uitype, opts.loop, opts.only);
     //         if (results && results.length > 0) {
     //             let that = this;
     //             _forEach(results, function(item, index) {
     //                 results[index].api = that._findByRef(item.nid);// 扩展API对象
     //             });
     //             return results;
     //         }
     //     }
     //     return null;
     // };

     // ref值指向nid值
     //  _findByRef(ref: string) {
     //      // TODO 目前存在缺陷，在折叠卡片页签第一次未展开时，无法通过refs获取内部组件
     //      // eslint-disable-next-line react/no-string-refs
     //      return ref && this.refItems[ref] ? this.refItems[ref] : this.refs[ref] ? this.refs[ref] : null;// 对象refs是为了兼容react15版本
     //  }

     /**
      * 通过键值查找UI组件实例
      * @param uikey
      */
     // findComp = (uikey) => {
     //     if (this.state.uiMeta) {
     //         let _uiMeta = _isArray(this.state.uiMeta) ? this.state.uiMeta : [this.state.uiMeta];
     //         let results = TreeUtils.findWithPropName(_uiMeta, 'uikey', uikey, true, true);
     //         return this._findByRef(results.length > 0 ? results[0].nid : null);
     //     }
     //     return null;
     // };

     // 环境参数替换 (因this.state.uiParams值一直为{}，所以_isEmpty(uiParams)为true，主要看_processUIParams方法，初始化)
     //  _processParams(node: {[key: string]: any}) {
     //      let uiParams = this.state.uiParams;
     //      if (_isEmpty(uiParams)) return;
     //      if (!node) return;
     //      let keys = [];
     //      // 过滤出包含运行参数的属性
     //      for (let key in node) {
     //          let value = node[key];
     //          if (value && (typeof value == 'string') && hasParamPatten(value)) {
     //              keys.push(key);
     //          }
     //      }
     //      // 进行运行参数替换
     //      if (keys.length == 0) return;
     //      for (let idx in keys) {
     //          let propName = keys[idx];
     //          let propValue = node[propName];
     //          if (!propValue) break;
     //          for (let paramKey in uiParams) {
     //              node[propName] = propValue.replace(new RegExp('!{' + paramKey + '}', 'gm'), uiParams[paramKey]);
     //              if (!hasParamPatten(node[propName])) break;// 性能优化，如果替换一次后，就不存在需要替换的参数则跳过本次。
     //          }
     //      }
     //  }

     // 获取解析后的视图
     _getViewContent(options: { uiMeta: CreateViewState['uiMeta'], uiParser: CreateViewState['uiParser'], uiEvent: CreateViewState['uiEvent'] }) {
         // console.log('第'+this.state.refreshTime+'次进行视图解析');
         let { uiMeta, uiEvent, uiParser } = options;
         let views = null;
         if (uiMeta) {
             if (_isArray(uiMeta)) {
                 views = this._processViews(uiMeta, uiEvent, uiParser);
             } else {
                 views = this._processViews([uiMeta], uiEvent, uiParser);
             }
         }
         if (views && views.length > 0) {
             return _isArray(uiMeta) ? views : views[0];
         } else {
             return null;
         }
     }

     // 解析视图
     _processViews(items: CreateViewState['uiMeta'], uiEvent: CreateViewState['uiParser'], uiParser: CreateViewState['uiEvent']) {
         if (!_isArray(items)) return null;
         let views = [];
         let { uiRunMode, onCreateParser, globalActions, appCode, pageCode, billTypeCode } = this.props;
         let _globalActions = typeof globalActions == 'function' ? globalActions() : globalActions;
         let that = this;
         for (let i = 0; i < items.length; i++) {
             let itemUI = items[i];
             if (!itemUI || _isEmpty(itemUI)) continue;
             // 解析子视图
             let childView = itemUI.children;
             let doEachChild = _isArray(itemUI.children);
             if (doEachChild) {
                 childView = this._processViews(itemUI.children, uiEvent, uiParser);
             }
             // 解析视图事件
             let itemEvents: {[key: string]: (ui: any, event: any, actions: any[]) => void} | null = null;
             if (itemUI.uikey && uiEvent) {
                 itemEvents = uiEvent[itemUI.uikey];
             }
             // 解析事件动作
             if (itemUI.uievents) {
                 if (!itemEvents) itemEvents = {};
                 // 解析绑定的所有事件
                 _forEach(itemUI.uievents, function(uiactions, uievent) {
                     // 解析对应事件下的动作列表
                     itemEvents![uievent] = function(ui: any, event: any, actions: any[]) {
                         for (let actSeq = 0; actSeq < actions.length; actSeq++) {
                             let action = actions[actSeq];
                             // 获取动作函数
                             let actionFunc = _globalActions ? _globalActions[action.uiaction] : null;
                             // 执行动作处理
                             if (typeof actionFunc == 'function') {
                                 actionFunc.call(this, { event, action, ui });
                             }
                         }
                     }.bind(that, itemUI, uievent, uiactions);
                 });
             }
             // // 解析前的回调事件
             // if(itemEvents&&_isFunction(itemEvents['onParserBefore'])){
             //     let _itemUI = itemEvents['onParserBefore'].call(that,itemUI);
             //     if(_itemUI)itemUI = _itemUI;
             // }
             // 解析当前组件
             let UIComponent = null;
             if (typeof onCreateParser == 'function') {
                 UIComponent = onCreateParser({ item: itemUI });
             }
             if (!UIComponent) {
                 UIComponent = itemUI.uitype ? uiParser![itemUI.uitype] : null;
             }

             // 清理掉API对象
             delete itemUI.api;
             // 当前组件属性
             let itemProps = _assign({
                 // 为所有组件追加uiorigin属性，本属性提供了界面设计器解析构建页面时的特有属性
                 uiorigin: { appCode, pageCode, billTypeCode }
             }, itemUI);
             // 环境参数替换
             //  this._processParams(itemProps);
             // 标识运行模式
             if (uiRunMode) itemProps.uirunmode = uiRunMode;// 注意react16下校验html元素属性大写会给予警告日志
             // 添加到视图集合，当uidisabled==true时，则不进行组件解析，返回原始数据。
             if (itemUI.uidisabled == true) {// 不进行组件解析
                 views.push(itemProps);
             } else if (UIComponent) {// 存在相关组件解析器
                 // 将当前UI元数据绑定到组件的ui属性
                 let privateProps: {[key: string]: any} = {};
                 if (itemUI.nid) {
                     privateProps.ref = (t: HTMLElement) => this.refItems[itemUI.nid] = t;
                     if (!itemUI.key) {
                         privateProps.key = pageCode + "_" + itemUI.nid;
                     }
                 }
                 let itemViewProps = _assign({}, itemProps, privateProps, itemEvents);
                 let itemView = null;
                 // 处理视图是否拥有权限
                 let authInfo = this.isHasAuthorityView({ item: itemUI, appCode, pageCode });
                 if (authInfo.enable) {// 已授权权限
                     itemView = React.createElement(UIComponent, itemViewProps, childView);
                 } else {// 未授权权限
                     if (authInfo.viewMode == 'disabled') {// 未授权时显示为禁用
                         itemViewProps.roleDisabled = true;
                         itemView = React.createElement(UIComponent, itemViewProps, childView);
                     } else {// 未授权时不显示
                         itemView = null
                     }
                 }
                 if (itemView) views.push(itemView);
                 // let itemView= <UIComponent {...itemProps} ref={itemUI.nid} key={itemKey} {...itemEvents} children={childView} />;
                 // // 解析后的回调事件
                 // if(itemEvents&&_isFunction(itemEvents['onParserAfter'])){
                 //     let _itemView = itemEvents['onParserAfter'].call(that,itemUI,itemView);
                 //     if(_itemView)itemView = _itemView;
                 // }
             } else {// 不存在相关组件解析器则返回包含元数据的空视图组件
                 let emptyView = React.createElement(EmptyView, itemProps);
                 views.push(emptyView);
             }
         }
         return views;
     }

     // 指定uikey键值的视图组件是否有权限
     isHasAuthorityView(options: { item: {nid: string;uidisabled: boolean; uitype: any; api: string; uievents: any[]; uikey: string; children: ReactNode}, appCode: string, pageCode: string }) {
         // enable-是否有授权权限、viewMode-当没有权限时的显示方式
         if (typeof this.props.onViewAuthValidate == 'function') {
             return this.props.onViewAuthValidate(options);
         } else {
             return { enable: true, viewMode: 'hidden' };
         }
     }

     render() {
         let { uiMeta, uiParser, uiEvent } = this.state;
         // 获取解析后的视图
         let viewContent = this._getViewContent({ uiMeta, uiParser, uiEvent });
         return viewContent;
     }
}
