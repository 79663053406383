/*
 * @Author: Mr.mjc
 * @Date: 2022-06-16 19:38:36
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2022-12-30 16:01:18
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/ColumnManager.tsx
 */
import React from 'react';
import Column from './Column';
import ColumnGroup from './ColumnGroup';
import { columnType, defaultDragHandleColumn } from './constant';
import { DefaultRecordType, FixedType } from './interface';
import { ColumnType, ShowRowNumType, ColumnsType } from './iTable';
import i18n from "./lib/i18n";
import {getLangInfo} from "../../wui-locale/src/tool";

// 行控制管理
export default class ColumnManager {
	columns: ColumnsType<DefaultRecordType>;
	// columns: ColumnType<DefaultRecordType>[];
	originWidth: number;
	_cached = {}
	static DefaultColumnWidth = 200;// 缺省的列宽度
	locale: any;
	constructor(columns: ColumnType<DefaultRecordType>[], elements: JSX.Element[], originWidth: number, rowDraggAble: boolean, showRowNum: boolean | ShowRowNumType, locale: any) {
	    if (columns && columns.length) {
	        columns = this.addDragHandleColumn(columns, rowDraggAble);
	        columns = this.addOrderColumn(columns, showRowNum, locale);
	        columns = this.deleteColumnNotShow(columns);
	    }
	    this.columns = columns && columns.length ? columns : this.normalize(elements);

	    this.originWidth = originWidth;
	}

	// 向数据列中添加一列:行拖拽标识
	addDragHandleColumn = (columns:ColumnType<DefaultRecordType>[], rowDraggAble: boolean) => {
	    if (!rowDraggAble) {
	        return columns
	    }
	    let currentColumn = columns.find(item => item.columnType === columnType.ROWDRAG);
	    let currentColumnIndex = columns.findIndex(item => item.columnType === columnType.ROWDRAG) || 0;
	    let dragHandleColumn: ColumnType<DefaultRecordType> = {
	        ...defaultDragHandleColumn,
	        ...(currentColumn || {})
	    }
	    if (!currentColumn) { // 外部未传拖拽列的配置
	        columns = [dragHandleColumn].concat(columns);
	    } else {
	        columns.splice(currentColumnIndex, 1, dragHandleColumn)
	    }
	    return columns;
	}

	// delete the column which does not show
	deleteColumnNotShow = (columns:ColumnType<DefaultRecordType>[]) => {
	    let len = columns.length;
	    for (let i = 0; i < len; i++) {
	        if (columns && columns[i] && (columns[i].ifshow === false || columns[i].isShow === false)) {
	            columns.splice(i, 1);
	            i--;
	        }
	    }
	    return columns;
	}

	// 向数据列中添加一列:序号
	addOrderColumn = (columns:ColumnType<DefaultRecordType>[], showRowNum: boolean | ShowRowNumType, locale: any):ColumnType<DefaultRecordType>[] => {
	    if (!showRowNum) {
	        return columns
	    }
	    let {key, fixed, width, name, type, base} = showRowNum as ShowRowNumType;
	    let _locale = getLangInfo(locale, i18n)
	    let order: ColumnType<DefaultRecordType> = {
	        dataIndex: key || '_index',
	        key: '_index',
	        fixed: fixed !== undefined ? fixed : 'left',
	        width: width || 50,
	        title: name || _locale.langMap.show_row_num || '序号',
	        render: (_text: any, _record: DefaultRecordType, index: number) => {
	            switch (type) {
	                case 'ascii': {
	                    // @ts-ignore
	                    return (String.fromCharCode((base || 'a')?.charCodeAt() + index));
	                }
	                case 'number':
	                default: {
	                    return (Number(base || 0)) + index;
	                }
	            }
	        }
	    }
	    if (columns.length > 0 && columns[0].dataIndex !== 'checkbox' && columns[0].dataIndex !== 'radio') { // 多选表格/单选表格时放在第二列,其他情况放到第一列
	        columns = [order].concat(columns);
	    } else {
	        columns.splice(1, 0, order); // splice方法改变原数组,返回切割出的数组,此处为[]
	    }
	    return columns;
	}

	isAnyColumnsFixed() {
	    return this._cache('isAnyColumnsFixed', () => {
	        return this.columns.some((column: ColumnType<DefaultRecordType>) => !!column.fixed);
	    });
	}

	isAnyColumnsLeftFixed() {
	    return this._cache('isAnyColumnsLeftFixed', () => {
	        return this.columns.some(
	            (column: ColumnType<DefaultRecordType>) => column.fixed === 'left' || column.fixed === true
	        );
	    });
	}

	isAnyColumnsRightFixed() {
	    return this._cache('isAnyColumnsRightFixed', () => {
	        return this.columns.some(
	            (column: ColumnType<DefaultRecordType>) => column.fixed === 'right'
	        );
	    });
	}

	leftColumns() {
	    return this._cache('leftColumns', () => {
	        return this.groupedColumns().filter(
	            (column: ColumnType<DefaultRecordType>) => column.fixed === 'left' || column.fixed === true
	        );
	    });
	}

	rightColumns() {
	    return this._cache('rightColumns', () => {
	        return this.groupedColumns().filter(
	            (column: ColumnType<DefaultRecordType>) => column.fixed === 'right'
	        );
	    });
	}

	centerColumns() {
	    return this._cache('centerColumns', () => {
	        return this.groupedColumns().filter(
	            (column: ColumnType<DefaultRecordType>) => !column.fixed
	        );
	    });
	}

	// 全部末级列（多表头下）节点
	leafColumns() {
	    return this._cache('leafColumns', () =>
	        this._leafColumns(this.columns as ColumnType<DefaultRecordType>[])
	    );
	}

	leftLeafColumns() {
	    return this._cache('leftLeafColumns', () =>
	        this._leafColumns(this.leftColumns())
	    );
	}

	rightLeafColumns() {
	    return this._cache('rightLeafColumns', () =>
	        this._leafColumns(this.rightColumns())
	    );
	}

	centerLeafColumns() {
	    return this._cache('centerLeafColumns', () =>
	        this._leafColumns(this.centerColumns())
	    );
	}

	// 获取显示的列，加入第二个参数, 判断是否需要全部列满足sticky版表格
	showLeafColumns(fixed?: FixedType, isAll?: boolean) {
	    let columns = [];
	    if (isAll) {
	        columns = this.leafColumns();
	    } else {
	        if (fixed) {
	            if (fixed == 'right') {
	                columns = this.rightLeafColumns();
	            } else {
	                columns = this.leftLeafColumns();
	            }
	        } else {
	            columns = this.centerLeafColumns();
	        }
	    }
	    let showColumns = columns.filter((col: ColumnType<DefaultRecordType>) => col.ifshow == true || col.isShow == true);
	    return showColumns;
	}

	// add appropriate rowspan and colspan to column
	groupedColumns(type?:string) {
	    return this._cache('groupedColumns', () => {
	        const _groupColumns = (columns: ColumnsType<DefaultRecordType>, currentRow: number = 0, parentColumn: ColumnType<DefaultRecordType> = {}, rows: any[] = []) => {
	            // track how many rows we got
	            rows[currentRow] = rows[currentRow] || [];
	            const grouped:ColumnsType<DefaultRecordType> = [];
	            const setRowSpan = (column:ColumnType<DefaultRecordType>) => {
	                const rowSpan = rows.length - currentRow;
	                if (column &&
						!column.children && // parent columns are supposed to be one row
						rowSpan > 1 &&
						(!column.rowSpan || column.rowSpan < rowSpan)
	                ) {
	                    column.rowSpan = rowSpan;
	                }
	            };
	            columns.forEach((column, index) => {
	                let defaultOpt: ColumnType<DefaultRecordType> = {
	                    ifshow: true,
	                    isShow: true
	                }
	                if (!this.originWidth) {
	                    defaultOpt.width = ColumnManager.DefaultColumnWidth;
	                }
	                // 获取非固定列
	                if (type == 'nofixed' && column.fixed) {
	                    return false;
	                }
	                const newColumn: ColumnType<DefaultRecordType> = {...defaultOpt, ...column};
	                rows[currentRow].push(newColumn);
	                parentColumn.colSpan = parentColumn.colSpan || 0;
	                if (newColumn.children && (newColumn.children as ColumnType<DefaultRecordType>[]).length > 0) {
	                    newColumn.children = _groupColumns((newColumn.children as ColumnType<DefaultRecordType>[]), currentRow + 1, newColumn, rows);
	                    parentColumn.colSpan = parentColumn.colSpan + (newColumn.colSpan || 0);
	                } else {
	                    parentColumn.colSpan++;
	                }
	                // update rowspan to all same row columns
	                for (let i = 0; i < rows[currentRow].length - 1; ++i) {
	                    setRowSpan(rows[currentRow][i]);
	                }
	                // last column, update rowspan immediately
	                if (index + 1 === columns.length) {
	                    setRowSpan(newColumn);
	                }
	                grouped.push(newColumn);
	            });
	            return grouped;
	        };
	        return _groupColumns(this.columns);
	    });
	}

	normalize(elements: JSX.Element[]) {
	    const columns:ColumnType<DefaultRecordType>[] = [];
	    React.Children.forEach(elements, element => {
	        if (!this.isColumnElement(element)) return;
	        let column = {...element.props};
	        let _columnType = column.columnType || columnType.DEFAULTTYPE;
	        if (element.key) {
	            column.key = element.key;
	        }
	        if (element.type === ColumnGroup) {
	            column.children = this.normalize(column.children);
	        }
	        if (_columnType === columnType.ROWDRAG) { // 拖拽行
	            column = {
	                ...defaultDragHandleColumn,
	                ...column
	            }
	        }
	        columns.push(column);
	    });
	    return columns;
	}

	isColumnElement(element: JSX.Element) {
	    return element && (element.type === Column || element.type === ColumnGroup);
	}

	reset(columns:ColumnType<DefaultRecordType>[], elements: JSX.Element[] | null | undefined, showRowNum: boolean | ShowRowNumType, rowDraggAble: boolean, locale: any) {
	    columns = this.addDragHandleColumn(columns, rowDraggAble);
	    columns = this.addOrderColumn(columns, showRowNum, locale);
	    columns = this.deleteColumnNotShow(columns);
	    this.columns = columns || (elements && this.normalize(elements));
	    this._cached = {};
	}

	clearCache() {
	    this._cached = {};
	}

	getColumnWidth(contentWidth: number) {
	    let columns = this.leafColumns();
	    let res = {computeWidth: 0, lastShowIndex: -1};
	    let centerShowColCount = 0;// 中间区域的可见列个数
	    columns.forEach((col:ColumnType<DefaultRecordType>) => {
	        // 如果列显示
	        if (col.ifshow || col.isShow) {
	            let width = col.width;
	            if (typeof (width) == 'string' && width.includes('%')) {
	                width = contentWidth * parseInt(col.width + '') / 100;
	            }
	            res.computeWidth += parseInt(width + '');
	            if (!col.fixed) {
	                centerShowColCount++;
	            }
	        }
	    })
	    res.lastShowIndex = centerShowColCount - 1;
	    return res;
	}

	getLeftColumnsWidth(contentWidth = 1) {
	    return this._cache('leftColumnsWidth', () => {
	        let leftColumnsWidth = 0;
	        this.groupedColumns().forEach((column:ColumnType<DefaultRecordType>) => {
	            if (column.fixed === 'left' || column.fixed === true) {
	                let width = column.width;
	                if (typeof (width) == 'string' && width.includes('%')) {
	                    width = contentWidth * parseInt(column.width + '') / 100;
	                }
	                leftColumnsWidth += parseInt(width + '')
	            }
	        });
	        return leftColumnsWidth;
	    });
	}

	getRightColumnsWidth(contentWidth = 1) {
	    return this._cache('rightColumnsWidth', () => {
	        let rightColumnsWidth = 0;
	        this.groupedColumns().forEach((column:ColumnType<DefaultRecordType>) => {
	            if (column.fixed === 'right') {
	                let width = column.width;
	                if (typeof (width) == 'string' && width.includes('%')) {
	                    width = contentWidth * parseInt(column.width + '') / 100;
	                }
	                rightColumnsWidth += parseInt(width + '')
	            }
	        });
	        return rightColumnsWidth;
	    });
	}

	getLeftColumnKeys() {
	    return this._cache('leftColumnKeys', () => {
	        return this.leftColumns().map((column: ColumnType<DefaultRecordType>) => {
	            return column.key || column.dataIndex
	        })
	    });
	}

	getRightColumnKeys() {
	    return this._cache('rightColumnKeys', () => {
	        return this.rightColumns().map((column: ColumnType<DefaultRecordType>) => {
	            return column.key || column.dataIndex;
	        })
	    });
	}

	// 通过key或dataIndex查找匹配的列定义（支持多表头的列定义情况）
	findColumn(columnKey: string | number) {
	    return this._findColumn(columnKey, this.columns || []);
	}

	_findColumn(columnKey: string | number, columns: ColumnType<DefaultRecordType>[]): any {
	    for (let i = 0; i < columns.length; i++) {
	        let findOne = null;
	        let column = columns[i];
	        if (!column) continue;
	        if (column.key == columnKey || column.dataIndex == columnKey) {// 兼容只有key的情况
	            findOne = column;
	        } else {
	            if (column.children) {
	                findOne = this._findColumn(columnKey, (column.children as ColumnType<DefaultRecordType>[]));
	            }
	        }
	        if (findOne) return findOne;
	    }
	}


	_cache(name: string, fn: any) {
	    if (name in this._cached) {
	        return this._cached[name];
	    }
	    this._cached[name] = fn();
	    return this._cached[name];
	}

	// todo 含有children的宽度计算
	_leafColumns(columns:ColumnType<DefaultRecordType>[]) {
	    const leafColumns:ColumnType<DefaultRecordType>[] = [];

	    columns.forEach(column => {
	        if (!column.children) {

	            let defaultOpt: ColumnType<DefaultRecordType> = {
	                ifshow: true,
	                isShow: true,
	            }
	            if (!this.originWidth) {
	                defaultOpt.width = ColumnManager.DefaultColumnWidth;
	            }
	            const newColumn = {...defaultOpt, ...column};
	            leafColumns.push(newColumn);
	        } else {
	            leafColumns.push(...this._leafColumns(column.children as ColumnType<DefaultRecordType>[]));
	        }
	    });
	    return leafColumns;
	}

    // _fixedInfoColumns(columns:ColumnType<DefaultRecordType>[]) {
    //     const leafColumns:ColumnType<DefaultRecordType>[] = [];
    //     this.leftColumns().forEach((item, index) => {
    //         item.style = Object.assign({}, item.style || {}, {
    // 			position: 'sticky';
    // 			left: 0 * item.width;
    //         })
    //     })
    //     columns.forEach(column => {
    //         if (!column.fixed) {
    //             return column
    //         } else {

    //         }
    //     });
    //     return leafColumns;
    // }
}
