import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/vi'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-tw'
import viVn from 'rc-picker/lib/locale/vi_VN'
import enUS from '../locale/en_US'
import zhCN from '../locale/zh_CN'
import zhTW from '../locale/zh_TW'
import {getLangInfo as getLangTool, langTransform} from './../../../wui-locale/src/tool'
import type {Locale as RcPickerLocale} from 'rc-picker/lib/interface'
import type {Lang} from '../../../wui-core/src/utils/type'
import type {Locale} from '../../../wui-locale/src/iLocale'

// 语言标识与内部语言包关系，TODO:此处其他语言包待丰富
const LANG_MAP: {[language: string]: any} = {
    en: enUS,
    'en-us': enUS,
    zh: zhCN,
    'zh-cn': zhCN,
    'zh-tw': zhTW,
    'vi-vn': viVn
}

/**
 * @desc 获取语言包信息
 * @param localeVal locale属性值
 * @param momentSync 是否同步变更moment语言
 */

export function getLangInfo(localeVal?: any, momentSync = true): {lang: Lang; langMap: RcPickerLocale} {
    const {lang = 'zh-cn'} = getLangTool(localeVal)
    if (momentSync) {
        moment.locale(lang)
    }
    const defaultLocale = LANG_MAP[lang] || zhCN
    return {
        // 默认中文
        lang: lang, // 语言类型
        langMap: typeof localeVal === 'object' ? Object.assign({}, defaultLocale, localeVal) : defaultLocale // 语言关系包,合并用户传入的locale
    }
}

// 兼容处理props.locale = {locale:'zh-cn'}情况，修复props.locale='zh-cn'变为'en'时，moment实例locale没有同步更新的问题
export function updateValueLocale(localeVal: string | Locale, value: any) {
    let lang = ''
    if (typeof localeVal === 'string') {
        lang = localeVal
    } else if (typeof localeVal === 'object') {
        if (localeVal.locale) {
            lang = localeVal.locale
        } else if (localeVal.lang) {
            lang = localeVal.lang as string
        }
    }
    lang = langTransform(lang)
    if (value && typeof value.locale === 'function') {
        value.locale(lang || 'zh-cn')
    }
}
