import classNames from 'classnames';
// import PropTypes from 'prop-types';
import * as React from 'react';
import {ConfigContext} from "../../wui-provider/src/context";
import { DividerProps } from './iDivider';

// const propTypes = {
//     type: PropTypes.oneOf(['horizontal', 'vertical']),
//     orientation: PropTypes.oneOf(['left', 'right', 'center']), // 文字位置
//     className: PropTypes.string,
//     children: PropTypes.element,
//     dashed: PropTypes.bool,
//     style: PropTypes.object,
//     fieldid: PropTypes.string,
// }

const defaultProps: DividerProps = {
    type: 'horizontal',
    orientation: 'center',
    dashed: false,
}


const Divider: React.FC<DividerProps> = props => {

    const {getPrefixCls} = React.useContext(ConfigContext);
    const {
        type,
        orientation = "center",
        className,
        children,
        dashed,
        ...restProps
    } = props;

    const prefixCls = getPrefixCls('divider');
    const orientationPrefix = orientation.length > 0 ? `-${orientation}` : orientation;
    const hasChildren = !!children;
    const classString = classNames(
        prefixCls,
        `${prefixCls}-${type}`,
        {
            [`${prefixCls}-with-text`]: hasChildren,
            [`${prefixCls}-with-text${orientationPrefix}`]: hasChildren,
            [`${prefixCls}-dashed`]: !!dashed,
            // [`${prefixCls}-rtl`]: direction === 'rtl',
        },
        className,
    );
    return (
        <div className={classString} {...restProps} role="separator">
            {children && <span className={`${prefixCls}-inner-text`}>{children}</span>}
        </div>
    );
};

// Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
