/*
 * @Author: Mr.mjc
 * @Date: 2022-06-15 17:39:30
 * @LastEditors: Mr.mjc
 * @LastEditTime: 2022-11-23 16:27:57
 * @Description:
 * @FilePath: /next-ui/packages/wui-table/src/lib/sort.tsx
 */
// import PropTypes from 'prop-types';
import React, {Component} from "react";
import {prefix} from "../../../wui-core/src/index";
// import Icon from '../../../wui-icon/src';
import { TableProps, ISortState, ColumnType, SortColType } from '../iTable';
import { DefaultRecordType, DataIndex } from '../interface';
import { TableInterface } from "../index";

/**
 * 参数：prefixCls，默认bee-table,用于设置图标的样式
 * @param {*} Table
 * @param {*} Icon
 */

export default function sort(Table: React.ComponentClass<Partial<TableProps>> | TableInterface) {
    return class SortTable extends Component<TableProps, ISortState> {
        constructor(props: TableProps) {
            super(props);
            let flatColumns = this._toFlatColumn(props.columns, -1);
            let oldData = this.props.data.concat();
            // this.showData = this.getShowData(props.data,props.columns)
            this.state = {
                data: props.data,
                columns: props.columns,
                flatColumns: flatColumns,
                oldData
            };
        }

		static defaultProps = {
		    prefixCls: `${prefix}-table`,
		    sort: {mode: "single", backSource: false}, // 默认是前端排序，值为true为后端排序s
		    rowKey: 'key'
		};

		// static propTypes = {
		//     columns: PropTypes.any,
		//     sort: PropTypes.any,
		//     data: PropTypes.any,
		//     onDropBorder: PropTypes.func,
		//     sortDirections: PropTypes.array, // 支持的排序方式
		//     rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
		//     _onDataChange: PropTypes.func,
		// }

		componentDidMount() {
		    this._initSort();
		}

		componentWillReceiveProps(nextProps: TableProps) {

		    if (nextProps.columns !== this.props.columns) {
		        let flatColumns = this._toFlatColumn(nextProps.columns, -1);
		        this.setState({
		            columns: nextProps.columns,
		            flatColumns
		        });
		    }
		    if ('data' in nextProps) {
		        // const { data, columns } = nextProps;
		        // let newDataKeys = this.getOldDataKey(data);
		        // let oldDataKeys = this.getOldDataKey(this.state.data);
		        // if(nextProps.data !== this.state.data && newDataKeys.toString() !== oldDataKeys.toString()) { // 多选后数据经过处理引用地址不同，但是顺序没变
		        // if(JSON.stringify(this.getShowData(data, columns)) !== JSON.stringify(this.showData)){
		        if (nextProps.data !== this.state.data) {
		            this.setState({
		                data: nextProps.data,
		                oldData: nextProps.data.concat()
		            }, function() {
		                this._initSort(); // 数据更新后需要重新排序
		            });
		            // this._initSort()
		        }
		    }
		}

		// getOldDataKey = (data) => {
		// 	let rowKeyList = []
		// 	const { rowKey } = this.props;
		// 	(data || []).forEach((item, index) => {
		// 		let key = getRowKey(item, index, rowKey);
		// 		rowKeyList.push(key);
		// 	})
		// 	return rowKeyList;
		// }

		// 通过oldData还原data
		resetData = (data: DefaultRecordType[]) => {
		    const {oldData = []} = this.state;
		    oldData.forEach((item, index) => {
		        data[index] = item;
		    })
		    return data;
		}

		// 初始化或data数据变化时重新依据排序状态进行排序
		_initSort = () => {
		    const {sort} = this.props;
		    const {flatColumns} = this.state;
		    let needSort = false;
		    flatColumns.forEach(item => {
		        if (item.order == 'descend' || item.order == 'ascend') {
		            needSort = true;
		            return
		        }
		    })
		    if (needSort) {
		        if (sort && !sort.backSource && sort.mode !== "single") {// 多列排序情况下数据排序
		            let data = this.multiSort(flatColumns)
		            this.setState({data})
		            this.props._onDataChange && this.props._onDataChange(data)
		        } else {
		            // //单列排序情况下如果data受控会出现死循环，需要重新考虑
		            if (sort && !sort.backSource) {
		                this.singleSort(flatColumns)
		            }
		        }
		    }
		}

		/**
		 *column扁平化处理，适应多表头避免递归操作
		 *
		 */
		_toFlatColumn(columns: ColumnType[], parentIndex: number = -1, flatColumns:ColumnType[] = []):ColumnType[] {
		    const that = this;
		    let children = [];
		    flatColumns = flatColumns || [];
		    columns.forEach((item: ColumnType) => {
		        item.parentIndex = parentIndex;
		        item.key = item.key || item.dataIndex; // 保证唯一值
		        children = item.children as ColumnType[];
		        flatColumns.push(item);
		        if (children) {
		            // item.children = [];
		            that._toFlatColumn(children, flatColumns.length - 1, flatColumns);
		        }
		    });
		    return flatColumns;
		}

		getOrderNum = () => {
		    let orderNum = 0;
		    // todo 1
		    this.state.flatColumns.forEach((item) => {
		        if (item.order == "ascend" || item.order == "descend") {
		            orderNum++;
		        }
		    });
		    return orderNum ? orderNum : 1;
		};

		/**
		 * column 当前的排序的列
		 * 当有的列不排序时，将该列的orderNum置为‘’，并动态的修改其他列的orderNum。
		 */
		changeOrderNum = (column:ColumnType) => {
		    let {flatColumns} = this.state;
		    // todo 2
		    flatColumns.forEach((col:ColumnType) => {
		        if (col.orderNum && column.orderNum && col.orderNum > column.orderNum) {
		            col.orderNum--;
		        }
		        if (col.orderNum && column.key == col.key) {
		            // col.orderNum = null;
		            col.orderNum = undefined;
		        }
		    });
		    this.setState({flatColumns});
		};
		/**
		 * 获取排序字段
		 */
		getOrderCols = (columns: ColumnType[]) => {
		    let orderCols: SortColType[] = [];
		    // todo 3
		    columns.forEach(item => {
		        if (item.order == "ascend" || item.order == "descend") {
		            orderCols.push({
		                order: item.order,
		                field: item.dataIndex,
		                orderNum: item.orderNum
		            });
		        }
		    });
		    return orderCols;
		};

		/**
		 * pre：前一条数据
		 * after:后一条数据
		 * orderType:升序、降序
		 */
		_sortBy = (pre:DefaultRecordType, after:DefaultRecordType, orderCols:Partial<ColumnType>, orderColslen: number, currentIndex: number): any => {
		    const currentCol = orderCols[currentIndex];
		    const getMultiSorterValueFunc = currentCol.getMultiSorterValue
		    let preKey = pre[currentCol.key];
		    let afterKey = after[currentCol.key];
		    if (getMultiSorterValueFunc) {
		        preKey = getMultiSorterValueFunc(pre, currentCol)
		        afterKey = getMultiSorterValueFunc(after, currentCol)
		    }
		    let colSortFun = currentCol.sorter;
		    if (typeof colSortFun !== 'function') {
		        colSortFun = () => preKey - afterKey;
		    }
		    if (preKey == afterKey && currentIndex + 1 <= orderColslen) {
		        return this._sortBy(pre, after, orderCols, orderColslen, currentIndex + 1);
		    }
		    if (currentCol.order == "ascend") {
		        return colSortFun(pre, after);
		    } else {
		        return -colSortFun(pre, after);
		    }
		};
		/**
		 * 多列排序 先排order为1的，其他的基于已排序的数据排
		 */
		multiSort = (columns: ColumnType[]) => {
		    let {data} = this.state;
		    const that = this;
		    let orderCols = {},
		        orderColslen = 0;
		    // todo 4
		    columns.forEach(item => {
		        if (item.orderNum) {
		            orderColslen++;
		            orderCols[item.orderNum] = item;
		        }
		    });
		    if (orderColslen > 0) {
		        data = data.sort(function(a, b) {
		            return that._sortBy(a, b, orderCols, orderColslen, 1);
		        });
		    } else {
		        // data = oldData.concat();
		        data = this.resetData(data)
		    }
		    return data;
		};

		singleSort = (columns: ColumnType[] = []) => {
		    let currentCol = columns.find(item => item.order && item.order !== 'flatscend')// 初始化第一个order排序
		    if (currentCol) {
		        let order = currentCol.order || ''
		    	this.toggleSortOrder(order as string, currentCol)
		    }
		}

		toggleSortOrder = (order: string, column:ColumnType) => {
		    let {data = [], oldData = [], flatColumns = []} = this.state;
		    let {sort} = this.props;
		    let seleObj: Partial<ColumnType> = {};
		    // if (!oldData.length) {
		    // 	oldData = data.concat();
		    // }
		    let sortCol;
		    // 单列排序，清空其他列的排序
		    if (sort && sort.mode == "single") {
		        // todo 5
		        flatColumns.forEach(da => {
		            if (da.key == column.key) {
		                seleObj = da;
		            } else {
		                if (da.order) {
		                    da.order = "flatscend";// 还原排序状态
		                }
		            }
		        });
		        seleObj.order = order;// 当前排序列状态
		        sortCol = [{order: order, field: seleObj.dataIndex}]
		        // 通过后端请求
		        if (sort.backSource && typeof sort.sortFun === "function") {
		            // 获取排序的字段和方式
		            sort.sortFun(sortCol);

		        } else {
		            if (order === "ascend" && column.sorter && typeof column.sorter == 'function') {
		                data = data.sort(function(a, b) {
		                    // @ts-ignore
		                    return column.sorter(a, b);
		                });
		            } else if (order === "descend" && column.sorter && typeof column.sorter == 'function') {
		                data = data.sort(function(a, b) {
		                    // @ts-ignore
		                    return column.sorter(b, a);
		                });
		            } else {
		                // data = oldData.concat();
		                data = this.resetData(data)
		            }
		            typeof sort.sortFun === "function" && sort.sortFun(sortCol, data, oldData);
		        }
		    } else {// 多列排序
		        seleObj = flatColumns.find(da => da.key == column.key) as ColumnType;
		        seleObj.order = order;
		        if (order === "flatscend") {
		            this.changeOrderNum(column);
		        }
		        if (!seleObj.orderNum && (order == "ascend" || order == "descend")) {
		            seleObj.orderNum = this.getOrderNum();
		        }
		        sortCol = this.getOrderCols(flatColumns);
		        if (sort && sort.backSource && typeof sort.sortFun === "function") {
		            sort.sortFun(sortCol);
		        } else {
		            data = this.multiSort(flatColumns);
		            sort && typeof sort.sortFun === "function" && sort.sortFun(sortCol, data, oldData);
		        }
		    }
		    if (!sort || (sort && !sort.backSource)) { // 非后端排序
		        this.setState({data, oldData, flatColumns});
		        this.props._onDataChange && this.props._onDataChange(data)
		    }
		};
		// 每个column上添加orderNum属性，不排序时为“”。
		// 点击时orderNum有值则不重新赋值，如果没有值，则取当前column下的有oderNum的length值。并排序
		// 点击置为“”时，动态的设置相关column的orderNum值。并排序
		renderColumnsDropdown = (columns: ColumnType[]) => {
		    let tempColumns = [], rsColumns = [];
		    tempColumns = columns.map((originColumn:ColumnType) => {
		        let column = Object.assign({}, originColumn);
		        return this.sortColumn(column);
		    });
		    rsColumns = this._flatToColumn(tempColumns);
		    return rsColumns;
		};

		sortColumn = (column: ColumnType) => {
		    const { mode = 'single' } = this.props.sort || {};
		    let {sortDirections, fieldid} = this.props;
		    let fieldidUpAttr = fieldid ? { fieldid: `${fieldid}_sort_up_icon` } : {}
		    let fieldidDownAttr = fieldid ? { fieldid: `${fieldid}_sort_down_icon` } : {}
		    sortDirections = column.sortDirections || sortDirections || ['ascend', 'descend']
		    let iconTypeIndex = 0;
		    let sorterClass = "flat";

		    // 兼容antd的sortOrder
		    if (column.order === "ascend") {
		        iconTypeIndex = 1;
		        sorterClass = "up";
		    } else if (column.order === "descend") {
		        iconTypeIndex = 2;
		        sorterClass = "down";
		    }

		    let sortButton;

		    // sorter和sortEnable均可触发排序,且sorter优先级更高
		    if (column.sorter || column.sortEnable) {
		        // 大于0说明不是升序就是降序，判断orderNum有没有值，没有值赋值
		        if (column.sortEnable && !column.sorter && column.dataIndex) {
		            switch (column.fieldType) {
		            case 'number': {
		                column.sorter = this.numberSortFn(column.dataIndex);
		                break;
		            }
		            case 'stringChinese': {
		                column.sorter = this.chineseSortFn(column.dataIndex);
		                break;
		            }
		            default: {
		                column.sorter = this.defaultSortFn(column.dataIndex);
		                break;
		            }
		            }
		        }
		        if (iconTypeIndex > 0 && !column.orderNum && mode == "multiple") {
		            column.orderNum = this.getOrderNum();
		        }
		        let wrapClassName = sorterClass === 'up' || sorterClass === 'down' ? `${prefix}-table-column-sorter ${prefix}-table-column-sorter-active` : `${prefix}-table-column-sorter`;
		        sortButton = <div className={`${wrapClassName} ${prefix}-table-title-icon`}>
		            <span className={`${prefix}-table-column-sorter-${sorterClass}`}>
		                {sortDirections && sortDirections.includes('ascend') ? <i className={`uf uf-triangle-up`} { ...fieldidUpAttr } onClick={() => {
		                    this.iconClick('ascend', 'up', column)
		                }}/> : null}
		                {sortDirections && sortDirections.includes('descend') ? <i className={`uf uf-triangle-down`} { ...fieldidDownAttr } onClick={() => {
		                    this.iconClick('descend', 'down', column)
		                }}/> : null}
		                <span className={`${prefix}-table-column-sorter-num`}>{column.orderNum}</span>
		            </span>
		        </div>;
		    }
		    column.title = <>
		        {typeof column.title === 'string' ? <span className={`${prefix}-table-title-text-span`}
														  title={column.title}>{column.title}</span> : column.title}
		        {sortButton}
		    </>;
		    return column;
		};

		iconClick = (order: string, type: string, column: ColumnType) => {
		    let tempOrder = order;
		    let tempType = type;
		    if (column.order === order) {
		        tempOrder = 'flatscend';
		        tempType = 'flat';
		    }

		    this.toggleSortOrder(tempOrder, column);

		    if (column.sorterClick) {
		        column.sorterClick(column, tempType);
		    }
		}


		// 默认的比较函数,即字符串比较函数
		defaultSortFn = (key: DataIndex) => (a:DefaultRecordType, b: DefaultRecordType) => {
		    return a[key] >= b[key] ? 1 : -1;
		}
		// 数值比较函数
		numberSortFn = (key: DataIndex) => (a:DefaultRecordType, b: DefaultRecordType) => {
		    let numberA = parseFloat(a[key]);
		    let numberB = parseFloat(b[key]);
		    return numberA >= numberB ? 1 : -1;
		}

		// 中文比较函数，按拼音排序
		chineseSortFn = (key: DataIndex) => (a:DefaultRecordType, b: DefaultRecordType) => {
		    return a[key].localeCompare(b[key], 'zh-Hans-CN', {sensitivity: 'accent'});
		}

		_flatToColumn(flatColumns: ColumnType[]) {
		    const colLen = flatColumns.length;
		    let parentIndex, rsColumns = [];
		    // 每次渲染需要将父类的children置空，避免重复
		    flatColumns.forEach(item => {
		        if (item.children) {
		            item.children = [];
		        }
		    })
		    for (let i = colLen - 1; i >= 0; i--) {
		        parentIndex = flatColumns[i].parentIndex;
		        if (parentIndex && parentIndex >= 0) {
		            // @ts-ignore
		            flatColumns[parentIndex].children.unshift(flatColumns[i]);
		        }
		    }
		    rsColumns = flatColumns.filter(item => {
		        return item.parentIndex == -1
		    })
		    return rsColumns;
		}

		// 列宽度拖拽后需要更新sort组件的state.columns数据（否则点了排序后被拖拽的列宽度会被还原）
		handleDropBorder = (event:React.MouseEvent<HTMLElement>, newWidth: number, newColumn:ColumnType, newColumns:ColumnType[]) => {
		    const {onDropBorder} = this.props;
		    let flatColumn = this.state.flatColumns.find(column => column.dataIndex == newColumn.dataIndex) as ColumnType;
		    flatColumn.width = newWidth;
		    this.setState({flatColumns: this.state.flatColumns});
		    typeof onDropBorder == 'function' && onDropBorder(event, newWidth, newColumn, newColumns);
		}

		_onDataChange = (data: DefaultRecordType[]) => {
		    this.setState({data})
		    this.props._onDataChange && this.props._onDataChange(data)
		}

		render() {
		    let columns = this.renderColumnsDropdown(this.state.flatColumns.concat());
		    return <Table {...this.props} columns={columns} data={this.state.data} onDropBorder={this.handleDropBorder}
						  _onDataChange={this._onDataChange}/>;
		}
    } as React.ComponentClass<Partial<TableProps>> | TableInterface;
}
