import classNames from 'classnames';
// import {isFunction, isPlainObject, isArray, toString} from "lodash-es"
// import PropTypes from 'prop-types';
import RcSelect, {OptGroup, Option, BaseSelectRef} from 'rc-select';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import {cssUtil, Warning, prefix, isPlainObject} from "../../wui-core/src";
import Icon from "../../wui-icon/src"
import {getLangInfo} from '../../wui-locale/src/tool';
import {ConfigContext} from "../../wui-provider/src/context";
import addEventListener from '../../wui-overlay/src/utils/addEventListener';
import SizeContext from "../../wui-provider/src/SizeContext";
import i18n from './i18n';
import AutoTagSelect from "./AutoTagSelect";
import { SelectProps, SelectValue, OptionProps, RawValue, AddEventLReturn, DisplayValueType } from './iSelect';

// lodash-es 方法替换
const isArray = Array.isArray
const isFunction = function(value: any) {
    return typeof value === 'function'
}
const INFINITY = 1 / 0
function toString(value: any): string {
    if (value == null) {
        return ''
    }
    if (typeof value === 'string') {
        return value
    }
    if (Array.isArray(value)) {
        return `${value.map((other) => other == null ? other : toString(other))}`
    }
    if (typeof value === 'symbol' || ((typeof value === 'object' && value != null && Object.prototype.toString.call(value) == '[object Symbol]'))) {
        return value.toString()
    }
    const result = `${value}`
    return (result == '0' && (1 / value) == -INFINITY) ? '-0' : result
}

const {isShouldUpdate} = Warning;
const defaultClearIcon = <Icon type="uf-close-c"/>
const defaultMenuItemSelectedIcon = <Icon type="uf-correct-2"/>
const defaultSuffixIcon = <Icon type="uf-arrow-down"/>
const defaultRemoveIcon = <Icon type="uf-close"/>

const InternalSelect = <VT extends SelectValue = SelectValue>(
    {
        prefixCls: customizePrefixCls,
        bordered = true,
        className,
        getPopupContainer,
        dropdownClassName,
        onDropdownVisibleChange,
        getSelectAttrs,
        listHeight = 256,
        listItemHeight = 24,
        size: customizeSize,
        notFoundContent,
        combobox,
        dropdownMatchSelectWidth,
        virtual,
        direction,
        onInputKeyDown,
        onKeyDown,
        options,
        onChange,
        onSelect,
        onDeselect,
        data,
        id,
        multiple,
        tags,
        open,
        defaultOpen,
        defaultValue,
        children,
        fieldid,
        maxTagCount,
        ...props
    }: SelectProps<VT>,
    ref?: React.Ref<BaseSelectRef>,
) => {
    const context = React.useContext(ConfigContext)
    const innerRef = React.useRef<BaseSelectRef>(null);
    let [optionList, setOptionList] = React.useState(options || data);
    let [clearIcon, setClearIcon] = React.useState(props.clearIcon || defaultClearIcon)
    let [menuItemSelectedIcon, setMenuItemSelectedIcon] = React.useState(props.menuItemSelectedIcon || defaultMenuItemSelectedIcon)
    let [suffixIcon, setSuffixIcon] = React.useState(props.suffixIcon || defaultSuffixIcon)
    let [openState, setOpenState] = React.useState(!!defaultOpen || !!open) // 默认值是初始的open值
    let [attrsSet, setAttrsSet] = React.useState(false)
    let [childrenArr, setChildrenArr] = React.useState(children)
    let [defaultVal, setDefaultVal] = React.useState<SelectValue | null>(defaultValue)
    let [eventHandler, setEventHandler] = React.useState<AddEventLReturn | null>(null)
    let [value, setValue] = React.useState<SelectValue | null>(typeof props.value === 'undefined' ? defaultValue : props.value) // Select的value状态
    let [selectDom, setSelectDom] = React.useState<HTMLElement | null>(null)
    let removeIcon = props.removeIcon || defaultRemoveIcon // 不会在state中

    const {
        getPrefixCls,
    } = context;
    const size = React.useContext(SizeContext);
    const locale = props.locale || context.locale || 'zh-cn';
    const local = getLangInfo(locale, i18n);
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    // modal 和 drawer 是其父元素渲染到，父元素节点下面
    const getPopupContainerDom = (dom: HTMLElement) => {
        const container = getPopupContainer;
        if (typeof container === 'function') {
            return container(dom);
        } else {
            return cssUtil.parentsUntil(dom);
        }
    }

    const mode = React.useMemo(() => { // 调整一下位置。放到前面，便于设置fieldid方法使用
        const {mode: m} = props;
        if (tags) {
            return 'tags'
        }
        if (multiple) {
            return 'multiple'
        }
        if (combobox) { // combobox属性依然支持，并且这样传combobox功能可以用
            return 'combobox'
        }
        return m;
    }, [props.mode, multiple, tags, combobox]);

    const isMultiple = mode === 'multiple' || mode === 'tags';

    React.useEffect(() => {
        if (fieldid) {
            if (!selectDom) { // 获取这个Select的外层dom结构
                const selectDoms = document.querySelectorAll(`.${prefixCls}`) // 获取页面上的wui-select
                if (selectDoms?.length) {
                    for (let i = 0; i < selectDoms.length; i++) {
                        if (selectDoms[i] && selectDoms[i]?.getAttribute('fieldid') === fieldid) { // 找到了这个select
                            setSelectDom(selectDoms[i] as HTMLElement) // 获得了组件的外层容器
                            break;
                        }
                    }
                }
            } else { // 得到了这个Select的dom结构，进行fieldid的设置
                if (isMultiple) {
                    const tags = (selectDom as HTMLElement)?.querySelectorAll(`.${prefixCls}-selection-overflow-item:not(.${prefixCls}-selection-overflow-item-suffix)`) // 占位的空tag不加fieldid
                    for (let i = 0; i < tags.length; i++) {
                        tags[i] && tags[i].setAttribute('fieldid', `${fieldid}_tag_${i}`) // 获取到所有的多选已选结果，设置fieldid
                    }
                    const removeIcons = (selectDom as HTMLElement)?.querySelectorAll(`.${prefixCls}-selection-item-remove`)
                    for (let i = 0; i < removeIcons.length; i++) {
                        removeIcons[i] && removeIcons[i].setAttribute('fieldid', `${fieldid}_remove_${i}`) // 获取到所有的多选已选结果的删除图标，设置fieldid
                    }
                }
            }
        }
    }, [selectDom, value, fieldid]) // fieldid，获取到的外层元素，以及Select的value，这三种发生变化的时候，设置内部结构的fieldid

    React.useEffect(() => {
        let childrenArr: React.ReactNode[] | React.ReactNode = children
        if (childrenArr) { // 如果是传了自己构造的子节点
            if (!isArray(childrenArr)) {
                childrenArr = [childrenArr] // 如果只有一个子节点，把它变成数组
            }
            if ((childrenArr as React.ReactNode[]).some((child: React.ReactNode) => Array.isArray(child))) { // 如果子节点里，还有数组，再处理一级
                let res: React.ReactNode[] = [];
                (childrenArr as React.ReactNode[]).forEach((child: React.ReactNode) => {
                    if (Array.isArray(child)) {
                        res = res.concat(child)
                    } else {
                        res.push(child)
                    }
                })
                childrenArr = res
            }
            const children = (childrenArr as React.ReactNode[]).filter((child: React.ReactNode) => React.isValidElement(child)).map((child: React.ReactElement, index: number) => { // 过滤掉非组件的子元素
                let res = child
                const value = child.props.value
                if (value && isArray(value)) { // 如果value是数组，变成string类型，设置data-is-array
                    res = React.cloneElement(child, {
                        'data-is-array': true,
                        value: toString(value)
                    })
                }
                if (!('value' in child.props) && child.key) { // 如果是旧的写法，不传value，只传了key。将value设置成key
                    res = React.cloneElement(child, {
                        value: child.key,
                        key: child.key
                    })
                }
                if (!id && !fieldid) { // 如果没有id或者fieldid，直接返回Option
                    return res
                }
                const childId = child.props.id
                let idProp = {}
                let fieldidProp = {}
                let dataIdProp = {}
                if (id) {
                    idProp = { id: `${id}_option_${index}` }
                }
                if (fieldid) {
                    fieldidProp = { fieldid: `${fieldid}_option_${index}` }
                }
                if (childId) { // 如果用户在Option中自己传了id，标记一下，onChange的时候返回这个id，而不是测试传的那个id
                    dataIdProp = {
                        'data-id': childId
                    }
                }
                res = React.cloneElement(res, {...idProp, ...fieldidProp, ...dataIdProp})
                return res
            })
            setChildrenArr(children)
        }
    }, [children, id, fieldid]) // 外部传来的子节点, id, fieldid发生变化时，进行Option的处理

    // ===================== Attibutes =====================
    React.useEffect(() => {
        const tinperProps = {
            multiple,
            data,
            onKeyDown,
            tags
        }
        isShouldUpdate("Select", tinperProps);
    }, [])

    React.useEffect(() => { // 如果id或者数据发生变化，根据格式重新计算数据中的id，以及value的处理
        let optionList = options || data
        if (optionList) {
            optionList = optionList.filter((option) => option).map((option, index) => {
                if (option.id) { // 如果用户传了id，那么使用data-id保存一下，onChange的时候使用
                    option['data-id'] = option.id
                }
                if (id) {
                    option.id = `${id}_option_${index}`;
                }
                if (fieldid) {
                    option.fieldid = `${fieldid}_option_${index}`
                }
                const val = option.value
                const key = option.key
                if (val && isArray(val)) { // 只有数组需要处理
                    option['data-is-array'] = true
                    option.value = toString(val)
                }
                if (!('value' in option) && key) { // 没有传value的情况
                    option.value = key
                    option.key = key
                }
                return option
            })
            setOptionList(optionList)
        }
    }, [options, data, id, fieldid])

    // ===================== Empty =====================
    let mergedNotFound;
    if (notFoundContent !== undefined) { // 优先显示传了notFoundContent的内容
        mergedNotFound = notFoundContent;
    } else if (mode === 'combobox') {
        mergedNotFound = null;
    } else {
        mergedNotFound = <span>{local.langMap.notFoundContent}</span>;
    }

    // ===================== Icons =====================
    let {
        showArrow
    } = props

    React.useEffect(() => {
        if (dropdownClassName && getSelectAttrs && isFunction(getSelectAttrs) && openState && !attrsSet) {
            const dropdown = document.getElementsByClassName(dropdownClassName)
            if (dropdown.length) {
                const dropdownWrapper = dropdown[0] // 默认使用getSelectAttrs的同时，传了一个特殊的类名，类似于id
                const attrs = getSelectAttrs()
                if (isPlainObject(attrs)) {
                    const keys = Object.keys(attrs)
                    keys.forEach(key => {
                        dropdownWrapper.setAttribute(
                            key, attrs[key]
                        )
                    })
                    setAttrsSet(true) // 这个操作只在第一次渲染dropdown的时候进行
                }
            }
        }
        if (openState) { // 打开了下拉框，禁止modal-body上的键盘行为
            const modalBody = document.querySelector(`.${prefix}-modal-body`)
            if (modalBody) { // 有modalBody的时候，注册事件
                const handler = addEventListener(modalBody, 'keyup', (e: React.KeyboardEvent)=> {
                    e.stopPropagation()
                });
                setEventHandler(handler)
            }
        } else {
            if (eventHandler) { // 关闭下拉框，取消禁止modal-body的键盘行为
                setTimeout(() => {
                    eventHandler && (eventHandler as AddEventLReturn).remove()
                    setEventHandler(null)
                }, 150);
            }
        }
    }, [openState])

    const handleDropdownVisibleChange = React.useCallback(open => {
        setOpenState(open)
        if (onDropdownVisibleChange) {
            onDropdownVisibleChange(open)
        }
    }, [onDropdownVisibleChange])

    const handleChange = React.useCallback((value, option) => {
        let newValue = value
        let newOption = option
        if (!newValue && !newValue) { // 点击clear清除的时候，不处理
            return onChange?.(newValue, newOption)
        }
        if (!isMultiple) { // 单选和combobox的情况下
            if (newOption['data-is-array']) {
                newValue = newValue.split(',')
                delete newOption['data-is-array']
                newOption = {
                    ...newOption,
                    value
                }
            }
        } else { // 多选的情况下
            for (let i = 0; i < newValue.length; i++) {
                if (newOption[i]?.['data-is-array']) {
                    newValue[i] = [newValue[i].split(',')]
                    delete newOption[i]['data-is-array']
                    newOption[i] = {
                        ...newOption[i],
                        value: newValue[i]
                    }
                }
            }
        }
        if (option['data-id']) { // 如果用户自己在Option中传了id，那么在回调的时候，使用这个id放到数据中。
            newOption.id = option['data-id']
        }
        onChange?.(newValue, newOption)
    }, [onChange, isMultiple])

    const handleSelect = React.useCallback((value, option = {}) => {
        if (option['data-is-array']) {
            value = value.split(',')
            delete option['data-is-array']
            option = {
                ...option,
                value
            }
        }
        if (option['data-id']) { // onSelect事件也要有这个处理
            option.id = option['data-id']
        }
        onSelect?.(value, option)
    }, [onSelect])

    const handleDeselect = React.useCallback((value, option = {}) => {
        if (option['data-is-array']) {
            value = value.split(',')
            delete option['data-is-array']
        }
        onDeselect?.(value, option)
    }, [onDeselect])

    React.useEffect(() => {
        if (!defaultVal) return
        if (isMultiple) { // 多选的话，遍历每一个默认值
            if (!isArray(defaultVal)) { // 如果传了默认值，但不是个数组
                defaultVal = [defaultVal] as SelectValue
            }
            for (let i = 0; i < (defaultVal as RawValue[]).length; i++) {
                if (isArray((defaultVal as RawValue[])[i])) {
                    (defaultVal as RawValue[])[i] = toString((defaultVal as RawValue[])[i])
                }
            }
            setDefaultVal(defaultVal)
        } else {
            if (isArray(defaultVal)) { // 如果不是多选，而且默认值是个数组
                setDefaultVal(toString(defaultVal)) // 将这个值变成字符串，单选没有传数组的
            }
        }
    }, []) // 默认值只处理第一次

    React.useEffect(() => { // 处理id和fieldid
        ['id', 'fieldid'].forEach(item => {
            const idValue = item === 'id' ? id : fieldid
            if (idValue) {
                suffixIcon = React.cloneElement(suffixIcon as React.ReactElement, {
                    [item]: `${idValue}_suffix`
                })
                setSuffixIcon(suffixIcon)
                menuItemSelectedIcon = React.cloneElement(menuItemSelectedIcon as React.ReactElement, {
                    [item]: `${idValue}_item_selected`
                })
                setMenuItemSelectedIcon(menuItemSelectedIcon)
                clearIcon = React.cloneElement(clearIcon as React.ReactElement, {
                    [item]: `${idValue}_clear`
                })
                setClearIcon(clearIcon)
            }
        })
    }, [id, fieldid])

    const selectProps = omit(props, ['suffixIcon', 'itemIcon']);
    const rcSelectRtlDropDownClassName = classNames(dropdownClassName, {
        [`${prefixCls}-dropdown-${direction}`]: direction === 'rtl',
        [`${prefixCls}-dropdown--multiple`]: !!isMultiple
    });

    const mergedSize = customizeSize || size;
    const mergedClassName = classNames(
        {
            [`${prefixCls}-lg`]: mergedSize === 'large',
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-borderless`]: !bordered,
        },
        className,
    );

    const showArrowProps = showArrow === undefined ? true : showArrow
    const extral = {
        mode,
        onInputKeyDown: onInputKeyDown || onKeyDown,
        options: optionList,
        showArrow: showArrowProps
    }
    const idProp = id ? {id} : {}
    const fieldidProp = fieldid ? {fieldid} : {}
    React.useEffect(() => { // 受控的select，每次都随传来的value更新state
        if ('value' in props) {
            let value = props.value
            if (mode === 'combobox' && (props.optionLabelProp === 'children' || !props.optionLabelProp) && childrenArr) { // 在combobox模式下，如果传入value，optionLabelProp为children的Select，用于展示选中值的内部value使用children来设置
                const child = (childrenArr as React.ReactNode[]).find((child: React.ReactElement) => child?.props?.value === props.value) || {}
                const val = (child as any)?.props?.children // children当作value, 同时children 只能是非对象模式
                if (val && typeof val !== 'object') {
                    value = val
                }
            }
            setValue(value)
        }
    })
    const changeHandler = (value: SelectValue, option: OptionProps) => {
        let val = value
        if (mode === 'combobox') {
            if ((props.optionLabelProp === 'children' || !props.optionLabelProp) && option && typeof option.children !== 'object') { // 设置了值为children或者没设置的optionLabelProp，同时children 只能是非对象模式
                if (option && option.children) {
                    setValue(option.children as string)
                    return onChange ? handleChange(val, option) : null
                }
            }
        }
        setValue(val)
        onChange ? handleChange(val, option) : null
    }

    // 多选隐藏标签支持用户自定义&默认渲染最大值99
    const MaxTagPlaceholderHandler = (omittedValues: DisplayValueType[]) => {
        if (props.maxTagPlaceholder) return typeof props.maxTagPlaceholder === 'function' ? props.maxTagPlaceholder(omittedValues) : props.maxTagPlaceholder;
        if (omittedValues.length > 99) return `99 +`;
        return `+ ${omittedValues.length}`;
    }

    const setInputCopyValue = (item: any) => {
        let copyValue = '';
        // 默认模式且非检索模式，item.label为ReactElement不可复制(需保留span 的样式)
        if (!mode && !props.showSearch && item && item.label) { // string | number | bool | ReactElement
            if (typeof item.label !== 'object') { // string | number | bool
                copyValue = item.label.toString();
            }
            if (typeof item.label === 'object' && !React.isValidElement(item.label)
            && Array.isArray(item.label) && !item.label.find((el: any) => React.isValidElement(el))) { // 文本数组['text1', 'text2]
                item.label.forEach((el: string | number) => {
                    copyValue = copyValue + el.toString();
                })
            }
        }
        return copyValue;
    }

    React.useImperativeHandle(ref, () => {
        return {
            handleDeselect,
            ...(innerRef.current as BaseSelectRef)
        }
    });
    const childrenProp = childrenArr ? { children: childrenArr } : {}
    const defaultValueProp = defaultVal ? { defaultValue: defaultVal } : {}
    const selectHandler = onSelect ? { onSelect: handleSelect } : {}
    const deselectHandler = onDeselect ? { onDeselect: handleDeselect } : {}
    const optionLabelPropObj = mode === 'combobox' ? {
        optionLabelProp: props.optionLabelProp || 'children'
    } : {} // 其它模式的Select没有这个属性的默认值也不要传
    return (
        <RcSelect
            ref={innerRef}
            virtual={virtual}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            {...omit(selectProps, ["dropdownMenuStyle", "dataIndex", "filterDropdown", "filterDropdownType", "filterDropdownIncludeKeys", "scrollToEnd", "supportWrite", "onFilterChange", "onFilterClear"])}
            listHeight={listHeight}
            listItemHeight={listItemHeight}
            prefixCls={prefixCls}
            direction={direction}
            inputIcon={suffixIcon}
            menuItemSelectedIcon={isMultiple ? menuItemSelectedIcon : null}
            removeIcon={removeIcon}
            clearIcon={clearIcon}
            notFoundContent={mergedNotFound}
            className={mergedClassName}
            open={open}
            defaultOpen={defaultOpen}
            getPopupContainer={getPopupContainerDom}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            dropdownClassName={rcSelectRtlDropDownClassName}
            onChange={changeHandler}
            maxTagPlaceholder={MaxTagPlaceholderHandler} // ue 设计要求去除省略号
            {...extral}
            {...idProp}
            {...fieldidProp}
            {...childrenProp}
            {...defaultValueProp}
            {...optionLabelPropObj}
            {...selectHandler}
            {...deselectHandler}
            value={value}
            maxTagCount={maxTagCount as number | 'responsive'}
            setInputCopyValue={setInputCopyValue}
        />
    );
};

// const selectPropTypes = {
//     allowClear: PropTypes.bool,
//     autoClearSearchValue: PropTypes.bool,
//     autoFocus: PropTypes.bool,
//     bordered: PropTypes.bool,
//     defaultActiveFirstOption: PropTypes.bool,
//     defaultOpen: PropTypes.bool,
//     disabled: PropTypes.bool,
//     labelInValue: PropTypes.bool,
//     open: PropTypes.bool,
//     loading: PropTypes.bool,
//     showArrow: PropTypes.bool,
//     showSearch: PropTypes.bool,
//     virtual: PropTypes.bool,
//     multiple: PropTypes.bool,
//     dropdownClassName: PropTypes.string,
//     optionFilterProp: PropTypes.string,
//     optionLabelProp: PropTypes.string,
//     searchValue: PropTypes.string,
//     placeholder: PropTypes.string,
//     tokenSeparators: PropTypes.string,
//     id: PropTypes.string,
//     clearIcon: PropTypes.element,
//     menuItemSelectedIcon: PropTypes.element,
//     notFoundContent: PropTypes.element,
//     removeIcon: PropTypes.element,
//     suffixIcon: PropTypes.element,
//     defaultValue: PropTypes.any,
//     maxTagCount: PropTypes.any,
//     options: PropTypes.any,
//     value: PropTypes.any,
//     combobox: PropTypes.any,
//     data: PropTypes.any,
//     tags: PropTypes.any,
//     dropdownMatchSelectWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
//     filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//     maxTagPlaceholder: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
//     tagRender: PropTypes.func,
//     dropdownRender: PropTypes.func,
//     filterSort: PropTypes.func,
//     onBlur: PropTypes.func,
//     getPopupContainer: PropTypes.func,
//     onChange: PropTypes.func,
//     onClear: PropTypes.func,
//     onDeselect: PropTypes.func,
//     onDropdownVisibleChange: PropTypes.func,
//     onFocus: PropTypes.func,
//     onInputKeyDown: PropTypes.func,
//     onMouseEnter: PropTypes.func,
//     onMouseLeave: PropTypes.func,
//     onPopupScroll: PropTypes.func,
//     onSearch: PropTypes.func,
//     onSelect: PropTypes.func,
//     onKeyDown: PropTypes.func,
//     dropdownStyle: PropTypes.object,
//     fieldNames: PropTypes.object,
//     listHeight: PropTypes.number,
//     maxTagTextLength: PropTypes.number,
//     listItemHeight: PropTypes.number,
//     mode: PropTypes.oneOf(['multiple', 'tags']),
//     size: PropTypes.oneOf(['large', 'middle', 'small']),
//     locale: PropTypes.string,
//     getSelectAttrs: PropTypes.func
// }

// InternalSelect.propTypes = selectPropTypes

const SelectRef = React.forwardRef(InternalSelect)

const Select = SelectRef;

type SelectType = typeof Select;

interface SelectInterface extends SelectType {
    Option: typeof Option;
    OptGroup: typeof OptGroup;
}

const SelectWrapper = React.forwardRef(<VT extends SelectValue = SelectValue>(props:SelectProps<VT>, ref?: React.Ref<BaseSelectRef>) => {
    if (props.maxTagCount === 'auto') {
        return <AutoTagSelect {...props} ref={ref} />
    } else {
        return <Select {...props} ref={ref} />
    }
}) as SelectInterface


SelectWrapper.Option = Option;
SelectWrapper.OptGroup = OptGroup;
// SelectWrapper.propTypes = {
//     maxTagCount: PropTypes.any
// }

export { Select };
export default SelectWrapper;
