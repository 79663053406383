import classNames from 'classnames';
import omit from 'omit.js';
// import PropTypes from 'prop-types';
import RcMenu, {ItemGroup} from 'rc-menu';
import { MenuProps as RcMenuProps } from 'rc-menu';
import * as React from 'react';
import {WebUI} from "../../wui-core/src/index"
import {SiderContext} from '../../wui-layout/src/Sider';
import {ConfigContext} from "../../wui-provider/src/context";
import MenuContext from './config-provider/MenuContext';
import Item from './MenuItem';
import SubMenu from './SubMenu';
import MenuDivider from './MenuDivider';
import { InternalMenuProps, MenuProps, MenuPosition } from './iMenu';

// const {propTypes: RcMenuProps} = RcMenu;

export function cloneElement(
    element: React.ReactNode,
    props: {},
) {
    if (!React.isValidElement(element)) return element;

    return React.cloneElement(
        element,
        typeof props === 'function' ? props(element.props || {}) : props,
    );
}

const popupPlacementMap: Record<MenuPosition, 'vertical-left' | 'vertical-right'> = {
    'rightTop': 'vertical-left',
    'leftTop': 'vertical-right',
};
// interface CompoundedComponent extends React.ForwardRefExoticComponent<Omit<RcMenuProps & React.RefAttributes<MenuRef>, 'mode'>> {
// 	mode?: MenuMode;
// }

// const RcMenuWidthMode = RcMenu as CompoundedComponent;

const defaultProps = {
    className: '', // 自定义类名
    theme: 'light', // 主题颜色
    mode: 'vertical', // 菜单类型，对外使用vertical horizontal inline
    keyboard: false, // 是否使用键盘操作
    inlineIndent: 24,
    tabIndex: 0,
    // position: 'rightTop', // 子菜单呼出位置，在vertical模式下使用
    // selectedKeys: [], // 当前选中的菜单项 key 数组
    // defaultSelectedKeys: [], // 初始选中的菜单项 key 数组
    // openKeys: [], // 当前展开的 SubMenu 菜单项 key 数组
    // defaultOpenKeys: [], // 初始展开的 SubMenu 菜单项 key 数组
    // onOpenChange: null, // SubMenu 展开/关闭的回调 Function(openKeys: string[])
    // onSelect: null, // 被选中时调用
    // onDeselect: null, // 取消选中时调用，仅在 multiple 生效
    // onClick: null, // 点击 menuitem 调用此函数，参数为 {item, key, keyPath}
    // onKeyDown: null, // 键盘操作回调函数,
    // tabIndex: '0', // 设置tabIndex
    // children: null, // MenuItem组件/SubMenu组件/MenuItemGroup组件
};

@WebUI({name: "menu", defaultProps})
class InternalMenu extends React.Component<InternalMenuProps> {

    // static propTypes = {
    //     ...RcMenuProps,
    //     clsPrefix: PropTypes.string,
    //     theme: PropTypes.oneOf(['light', 'dark']),
    //     inlineIndent: PropTypes.number,
    //     keyboard: PropTypes.bool,
    //     mode: PropTypes.oneOf(['vertical', 'vertical-left', 'vertical-right', 'horizontal', 'inline']),
    //     position: PropTypes.oneOf(['leftTop', 'rightTop']),
    //     tabIndex: PropTypes.number,
    //     fieldid: PropTypes.string,
    // }

	static contextType = ConfigContext

	getInlineCollapsed = () => {
	    const {inlineCollapsed, siderCollapsed} = this.props;
	    if (siderCollapsed !== undefined) {
	        return siderCollapsed;
	    }
	    return inlineCollapsed;
	}

	render() {

	    const {
	        clsPrefix,
	        className,
	        theme,
	        expandIcon,
	        getPopupContainer,
	        direction,
	        keyboard,
	        tabIndex,
	        position,
	        mode,
	        selectIcon,
	        multiple,
	        fieldid,
	    } = this.props;
	    // const defaultMotions = {
	    //   horizontal: { motionName: `slide-up` },
	    //   inline: collapseMotion,
	    //   other: { motionName: `zoom-big` },
	    // };

	    const menuClassName = classNames(
	        `${clsPrefix}-${theme}`,
	        {
	            [`${clsPrefix}-inline-collapsed`]: this.getInlineCollapsed(),
	        },
	        className,
	    );

	    return (
	        <MenuContext.Provider
	            value={{
	                inlineCollapsed: this.getInlineCollapsed() || false,
	                menuTheme: theme,
	                direction,
	                firstLevel: true,
	                rootPrefixCls: clsPrefix,
	                selectIcon: selectIcon,
	                multiple,
	                parentFieldid: fieldid,
	            }}
	        >
	            <RcMenu
	                getPopupContainer={getPopupContainer}
	                {...omit(this.props, ['keyboard', 'mode', 'clsPrefix', 'className', 'expandIcon', 'tabIndex', 'selectIcon'])}
	                mode={(position && popupPlacementMap[position] ? popupPlacementMap[position] : mode) as RcMenuProps['mode']}
	                className={menuClassName}
	                prefixCls={clsPrefix}
	                direction={direction}
	                tabIndex={keyboard ? tabIndex : -1}
	                // defaultmotions={defaultMotions}
	                expandIcon={cloneElement(expandIcon, {
	                    className: `${clsPrefix}-submenu-expand-icon`,
	                })}
	            />
	        </MenuContext.Provider>
	    );
	}
}

// We should keep this as ref-able
class Menu extends React.Component<MenuProps> {
	static Divider = MenuDivider;

	static Item = Item;

	static SubMenu = SubMenu;

	static ItemGroup = ItemGroup;

	render() {
	    return (
	        <SiderContext.Consumer>
	            {(context) => <InternalMenu {...this.props} {...context} />}
	        </SiderContext.Consumer>
	    );
	}
}


export default Menu;
