import classnames from 'classnames';
import React, { CSSProperties } from 'react';
import warning from 'warning';
import {prefix} from "../../wui-core/src/index"
import { OrNull } from '../../wui-core/src/utils/type';
import Notification from '../../wui-notification/src';
import { Color, Content, NewInstanceCb, NewInstanceCbArg } from '../../wui-notification/src/iNotification';
import { MessageProps } from './iMessage';

let defaultDuration = 1.5;
let newDuration: number | undefined;
let defaultTop = 40;
let defaultBottom = 48;
let bottom = 90;
let padding = 30;
let width = 227;
let messageInstance: OrNull<NewInstanceCbArg> = null;
let key = 1;
let clsPrefix = `${prefix}-message`;
const noop = () => {
};
let notificationStyleCopy: OrNull<CSSProperties> = {};
let messageStyleCopy: OrNull<CSSProperties> = {};
const positionType = ['topRight', 'bottomRight', 'top', 'bottom', 'topLeft', 'bottomLeft', ''];
let defaultStyle: OrNull<CSSProperties> = {};
let positionObj = {
    "top": {
        messageStyle: {},
        notificationStyle: {
            top: defaultTop,
            left: '50%',
            transform: 'translateX( -50%)',
            maxHeight: `calc( 100% - 40px )`,
            // 移除 overflow auto 会对 box-shadow影响
            // overflow: 'auto'
        },
        transitionName: 'top'
    },
    "bottom": {
        messageStyle: {},
        notificationStyle: {
            bottom: defaultBottom,
            left: '50%',
            transform: 'translateX( -50%)'
        },
        transitionName: 'bottom'
    },
    "topRight": {
        messageStyle: {
            width: width
        },
        notificationStyle: {
            top: padding,
            right: padding,
            width: width
        },
        transitionName: 'right'
    },
    "bottomRight": {
        messageStyle: {
            width: width
        },
        notificationStyle: {
            bottom: bottom,
            right: padding,
            width: width
        },
        transitionName: 'right'
    },
    "topLeft": {
        messageStyle: {
            width: width
        },
        notificationStyle: {
            top: padding,
            left: padding,
            width: width
        },
        transitionName: 'left'
    },
    "bottomLeft": {
        messageStyle: {
            width: width
        },
        notificationStyle: {
            bottom: bottom,
            left: padding,
            width: width
        },
        transitionName: 'left'
    }
}

// function getMessageInstance(position: PositionType = 'top', callback: NewInstanceCb, keyboard: boolean | undefined, onEscapeKeyUp: ((e?: KeyboardEvent) => void) | undefined, fieldid: string | undefined) {
function getMessageInstance(callback: NewInstanceCb, obj: MessageProps) {
    // keyboard: boolean | undefined, onEscapeKeyUp: ((e?: KeyboardEvent) => void) | undefined, fieldid: string | undefined
    const {keyboard, onEscapeKeyUp, fieldid, position = 'top', ...otherProps} = obj;
    if (messageInstance) {
        callback(messageInstance);
        return;
    }
    switch (position) {
        case 'top':
            (notificationStyleCopy as CSSProperties).top = defaultTop;
            break;
        case 'bottom':
            (notificationStyleCopy as CSSProperties).bottom = defaultBottom;
            break;
        case 'bottomRight':
            (notificationStyleCopy as CSSProperties).bottom = bottom;
            break;
        case 'bottomLeft':
            (notificationStyleCopy as CSSProperties).bottom = bottom;
            break;
        default:
            break;
    }
    if (position !== 'top' && position !== 'bottom') {
        (messageStyleCopy as CSSProperties).width = width;
    }
    const style = JSON.stringify(notificationStyleCopy) == "{}" ? positionObj[position].notificationStyle : notificationStyleCopy;
    let instanceObj: MessageProps = {
        clsPrefix,
        fieldid,
        transitionName: `${clsPrefix}-${positionObj[position].transitionName}`,
        style: Object.assign({}, style, defaultStyle), // 覆盖原来的样式
        position: position,
        ...otherProps
    }
    if (typeof keyboard === 'boolean') {
        instanceObj.keyboard = keyboard;
    }
    if (typeof onEscapeKeyUp === 'function') {
        instanceObj.onEscapeKeyUp = onEscapeKeyUp;
    }
    Notification.newInstance(instanceObj, instance => {
        messageInstance = instance;
        callback(instance);
    });
}


function notice(propsTransfer: MessageProps & {color: Color; position: string}) {
    const { content, duration: durationArg, color, onClose, position, style, innerStyle, wrapperStyle, keyboard, onEscapeKeyUp, showIcon, icon, fieldid, ...otherPorps } = propsTransfer;
    if (positionType.findIndex((item) => item === position) < 0) {
        warning(
            false,
            'Failed prop type: Invalid prop `position` supplied to `Message`, expected one of ["top","bottom","topRight","topLeft","bottomRight","bottomLeft"].',
        );
        return
    }
    let duration = durationArg !== undefined ? durationArg : defaultDuration;
    notificationStyleCopy = Object.assign({}, positionObj[position].notificationStyle);
    messageStyleCopy = Object.assign({}, positionObj[position].messageStyle);

    let iconType = ({
        info: 'uf uf-xingzhuangbeifen',
        success: 'uf uf-chenggongtishi',
        danger: 'uf uf-exc-c-2',
        error: 'uf uf-exc-c-2',
        warning: 'uf uf-exc-t',
        light: 'uf uf-notification',
        dark: 'uf uf-notification',
        news: 'uf uf-bell',
        infolight: 'uf uf-xingzhuangbeifen',
        successlight: 'uf uf-chenggongtishi',
        dangerlight: 'uf uf-exc-c-2',
        warninglight: 'uf uf-exc-t',
    })[color];

    let positionStyle = JSON.stringify(messageStyleCopy) == "{}" ? positionObj[position].messageStyle : messageStyleCopy;
    defaultStyle = Object.assign({}, positionStyle, style, wrapperStyle);
    const object1 = {
        position,
        keyboard,
        onEscapeKeyUp,
        fieldid: fieldid ? `${fieldid}_alert` : undefined,
        ...otherPorps
    }
    getMessageInstance(instance => {
        instance.notice({
            ...propsTransfer,
            key,
            duration,
            color: color,
            style: Object.assign({}, positionStyle, style, innerStyle),
            fieldid: fieldid ? `${fieldid}_alert` : undefined,
            content: (
                <div>
                    {
                        showIcon ? (
                            <div className={`${clsPrefix}-notice-description-icon`}>
                                <i fieldid={ fieldid ? `${fieldid}_alert_${color}` : undefined} className={classnames(icon ? `${icon}` : iconType)}/>
                            </div>
                        ) : null
                    }
                    <div className={`${clsPrefix}-notice-description-content`}>{content}</div>
                </div> as Content
            ),
            onClose
        });
    }, object1)
    return (function() {
        let target = key++;
        return function() {
            if (messageInstance) {
                messageInstance.removeNotice(target);
            }
        };
    }());
}

function isArgsProps(content: any) {
    return (
        Object.prototype.toString.call(content) === '[object Object]' &&
		!!content.content
    );
}

const api: any = {
    create(obj: MessageProps) {
        if (newDuration) { // 如果在config方法里设置了duration
            obj.duration = newDuration;
        }
        const content = obj.message || obj.content || '';
        const duration = typeof obj.duration == 'undefined' ? defaultDuration : obj.duration;
        const color = obj.color || 'light';
        const onClose = obj.onClose || noop;
        const position = obj.position || "top";
        const style = obj.style || {};
        const innerStyle = obj.innerStyle || {};
        const wrapperStyle = obj.wrapperStyle || {};
        const fieldid = obj.fieldid;
        const showIcon = obj.hasOwnProperty('showIcon') ? obj.showIcon : true;
        const icon = obj.hasOwnProperty('icon') ? obj.icon : '';
        const objTransfer: MessageProps & {color: Color; position: string} = {
            ...obj,
            content,
            duration,
            color,
            onClose,
            position,
            style,
            innerStyle,
            wrapperStyle,
            showIcon,
            icon,
            fieldid};
        return notice(objTransfer);
    },
    config(options: MessageProps & {top: number; defaultBottom: number; bottom: number; width: number;}) {
        if (options.top !== undefined) {
            defaultTop = options.top;
        }
        if (options.duration !== undefined) {
            defaultDuration = options.duration;
            newDuration = defaultDuration;
        }
        if (options.clsPrefix !== undefined) {
            clsPrefix = options.clsPrefix;
        }
        if (options.defaultBottom !== undefined) {
            defaultBottom = options.defaultBottom;
        }
        if (options.bottom !== undefined) {
            bottom = options.bottom;
        }
        if (options.width !== undefined) {
            width = options.width;
        }
    },
    destroy() {
        if (messageInstance) {
            messageInstance.destroy();
            messageInstance = null;
            defaultDuration = 1.5;
            newDuration = undefined;
            defaultTop = 40;
            defaultBottom = 48;
            bottom = 90;
            padding = 30;
            width = 227;
            notificationStyleCopy = null;
            messageStyleCopy = null;
            defaultStyle = null;
        }
    }
};
['success', 'info', 'warning', 'error', 'loading', 'infolight', 'successlight', 'dangerlight', 'warninglight'].forEach(type => {
    api[type] = (config: MessageProps) => {
        let {content, message, duration, onClose, ...otherProps} = config;
        content = message || content;
        if (typeof config === "string") content = config;

        if (isArgsProps(content)) {
            return api.create({...content as Record<string, any>, color: type});
        }
        if (typeof duration === 'function') {
            onClose = duration;
            duration = undefined;
        }
        return api.create({content, duration, color: type === 'error' ? 'danger' : type, onClose, ...otherProps});
    };
})
api.warn = api.warning
export default api;
