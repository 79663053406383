/**
 * This source code is quoted from rc-steps.
 * homepage: https://github.com/react-component/steps
 */
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import React, { ReactNode, isValidElement } from 'react';
import omit from 'omit.js';
// import {Icon} from "../../index";
import Icon from '../../wui-icon/src';
import {StepProps, StepStatus} from './iSteps'

function isString(str: string | ReactNode) {
    return typeof str === 'string';
}

const iconMap = {
    finish: <Icon type="uf-yiwancheng"/>,
    process: <Icon type="uf-jinhangzhong"/>,
    error: <Icon type="uf-jinhangzhong"/>,
    wait: <Icon type="uf-weikaishi"/>
}

class Step extends React.Component<StepProps> {

	click = (...args: []) => {
	    const {onClick, onStepClick, stepIndex} = this.props;
	    if (onClick) {
	        onClick(...args);
	    }

	    onStepClick?.(stepIndex);
	}

	isValidNode = (node: React.ReactNode): string | undefined => {
	    return isValidElement(node) ? undefined : typeof node === 'string' ? node : undefined;
	}

	renderIconNode() {
	    const {
	        clsPrefix, progressDot, stepNumber, status, title, description, icon,
	        iconPrefix, icons, type,
	    } = this.props;
	    let iconNode;
	    const iconClassName = classNames(`${clsPrefix}-icon`, `${iconPrefix}icon`, {
	        [`${iconPrefix}icon-${icon}`]: icon && isString(icon),
	        [`${iconPrefix}icon-check`]: !icon && status === 'finish' && (icons && !icons.finish),
	        [`${iconPrefix}icon-close`]: !icon && status === 'error' && (icons && !icons.error),
	    });
	    const iconDot = <span className={`${clsPrefix}-icon-dot`}></span>;
	    // `progressDot` enjoy the highest priority
	    if (progressDot || type === 'dot') { // 点状步骤条
	        if (typeof progressDot === 'function') {
	            iconNode = (
	                <span className={`${clsPrefix}-icon`}>
	                    {progressDot(iconDot, {index: stepNumber! - 1, status, title, description})}
	                </span>
	            );
	        } else {
	            iconNode = <span className={`${clsPrefix}-icon`}>{iconDot}</span>;
	        }
	    } else if (icon && !isString(icon)) { // 自定义icon样式
	        iconNode = <span className={`${clsPrefix}-icon`}>{icon}</span>;
	    } else if (type && type === 'arrow') { // 箭头步骤条
	        let iconDom: ReactNode = '';
	        if (status === 'finish') {
	            iconDom = <Icon type="uf-correct-2"/>;
	        } else if (status === 'error') {
	            iconDom = <Icon type="uf-exc-t"/>
	        }
	        iconNode = <span className={`${clsPrefix}-icon`}>{iconDom}</span>;
	    } else if (type && type === 'number') { // 数字步骤条
	        iconNode = <span className={`${clsPrefix}-icon`}>{stepNumber}</span>;
	    } else if (icons && icons.finish && status === 'finish') {
	        iconNode = <span className={`${clsPrefix}-icon`}>{icons.finish}</span>;
	    } else if (icons && icons.error && status === 'error') {
	        iconNode = <span className={`${clsPrefix}-icon`}>{icons.error}</span>;
	    } else {
	        iconNode = <span className={iconClassName}>{iconMap[status as StepStatus]}</span>;
	    }

	    return iconNode;
	}

	render() {
	    const {
	        className, clsPrefix, style, itemWidth,
	        status = 'wait', icon,
	        adjustMarginRight, stepNumber,
	        description, title, tailContent,
	        more, out, disabled, stepIndex, onStepClick, onClick, active, type, stepFieldId, subTitle,
	        ...restProps // iconPrefix, wrapperStyle, progressDot, icons,
	    } = this.props;

	    let isActive = active;
	    let isOnchange = onStepClick && !disabled;
	    const classString = classNames(
	        `${clsPrefix}-item`,
	        `${clsPrefix}-item-${status}`,
	        className,
	        {
	            [`${clsPrefix}-item-custom`]: icon,
	            [`${clsPrefix}-more-out`]: out,
	            [`${clsPrefix}-disabled`]: disabled,
	            [`${clsPrefix}-item-active`]: isActive,
	            [`${clsPrefix}-item-hover`]: isOnchange
	        },
	    );
	    const stepItemStyle = {...style};
	    if (itemWidth) {
	        stepItemStyle.width = itemWidth;
	    }
	    if (adjustMarginRight) {
	        stepItemStyle.marginRight = adjustMarginRight;
	    }

	    const accessibilityProps: Pick<StepProps, 'onClick' | 'fieldid'> = {}
	    if (isOnchange) {
	        accessibilityProps.onClick = this.click;
	    }
	    if (this.props.fieldid || stepFieldId) {
	        accessibilityProps.fieldid = this.props.fieldid ?? `${stepFieldId}_steps_icon_${stepIndex}`;
	    }

	    const subTitleDom = subTitle ? (<div className={`${clsPrefix}-item-subtitle`} title={this.isValidNode(subTitle)}>{subTitle}</div>) : '';


	    if (type && type === 'arrow') {
	        return (
	            <div
	                {...omit(restProps, ["iconPrefix", "progressDot", "icons"])}
	                className={classString}
	                style={stepItemStyle}
	                data-text={this.isValidNode(title)}
	                data-num={stepIndex}
	                data-status={disabled}
	                onClick={onClick}
	                {...accessibilityProps}
	            >
	                <div className={`${clsPrefix}-item-number`}>
	                    {stepNumber}
	                </div>
	                <div className={`${clsPrefix}-item-content`}>
	                    <div className={`${clsPrefix}-item-title`}>
	                        <span title={this.isValidNode(title)}>{title}{subTitleDom}</span>
	                        {this.renderIconNode()}
	                    </div>
	                    {description &&
							<div className={`${clsPrefix}-item-description`} title={this.isValidNode(description)}>{description}</div>}
	                </div>
	            </div>
	        )
	    }

	    return (
	        <div
	            {...omit(restProps, ["iconPrefix", "progressDot", "icons"])}
	            className={classString}
	            style={stepItemStyle}
	            data-text={this.isValidNode(title)}
	            data-num={stepIndex}
	            data-status={disabled}
	            onClick={onClick}
	            {...accessibilityProps}
	        >
	            <div className={`${clsPrefix}-item-tail`}>
	                {tailContent}
	            </div>
	            <div className={`${clsPrefix}-item-icon`}>
	                {this.renderIconNode()}
	            </div>
	            <div className={`${clsPrefix}-item-content`}>
	                <div className={`${clsPrefix}-item-title`} title={this.isValidNode(title)}>
	                    {title}{subTitleDom}
	                </div>
	                {description && <div className={`${clsPrefix}-item-description`}>{description}</div>}
	            </div>
	            {more && (<div className={`${clsPrefix}-item-line`}></div>)}
	        </div>
	    );
	}
}

/* Step.propTypes = {
    className: PropTypes.string,
    clsPrefix: PropTypes.string,
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
    itemWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    status: PropTypes.string,
    iconPrefix: PropTypes.string,
    icon: PropTypes.node,
    adjustMarginRight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    stepNumber: PropTypes.string,
    description: PropTypes.any,
    title: PropTypes.any,
    progressDot: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.func,
    ]),
    tailContent: PropTypes.any,
    icons: PropTypes.shape({
        finish: PropTypes.node,
        error: PropTypes.node,
    }),
    onClick: PropTypes.func,
    onStepClick: PropTypes.func,
    stepIndex: PropTypes.number,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    more: PropTypes.bool,
    out: PropTypes.bool,
    type: PropTypes.oneOf(['default', 'number', 'dot', 'arrow']),
    fieldid: PropTypes.string,
    stepFieldId: PropTypes.string
}; */

export default Step;
