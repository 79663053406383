export default {
    locale: 'zh-cn',
    today: '今天',
    now: '此刻',
    backToToday: '返回今天',
    ok: '确定',
    timeSelect: '选择时间',
    dateSelect: '选择日期',
    weekSelect: '选择周',
    clear: '清除',
    month: '月',
    year: '年',
    previousMonth: '上个月',
    nextMonth: '下个月',
    monthSelect: '选择月份',
    yearSelect: '选择年份',
    decadeSelect: '选择年代',

    firstHalfYear: '上半年',
    secondHalfYear: '下半年',

    yearFormat: 'YYYY年',
    quarterFormat: 'Q季度',
    // monthFormat: 'M月',
    dayFormat: 'D日',
    dateFormat: 'YYYY年M月D日',
    dateTimeFormat: 'YYYY年M月D日 HH时mm分ss秒',
    // /** Display month before year in date panel header */
    // monthBeforeYear?: boolean,
    // shortWeekDays?: string[],
    // shortMonths?: string[],
    previousYear: '上一年',
    nextYear: '下一年',
    previousDecade: '上一年代',
    nextDecade: '下一年代',
    previousCentury: '上一世纪',
    nextCentury: '下一世纪'
};
